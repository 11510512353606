import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import _ from "lodash";

import DownArrow from "../../../../assets/icons/DownArrow";
import Title from "../../../../components/common/Title";
import { getGuestTraffic } from "../../../../apis";
import { useUser } from "../../../../Store/User";
import DownloadOptions from "../EventFavouriteList/Components/DownloadOptions/";
import LightroomPopUp from "../EventFavouriteList/Components/LightroomPopUp";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const Traffic = ({ eventId, setRef, selectedFavList, setSelectedFavList }) => {
  const { user } = useUser();
  const photographer = user.photographer;
  const [guestFilterOptions, setGuestFilterOptions] = useState(false);
  const guestFilters = ["Lifetime", "This Month", "This Week"];
  const [guestFilter, setGuestFilter] = useState("Lifetime");
  const [guestTraffic, setGuestTraffic] = useState([]);
  const [showOption, setShowOption] = useState(false);
  const [showLightroom, setShowLightroom] = useState(false);
  const [lightRoomData, setLightRoomData] = useState(""),
    [isLoading, setIsLoading] = useState(true),
    [hasError, setHasError] = useState(false),
    [filteredGuestTraffic, setFilteredGuestTraffic] = useState([]),
    [guestSearch, setGuestSearch] = useState("");

  useEffect(async () => {
    if (eventId) {
      updateData(guestFilter);
    }
  }, []);

  function fetchGuestData(startDate, endDate) {
    setIsLoading(true);
    setHasError(false);
    getGuestTraffic(startDate, endDate, eventId)
      .then((response) => {
        setGuestTraffic(response.data);
        setFilteredGuestTraffic(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        setIsLoading(false);
      });
  }

  const guestFilterToggle = (e) => {
    setGuestFilterOptions(!guestFilterOptions);
  };

  const guestFilterClicked = (e) => {
    setGuestFilter(e);
    updateData(e);
  };

  let filterDorpDownContainer = useRef();
  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!filterDorpDownContainer?.current?.contains(event.target)) {
        setGuestFilterOptions(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  useEffect(() => {
    if (!_.isEmpty(guestTraffic) && !_.isEmpty(guestSearch)) {
      let filterByEmail = guestTraffic.filter((item) =>
        item.email.toLowerCase().includes(guestSearch.toLowerCase())
      );

      let combinedList = filterByEmail;
      let set = new Set(combinedList);
      setFilteredGuestTraffic(Array.from(set));
    } else {
      setFilteredGuestTraffic(guestTraffic);
    }
  }, [guestSearch]);

  useEffect(() => {
    setFilteredGuestTraffic(guestTraffic);
  }, [guestTraffic]);

  const updateData = (timeline) => {
    switch (timeline) {
      case "Lifetime":
        fetchGuestData(null, null);
        break;
      case "This Week":
        let start = moment().startOf("week").toDate();
        let end = moment().endOf("week").toDate();
        fetchGuestData(
          moment(start).format("DD MMM YYYY"),
          moment(end).format("DD MMM YYYY")
        );
        break;
      case "This Month":
        let startM = moment().startOf("month").toDate();
        let endM = moment().endOf("month").toDate();
        fetchGuestData(
          moment(startM).format("DD MMM YYYY"),
          moment(endM).format("DD MMM YYYY")
        );
        break;
      default:
    }
  };

  function downloadAsCSV(divEle, separator = ",") {
    let filename = divEle;
    let div = document.getElementById(divEle);
    let csvRows = [],
      header = ["fn", "ln", "email", "phone", "listName", "Fi"];
    csvRows.push(header.join(separator));
    Array.from(div.children).forEach(function (node) {
      //using scope to only get direct div of node
      let csvLine = [];
      //again scope to only get direct children
      node.querySelectorAll(":scope > div.export").forEach(function (td) {
        //clone as to not remove anything from original
        let copytd = td.cloneNode(true),
          data;
        if (copytd.dataset.val) {
          data = copytd.dataset.val.replace(/(\r\n|\n|\r)/gm, "");
        } else {
          Array.from(copytd.children).forEach(function (remove) {
            //remove nested elements before getting text
            remove.parentNode.removeChild(remove);
          });
          data = copytd.textContent.replace(/(\r\n|\n|\r)/gm, "");
        }
        data = data.replace(/(\s\s)/gm, " ").replace(/"/g, '""');
        csvLine.push('"' + data.trim() + '"');
      });
      csvRows.push(csvLine.join(separator));
    });
    var a = document.createElement("a");
    a.style = "display: none; visibility: hidden"; //safari needs visibility hidden
    a.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
  const { items, requestSort, sortConfig } =
    useSortableData(filteredGuestTraffic);

  /*const url = /!*( photographer.branding_allowed && photographer.domain_name ) ?'https://'+ photographer.domain_name :  *!/'https://' + photographer.subDomain + '.' + process.env.REACT_APP_URL;*/
  return (
    <div ref={setRef}>
      {!_.isEmpty(eventId) ? (
        <div className="pb-24" id="favourites">
          <Title
            className="pb-4 border-zinc-700 border-b mt-10 font-medium"
            text="Favourite Lists"
          />
          <div className="flex justify-between ml-2 mt-6">
            {/* <div className='text-lg'>Total Favourite Lists
                        <span
                            className='block text-sm'>{guestTraffic.length ? guestTraffic.length + ' favourite lists' : ''}</span>
                    </div> */}
            <div className="relative">
              <i className="absolute top-3 left-4">
                <svg
                  className="w-4 h-4"
                  fill="#eee"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </i>
              <input
                value={guestSearch}
                onChange={(e) => setGuestSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                size={31}
                className={`bg-black text-sm h-10 px-12 rounded-lg`}
                name="search"
              />
            </div>
            <div className="flex justify-end items-center">
              <div
                onClick={guestFilterToggle}
                ref={filterDorpDownContainer}
                className="flex relative items-center cursor-pointer text-sm shadow-button rounded-lg p-2 px-3 whitespace-nowrap"
              >
                {guestFilter}
                <span className="pl-2">
                  <DownArrow rotate={guestFilterOptions ? true : false}>
                    {" "}
                  </DownArrow>
                </span>
                {guestFilterOptions && (
                  <div className="absolute rounded-[10px] overflow-hidden bg-secondary-800 shadow-card z-40 w-[200px] right-0 top-[95%] mt-3">
                    <div className="flex select-none flex-col  text-light-gray text-sm">
                      {guestFilters.map(function (guest, index) {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              guestFilterClicked(guest);
                              guestFilterToggle();
                            }}
                            className="cursor-pointer capitalize px-4 py-3 text-sm hover:bg-black hover:text-primary-green"
                          >
                            {guest}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3">
              <h1 className="text-center">Loading...</h1>
            </div>
          ) : filteredGuestTraffic.length > 0 ? (
            <div className="items-center">
              <div
                className={`flex items-start 3xl:items-center 4xl:items-center grid grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3`}
              >
                <div className="col-span-2">
                  <div className="flex items-center gap-x-2 col-span-2">
                    <span>Email</span>
                    <img
                      onClick={() => requestSort("email")}
                      src="/sortIcon.webp"
                      className="w-3 h-3  cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="flex items-center gap-x-2 col-span-2 justify-center">
                    <span>List Name</span>
                    <img
                      onClick={() => requestSort("listName")}
                      src="/sortIcon.webp"
                      className="w-3 h-3  cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
                <div className=" col-span-2">
                  <div className="flex items-center gap-x-2 col-span-2 justify-center">
                    <span>No. of Images</span>
                    <img
                      onClick={() => requestSort("images")}
                      src="/sortIcon.webp"
                      className="w-3 h-3  cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-center col-span-2">
                  <div className="flex items-center gap-x-2 col-span-2 justify-center">
                    <span>Actions</span>
                  </div>
                </div>
              </div>
              <div
                id="Guest_list"
                className="h-[50vh] overflow-y-auto sm:overflow-x-hidden"
              >
                {!_.isEmpty(guestTraffic)
                  ? Object.entries(_.groupBy(items, "email")).map(function (
                      [email, guest],
                      index
                    ) {
                      return (
                        <div
                          className="flex grid auto-cols-max grid-cols-8 gap-4 ml-2 mt-3 border-b border-zinc-700 text-sm p-3"
                          key={email + index}
                        >
                          <div
                            title={email}
                            className="export col-span-2 truncate"
                          >
                            {email}
                          </div>
                          <div className="export col-span-2 text-center truncate">
                            {guest.map((value, index) => {
                              return (
                                <div key={value.listName} className="pb-5 break-words">
                                  {value.listName}
                                </div>
                              );
                            })}
                          </div>
                          <div className="export col-span-2 text-center break-words">
                            {guest.map((value, index) => {
                              return (
                                <div key={value.images} className="pb-5">
                                  {value.images}
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex flex-col col-span-2 text-center justify-start items-center gap-y-4">
                            {guest.map((value, index) => {
                              return (
                                <div
                                  key={index}
                                  className="text-left relative cursor-pointer"
                                >
                                  <DownloadOptions
                                    guestTraffic={guestTraffic}
                                    setGuestTraffic={setGuestTraffic}
                                    eventId={eventId}
                                    totalImages={value.images}
                                    currentFavList={value.id}
                                    showLightroom={showLightroom}
                                    setShowLightroom={setShowLightroom}
                                    lightRoomData={lightRoomData}
                                    setLightRoomData={setLightRoomData}
                                    setSelectedFavList={setSelectedFavList}
                                    favListName={value.listName}
                                    selectedFavList={selectedFavList}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : (
            <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3">
              <h1 className="text-center">
                No list found for {guestFilter.toLowerCase()}
              </h1>
            </div>
          )}

          {/* <div className='flex justify-between mt-10 ml-2 w-full'>
                            <div className='w-1/2'>
                                <div className='flex items-center justify-between'>
                                    <div className='text-lg'>Most Used Browser</div>
                                    <div className='flex items-center p-2 px-4 text-sm dark:shadow-dark-input mr-10 cursor-pointer'>This Week<span className='ml-2 inline-block'><DownArrow /></span></div>
                                </div>
                                <div className='mt-4'><img src={MostUsedBrowser} /></div>
                            </div>
                            <div className='w-1/2'>
                                <div className='flex items-center justify-between'>
                                    <div className='text-lg'>Sources By Visits</div>
                                    <div className='flex items-center p-2 px-4 text-sm dark:shadow-dark-input cursor-pointer'>This Week<span className='ml-2 inline-block'><DownArrow /></span></div>
                                </div>
                                <div className='mt-4'><img src={SourcesByVisit} /></div>
                            </div>
                        </div> */}
        </div>
      ) : (
        ""
      )}
      {showLightroom && (
        <LightroomPopUp
          showLightroom={showLightroom}
          setShowLightroom={setShowLightroom}
          lightRoomData={lightRoomData}
          setLightRoomData={setLightRoomData}
        />
      )}
    </div>
  );
};

export default Traffic;
