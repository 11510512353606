import React, { useCallback } from "react";
import { updateFolderSort } from "../../../../../apis";
import { useEvent } from "../../../../../Store/Event/Show";
import { useFolder } from "../../../../../Store/Folder/Show";
import "./sortingOptions.css";

const SortingOptions = ({setShowSortOptions,setCurrentSubPage,imageCount}) => {
    const {event,setEvent} = useEvent()
    const {folder,setImageSortFields,setImageSortCond,imageSortCond,imageSortField,setImages} = useFolder()
    const handleFilters = useCallback(async (sortBy,sort)=>{
        if(imageCount>0 && (sortBy !== imageSortField || sort != imageSortCond) ){
            let res = await updateFolderSort(folder,sort,sortBy)
            setEvent({...event,folders:res.data})
            setCurrentSubPage("Loading");
            setShowSortOptions(false);
            setImages(null);
            setImageSortFields(sortBy);
            setImageSortCond(sort)
        }
    },[imageCount])
    return (
        <div className='absolute light:bg-theme-light bg-dark-gray light:shadow-light-input shadow-dark-input w-[200px] right-[0px] top-[30px] rounded-xl z-40 mt-3 overflow-hidden'>
        {/* // <div className='absolute p-4 bg-theme-dark  shadow-dark-input  w-[200px] right-[0px] top-[30px] z-40'> */}
            <div className='flex flex-col  text-light-gray text-sm'>
                <div className='disabled-view pt-4 pb-1 px-4'>Date Created</div>
                <div
                    onClick={()=>{handleFilters('image_date','asc')}} 
                    className={`cursor-pointer px-4 py-3 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='asc' && imageSortField==='image_date'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Old - New</div>
                <div
                    onClick={()=>{handleFilters('image_date','desc')}} 
                    className={`cursor-pointer px-4 py-3 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='desc' && imageSortField==='image_date'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>New - Old</div>
                <div className='border-t border-zinc-700 px-10 h-1 '></div>
                <div className='disabled-view px-4 py-3'>File Name</div>
                <div
                    onClick={()=>{handleFilters('og_image_name','asc')}} 
                    className={`cursor-pointer px-4 py-3 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='asc' && imageSortField==='og_image_name'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>A - Z</div>
                <div
                    onClick={()=>{handleFilters('og_image_name','desc')}} 
                    className={`cursor-pointer px-4 py-3 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='desc' && imageSortField==='og_image_name'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Z - A</div>

                <div className='border-t border-zinc-700 px-10 h-1 '></div>
                <div className='disabled-view px-4 py-3'>File Size</div>
                <div
                    onClick={()=>{handleFilters('size_mb','asc')}} 
                    className={`cursor-pointer px-4 py-3 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='asc' && imageSortField==='size_mb'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Smallest - Largest</div>
                <div
                    onClick={()=>{handleFilters('size_mb','desc')}} 
                    className={`cursor-pointer pt-3 pb-4 px-4 ${imageCount<=0?'disabled-view cursor-not-allowed hover:bg-transparent':'hover:text-cyan-500 hover:bg-black'} ${imageSortCond==='desc' && imageSortField==='size_mb'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Largest - Smallest</div>
            </div>
        </div>
    );
};

export default SortingOptions;
