import React, { useState, useEffect, useRef } from "react";

import Title from "../../../../components/common/Title";
import Text from "../../../../components/common/Text";
import Checkbox from "../../../../components/common/Checkbox";
import {
  InputEmail,
  InputText,
  InputEditable,
} from "../../../../components/common/Input";
import Select from "../../../../components/common/Select";
import SwitchWithTitle from "../../../GeneralSettings/components/SwitchWithTitle";
import RadioButtonSmall from "../../../../components/common/RadioButtonSmall";
import EnableButton from "../../../GeneralSettings/components/EnableButton/Index";
import DownArrow from "../../../../assets/icons/DownArrow";
import RadioButton from "../../../../components/common/RadioButton";

import { useEvent } from "../../../../Store/Event/Show";
import { useUser } from "../../../../Store/User";

import _, { isNull } from "lodash";
import {
  updateDownloadSettings,
  updateSingleDownloadSettings,
  restrictEmailForEvent,
  getEventGuestEmail,
  handleFolderDownload,
  updateEmailRestriction,
  updateFolderRestrictions,
  getFoldersOfEvent,
  updateDigitalDownloads,
} from "../../../../apis";

import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import BigTick from "../../../../assets/icons/BigTick";
import PlusIcon from "../../../../assets/icons/PlusIcon";

const DigitalDownloadSetting = ({
  isBulkDownloadEnable,
  setIsBulkDownloadEnable,
  isSingleDownloadEnable,
  setIsSingleDownloadEnable,
  shareImage,
  setShareImage,
}) => {
  const singleDownloadSizes = ["optimized", "original"];
  const bulkDownloadSizes = ["optimized", "original"];
  const { event, setEvent } = useEvent();
  let { user } = useUser();

  const [chargeDigitalDownload, setChargeDigitalDownload] = useState(false);

  const [success, setSuccess] = useState({ email: false });
  const [currency, setCurrency] = useState("inr");
  const [selectCurrency, setSelectCurrency] = useState(false);
  const [error, setError] = useState({ email: false });
  // TODO: From the API get the number of rows
  // const [priceRows , setPriceRows] = useState([1])
  const [priceRows, setPriceRows] = useState([
    { quantity: "1", amount: "100" },
  ]);

  let currencyDropDown = useRef();

  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!currencyDropDown?.current?.contains(event.target)) {
        setSelectCurrency(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  //setting state for single image
  useEffect(() => {
    if (!_.isEmpty(event)) {
      setChargeDigitalDownload(event.isDownloadChargeable);
      if (!_.isNull(event.downloadPricing)) {
        setPriceRows(event.downloadPricing.price);
        setCurrency(event.downloadPricing.currency == "dollar" ? "USD" : "INR");
      }
    }
  }, [event]);

  // console.log('event', event.downloadPricing);

  //function to toggle currency dropdown
  function toggleCurrencyDropdown() {
    setSelectCurrency(!selectCurrency);
  }

  //function called with Digital Download is disabled
  function offDigitalDownload() {
    chargeDigitalDownload(false);
    updateDigitalDownloads(event, 0)
      .then(function (response) {})
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Bulk download settings could not be updated",
        });
        //window.location.reload()
        // chargeDigitalDownload(true);
      });
  }

  function handlePriceChange(e, index) {
    const { name, value } = e.target;
    const list = [...priceRows];
    list[index][name] = value;
    setPriceRows(list);
  }

  function updatePrice(status) {
    if (status) {
      if (event.watermarkId == null) {
        window.Toast.fire({
          icon: "error",
          title: "Event Should have watermark",
        });
        return;
      }

      setIsBulkDownloadEnable(false);
      setIsSingleDownloadEnable(false);
      setShareImage(false);
    }
    setChargeDigitalDownload(status);
    const priceArray = JSON.stringify(priceRows, null, 2);
    const price = JSON.parse(priceArray, null, 2);

    updateDigitalDownloads(event.id, status, currency, price)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: "Digital Download Setting Updated",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error in updating frame price",
        });
        setChargeDigitalDownload(!status);
      });
  }

  const increasePriceRow = () =>
    setPriceRows((prev) => [...prev, { quantity: "", amount: "" }]);

  const removePrice = (i) => {
    const priceRowsCopy = [...priceRows];
    priceRowsCopy.splice(i, 1);
    setPriceRows(priceRowsCopy);
  };
  return (
    <>
      <Title
        className="pb-4 mb-6 border-b border-secondary-600 mt-4"
        text="Paid Digital Download Settings"
      />

      {/* <Text as='p' className='text-secondary-300 text-xl'>
                    Download images
                </Text> */}

      <div className="flex flex-wrap 2xl:flex-nowrap gap-x-32 gap-y-8 mt-8">
        <div className="flex flex-col basis-1/2">
          <SwitchWithTitle
            className="mb-8 font-medium"
            title="Enable Digital Download"
            subtitle={"Turn on to allow your clients to pay for"}
            value={chargeDigitalDownload}
            onChange={() => {
              chargeDigitalDownload ? updatePrice(false) : updatePrice(true);
            }}
          />
        </div>

        {chargeDigitalDownload ? (
          <>
            <div className="flex flex-col basis-1/2">
              <div className="flex basis-1/2">
                <Text as="p" className="font-medium">
                  Currency
                </Text>
              </div>
              <div className="flex w-full" ref={currencyDropDown}>
                <div className="w-full mb-[50px]">
                  <div className="relative max-w-[28rem]">
                    <Select
                      onClick={() => toggleCurrencyDropdown()}
                      text={
                        currency
                          ? ["dollar", "USD"].includes(currency)
                            ? "USD"
                            : "INR"
                          : "Select Currency"
                      }
                      className="mt-4"
                      isOpen={selectCurrency}
                    />
                    {selectCurrency && (
                      <div className="absolute w-full rounded-[10px] overflow-hidden bg-dark-gray shadow-card z-40 right-15 mt-3 top-[99%]">
                        <div className="flex select-none flex-col text-light-gray text-sm">
                          <div
                            onClick={() => {
                              setCurrency("inr");
                              setSelectCurrency(!selectCurrency);
                            }}
                            className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                          >
                            INR
                          </div>
                          <div
                            onClick={() => {
                              setCurrency("dollar");
                              setSelectCurrency(!selectCurrency);
                            }}
                            className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                          >
                            USD
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-x-4">
                <Text as="p" className="basis-1/2 text-sm ">
                  Quantity
                </Text>
                <Text as="p" className="basis-1/2 text-sm ">
                  Price
                </Text>
              </div>

              {priceRows.map((el, i) => (
                <div
                  key={el + i}
                  className="flex basis-1/2 gap-x-4 mt-4 items-center"
                >
                  <div
                    className={`flex justify-between items-center pr-5 rounded-[10px] bg-dark-gray mt-1 text-light-gray shadow-input-empty w-full`}
                  >
                    <input
                      className="pl-4 bg-transparent py-2 placeholder:text-secondary-500 placeholder:text-sm outline-none w-full text-light-gray text-sm "
                      name="quantity"
                      onChange={(e) => handlePriceChange(e, i)}
                      value={el.quantity}
                    />
                  </div>
                  <div
                    className={`flex justify-between items-center pr-5 rounded-[10px] bg-dark-gray mt-1 text-light-gray shadow-input-empty w-full`}
                  >
                    <input
                      className="pl-4 bg-transparent py-2 placeholder:text-secondary-500 placeholder:text-sm outline-none w-full text-light-gray text-sm"
                      name="amount"
                      onChange={(e) => handlePriceChange(e, i)}
                      value={el.amount}
                    />
                  </div>
                  <button
                    onClick={i === 0 ? increasePriceRow : () => removePrice(i)}
                    className="shadow-card h-8 w-8 px-2"
                  >
                    {i === 0 ? (
                      <PlusIcon width={16} height={16} />
                    ) : (
                      <span className="text-2xl">-</span>
                    )}
                  </button>
                </div>
              ))}

              <div className="flex justify-end mt-8">
                <button
                  onClick={() => {
                    updatePrice(true);
                  }}
                  className="text-sm text-center text-light-gray shadow-card rounded-[10px] py-[9px] px [16px] font-semibold w-[8rem] h-[2.5rem]"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col basis-1/2"></div>
        )}
      </div>
    </>
  );
};
export default DigitalDownloadSetting;
