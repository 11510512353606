import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { InputEmail, InputText } from "../../components/common/Input";
import Button from "../../components/common/Button";
import { changePassword } from "../../apis";
import { useUser } from "../../Store/User";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { loginUser } = useUser();

  const queryParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState(queryParams.get("email"));
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState({});

  const [buttonDisable, setButtonDisable] = useState(true);

  const token = useRef(queryParams.get("token"));

  useEffect(() => {
    if (email && password && confirmPassword) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [email, password, confirmPassword]);

  const handlePassword = (e) => {
    if (!email) {
      setErrors({ ...errors, email: "Email is required" });
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrors({ ...errors, email: "Email is invalid" });
      return;
    } else if (!email) {
      setErrors({ ...errors, password: "Password is required" });
      return;
    } else if (!confirmPassword) {
      setErrors({ ...errors, password: "Confirm Password is required" });
      return;
    } else if (password !== confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Confirm password doesn't match with password",
      });
      return;
    }

    if (email && password && confirmPassword && token) {
      changePassword(email, password, token, confirmPassword)
        .then(async function () {
          window.Toast.fire({
            icon: "success",
            title: "Password reset Successful",
          });
          try {
            await loginUser(email, password);
            navigate("/");
          } catch (err) {
            window.Toast.fire({
              icon: "error",
              title: "Login Failed",
            });
          }
        })
        .catch((error) => {
          window.Toast.fire({
            icon: "error",
            title: "Something went wrong",
          });
          console.log(error)
          if (error.response?.data?.message.includes("password")) {
            setErrors({
              ...errors,
              password: error.response.data.message,
            });
          }
          if (error.response?.data?.message.includes("token")) {
            setErrors({
              ...errors,
              email: error.response?.data?.message
            });
          }
        });
    }
  };

  return (
    <>
      {token.current ? (
        <div className="flex items-center align-middle min-h-[calc(100vh_-_80px)]">
          <div className="container mx-auto max-w-screen-lg xl:max-w-screen-xl px-10">
            <div className="grid grid-cols-1 xl:grid-cols-1 flex items-center sm:w-[50%] sm:m-auto">
              <div className="boxShadow lg:m-5 lg:p-8 rounded-lg shadow-button py-[9px] px-[16px] text-center">
                <h1 className="text-secondary-300 text-2xl font-medium text-center">
                  Forgot Password?
                </h1>
                <div className="mt-5 max-w-[28rem] mx-auto">
                  <div className="flex flex-col pb-4">
                    <InputEmail
                      value={email}
                      label="Email ID"
                      onChange={(e) => {
                        setEmail(e);
                        setErrors({ ...errors, email: null });
                      }}
                      error={errors.email}
                      readOnly={true}
                    />
                  </div>
                  <div className="flex flex-col pb-4">
                    <InputText
                      onChange={(e) => {
                        setPassword(e);
                        setErrors({ ...errors, password: null });
                      }}
                      value={password}
                      label="Password"
                      type="password"
                      error={errors.password}
                      autoFocus={true}
                    />
                  </div>
                  <div className="flex flex-col pb-4">
                    <InputText
                      onChange={(e) => {
                        setConfirmPassword(e);
                        setErrors({ ...errors, confirmPassword: null });
                      }}
                      value={confirmPassword}
                      label="Confirm Password"
                      type="password"
                      error={errors.confirmPassword}
                    />
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <Button
                    disabled={buttonDisable}
                    onClick={(e) => {
                      handlePassword(e);
                    }}
                    text="Submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ChangePassword;
