import React from "react";
import Facebook from "../../../../assets/images/Facebook-icon.png";
import Instagram from "../../../../assets/images/Instagram-icon.png";
import Twitter from "../../../../assets/images/Twitter-icon.png";
import Linkedin from "../../../../assets/images/Linkedin-icon.png";
import Mail from "../../../../assets/images/Mail-icon.png";
import "./emailTemplateGradient.css";
import QRCode from "qrcode.react";

const EmailTemplateGradient = ({
  mailData,
  photographer,
  accessType,
  url,
  isPinEnabled,
}) => {
  return (
    <div>
      <div className="shadow-dark-input rounded-md mb-10">
        <div className="flex flex-rows justify-between items-center p-6">
          <div className="text-sm">{photographer.email.toLowerCase()}</div>
          <div>
            <ul className="flex flex-row justify-center">
              {photographer.facebook && photographer.branding_allowed ? (
                <li className="px-2">
                  <a target="_blank" href={photographer.facebook}>
                    <img src={Facebook} width="20" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.insta && photographer.branding_allowed ? (
                <li className="px-2">
                  <a target="_blank" href={photographer.insta}>
                    <img src={Instagram} width="20" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.twitter && photographer.branding_allowed ? (
                <li className="px-2">
                  <a target="_blank" href={photographer.twitter}>
                    <img src={Twitter} width="20" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.linkedin && photographer.branding_allowed ? (
                <li className="px-2">
                  <a target="_blank" href={photographer.linkedin}>
                    <img src={Linkedin} width="20" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.email && photographer.branding_allowed ? (
                <li className="px-2">
                  <a target="_blank" href={`mailto:${photographer.email}`}>
                    <img src={Mail} width="20" />
                  </a>{" "}
                </li>
              ) : null}
            </ul>
          </div>

          {photographer.url ? (
            <div className="text-sm">
              <a target="_blank" href={photographer.url}>
                {photographer.url.replace("//", "")}
              </a>
            </div>
          ) : null}
        </div>
        <div className="py-14 px-36 gradient_bg">
          <h1 className="text-center text-3xl font-bold">
            {mailData.event_name}
          </h1>
          <div className="relative flex items-center mt-4">
            <div className="flex-grow border-t border-dashed"></div>
            <span className="flex-shrink mx-4 text-base">{mailData.date}</span>
            <div className="flex-grow border-t border-dashed"></div>
          </div>
          <div className="text-base mt-10">
            <h1 className="text-2xl block pb-6 font-bold">Hi,</h1>
            <p className="whitespace-pre-wrap mb-4">{mailData.message}</p>
          </div>

          <div className="flex justify-center p-8">
            <a
              target="_blank"
              className="rounded-lg -button dark:shadow-button py-[9px] px-[16px] dark:text-slate-100 font-semibold bg-black"
              href={url}
            >
              {accessType
                ? mailData.published
                  ? "Find Photos"
                  : "Register"
                : "View Photos"}
            </a>
          </div>
          <div className="text-center">
            {accessType ? (
              <>
                <div className="flex justify-center my-3">
                  <QRCode
                    bgColor={"#1a1a1a"}
                    fgColor={"#aaaaaa"}
                    id="qrCodeEl"
                    className="p-3 border-[1px]  rounded-2xl border-[#00b7b8]"
                    size={150}
                    value={url}
                  />
                </div>
                {isPinEnabled ? (
                  <>
                    <p className="text-sm">
                      Use this four digit pin when asked -
                    </p>
                    <h1 className="text-2xl block font-bold">
                      PIN: {mailData.pin}
                    </h1>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              mailData.password_enabled &&
              mailData.password_text && (
                <div>
                  <p className="block text-sm pt-4">
                    Use this password when asked-
                  </p>
                  <h1 className="text-2xl block font-bold">
                    Password: {mailData.password_text}
                  </h1>
                </div>
              )
            )}
          </div>
        </div>
        <div className="p-8">
          <img
            className="max-w-none w-full rounded-xl"
            src={mailData.banner_image}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateGradient;
