import { useEffect } from "react"

const AddAddress = ()=>{


    useEffect(()=>{
        window.location = "//payments." + process.env.REACT_APP_URL + "/address";
    },[])

    return (
    <></>
    )
}

export default AddAddress