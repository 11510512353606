import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "./components/SelectedImage";
import Fancybox from "../../../../components/common/Fancybox";
import { useFolder } from "../../../../Store/Folder/Show";
import Bulkactions from "../../../../components/Event/Popups/Bulkactions";
import _ from "lodash";

const GalleryTileView = ({
  tileSize,
  setShowMoveFolder,
  selectedImages,
  setSelectedImages,
  eventId,
  slug,
}) => {
  const { images } = useFolder();

  const FancyboxOptions = {
    infinite: false,
    autoFocus: true,
    preload: 2,
    images: { images },
    Toolbar: {
      display: ["zoom", "slideshow", "close"],
    },
    Thumbs: {
      autoStart: false,
    },
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo, margin, direction }) => (
      <SelectedImage
        key={key}
        direction={direction}
        margin={margin}
        index={index}
        photo={photo}
        left={left}
        top={top}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />
    ),
    [selectedImages, images]
  );
  useEffect(() => {
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style>.react-photo-gallery--gallery{margin:-${
        tileSize + ((tileSize + 1) * 2 + 1)
      }px}</style>`
    );
  }, [tileSize]);
  return (
    <>
      {!_.isNull(images) && images.length > 0 ? (
        <div className="w-full mx-auto p-[20px] md:p-[0px] m-4">
          <Fancybox options={FancyboxOptions}>
            <Gallery
              photos={images}
              direction={"column"}
              columns={Number(tileSize + 5 - tileSize * 2)}
              margin={tileSize + ((tileSize + 1) * 2 + 1)}
              renderImage={imageRenderer}
            />
          </Fancybox>
        </div>
      ) : (
        <></>
      )}

      {selectedImages.length > 0 ? (
        <Bulkactions
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          setShowMoveFolder={setShowMoveFolder}
          eventId={eventId}
          slug={slug}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default GalleryTileView;
