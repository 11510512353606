/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState,useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DownArrow from "../../../../assets/icons/DownArrow";
import {useEventIndex} from "../../../../Store/Event/Index";
import _ from 'lodash';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example({sortEvents}) {
  const {events, isLoaded, hasError} = useEventIndex();
  const [selected, setSelected] = useState("Sort By")
  const [tag, setTag] = useState([]);

  useEffect(async () => {
    let tag = !_.isEmpty(events) ? events.flatMap(function (event, key) {
        return event.tags;
    }) : [];
    setTag(_.uniq(tag));
}, [events]);

  const onOptionChange = (e) => {
    setSelected(e);
    if(e == "Old - New"){
      sortEvents("oldest");
    }
    if(e == "New - Old"){
      sortEvents("latest");
    }
    if (e == "A - Z"){
      sortEvents("a-z");
    }
    if (e == "Z - A"){
      sortEvents("z-a");
    }
  }



  return (
    <Listbox value={selected} onChange={(e)=>{onOptionChange(e)}}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative">

            <Listbox.Button className="flex relative items-center text-sm shadow-card py-2 px-4 whitespace-nowrap text-colors-secondary-300 w-[6.56rem] h-[1.875rem] rounded-xl ">
              <span className="flex items-center truncate">
                <span className="block">{selected}</span>
              </span>
              <span className="inset-y-0 right-0 flex items-center pointer-events-none ml-3">
                <DownArrow className=" h-3 w-3  text-gray-400" aria-hidden="true" rotate={open ? true : false } />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >

              <Listbox.Options className="absolute z-50 mt-[0.93rem] pt-5 w-[11rem] bg-dark-gray shadow-dark-input rounded-xl r-2 top-[95%] text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">

              <Listbox.Option
                    className={({ active }) =>
                      classNames(
                        'cursor-default select-none relative pl-5 disabled-view text-sm'
                      )
                    }
                    value={"Date"}
                    disabled={true}

                  >
                  Date



              </Listbox.Option>

              <Listbox.Option

                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary-green bg-black' : 'text-secondary-300',
                        'cursor-pointer select-none relative pl-5 py-2 '
                      )
                    }
                    value={"New - Old"}

                  >

                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'text-primary-green' : 'font-normal', 'block truncate')}
                          >
                            New - Old
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
              </Listbox.Option>

              <Listbox.Option

                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary-green bg-black ' : 'text-secondary-300',
                        'cursor-pointer select-none relative py-2 pl-5 '
                      )
                    }
                    value={"Old - New"}

                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'text-primary-green' : 'font-normal', 'block truncate')}
                          >
                            Old - New
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
              </Listbox.Option>

              <hr className='border-zinc-700 my-3 mx-2' />
              <Listbox.Option
                    className={({ active }) =>
                      classNames(
                        'cursor-default select-none relative pl-5 disabled-view'
                      )
                    }
                    value={"Name"}
                    disabled={true}

                  >
                  Event Name



              </Listbox.Option>

              <Listbox.Option

                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary-green bg-black' : 'text-secondary-300',
                        'cursor-pointer select-none relative py-2 pl-5'
                      )
                    }
                    value={"A - Z"}

                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'text-primary-green' : 'font-normal', 'block truncate')}
                          >
                            A - Z
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
              </Listbox.Option>

              <Listbox.Option

                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary-green bg-black' : 'text-secondary-300',
                        'cursor-default select-none relative py-2 pl-5'
                      )
                    }
                    value={"Z - A"}

                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(selected ? 'text-primary-green' : 'font-normal', 'block truncate')}
                          >
                            Z - A
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
              </Listbox.Option>





              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
