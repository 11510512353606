import React, { useState,useEffect } from "react";
import Text from "../Text";
import checkedsvg from "../../../assets/images/checked.jpeg";
import _ from "lodash"

function Checkbox(props) {
    const {
        className,
        checked = false,
        desc="",
        id = "",
        label = "",
        name = "",
        disabled = false,
        onChange = () => {},
        value = "",
        removeDisable
    } = props;
    const [isChecked, setChecked] = useState(value);
    const [isUpdated, setUpdated] = useState(false);

    const changeHandler = val => {
        if(removeDisable) onChange(val.target.checked)
        setUpdated(true);
        if(isChecked === 0){
            setChecked(1);
        }else{
            setChecked(0);
        }
    };

    useEffect(() => {
        if(isUpdated && !removeDisable){
            onChange(isChecked);
        }
        setUpdated(false);
    }, [isUpdated])

    useEffect(()=>{
        setChecked(value)
    },[value])


    const myStyle={
        backgroundImage: `url(${checkedsvg})`,
        maxWidth:'100%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer'
    };

    return (

        <label
            className={`text-light-gray text-sm flex  relative ${className} ${removeDisable ? "" : _.isEmpty(desc) ?'cursor-not-allowed':''} `}
            htmlFor={id}
            title={`${removeDisable ? name||id : _.isEmpty(desc)? 'Not set':'' }`}
        >
            <input
                className={`absolute w-0 h-0 opacity-0  `}
                type='checkbox'
                name={name || id}
                id={id}
                checked={isChecked}
                disabled={removeDisable ? false : _.isEmpty(desc)}
                onChange={changeHandler}
            />
            <span className={`w-6 h-6 bg-dark-gray shadow-input-filled mt-1 rounded
             ${removeDisable ? "" : !_.isEmpty(desc) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
             style={ isChecked ? myStyle : {}} >
            </span>
            <span className='flex flex-col ml-6'>
                <Text className='flex cursor-pointer text-base font-medium whitespace-nowrap'>{label}</Text>
                {desc && <Text className='flex text-sm text-gray'>
                    {desc}
                </Text>}
            </span>
            </label>

    );
}

export default Checkbox;
