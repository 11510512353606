import React from "react";

const CalenderIcon = props => {
    const { fill= "#EEE", width = "16", height = "16" } = props
    return (
        
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0a1 1 0 0 1 1 1v1h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1V1a1 1 0 1 1 2 0v1h6V1a1 1 0 0 1 1-1zm0 5H4a1 1 0 1 0 0 2h8a1 1 0 0 0 0-2z" fill="#EEE" fillRule="nonzero"/>
    </svg>

    );
};

export default CalenderIcon;
