import Button from "../../../components/common/Button";
import Information from "../../../assets/images/information.png";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import DownArrow from "../../../assets/icons/DownArrow";
import { validateEmail,copyText } from "../../../functions";
import { useState } from "react";
import { addGuest } from "../../../apis";
import {useParams, useHistory, Link} from "react-router-dom";
import { useUser } from  '../../../Store/User';

export const AddEmail = ({setPageErrors,
    emailListFull,setEmailListFull,pageErros,emailListFace,
    setGlobalViewerState,accessType,setEmailListFace,emailInput,
    changeStatusType,globalViewerState,
    faceEmailDetails,updateEventDetailsKey
    }) => {
      const { eventId } = useParams();
      const {user} = useUser();

      const [openRoles,setOpenRoles] = useState(false)
    const deleteEmail = (emailToDelete) => {
        if (accessType == 1) {
          var filtered = emailListFace.filter(function (value, index, arr) {
            return value != emailToDelete;
          });
          setEmailListFace(filtered);
        }
        if (!accessType) {
          let fullList = [...emailListFull];
          if (emailToDelete > -1) {
            fullList.splice(emailToDelete, 1);
            setEmailListFull(fullList);
          }
        }
      };
      const insertDataInput = async (e) => {
        e.preventDefault()
        const emails = emailInput.current.value.split(',');
        setPageErrors({
          ...pageErros,
          email:false
        })
        let faileEmails = []
        let goodEmails = []
        emails.forEach(email => {
          
          email =  email.trim()
       
        if (validateEmail(email)) {
          if (accessType && !emailListFace.includes(email)) {
           goodEmails.push(email)
      
           
          }

          if (!accessType && !emailListFull.some((el) => el.email === email)) {
            let admin = globalViewerState;
            goodEmails.push({ email: email, admin: admin })
            
            
          }
          emailInput.current.value = ""
        }else{
          faileEmails.push(email)
        }
      });
      if(goodEmails.length){
        if(accessType)
        setEmailListFace([...emailListFace, ...goodEmails]);
        if(!accessType){
          setEmailListFull([...emailListFull, ...goodEmails]);
          addGuest({event_id:faceEmailDetails.event_id,  guestList:goodEmails,accessType}).then((x)=>{
            window.Toast.fire({
              icon: "success",
              title: `Users added to guest list`,
            });
          })
        }
        

      }

      if(faileEmails.length){
        setPageErrors({
          ...pageErros,
          email:faileEmails.join(',')
        })
      }
       
        return;
      };


    return(
        <div className="lg:mr-16 mt-6">
        <div className="text-lg mb-4">
          Enter email address to invite people
        </div>
        <div className="relative ">
        {pageErros.email && (
            <div className="mb-1 ml-3 text-[12px] text-right text-[#e16a6a] absolute right-2 top-[-25px] ">
              *Please check emails {pageErros.email}
            </div>
          ) }
          <input
            placeholder="guest1@mail.com, guest2@mail.com"
            className="w-full text-white shadow-dark-input rounded-2xl bg-transparent p-2 pl-4 "
            ref={emailInput}
            onKeyPress={(e) =>
        
              e.key === "Enter" ? insertDataInput(e) : null
         
             
            }
          />


          {!accessType ? (
            <div className="absolute right-7  top-[50%] translate-y-[-50%]  flex justify-between items-center dropdown">
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                className="pr-2 underline text-primary-green"
              >
                {globalViewerState ? "Co-admin" : "Viewer"}
              </a>
              <span>
                <DownArrow  color="#00b7b8" />
              </span>
              <div className="dropdown-list">
                <a
                  href="#"
                  onClick={(e) => {
                    setGlobalViewerState(1);
                    e.preventDefault();
                  }}
                  className="pr-2 underline text-primary-green"
                >
                  Co-admin
                </a>
                {faceEmailDetails.published == 1 && 
                <a
                  href="#"
                  onClick={(e) => {
                    setGlobalViewerState(0);
                    e.preventDefault();
                  }}
                  className="pr-2 underline text-primary-green"
                >
                  Viewer
                </a> }
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex justify-end mt-4">
          <a href="" className={'rounded-lg dark:shadow-button py-[9px] px-[16px]  h-[42px] text-center font-semibold w-[11rem] bg-button text-light-gray'} onClick={e=>insertDataInput(e)} >Add </a>
        </div>

        <div className="flex justify-even items-center text-lg mt-10 mb-4">
          {accessType ? "Invite List" : "Assign roles"}
          {!accessType ? <span className="pl-3 cursor-pointer">
            <img className="cusor-pointer" onClick={()=>{setOpenRoles(true); document.body.style.overflow = "hidden";}} src={Information} />
          </span>:null }
        </div>

        {!accessType ? (
          emailListFull.length ? (
            emailListFull.map((list, i) => (
              <div
                key={"fullEmai" + i}
                className="flex justify-between mb-5 items-center"
              >
                <div className="flex items-center">
                  <div className="p-3  rounded-md bg-neutral-600 text-lg font-medium cursor-pointer uppercase">
                    {list.email.slice(0, 2)}
                  </div>
                  <div className=" leading-3 flex  pl-4 items-center">
                  {/* <div className='font-semibold'>  {" "}</div> */}
                    <div className='font-semibold'>  {list.email}</div>
                
                  </div>
                </div>
                <div className="flex items-center justify-between text-primary-green">
                  <div className="flex items-center mr-5 text-xs underline cursor-pointer dropdown relative">
                    {list.admin ? "Co-admin" : "Viewer"}
                    <span className="ml-2">
                      <DownArrow color="#00b7b8" />
                    </span>
                     <div className="dropdown-list innerlist ">
                        <a
                        href="#"
                        onClick={(e) => {
                            changeStatusType(e,i,1)
                        }}
                        className="pr-2 underline text-primary-green"
                        >
                        Co-admin
                        </a>
                        {faceEmailDetails.published == 1 && 
                        <a
                        href="#"
                        onClick={(e) => {
                            changeStatusType(e,i,0)
                        }}
                        className="pr-2 underline text-primary-green"
                        >
                        Viewer
                        </a>
                        }
              </div>
                  </div>
                  <div
                    className="ml-5 cursor-pointer"
                    onClick={() => deleteEmail(i)}
                  >
                    <DeleteIcon color="#00b7b8" />
                  </div>
                </div>
                
              </div>  
            )
            
            )
            
          ) : 
          <>
          {user.subscription.with_app == 1 ?
          <>
          <p className="text-red-500">No emails added yet</p>
          <div className="text-lg mt-12">Event code for Mobile App</div>
            <div className="flex justify-between shadow-dark-input text-xs p-2 mt-4 rounded-xl">
              <div className="pl-2">
              {eventId}
              </div>
              <a href="#" onClick={e=>copyText(e,eventId)} className="pr-2 underline text-primary-green">
                Copy
              </a>
            </div>
            </>
            :null}
            </>
          

        ) : emailListFace.length ? (
          <>
          <div className="inline-grid grid-cols-3 gap-4 lg:gap-x-24">
            {emailListFace.map((x, i) => (
              <div title={x} className=" flex " key={"list" + i}>
                {x.length > 20 ? x.slice(0, 20) + "...." : x}{" "}
                <div
                  className=" ml-3 cursor-pointer"
                  onClick={() => deleteEmail(x)}
                >
                  <DeleteIcon color="#00b7b8" />
                </div>
              </div>
            ))}
          </div>
          
          </>
        ) : 
        <>
        </>
        }


  <div className="text-lg mt-6 mb-4">Email Subject*</div>
        <div className=" mt-6 relative">

        {pageErros.subject && (
                <div className="mb-1 ml-3 text-[12px] text-right text-[#e16a6a]  absolute right-2 top-[-25px] ">
                *{pageErros.subject}
                </div>
            ) }
        <input
            type="text"
            className= {`w-full ${(faceEmailDetails.subject)?'shadow-inner-all': 'shadow-dark-input'} rounded-2xl bg-transparent p-2 pl-4  lg:pr-16 text-white`}
            name="subject"
            placeholder="Get your pictures!"
            value={faceEmailDetails.subject}
            onChange={(e) => updateEventDetailsKey(e)}
        />
        </div>
        
        {openRoles? 
        <div className="fixed w-full z-10 h-screen top-0 left-0 bg-bb-500 flex items-center  ">
          <div className="w-1/2 max-w-[50rem] mx-auto bg-[#1a1a1a] py-5 px-7 rounded-xl">
            <div className="flex justify-between w-full">
              <h1 className="text-3xl font-medium mb-1">Viewer Roles</h1>
            <a className="text-3xl font-medium mb-1" href="#" onClick={e=>{e.preventDefault();setOpenRoles(false);document.body.style.overflow = "auto";}}>X</a>
            </div>
            <p className="text-[1rem]">Assigning different viewer roles can decide the viewer's access to the event </p>
            <div className="w-full mt-5 mb-4 h-[1px] bg-[#555555]"></div>
            <h5 className="font-medium text-xl mb-1">Co-admin</h5>
            <ul className="list-disc px-4 mb-5 text-sm">
              <li>Upload (to guest folder), hide, download, favourite, share images and add them to cart </li>
            </ul>
            <h5 className="font-medium text-xl">Viewer</h5>
            <ul className="list-disc px-4 mt-3 text-sm">
              <li>Download, favourite, share images and add them to cart</li>
            </ul>
          
            <div className="flex justify-center mt-10 mb-7">
              <button text="Done" className='rounded-lg dark:shadow-button font-semibold py-3 px-10 min-w-[150px] bg-button flex items-center justify-center' onClick={e=>{setOpenRoles(false);document.body.style.overflow = "auto"}} >Done</button>
            </div>


          </div>
          </div>
          :null }
      </div>
    )
}
