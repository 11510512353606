import React from 'react';
import {Link} from "react-router-dom";

function Error({message,text,link}) {
    return (
        <div className="dark">
            <div className="grid place-items-center h-screen w-full bg-secondary-800">
                <div className="flex flex-col justify-center items-center text-light-gray max-w-sm">
                    <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g fillRule="nonzero" fill="none">
                            <path fill="#842D73" d="M70.717 0H29.283L0 29.283v41.434L29.283 100h41.434L100 70.717V29.283z"/>
                            <path fill="#702065" d="M50 100h20.717L100 70.717V29.283L70.717 0H50z"/>
                            <path fill="#BC66AC" d="M66.567 10H33.433L10 33.433v33.134L33.433 90h33.134L90 66.567V33.433z"/>
                            <path fill="#A64492" d="M50 90h16.567L90 66.567V33.433L66.567 10H50z"/>
                            <circle fill="#842D73" cx="50" cy="69.45" r="6.417"/>
                            <path d="M50 56.3a6.348 6.348 0 0 1-6.35-6.35V30.083c0-3.516 2.85-6.35 6.35-6.35 3.517 0 6.35 2.85 6.35 6.35V49.95c0 3.5-2.833 6.35-6.35 6.35z" fill="#842D73"/>
                            <path d="M50 23.733c3.517 0 6.35 2.85 6.35 6.35V49.95c0 3.517-2.85 6.35-6.35 6.35V23.733zM50 75.867a6.409 6.409 0 0 0 6.417-6.417A6.409 6.409 0 0 0 50 63.033v12.834z" fill="#702065"/>
                        </g>
                    </svg>
                <h3 className="text-2xl mb-5">{message}</h3>
                <p className="text-sm text-center">{text}</p>
                <a href='/' className='mt-4' >
                    <p className='text-sm text-primary-green' >Go Back to Dashboard</p>
                </a> 
                </div>
            </div>
        </div>
    );
}

export default Error;
