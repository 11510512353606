import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";

import Text from "../Text";
import ThreeDots from "../ThreeDots";
import Button from "../Button";
import DownArrow from "../../../assets/icons/DownArrow";
import EditIcon from "../../../assets/icons/EditIcon";
import { useEvent } from "../../../Store/Event/Show";
import { InputEditable } from "../../../components/common/Input";
import { getEventGuest, publishEvent, updateEventTitle } from "../../../apis";
import SettingsOptions from "../../../pages/MyEventsPinned/components/Popups/SettingsOptions";
import { useUser } from "../../../Store/User";
import { useUploader } from "../../../Store/Uploader";
import UpdateTitlePopup from "../../../pages/Event/components/Popups/Title/UpdateTitle";

function TitleSection(props) {
  let { event, setEvent } = useEvent(),
    [showEventSettingsDropdown, setShowEventSettingsDropdown] = useState(false);
  let { isUploading, uploadFolders } = useUploader();
  const [editTitle, setEditTitle] = useState(false),
    [guests, setGuests] = useState([]),
    [title, setTitle] = useState(null),
    { dashboardUrl } = useUser(),
    [showTitleModal, setShowTitleModal] = useState(false),
    navigate = useNavigate();
  const { user } = useUser();
  function settingsClickHandler() {
    setShowEventSettingsDropdown(!showEventSettingsDropdown);
  }

  useEffect(() => {
    if (!_.isEmpty(event)) {
      getEventGuest(event).then(function (response) {
        setGuests(response);
      });
    }
  }, []);

  //function to publish event
  const markEventAsPublished = (e) => {
    e.stopPropagation();

    Swal.fire({
      title: "Images are ready to be published!",
      text: `Once the event is published, all pre-registered users will receive an email with their images.`,
      icon: "success",
      showCancelButton: true,
      confirmButtonText: `Publish event`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return publishEvent(event, true)
          .then(function (response) {
            navigate(`/share/${event.id}`);
          })
          .catch(function (error) {
            window.Toast.fire({
              icon: "error",
              title: "Event could not be published",
            });
          });
      },
    });
  };

  //function to Unpulish event
  const markEventAsUnpublished = (e) => {
    e.stopPropagation();

    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to unpublish ${event.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Unpublish event`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return publishEvent(event, false)
          .then(function (response) {
            setEvent(response);
            window.Toast.fire({
              icon: "success",
              title: "Event unpublished successfully",
            });
          })
          .catch((errors) => {
            window.Toast.fire({
              icon: "error",
              title: "Event could not be unpublished",
            });
          });
      },
    });
  };

  //function to update event Name
  const UpdateTitle = (value) => {
    setEditTitle(false);
    Swal.fire({
      title: "Are you sure?",
      text: `Updating event name will affect your share url?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Update event name`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return updateEventTitle(event, value)
          .then(function (response) {
            setEvent(response);
            window.Toast.fire({
              icon: "success",
              title: "Event name updated successfully",
            });
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Event name could not updated successfully",
            });
          });
      },
    });
  };

  // function to close the dropdown when clicked outside
  let threeDotContainer = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!threeDotContainer.current.contains(event.target)) {
        setShowEventSettingsDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return !_.isEmpty(event) ? (
    <div className="flex shadow-header px-10 items-center justify-between h-[4.6rem]">
      <div className="flex items-center">
        <span className="flex  min-w-[20rem]  items-center font-medium">
          <Link
            to={dashboardUrl}
            className="font-medium mr-3 cursor-pointer"
            onClick={() => {}}
          >
            <DownArrow className={"rotate-90"} />
          </Link>

          {editTitle && (
            <>
              <InputEditable
                actionText="Update"
                value={title}
                processing={false}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onUpdate={(value) => {
                  UpdateTitle(value);
                }}
              />
              <span
                className="font-medium ml-3 cursor-pointer"
                onClick={(e) => {
                  setEditTitle(false);
                }}
              >
                Cancel
              </span>
            </>
          )}
          {!editTitle && (
            <>
              <Text as="h1" className="text-3xl text-light-gray">
                {event.name}
              </Text>
              <span
                title={"Edit Event Name"}
                className="font-medium ml-3"
                onClick={() => {
                  setShowTitleModal(true);
                  /*setTitle(event.name);
                            setEditTitle(!editTitle);*/
                }}
              >
                <EditIcon />
              </span>
            </>
          )}
        </span>
      </div>

      <div className="flex items-center">
        <div className="relative" ref={threeDotContainer}>
          <span
            id="event-settings-more"
            onClick={settingsClickHandler}
            title={"More"}
          >
            <ThreeDots
              dimension="medium"
              color={showEventSettingsDropdown ? "#00B7B8" : "#eeeeee"}
              className="w-2 h-2"
            />
          </span>
          {showEventSettingsDropdown ? <SettingsOptions /> : ""}
        </div>

        <Link
          to={"settings#sharing-section"}
          className="flex items-center ml-5 cursor-pointer"
        >
          <ul className="flex gap-3">
            {guests.slice(0, 3).map((guest) => {
              return (
                <li className="rounded-lg uppercase w-[2.625rem] h-[1.875rem] bg-secondary-600 px-[0.61rem] py-[0.313rem] text-secondary-300 text-sm flex items-center justify-center">
                  {guest.name
                    ? guest.name.substring(0, 2)
                    : guest.email.substring(0, 2)}
                </li>
              );
            })}
          </ul>
          <span className="text-sm text-secondary-500 ml-2.5">
            {guests.length > 3 ? "+" + (guests.length - 3) : ""}
          </span>
        </Link>

        {user.permissions.share_event ? (
          <Link
            id="share-more"
            to={`/share/${event.id}`}
            className="text-sm text-center text-light-gray shadow-card rounded-[10px] py-[9px] px-[16px] font-semibold w-[11rem] h-[2.5rem] mx-4"
          >
            {event.isPublished ? "Share" : "Pre-share"}
            {/* {event.isPublished ? <p id="share-more" >Share</p> : <p id="pre-share-more" >Pre-share</p>} */}
          </Link>
        ) : (
          <p
            title="Upgrade Subscription"
            id="share-more"
            // to={`/share/${event.id}`}
            className="text-sm text-center text-light-gray shadow-card rounded-[10px] py-[9px] px-[16px] font-semibold w-[11rem] h-[2.5rem] mx-4 cursor-not-allowed"
          >
            {event.isPublished ? "Share" : "Pre-share"}
            {/* {event.isPublished ? <p id="share-more" >Share</p> : <p id="pre-share-more" >Pre-share</p>} */}
          </p>
        )}

        <div id="publish-more">
          {event.isPublished && user.permissions.create_event ? (
            <Button
              onClick={(e) => {
                markEventAsUnpublished(e);
              }}
              className={"py-[9px] !h-[2.5rem]"}
              disabled={
                isUploading &&
                uploadFolders.length > 0 &&
                uploadFolders[0].eventId === event.id
              }
              text="Un-publish Event"
            />
          ) : props.eventImagesCount > 0 && user.permissions.create_event ? (
            <Button
              onClick={(e) => {
                markEventAsPublished(e);
              }}
              className={"!h-[2.5rem] py-[9px]"}
              disabled={
                isUploading &&
                uploadFolders.length > 0 &&
                uploadFolders[0].eventId === event.id
              }
              text="Publish Event"
            />
          ) : user.permissions.create_event == false ? (
            <button
              title="Upgrade Subscription"
              className={`rounded-lg shadow-card font-semibold py-[9px] w-[11rem] h-[2.5rem] bg-dark-disabled-button text-gray cursor-not-allowed`}
            >
              Publish Event
            </button>
          ) : (
            <button
              className={`rounded-lg shadow-card font-semibold py-[9px] w-[11rem] h-[2.5rem] bg-dark-disabled-button text-gray`}
              onClick={() =>
                window.Toast.fire({
                  icon: "error",
                  title: "Please upload at least one image to publish event",
                })
              }
            >
              Publish Event
            </button>
          )}
        </div>
      </div>
      {showTitleModal && (
        <UpdateTitlePopup
          setShowTitleModal={setShowTitleModal}
          event={event}
          setEvent={setEvent}
        />
      )}
    </div>
  ) : (
    <></>
  );
}

export default TitleSection;
