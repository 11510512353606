import Text from '../Text';
import React, {useEffect, useState} from "react";
import _ from "lodash";

export const TextareaEditable = props => {
    const {
        name = (Math.random() + 1).toString(36).substring(7),
        className = "",
        label = "",
        required = false,
        type = "",
        onChange,
        value = '',
        error = '',
        placeholder = '',
        onKeyDown,
        processing = false,
        success = false,
        onUpdate,
        actionText = 'Update',
    } = props;

    const [isProcessing, setIsProcessing] = useState(true),
        [isSuccess, setIsSuccess] = useState(false);

    useEffect(async () => {
        setIsProcessing(processing);
    }, [processing]);

    useEffect(async () => {
        setIsSuccess(success);
    }, [success]);

    const showIsRequired = required ? "*" : "";
    return (
        <div className={`flex flex-col w-full ${className}`}>
            {label && <Text as="label"
                            className={`flex justify-between items-baseline text-base`}>
                <span className="inline-block">{label}</span>
            </Text>}
            {error && <span className="inline-block text-right text-xs pr-4 text-error-red">{error}</span>}

            {(required && value) || !required ?
                <span onClick={(event) => {
                    setIsSuccess(false);
                    setIsProcessing(true);
                    onUpdate(value);
                }}
                      className="inline-block text-xs font-semibold whitespace-nowrap underline cursor-pointer text-right text-primary-green pr-5 -mt-2">{actionText}</span> : null
            }

            <textarea
                className={`shadow-dark-input w-full p-2 rounded-[10px] bg-transparent 
                    min-h-[10rem] mt-2
                    ${!value ? 'shadow-input-empty' : 'shadow-input-filled'}  
                    ${error ? 'outline outline-1 outline-error-red' : 'outline-none'}`}
                placeholder={placeholder}
                value={value}
                autoComplete="off"
                onChange={(event) => onChange(event)}
                name={name}>
                </textarea>
        </div>
    );
};
