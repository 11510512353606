/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState,useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DownArrow from "../../../../../assets/icons/DownArrow";
// import {useEventIndex} from "../../../../Store/Event/Index"
import {useUser} from "../../../../../Store/User";
import _, { initial } from 'lodash';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example({items,className,onSelect,initialValue}) {
  // const {events, isLoaded, hasError} = useEventIndex();
  // const {user,setUser} = useUser();
  var initialSelected = "";
  // if (dropDownFor === 'logo-font') {
  //   !_.isEmpty(user.photographer) && (initialSelected = user.photographer.logo_text_font)
  // }
  
  // console.log("initialvalu",initialValue);

  const [selected, setSelected] = useState(initialValue);
  const [item, setitem] = useState(items);

  const onOptionChange = (e) => {
    setSelected(e);
    onSelect(e);
  }



  return (
    <Listbox value={selected} onChange={(e)=>{onOptionChange(e)}}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className={`flex relative items-center justify-between text-sm shadow-button rounded-lg p-2 px-3 whitespace-nowrap ${className}`}>
              <span className="flex items-center">
                {/* <span className="block truncate">{!_.isNull(selected) ? selected : item[0] }</span> */}
                <span className="block truncate">{!_.isNull(selected) ? selected : item[0] }</span>
              </span>
              <span className="pl-2 cursor-pointer  ">
                {/* <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                <DownArrow className=" h-3 w-3 ml-3 text-gray-400" aria-hidden="true" rotate={open ? true : false} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              
              <Listbox.Options className={`absolute z-50 mt-[0.625rem]  bg-dark-gray shadow-dark-input max-h-56 rounded-xl r-2 top-[95%] text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${className}`}>
                {item.map((tags,id) => (
                  <Listbox.Option
                    key={tags + id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-secondary-300 bg-black' : 'text-secondary-300',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }

                    value={tags}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* <img src={tags.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                          <span className={`rounded-full w-3 h-3  mt-1 mr-3 mb-2`} style={{backgroundColor:tags.colour}} > </span>
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                          >

                            {tags}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
