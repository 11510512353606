import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import NoPage from "../../pages/NoPage";
import SignupAdmin from "../../pages/SignupAdmin";
import SigninAdmin from "../../pages/SigninAdmin";
import EventIndex from "../../pages/Event/Index";
import EventShow from "../../pages/Event/Show";
import { EventIndexProvider } from "../../Store/Event/Index";
import { initialState, eventIndexReducer } from "../../Store/Event/Reducer";
import ShareEventEmail from "../../pages/ShareEventEmail";
import GeneralSettings from "../../pages/GeneralSettings";
import EventSettingsDesign from "../../pages/EventSettings";
import ViewersRoles from "./ViewerRoles";
import GlobalAnalytics from "../../pages/GlobalAnalytics";
import EventSettings from "../../pages/EventSettings";
import ViewProfile from "../../pages/ViewProfile";
import AddSubDomain from "../../pages/SignupAdmin/AddSubDomain";
import PrivateRoute from "./PrivateRoute";
import WithNavBar from "./withNavBar";
import ForgotEmail from "../../pages/Password/ForgotEmail";
import ChangePassword from "../../pages/Password/ChangePassword";
import { EventProvider } from "../../Store/Event/Show";
import EventFavouriteList from "../../pages/GlobalAnalytics/components/EventFavouriteList";
import EditPortfolio from "../../pages/ViewProfile/components/MobilePortfolioPage/EditPortfolio";
import DownloadNow from "../../pages/GlobalAnalytics/components/EventFavouriteList/Components/DownloadNow";
import CapturingWow from "../../pages/CapturingWow";
import AddAddress from "../../pages/AddAddress";
import ManualLogout from "../../pages/ManualLogout";

const MobilePortfolioPage = React.lazy(() =>
  import("../../pages/ViewProfile/components/MobilePortfolioPage")
);

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<WithNavBar />}>
            <Route element={<PrivateRoute />}>
              <Route
                path="/"
                element={
                  <EventIndexProvider
                    initialState={initialState}
                    reducer={eventIndexReducer}
                  >
                    <EventIndex />
                  </EventIndexProvider>
                }
              />

              <Route path="/events/:eventId" element={<EventShow />} />

              <Route
                path="/events/:eventId/settings"
                element={
                  <EventProvider>
                    <EventSettings />
                  </EventProvider>
                }
              />
              <Route
                path="/events/:eventId/analytics"
                element={
                  <EventProvider>
                    <GlobalAnalytics />
                  </EventProvider>
                }
              />
              {/* //TODO : Lazy load this Route */}
              <Route
                path="/downloadNow/:eventId/:eventName/:downloadId"
                element={
                  <EventProvider>
                    <DownloadNow />
                  </EventProvider>
                }
              />
              <Route
                path="/downloadNow/:downloadId"
                element={<DownloadNow />}
              />
              <Route
                path="share/:eventId"
                element={
                  <EventProvider>
                    <ShareEventEmail />
                  </EventProvider>
                }
              />
              <Route path="general-settings" element={<GeneralSettings />} />
              <Route path="user/manual-logout" element={<ManualLogout />} />
              <Route path="profile" element={<ViewProfile />} />
              <Route
                path="create-portfolio"
                element={<MobilePortfolioPage />}
              />
              <Route path="edit-portfolio" element={<EditPortfolio />} />
              <Route path="global-analytics" element={<GlobalAnalytics />} />
              <Route
                path="/event/:eventId/favourite/:favListId"
                element={
                  <EventProvider>
                    <EventFavouriteList />
                  </EventProvider>
                }
              />

              <Route path="dashboard" element={<Navigate replace to="/" />} />
            </Route>
            <Route path="add-subdomain" element={<AddSubDomain />} />
            <Route path="add-address" element={<AddAddress />} />
            <Route path="register" element={<SignupAdmin />} />
            <Route path="login" element={<SigninAdmin />} />
            <Route path="forgot-password" element={<ForgotEmail />} />
            <Route path="change-password" element={<ChangePassword />} />

            <Route path="event-settings" element={<EventSettingsDesign />} />
            <Route path="viewers-roles" element={<ViewersRoles />} />

            <Route path="profile" element={<ViewProfile />} />

            <Route path="*" element={<NoPage />} />
          </Route>
          <Route exact path="/capturingwow" element={<CapturingWow />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
