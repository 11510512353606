// import React,{useEffect, useState, useRef} from "react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Title from "../../../../components/common/Title";
import Text from "../../../../components/common/Text";
import "./coverImageSection.css";
import { useEvent } from "../../../../Store/Event/Show";
import _, { replace } from "lodash";
import {
  updateCoverTemplate,
  updateEventFonts,
  changeCoverImage,
  cropCoverImage,
} from "../../../../apis";
import moment from "moment";
import Cropper from "react-easy-crop";
import EventSettingFontDropDown from "../../../../components/common/DropDown/EventSettingFontDropDown";
import UploadIcon from "../../../../assets/icons/UploadIcon";
import IconButton from "../../../../components/common/IconButton";
import CropIcon from "../../../../assets/icons/CropIcon";
import AnimatedSpinnerIcon from "../../../../assets/icons/AnimatedSpinnerIcon";
import Loading from "../../../Loading";
import {
  OG_IMAGE_SUBDOMAIN,
  RESIZE_IMAGE_SUBDOMAIN,
} from "../../../../constants";

const CoverImageSection = () => {
  const { event, setEvent } = useEvent();
  const [coverImageDesign, setCoverImageDesign] = useState();
  const [zoom, setZoom] = useState(1);
  const [showCropLoader, setShowCropLoader] = useState(false);
  const [mobileCoverUploading, setMobileCoverUploading] = useState(false);
  const [coverImage, setCoverImage] = useState(),
    [mobileCoverImage, setMobileCoverImage] = useState(),
    [isCropping, setIsCropping] = useState(false),
    [desktopCoverUploading, setDesktopCoverUploading] = useState(false),
    [cropDesktopCover, setCropDesktopCover] = useState(false),
    [cropMobileCover, setCropMobileCover] = useState(false),
    [cropperDesktop, setCropperDesktop] = useState({
      imageSrc:
        "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 16 / 9,
    }),
    [cropperMobile, setCropperMobile] = useState({
      imageSrc:
        "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 16 / 31,
    }),
    [croppedAreaPixels, setCroppedAreaPixels] = useState(null),
    onCropChange = (crop) => {
      if (cropDesktopCover) {
        setCropperDesktop({ ...cropperDesktop, crop });
      } else {
        setCropperMobile({ ...cropperMobile, crop });
      }
    },
    onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

  useEffect(() => {
    if (Object.keys(event).length > 0) {
      setCoverImage(
        event.desktopCoverImageUrl.includes("?cr=")
          ? event.desktopCoverImageUrl + "&w=1600"
          : event.desktopCoverImageUrl + "?w=1600"
      );
      setMobileCoverImage(
        event.mobileCoverImageUrl.includes("?cr=")
          ? event.mobileCoverImageUrl + "&w=1600"
          : event.mobileCoverImageUrl + "?w=1600"
      );
      const cropValues = event.desktopCoverImageUrl.split("?cr=").pop();
      const coverImageUrl = event.desktopCoverImageUrl.replace(
        "?cr=" + cropValues,
        ""
      );

      const mobileCropValues = event.mobileCoverImageUrl.split("?cr=").pop();
      const mobileCoverImageUrl = event.mobileCoverImageUrl.replace(
        "?cr=" + mobileCropValues,
        ""
      );

      setCropperDesktop({
        ...cropperDesktop,
        imageSrc: coverImageUrl.replace(
          RESIZE_IMAGE_SUBDOMAIN,
          OG_IMAGE_SUBDOMAIN
        ),
      });
      setCropperMobile({
        ...cropperMobile,
        imageSrc: mobileCoverImageUrl.replace(
          RESIZE_IMAGE_SUBDOMAIN,
          OG_IMAGE_SUBDOMAIN
        ),
      });
    }

    if (!_.isEmpty(event.design)) {
      setCoverImageDesign(event.design.coverImageTemplate);
    }
  }, [event]);

  function updateCroppedCoverImage() {
    setIsCropping(true);
    let x = Math.round(croppedAreaPixels.x),
      y = Math.round(croppedAreaPixels.y),
      width = Math.round(croppedAreaPixels.width),
      height = Math.round(croppedAreaPixels.height);
    cropCoverImage(event, x, y, width, height, !cropDesktopCover)
      .then((response) => {
        setEvent(response);
        setCropDesktopCover(false);
        setCropMobileCover(false);
        setIsCropping(false);
        window.Toast.fire({
          icon: "success",
          title: "Event cover image cropped successfully.",
        });
      })
      .catch((err) => {
        setIsCropping(false);
      });
  }

  function updateCoverImageDesign(e, value) {
    setCoverImageDesign(value);
    updateCoverTemplate(event, value)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Event cover image template updated successfully.",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Cover Image Template could not be updated",
        });
      });
  }

  function handleFontUpdate(fonts) {
    updateEventFonts(event, fonts)
      .then(function (response) {})
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Event fonts could not be updated",
        });
        window.location.reload();
      });
  }

  const desktopCoverUploadRef = useRef();
  const mobileCoverUploadRef = useRef();

  const handleCoverImageChange = (e) => {
    setDesktopCoverUploading(true);
    let file = e.target.files[0];
    changeCoverImage(event, file)
      .then((response) => {
        setEvent(response);
        setDesktopCoverUploading(false);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Cover Image could not be updated",
        });
      });
  };

  const handleMobileCoverImageChange = (e) => {
    setMobileCoverUploading(true);
    let file = e.target.files[0];
    changeCoverImage(event, file, true)
      .then((response) => {
        setEvent(response);
        setMobileCoverUploading(false);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Cover Image could not be updated",
        });
      });
  };

  const MAX = 10;
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(zoom * 100) / MAX}% 100%`,
    };
  };

  const AUTH_BUTTON_CLASSES =
    " border py-1 px-2 text-xs px-3 rounded-lg border-light-gray";

  const AUTH_BUTTON_MOBILE_CLASS =
    "text-[8px] py-[2px] px-1 border  rounded-md border-light-gray";

  const authButtons = (
    wrapperClassname = "mt-1 md:mt-2 text-xs flex-row",
    buttonClass = AUTH_BUTTON_CLASSES,
    orClass = ""
  ) => (
    <div
      className={`flex  gap-1 md:gap-2 md:items-center whitespace-nowrap ${wrapperClassname} text-light-gray`}
    >
      <div className={buttonClass}>View mine</div>
      <span className="w-[1px] h-full bg-white"></span>
      <div className={buttonClass}>View all</div>
      <span className={`font-bold ${orClass}`}> OR </span>
      <div className={buttonClass}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (cropDesktopCover || cropMobileCover) setShowCropLoader(true);
  }, [cropDesktopCover, cropMobileCover]);

  return (
    <div>
      <div className="flex items-center justify-start border-b border-secondary-600 pb-4 mb-6 gap-6">
        <h2 className=" text-2xl text-secondary-300">Cover Image</h2>
        <div>
          <IconButton
            id="upload-cover-image"
            className="cursor-pointer !bg-dark-gray flex gap-4 items-center rounded-xl"
            onClick={(e) => {
              desktopCoverUploadRef.current.click();
            }}
          >
            {desktopCoverUploading ? (
              <AnimatedSpinnerIcon />
            ) : (
              <UploadIcon color="#00b7b8" />
            )}
            Desktop Cover Image
          </IconButton>

          <input
            type="file"
            ref={desktopCoverUploadRef}
            style={{ display: "none" }}
            onChange={(e) => {
              handleCoverImageChange(e);
            }}
            accept="image/jpg,image/jpeg,image/png"
          />
        </div>
        <div>
          <IconButton
            id="upload-mobile-cover-image"
            className="cursor-pointer !bg-dark-gray flex gap-4 items-center rounded-xl"
            onClick={(e) => {
              mobileCoverUploadRef.current.click();
            }}
          >
            {mobileCoverUploading ? (
              <AnimatedSpinnerIcon />
            ) : (
              <UploadIcon color="#00b7b8" />
            )}
            Mobile Cover Image
          </IconButton>

          <input
            type="file"
            ref={mobileCoverUploadRef}
            style={{ display: "none" }}
            onChange={(e) => {
              handleMobileCoverImageChange(e);
            }}
            accept="image/jpg,image/jpeg,image/png"
          />
        </div>
      </div>
      {!_.isEmpty(event) ? (
        <div>
          {cropDesktopCover || cropMobileCover ? (
            <div className="w-full">
              <div className="flex justify-between">
                <Text
                  as="p"
                  className="text-base text-secondary-300 mb-4 font-medium"
                >
                  Crop the image to your liking
                </Text>
                <div>
                  {isCropping ? (
                    <IconButton className="mr-3 cursor-pointer bg-button">
                      Cropping...
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        className="mr-3 cursor-pointer"
                        onClick={(e) => {
                          setCropDesktopCover(false);
                          setCropMobileCover(false);
                        }}
                      >
                        Cancel
                      </IconButton>

                      <IconButton
                        className="mr-3 cursor-pointer bg-button"
                        onClick={updateCroppedCoverImage}
                      >
                        Crop Image
                      </IconButton>
                    </>
                  )}
                </div>
              </div>
              <div className="flex  justify-start gap-12">
                <div
                  className={`relative   ${
                    cropDesktopCover
                      ? "aspect-video w-3/4"
                      : "aspect-mobile-crop w-1/4"
                  }`}
                >
                  {showCropLoader ? (
                    <div className="absolute z-[100000] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      {" "}
                      <Loading />{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                  <Cropper
                    image={
                      cropDesktopCover
                        ? cropperDesktop.imageSrc
                        : cropperMobile.imageSrc
                    }
                    crop={
                      cropDesktopCover
                        ? cropperDesktop.crop
                        : cropperMobile.crop
                    }
                    zoom={zoom}
                    aspect={cropDesktopCover ? 16 / 9 : 16 / 31}
                    showGrid={true}
                    viewMode={3}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit={"horizontal-cover"}
                    onMediaLoaded={() => setShowCropLoader(false)}
                  />
                </div>
                <div className="controls mt-2">
                  <div>Zoom</div>
                  <div>
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={MAX}
                      step={1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                      style={getBackgroundSize()}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-wrap 2xl:flex-nowrap justify-start gap-[1.875rem] my-[20px]">
                <div className="flex flex-col w-[75%]">
                  <div className="flex justify-between">
                    <Text
                      as="p"
                      className="text-base text-secondary-300 mb-4 font-medium"
                    >
                      Desktop view
                    </Text>
                  </div>
                  <div
                    className="relative w-full shadow-card aspect-video overflow-hidden"
                    style={{
                      backgroundImage: `url('${coverImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute right-2 top-2 z-40">
                      {!desktopCoverUploading && !mobileCoverUploading ? (
                        <IconButton
                          id="crop-desktop-cover"
                          className="mr-3 cursor-pointer !bg-dark-gray z-40"
                          onClick={(e) => {
                            setCropDesktopCover(!cropDesktopCover);
                          }}
                        >
                          {cropDesktopCover ? "Cancel" : <CropIcon />}
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>
                    {coverImageDesign === "split" && (
                      <div className="w-1/3 h-full flex flex-col justify-center  pl-8 right__container_gradient">
                        {/* <div className="absolute inset-0 bg-black bg-opacity-10 rounded-r-xl" ></div> */}
                        <div className="w-3/4">
                          <h2 className="text-secondary-300 font-medium text-2xl">
                            {event.name.replace("-", " ")}
                          </h2>
                          <h4 className="text-secondary-300 text-sm font-normal">
                            {
                              !_.isNull(event.eventDate) &&
                                moment(event.eventDate).format("Do MMM, YYYY")
                              // moment(event.date).format('Do MMM, YYYY')}
                            }
                          </h4>
                          {/* <p className="mt-4 text-xs text-secondary-300 text-left line-clamp-4"
                                                   style={{
                                                       overflow: 'hidden',
                                                       textOverflow: 'ellipsis',
                                                       display: '-webkit-box',
                                                       // lineHeight: '16px',
                                                       // maxHeight: '48px',
                                                       WebkitLineClamp: '4',
                                                       WebkitBoxOrient: 'vertical'

                                                   }}>
                                                    {event.description}
                                                </p> */}
                          {authButtons()}
                        </div>
                      </div>
                    )}
                    {coverImageDesign === "label" && (
                      <div className="flex flex-col justify-center items-center w-full h-full full__container_gradient">
                        {/* <div className="absolute inset-0 bg-black bg-opacity-20 rounded-xl" ></div> */}
                        <div className="w-1/2 flex flex-col justify-center items-center ">
                          <h2 className="text-secondary-300 font-medium text-2xl">
                            {event.name.replace("-", " ")}
                          </h2>
                          <h4 className="text-secondary-300 text-sm font-normal">
                            {!_.isNull(event.eventDate) &&
                              moment(event.eventDate).format("Do MMM, YYYY")}
                          </h4>
                          {/* <p className="mt-4 text-xs text-secondary-300 text-center line-clamp-4"
                                                   style={{
                                                       overflow: 'hidden',
                                                       textOverflow: 'ellipsis',
                                                       display: '-webkit-box',
                                                       // lineHeight: '16px',
                                                       // maxHeight: '48px',
                                                       WebkitLineClamp: '4',
                                                       WebkitBoxOrient: 'vertical'

                                                   }}
                                                >
                                                    {event.description}
                                                </p> */}
                          {authButtons()}
                        </div>
                      </div>
                    )}
                    {coverImageDesign === "border" && (
                      <div className="flex flex-col h-full justify-end">
                        <div className="flex justify-items-center w-full h-[30%] bottom-0 bottom__container_gradient px-6">
                          <div className="flex w-full">
                            <div className="w-2/3 flex flex-col items-start justify-center">
                              <h2 className="text-secondary-300 font-medium text-2xl">
                                {event.name.replace("-", " ")}
                              </h2>
                              <h4 className="text-secondary-300 text-sm font-normal">
                                {!_.isNull(event.eventDate) &&
                                  moment(event.eventDate).format(
                                    "Do MMM, YYYY"
                                  )}
                              </h4>
                            </div>
                            <div className="w-1/3 flex flex-col justify-center items-end">
                              {/* <p className="text-xs text-secondary-300 text-left line-clamp-4"
                                                           style={{
                                                               overflow: 'hidden',
                                                               textOverflow: 'ellipsis',
                                                               display: '-webkit-box',
                                                               // lineHeight: '16px',
                                                               // maxHeight: '48px',
                                                               WebkitLineClamp: '4',
                                                               WebkitBoxOrient: 'vertical'

                                                           }}

                                                        >
                                                            {event.description}
                                                        </p> */}
                              {authButtons()}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {coverImageDesign === "corner" && (
                      <div className="relative flex flex-col h-full justify-end">
                        <div className="absolute opacity-75 blur-[10px] w-1/2 h-1/2 -bottom-3 -left-3 bottom__container_gradient"></div>
                        <div className="flex flex-col justify-end pb-7 z-50">
                          <div className="flex flex-col pl-[1.37rem] w-8/12">
                            <div className="flex flex-col items-start">
                              <h2 className="text-secondary-300 font-medium text-2xl">
                                {event.name.replace("-", " ")}
                              </h2>
                              <h4 className="text-secondary-300 text-sm font-normal">
                                {!_.isNull(event.eventDate) &&
                                  moment(event.eventDate).format(
                                    "Do MMM, YYYY"
                                  )}
                              </h4>
                            </div>
                            {/* {event.description &&
                                                    <div className="flex flex-col justify-center pt-4">
                                                        <p className="text-xs text-secondary-300 text-left line-clamp-4">
                                                            {event.description}
                                                        </p>
                                                    </div>} */}
                            <div className="mb-[21px]">
                              {authButtons("mt-1 md:mt-0 text-xs")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="hidden 2xl:flex flex-col w-[22%]">
                  <Text as="p" className="mb-4 font-medium">
                    Mobile view
                  </Text>
                  <div className="mobile-view aspect-mobile-crop">
                    <div
                      className="relative overflow-hidden aspect-mobile-crop"
                      style={{
                        backgroundImage: `url('${mobileCoverImage}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="absolute right-2 top-2 z-40">
                        {!desktopCoverUploading && !mobileCoverUploading ? (
                          <IconButton
                            id="crop-mobile-cover"
                            className="mr-3 cursor-pointer !bg-dark-gray z-40"
                            onClick={(e) => {
                              setCropMobileCover(!cropMobileCover);
                            }}
                          >
                            {cropMobileCover ? "Cancel" : <CropIcon />}
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </div>

                      {coverImageDesign === "split" && (
                        <div className="flex flex-col w-1/2 h-full pl-3 right__container_gradient">
                          {/* <div className="absolute inset-0 bg-black bg-opacity-10 rounded-r-xl" ></div> */}
                          <div className="flex h-full justify-center items-center pb-4">
                            <div>
                              <h2 className="text-secondary-300 font-medium text-xs">
                                {event.name.replace("-", " ")}
                              </h2>
                              <h4 className="text-secondary-300 text-[0.5rem] font-normal ">
                                {!_.isNull(event.eventDate) &&
                                  moment(event.eventDate).format(
                                    "Do MMM, YYYY"
                                  )}
                              </h4>
                              {/* <p className="mt-[0.347rem] text-[0.37rem] text-secondary-300 text-left line-clamp-4"
                                                           style={{
                                                               overflow: 'hidden',
                                                               textOverflow: 'ellipsis',
                                                               display: '-webkit-box',
                                                               // lineHeight: '16px',
                                                               // maxHeight: '48px',
                                                               WebkitLineClamp: '4',
                                                               WebkitBoxOrient: 'vertical'

                                                           }}
                                                        >
                                                            {event.description}
                                                        </p> */}
                              {authButtons(
                                "mt-1 text-[8px] flex-col !items-baseline",
                                AUTH_BUTTON_MOBILE_CLASS,
                                "pl-[10px]"
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {coverImageDesign === "label" && (
                        <div className="flex flex-col justify-center items-center w-full h-full full__container_gradient">
                          {/* <div className="absolute inset-0 bg-black bg-opacity-20 rounded-xl" ></div> */}
                          <div className="w-1/2 flex flex-col justify-center items-center ">
                            <h2 className="text-secondary-300 text-center font-medium text-xs">
                              {event.name.replace("-", " ")}
                            </h2>
                            <h4 className="text-secondary-300 text-[0.5rem] pt-[0.12rem]">
                              {!_.isNull(event.eventDate) &&
                                moment(event.eventDate).format("Do MMM, YYYY")}
                            </h4>
                            {/* <p className="mt-[0.347rem] text-[0.375rem] text-secondary-300 text-center line-clamp-4"
                                                       style={{
                                                           overflow: 'hidden',
                                                           textOverflow: 'ellipsis',
                                                           display: '-webkit-box',
                                                           // lineHeight: '16px',
                                                           // maxHeight: '48px',
                                                           WebkitLineClamp: '4',
                                                           WebkitBoxOrient: 'vertical'

                                                       }}
                                                    >
                                                        {event.description}
                                                    </p> */}
                            {authButtons(
                              "mt-1 text-[8px] flex-col",
                              AUTH_BUTTON_MOBILE_CLASS
                            )}
                          </div>
                        </div>
                      )}

                      {coverImageDesign === "border" && (
                        <div className="flex flex-col h-full justify-end ">
                          <div className="flex justify-items-center w-full h-[30%] bottom-0 bottom__container_gradient overflow-hidden ">
                            {/* <div className="absolute inset-0 bg-black bg-opacity-10 rounded-b-xl" ></div> */}

                            {/* <div className="w-full flex flex-col justify-center items-center">
                                    <h2 className="text-2xl font-bold" >{event.name}</h2>
                                    <h4 className="text-lg" >{event.date}</h4>
                                    <p className="text-sm ">
                                        {event.description}
                                    </p>
                                </div> */}
                            <div className="flex flex-col items-center justify-center w-full mx-2 mb-2">
                              <div className="w-4/5 flex flex-col items-center justify-center">
                                <h2 className="text-secondary-300 font-normal text-xs mb-1">
                                  {event.name.replace("-", " ")}
                                </h2>
                                <h4 className="text-secondary-300 text-[0.5rem] font-normal">
                                  {!_.isNull(event.eventDate) &&
                                    moment(event.eventDate).format(
                                      "Do MMM, YYYY"
                                    )}
                                </h4>
                              </div>
                              <div className="w-4/5 flex flex-col items-center justify-center">
                                {/* <p className="text-[0.5rem] text-secondary-300 "
                                                               style={{
                                                                   overflow: 'hidden',
                                                                   textOverflow: 'ellipsis',
                                                                   display: '-webkit-box',

                                                                   // lineHeight: '16px',
                                                                   // maxHeight: '48px',
                                                                   WebkitLineClamp: '4',
                                                                   WebkitBoxOrient: 'vertical',
                                                                   textAlign: 'justify',
                                                                   // textJustify:'distribute',
                                                                   // textAlignLast:'left',
                                                                   wordBreak: 'break-all'

                                                               }}>
                                                                {event.description}
                                                            </p> */}
                                {authButtons(
                                  "mt-1 text-[8px] flex-col",
                                  AUTH_BUTTON_MOBILE_CLASS
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {coverImageDesign === "corner" && (
                        <div className="relative flex flex-col h-full justify-end">
                          <div className="absolute opacity-75 blur-[10px] w-1/2 h-1/2 -bottom-3 -left-3 bottom__container_gradient"></div>
                          <div className="flex flex-col justify-end pb-3 z-50">
                            <div className="flex flex-col pl-[0.647rem]">
                              <div className="flex flex-col items-start">
                                <h2 className="text-secondary-300 font-medium text-xs">
                                  {event.name.replace("-", " ")}
                                </h2>
                                <h4 className="text-secondary-300 text-[0.5rem] font-normal">
                                  {!_.isNull(event.eventDate) &&
                                    moment(event.eventDate).format(
                                      "Do MMM, YYYY"
                                    )}
                                </h4>
                              </div>
                              <div
                                className="flex flex-col justify-center items-end pt-[0.258rem]"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  // lineHeight: '16px',
                                  // maxHeight: '48px',
                                  WebkitLineClamp: "4",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {/* {event.description != "" ?
                                                                <p className="text-[0.37rem] text-secondary-300 text-left line-clamp-4"
                                                                >
                                                                    {event.description}
                                                                </p> : null} */}
                                {authButtons(
                                  "mt-1 text-[8px] flex-col !items-baseline",
                                  AUTH_BUTTON_MOBILE_CLASS,
                                  "pl-[10px]"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div id="cover-image-design" className="mb-[10px] mt-7">
                <div className="flex flex-wrap items-center justify-between">
                  {/* <span style={{ color: "grey" }}>{"<"}</span> */}
                  <div className="flex flex-wrap justify-between ml-[20px]">
                    <div
                      onClick={(e) => {
                        updateCoverImageDesign(e, "split");
                      }}
                      className="flex flex-col"
                    >
                      <Text as="p" className="mb-[10px] font-medium">
                        Split
                      </Text>
                      <div
                        className={
                          coverImageDesign === "split"
                            ? "element-option-selected"
                            : "element-option"
                        }
                      >
                        <div
                          className={
                            coverImageDesign === "split"
                              ? "outer-rectangle"
                              : "outer-rectangle"
                          }
                        >
                          <div
                            className={
                              coverImageDesign === "split"
                                ? "class-split-selected"
                                : "class-split"
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={(e) => {
                        updateCoverImageDesign(e, "label");
                      }}
                      className="flex flex-col"
                    >
                      <Text as="p" className="mb-[10px] font-medium">
                        Label
                      </Text>
                      <div
                        className={
                          coverImageDesign === "label"
                            ? "element-option-selected"
                            : "element-option"
                        }
                      >
                        <div className="outer-rectangle flex items-center justify-center">
                          <div
                            className={
                              coverImageDesign === "label"
                                ? "label-split-selected"
                                : "label-split"
                            }
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={(e) => {
                        updateCoverImageDesign(e, "border");
                      }}
                      className="flex flex-col"
                    >
                      <Text as="p" className="mb-[10px] font-medium">
                        Border
                      </Text>
                      <div
                        className={
                          coverImageDesign === "border"
                            ? "element-option-selected"
                            : "element-option"
                        }
                      >
                        <div className="outer-rectangle flex">
                          <div
                            className={
                              coverImageDesign === "border"
                                ? "border-split-selected self-end"
                                : "border-split self-end"
                            }
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={(e) => {
                        updateCoverImageDesign(e, "corner");
                      }}
                      className="flex flex-col"
                    >
                      <Text as="p" className="mb-[10px] font-medium">
                        Corner
                      </Text>
                      <div
                        className={
                          coverImageDesign === "corner"
                            ? "element-option-selected"
                            : "element-option"
                        }
                      >
                        <div className="outer-rectangle flex">
                          <div
                            className={
                              coverImageDesign === "corner"
                                ? "corner-split-selected self-end"
                                : "corner-split self-end"
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <span style={{ color: "grey" }}>{">"}</span> */}
                </div>
              </div>
              {/* <div className='mb-[10px] mt-7 '>
                   <div className='flex flex-wrap justify-between overflow-hidden items-center'>
                        <span style={{ color: "grey" }}>{"<"}</span>
                            <Splide
                                options={ {
                                    rewind: true,
                                    arrows: true,
                                    width: '90%',
                                    gap   : '1rem',
                                    perPage: 4,
                                    pagination: false,
                                    breakpoints: {
                                        1280: {
                                            perPage: 3,
                                        },
                                        768: {
                                            perPage: 2,
                                        },
                                        320: {
                                            perPage: 1,
                                        },
                                    },

                                }}
                            >
                            <SplideSlide>
                                <div onClick={(e)=>{updateCoverImageDesign(e,1)}}  className='flex flex-col'>
                                    <Text as='p' className='mb-[10px]'>
                                        Split
                                    </Text>
                                    <div className={coverImageDesign == "1" ? "element-option-selected" : "element-option"}>
                                        <div className={coverImageDesign == "1" ? 'outer-rectangle' : 'outer-rectangle' }>
                                            <div className={coverImageDesign == "1" ? 'class-split-selected' : 'class-split' }></div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div onClick={(e)=>{updateCoverImageDesign(e,2)}} className='flex flex-col'>
                                    <Text as='p' className='mb-[10px]'>
                                        Label
                                    </Text>
                                    <div className={coverImageDesign == "2" ? "element-option-selected" : "element-option"}>
                                        <div className='outer-rectangle flex items-center justify-center'>
                                            <div className={coverImageDesign == "2" ? 'label-split-selected' : 'label-split'}></div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div onClick={(e)=>{updateCoverImageDesign(e,3)}} className='flex flex-col'>
                                    <Text as='p' className='mb-[10px]'>
                                        Border
                                    </Text>
                                    <div className={coverImageDesign == "3" ? "element-option-selected" : "element-option"}>
                                        <div className='outer-rectangle flex'>
                                            <div className={coverImageDesign == "3" ? 'border-split-selected self-end' : 'border-split self-end'}></div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                    <div onClick={(e)=>{updateCoverImageDesign(e,4)}} className='flex flex-col'>
                                        <Text as='p' className='mb-[10px]'>
                                            Corner
                                        </Text>
                                        <div className={coverImageDesign == "4" ? "element-option-selected" : "element-option"}>
                                            <div className='outer-rectangle flex'>
                                                <div className={coverImageDesign == "4" ? 'corner-split-selected self-end' : 'corner-split self-end'}></div>
                                            </div>
                                        </div>
                                    </div>
                            </SplideSlide>
                            </Splide>
                        <span style={{ color: "grey" }}>{">"}</span>
                    </div>
                </div> */}
              {/*<div className='flex flex-row mt-12'>*/}
              {/*    <div className='flex flex-col basis-1/2 pr-[30px]'>*/}
              {/*        <Text as='p' className='font-secondary-300 text-base mb-[10px] font-medium'>*/}
              {/*            Fonts*/}
              {/*        </Text>*/}
              {/*        <div className="w-[28.5rem]">*/}
              {/*            <EventSettingFontDropDown className="w-full" items={styles} onSelect={handleFontUpdate}/>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CoverImageSection;
