import React from "react";

const NoPage = () => {
    return (
        <h1 className='text-dark-gray1 dark:text-light-gray text-base font-medium tracking-tight'>
            Oops, this page doesn't exist!
        </h1>
    );
};

export default NoPage;
