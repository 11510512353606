import React, { useState } from "react";

import SwitchWithTitle from "../SwitchWithTitle";
import Title from "../../../../components/common/Title";
import { useUser } from "../../../../Store/User";
import { updatePhotographerDetails } from "../../../../apis";

const GuestUpload = () => {
  const { user, setUser } = useUser();
  const [guestUploadValue, setGuestUploadValue] = useState(
    user.photographer.is_guest_upload_enable
  );

  const toggleGuestUploadValue = async () => {
    try {
      setGuestUploadValue((prev) => !prev);
      const data = await updatePhotographerDetails({
        guestUploadEnabled: !guestUploadValue,
      });

      setUser((prev) => ({
        ...prev,
        access_token: data.access_token,
      }));
      window.Toast.fire({
        icon: "success",
        title: "Guest uploads " + (guestUploadValue ? "disabled" : "enabled"),
      });
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        icon: "error",
        toast: "Something went wrong while updating",
      });
    }
  };

  return (
    <>
      <Title
        className="pb-4 border-zinc-700 border-b mb-4 mt-4"
        text="Guest Upload"
      />
      <div className="flex flex-row justify-between gap-32">
        <div className="flex flex-col w-full">
          <SwitchWithTitle
            className=" !justify-start gap-32"
            title="Enable Guest Uploads"
            value={guestUploadValue}
            onChange={toggleGuestUploadValue}
          />
        </div>
      </div>
    </>
  );
};

export default GuestUpload;
