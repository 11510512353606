export  const logoTextColors = [
    {
        'colorName': "Black",
        'colorCode': "#000000"
    },
    {
        'colorName': "Blue",
        'colorCode': "#0000FF"
    },
    {
        'colorName': "Brown",
        'colorCode': "#A52A2A"
    },
    {
        'colorName': "Green",
        'colorCode': "#008000"
    },
    {
        'colorName': "Orange",
        'colorCode': "#FFA500"
    },
    {
        'colorName': "Purple",
        'colorCode': "#800080"
    },
    {
        'colorName': "Red",
        'colorCode': "#FF0000"
    },
    {
        'colorName': "White",
        'colorCode': "#FFFFFF"
    },
    {
        'colorName': "Yellow",
        'colorCode': "#FFFF00"
    }

];


export const defaultPreset = {
    "title": "",
    "logoUrl": null,
    "logoText": "",
    "font": "Monospace",
    "fontColor": "White",
    "scale": 5,
    "opacity": 8,
    "xOffset": "0",
    "yOffset": "0",
    "isDefault": 0,
    "landscapeImagePosition": "north",
    "portraitImagePosition": "center",
    "fullWatermark" : 0
}


export const  waterMarkPlacement = (currentPreset,type)  =>{
    let properties = {
        top: "initial",
        bottom: "initial",
        left: "initial",
        right: "initial",
        'transformOrigin': "left top",
        //'width': '40%',
        color:'#00000',
        fontFamily:'Arial',
        'margin': "0",
        position: "absolute"
    };
    let xmarging = Number(currentPreset.xOffset)
    let ymarging = Number(currentPreset.yOffset)


    xmarging = (xmarging * 40 / 100)
    ymarging = (ymarging * 40 / 100)
    xmarging += "px";
    ymarging += "px";


    properties.transform = "";

    let water_pos = currentPreset.landscapeImagePosition
    if (water_pos === "southwest") {
        properties.left = "0px"
        properties.bottom = "0px"
        properties['transformOrigin'] = "left bottom"
        properties.margin = `0 0 ${ymarging} ${xmarging}`
        properties.textAlign = `left`
    }
    if (water_pos === "southeast") {
        properties.right = "0px"
        properties.bottom = "0px"
        properties['transformOrigin'] = "right bottom"
        properties.margin = `0 ${xmarging} ${ymarging} 0`
        properties.textAlign = `right`
    }
    if (water_pos === "west") {
        properties.left = "0px"
        properties.top = "50%"
        properties.margin = `0 0 ${ymarging} ${xmarging}`
        properties.transform = "translateY(-50%) "
        properties.textAlign = `left`

        properties['transformOrigin'] = "left center"
    }
    if (water_pos === "east") {
        properties.right = "0px"
        properties.top = "50%"
        properties.margin = `0 ${xmarging} ${ymarging} 0`
        properties.transform = "translateY(-50%) "
        properties['transformOrigin'] = "right center"
        properties.textAlign = `right`
    }
    if (water_pos === "northeast") {
        properties.right = "0px"
        properties.top = "0px"
        properties.margin = `${ymarging} ${xmarging}   0  0`

        properties['transformOrigin'] = "right top"
        properties.textAlign = `right`
    }
    if (water_pos === "northwest") {
        properties.left = "0px"
        properties.top = "0px"
        properties['transformOrigin'] = "left top"
        properties.margin = `${ymarging}  0 0 ${xmarging}`
        properties.textAlign = `left`

    }
    if (water_pos === "north") {
        properties.left = "50%"
        properties.top = "0px"
        properties['transformOrigin'] = "top center"
        properties.margin = `${ymarging}  0 0 ${xmarging}`
        properties.transform = "translateX(-50%) "
        properties.textAlign = `center`

    }
    if (water_pos === "south") {
        properties.left = "50%"
        properties.bottom = "0px"
        properties['transformOrigin'] = "bottom center"
        properties.margin = `0 0 ${ymarging}  ${xmarging}`
        properties.transform = "translateX(-50%) "
        properties.textAlign = `center`

    }
    if (water_pos === "center") {
        properties.right = "50%"
        properties.top = "50%"
        properties['transformOrigin'] = "center"
        properties.margin = `0 0 ${ymarging}  ${xmarging}`
        properties.transform = "translate(50%,-50%) "
        properties.textAlign = `center`

    }

    let scaleamt = currentPreset.scale / 10

    if (type=='file' )
        properties.transform += 'scale(' + scaleamt + ')';

    if (type=='text') {

        properties.fontSize = `${currentPreset.scale}px`;
        
        properties.color =  logoTextColors.filter(x=>x.colorName==currentPreset.fontColor)[0].colorCode ;
        properties.fontFamily = currentPreset.font
    }
    
    properties.opacity = (currentPreset.opacity??10) / 10
    return properties
}



export const  waterMarkPlacementPreset = (currentPreset)  =>{
    
    let properties = {
        top: "initial",
        bottom: "initial",
        left: "initial",
        right: "initial",
        'transformOrigin': "left top",
        //'width': '40%',
        color:'#00000',
        fontFamily:'Arial',
        'margin': "0",
        position: "absolute"
    };
    let xmarging = Number(currentPreset.xOffset)
    let ymarging = Number(currentPreset.yOffset)


    xmarging = (xmarging * 40 / 100)
    ymarging = (ymarging * 40 / 100)
    xmarging += "px";
    ymarging += "px";


    properties.transform = "";

    let water_pos = currentPreset.landscapeImagePosition
    if (water_pos === "southwest") {
        properties.left = "0px"
        properties.bottom = "0px"
        properties['transformOrigin'] = "left bottom"
        properties.margin = `0 0 ${ymarging} ${xmarging}`
        properties.textAlign = `left`
    }
    if (water_pos === "southeast") {
        properties.right = "0px"
        properties.bottom = "0px"
        properties['transformOrigin'] = "right bottom"
        properties.margin = `0 ${xmarging} ${ymarging} 0`
        properties.textAlign = `right`
    }
    if (water_pos === "west") {
        properties.left = "0px"
        properties.top = "50%"
        properties.margin = `0 0 ${ymarging} ${xmarging}`
        properties.transform = "translateY(-50%) "
        properties.textAlign = `left`

        properties['transformOrigin'] = "left center"
    }
    if (water_pos === "east") {
        properties.right = "0px"
        properties.top = "50%"
        properties.margin = `0 ${xmarging} ${ymarging} 0`
        properties.transform = "translateY(-50%) "
        properties['transformOrigin'] = "right center"
        properties.textAlign = `right`
    }
    if (water_pos === "northeast") {
        properties.right = "0px"
        properties.top = "0px"
        properties.margin = `${ymarging} ${xmarging}   0  0`

        properties['transformOrigin'] = "right top"
        properties.textAlign = `right`
    }
    if (water_pos === "northwest") {
        properties.left = "0px"
        properties.top = "0px"
        properties['transformOrigin'] = "left top"
        properties.margin = `${ymarging}  0 0 ${xmarging}`
        properties.textAlign = `left`

    }
    if (water_pos === "north") {
        properties.left = "50%"
        properties.top = "0px"
        properties['transformOrigin'] = "top center"
        properties.margin = `${ymarging}  0 0 ${xmarging}`
        properties.transform = "translateX(-50%) "
        properties.textAlign = `center`

    }
    if (water_pos === "south") {
        properties.left = "50%"
        properties.bottom = "0px"
        properties['transform-origin'] = "bottom center"
        properties.margin = `0 0 ${ymarging}  ${xmarging}`
        properties.transform = "translateX(-50%) "
        properties.textAlign = `center`

    }
    if (water_pos === "center") {
        properties.right = "50%"
        properties.top = "50%"
        properties['transformOrigin'] = "center"
        properties.margin = `0 0 ${ymarging}  ${xmarging}`
        properties.transform = "translate(50%,-50%) "
        properties.textAlign = `center`

    }



 
    if (currentPreset.logoText) {

       
        
        properties.color =  logoTextColors.filter(x=>x.colorName==currentPreset.fontColor)[0].colorCode ;
        properties.fontFamily = currentPreset.font
    }
    properties.opacity = currentPreset.opacity / 10
    return properties
}



