import React from "react";

function Title(props) {
  const { className, text = "", count, editButton, showSize=false, size=0 } = props;
  return (
    <div
      className={`flex items-center text-2xl text-secondary-300  ${className}`}
    >
      {text}
      {count ? (
        <span className="ml-2 px-1 py-0.5 rounded bg-black-gray text-xs">
          {count}
        </span>
      ) : (
        <></>
      )}
      {showSize ? (
        <span className="ml-2 px-4 py-0.5 rounded bg-black-gray text-xs font-bold">
          {`${size} gb`}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Title;
