import QRCode from 'qrcode.react';
import { validateEmail,copyText } from "../../../functions";
import { useUser } from  '../../../Store/User';
import { useParams, useHistory, Link } from "react-router-dom";


export const QrCodeComponent = ({qrCodeText,faceEmailDetails,accessType}) =>{
  const {user} = useUser();
  const { eventId } = useParams();
  
    const downloadQRCode = (e) => {
        e.preventDefault()
      const qrCodeURL = document.getElementById('qrCodeEl')
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = "Full_"+faceEmailDetails.event_name+".png";
      if(accessType)
      aEl.download = "Face_"+faceEmailDetails.event_name+".png";

      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    }

    return(
        <div>
        <div className="flex items-center lg:mr-52">
          <div className="text-lg mt-12 ">Download QR Code</div>
          <div className="text-xs mt-12 ml-10">
            <a href="#" onClick={(e)=>downloadQRCode(e)} className="underline text-primary-green font-medium">
              Download
            </a>
          </div>
        </div>
        <div className="lg:w-3/5 grid grid-cols-3 grid-flow-row gap-4">
          <div className="flex grid grid-flow-cols pt-3  cursor-pointer">
          <QRCode
            bgColor={"#ffffff"}
            fgColor={'#000000'}
             id="qrCodeEl"
             className='border-[1px]  rounded-2xl border-[#00b7b8]'
             size={250}
             value={qrCodeText}
             includeMargin={true}
             level={'H'}
            //  imageSettings={ user.photographer.branding_allowed? {
            //   src: "/images/amage.png",
            //   x: null,
            //   y: 220,
            //   height: 35,
            //   width: 140,
            //   excavate: true,
            // }:false}
            />

          </div>
        </div>

        <div className="lg:mr-16">
          <div className="text-lg mt-12">Copy direct link</div>
          <div className="flex justify-between shadow-dark-input text-sm p-2 mt-4 rounded-xl">
            <div className="pl-2">
             {qrCodeText}
            </div>
            <a href="#" onClick={e=>copyText(e,qrCodeText)} className="pr-2 text-xs underline text-primary-green">
              Copy
            </a>
          </div>
          
        </div>
        { (user.subscription.with_app == 1 && accessType == 0) ?
        <div className="lg:mr-16">
          <div className="text-lg mt-12">Event code for Mobile App</div>
          <div className="flex justify-between shadow-dark-input text-xs p-2 mt-4 rounded-xl">
            <div className="pl-2">
             {eventId}
            </div>
            <a href="#" onClick={e=>copyText(e,eventId)} className="pr-2 text-xs underline text-primary-green">
              Copy
            </a>
          </div>
          
        </div>
        :null}
      </div>
    )
}
