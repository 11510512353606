import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import SwitchWithTitle from "../SwitchWithTitle";
import PrintOrdersList from "./PrintOrdersList";
import Title from "../../../../components/common/Title";
import Select from "../../../../components/common/Select";
import Text from "../../../../components/common/Text";
import { InputText } from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import { useUser } from "../../../../Store/User";
import {
  getBankDetails,
  getPayout,
  getStripeURL,
  postMillerPriceChange,
  postStripeConnectDetails,
  updateBankDetails,
  updatePhotographerFramePrices,
  updatePhotographerPrintStore,
  updatePrintStoreCurrency,
} from "../../../../apis";
import Frames from "./Frames";
import Information from "../../../../assets/images/information.png";

const ALL_MILLER_PRODUCT_KEYS = {
  minis: "",
  wallets: "",
  print: {
    "3.5x5": "",
    "4x5": "",
    "4x6": "",
    "5x5": "",
    "5x7": "",
    "6x9": "",
    "8x8": "",
    "8x10": "",
    "8x12": "",
    "10x10": "",
    "10x13": "",
    "12x12": "",
    "10x15": "",
    "5x30": "",
    "11x14": "",
    "10x20": "",
    "12x18": "",
    "12x24": "",
    "10x30": "",
    "16x20": "",
    "16x24": "",
    "20x20": "",
    "20x24": "",
    "20x30": "",
    "24x36": "",
  },
  soho: {
    "3.5x5": "",
    "4x5": "",
    "4x6": "",
    "5x5": "",
    "5x7": "",
    "6x9": "",
    "8x8": "",
    "8x10": "",
    "8x12": "",
    "10x10": "",
    "12x12": "",
    "10x15": "",
    "5x30": "",
    "11x14": "",
    "10x20": "",
    "12x18": "",
    "12x24": "",
    "10x30": "",
    "16x20": "",
    "16x24": "",
    "20x20": "",
    "20x24": "",
    "20x30": "",
    "24x36": "",
  },
};

const BASE_FRAME_USD = {
  minis: 1.6,
  wallets: 2.2,
  print: {
    "3.5x5": 0.4,
    "4x5": 0.5,
    "4x6": 0.5,
    "5x5": 1.7,
    "5x7": 2.2,
    "6x9": 2.9,
    "8x8": 3.4,
    "8x10": 4.4,
    "8x12": 5.3,
    "10x10": 6.6,
    "10x13": 8.6,
    "12x12": 10,
    "10x15": 11.3,
    "5x30": 18.6,
    "11x14": 13,
    "10x20": 15.9,
    "12x18": 19.9,
    "12x24": 20.6,
    "10x30": 49.2,
    "16x20": 31.9,
    "16x24": 33.2,
    "20x20": 35.9,
    "20x24": 42.5,
    "20x30": 53.2,
    "24x36": 86.4,
  },
  soho: {
    "3.5x5": 57.9,
    "4x5": 60.5,
    "4x6": 65.2,
    "5x5": 66.5,
    "5x7": 66.5,
    "6x9": 73.8,
    "8x8": 75.7,
    "8x10": 80.5,
    "8x12": 87.1,
    "10x10": 89.1,
    "12x12": 100.4,
    "10x15": 107.7,
    "5x30": 114.4,
    "11x14": 103.7,
    "10x20": 116.4,
    "12x18": 114.4,
    "12x24": 145,
    "10x30": 192.8,
    "16x20": 155.6,
    "16x24": 186.9,
    "20x20": 196.8,
    "20x24": 218.1,
    "20x30": 228.1,
    "24x36": 270,
  },
  luster: {
    minis: 0.6,
    wallets: 0.6,
    "3.5x5": 0.6,
    "4x5": 0.7,
    "4x6": 0.7,
    "5x5": 0.7,
    "5x7": 0.9,
    "6x9": 1.2,
    "8x8": 1.2,
    "8x10": 2.3,
    "8x12": 2.3,
    "10x10": 2.3,
    "10x13": 2.3,
    "12x12": 2.3,
    "10x15": 2.6,
    "5x30": null,
    "11x14": 2.3,
    "10x20": 2.6,
    "12x18": 2.8,
    "12x24": 3.1,
    "10x30": 4.7,
    "16x20": 3.1,
    "16x24": 4,
    "20x20": 4,
    "20x24": 4,
    "20x30": 4.7,
    "24x36": 9.4,
  },
  "Single Weight Matboard": {
    minis: null,
    wallets: null,
    "3.5x5": 2.5,
    "4x5": 3.5,
    "4x6": 3.5,
    "5x5": 3.7,
    "5x7": 3.7,
    "6x9": 4,
    "8x8": 4,
    "8x10": 4,
    "8x12": 5.6,
    "10x10": 5.6,
    "10x13": null,
    "12x12": null,
    "10x15": null,
    "5x30": null,
    "11x14": 5.6,
    "10x20": null,
    "12x18": null,
    "12x24": null,
    "10x30": null,
    "16x20": null,
    "16x24": null,
    "20x20": null,
    "20x24": null,
    "20x30": null,
    "24x36": null,
  },
  "Double Weight Matboard": {
    minis: null,
    wallets: null,
    "3.5x5": null,
    "4x5": null,
    "4x6": null,
    "5x5": null,
    "5x7": null,
    "6x9": null,
    "8x8": null,
    "8x10": null,
    "8x12": null,
    "10x10": null,
    "10x13": null,
    "12x12": 11.7,
    "10x15": 11.7,
    "5x30": 11.7,
    "11x14": null,
    "10x20": 11.7,
    "12x18": 11.7,
    "12x24": 20.6,
    "10x30": 21.3,
    "16x20": 11.7,
    "16x24": 20.6,
    "20x20": 20.6,
    "20x24": 20.6,
    "20x30": 21.3,
    "24x36": 46.6,
  },
  Mat: {
    minis: null,
    wallets: null,
    "3.5x5": null,
    "4x5": 13.3,
    "4x6": 13.3,
    "5x5": 14,
    "5x7": 15.4,
    "6x9": 20.9,
    "8x8": 19.6,
    "8x10": 4,
    "8x12": 20.9,
    "10x10": 20.9,
    "10x13": null,
    "12x12": 25.1,
    "10x15": 25.1,
    "5x30": null,
    "11x14": 25.1,
    "10x20": 27.9,
    "12x18": 39.1,
    "12x24": 41.9,
    "10x30": 35.9,
    "16x20": 57.3,
    "16x24": 61.4,
    "20x20": 62.8,
    "20x24": 72.6,
    "20x30": 79.6,
    "24x36": null,
  },
  "Clear Glass": {
    minis: null,
    wallets: null,
    "3.5x5": null,
    "4x5": 4.7,
    "4x6": 4.7,
    "5x5": 5.6,
    "5x7": 4.9,
    "6x9": 5.6,
    "8x8": 5.6,
    "8x10": 5.6,
    "8x12": 5.6,
    "10x10": 5.6,
    "10x13": null,
    "12x12": 9.8,
    "10x15": 11.2,
    "5x30": null,
    "11x14": 9.8,
    "10x20": 12.6,
    "12x18": 9.8,
    "12x24": 11.2,
    "10x30": 13.3,
    "16x20": 15.4,
    "16x24": 16.8,
    "20x20": 18.2,
    "20x24": 18.2,
    "20x30": 19.6,
    "24x36": null,
  },
  "Non-Glare Glass": {
    minis: null,
    wallets: null,
    "3.5x5": null,
    "4x5": 6,
    "4x6": 6,
    "5x5": 6.3,
    "5x7": 5.6,
    "6x9": 7,
    "8x8": 7,
    "8x10": 7,
    "8x12": 7,
    "10x10": 8.4,
    "10x13": null,
    "12x12": 12.6,
    "10x15": 8.4,
    "5x30": null,
    "11x14": 8.4,
    "10x20": 15.4,
    "12x18": 14,
    "12x24": 15.4,
    "10x30": 23.4,
    "16x20": 19.6,
    "16x24": 23.7,
    "20x20": 23.7,
    "20x24": 23.7,
    "20x30": 27.9,
    "24x36": null,
  },
};

const PrintSection = () => {
  const { user, setUser, refreshAccessToken } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const accountType = ["savings", "current"];
  const [editBankDetails, setEditBankDetails] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    nameOnAccount: "",
    ifsc: "",
    gst: "",
    accountType: "Select Account Type",
  });
  const [errors, setErrors] = useState({});
  const [isPrintStoreEnable, setIsPrintStoreEnable] = useState(
    user.photographer.show_print_store
  );
  const [accountTypeDropDown, setAccountTypeDropDown] = useState(false);
  const [framePrices, setframePrices] = useState({});
  const [executePhotographerDebounce, setExecutePhotographerDebounce] =
    useState(false);
  const [showFrames, setShowFrames] = useState(false);
  const photographerDebounce = useCallback(
    _.debounce(() => setExecutePhotographerDebounce(true), 1000),
    []
  );
  const [payoutDetails, setPayoutDetails] = useState({});
  const [selectCurrency, setSelectCurrency] = useState(false);
  const [currency, setCurrency] = useState(
    user.photographer.print_store_currency
  );

  const [millerError, setMillerError] = useState(
    structuredClone(ALL_MILLER_PRODUCT_KEYS)
  );
  const [millerFramePrice, setMillerFramePrice] = useState(
    user.photographer.frame_price_usd ?? BASE_FRAME_USD
  );
  const [disableStripeConnect, setDisableStripeConnect] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(
    structuredClone(ALL_MILLER_PRODUCT_KEYS)
  );

  const timer = useRef(structuredClone(ALL_MILLER_PRODUCT_KEYS));

  let currencyDropDown = useRef();
  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!event.target.contains(currencyDropDown.current)) {
        setSelectCurrency(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  });

  useEffect(() => {
    if (executePhotographerDebounce) {
      setExecutePhotographerDebounce(false);

      if (
        framePrices.premium8x8 < user.photographer.frame_base_pricing.premium8x8
      ) {
        setErrors({
          ...errors,
          premium8x8: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.premium8x10 <
        user.photographer.frame_base_pricing.premium8x10
      ) {
        setErrors({
          ...errors,
          premium8x10: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.premium12x15 <
        user.photographer.frame_base_pricing.premium12x15
      ) {
        setErrors({
          ...errors,
          premium12x15: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.premium12x18 <
        user.photographer.frame_base_pricing.premium12x18
      ) {
        setErrors({
          ...errors,
          premium12x18: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.premium16x20 <
        user.photographer.frame_base_pricing.premium16x20
      ) {
        setErrors({
          ...errors,
          premium16x20: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.classic4x4 < user.photographer.frame_base_pricing.classic4x4
      ) {
        setErrors({
          ...errors,
          classic4x4: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.classic4x6 < user.photographer.frame_base_pricing.classic4x6
      ) {
        setErrors({
          ...errors,
          classic4x6: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.classic55x55 <
        user.photographer.frame_base_pricing.classic55x55
      ) {
        setErrors({
          ...errors,
          classic55x55: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.classic5x7 < user.photographer.frame_base_pricing.classic5x7
      ) {
        setErrors({
          ...errors,
          classic5x7: "Cannot be empty or less than base price",
        });
      }
      if (
        framePrices.classic8x10 <
        user.photographer.frame_base_pricing.classic8x10
      ) {
        setErrors({
          ...errors,
          classic8x10: "Cannot be empty or less than base price",
        });
      }

      if (
        framePrices.premium8x8 == "" ||
        framePrices.premium8x10 == "" ||
        framePrices.premium12x15 == "" ||
        framePrices.premium12x18 == "" ||
        framePrices.premium16x20 == "" ||
        framePrices.classic4x4 == "" ||
        framePrices.classic4x6 == "" ||
        framePrices.classic55x55 == "" ||
        framePrices.classic5x7 == "" ||
        framePrices.classic8x10 == "" ||
        framePrices.premium8x8 <
          user.photographer.frame_base_pricing.premium8x8 ||
        framePrices.premium8x10 <
          user.photographer.frame_base_pricing.premium8x10 ||
        framePrices.premium12x15 <
          user.photographer.frame_base_pricing.premium12x15 ||
        framePrices.premium12x18 <
          user.photographer.frame_base_pricing.premium12x18 ||
        framePrices.premium16x20 <
          user.photographer.frame_base_pricing.premium16x20 ||
        framePrices.classic4x4 <
          user.photographer.frame_base_pricing.classic4x4 ||
        framePrices.classic4x6 <
          user.photographer.frame_base_pricing.classic4x6 ||
        framePrices.classic55x55 <
          user.photographer.frame_base_pricing.classic55x55 ||
        framePrices.classic5x7 <
          user.photographer.frame_base_pricing.classic5x7 ||
        framePrices.classic8x10 <
          user.photographer.frame_base_pricing.classic8x10
      ) {
        return;
      }

      updatePhotographer();
      setframePrices({ ...framePrices, isUpdated: false });
    }
  }, [executePhotographerDebounce]);

  const getPayoutDetails = async () => {
    try {
      const data = await getPayout();
      setPayoutDetails(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPayoutDetails();
  }, []);

  function toggleCurrencyDropdown(e) {
    e.stopPropagation();
    setSelectCurrency(!selectCurrency);
  }

  function updatePhotographer() {
    updatePhotographerFramePrices(framePrices)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Frame prices updated successfully",
        });
        setUser({ ...user, access_token: response.access_token });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error updating frame prices",
        });
      });
  }

  useEffect(() => {
    setIsPrintStoreEnable(user.photographer.show_print_store);
  }, [user.photographer.show_print_store]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      // setCurrency(user.photographer.print_store_currency);
      setframePrices({
        ...framePrices,
        premium8x8: user.photographer.frame_prices
          ? user.photographer.frame_prices.premium8x8
          : user.photographer.frame_base_pricing.premium8x8,
        premium8x10: user.photographer.frame_prices
          ? user.photographer.frame_prices.premium8x10
          : user.photographer.frame_base_pricing.premium8x10,
        premium12x15: user.photographer.frame_prices
          ? user.photographer.frame_prices.premium12x15
          : user.photographer.frame_base_pricing.premium12x15,
        premium12x18: user.photographer.frame_prices
          ? user.photographer.frame_prices.premium12x18
          : user.photographer.frame_base_pricing.premium12x18,
        premium16x20: user.photographer.frame_prices
          ? user.photographer.frame_prices.premium16x20
          : user.photographer.frame_base_pricing.premium16x20,
        classic4x4: user.photographer.frame_prices
          ? user.photographer.frame_prices.classic4x4
          : user.photographer.frame_base_pricing.classic4x4,
        classic4x6: user.photographer.frame_prices
          ? user.photographer.frame_prices.classic4x6
          : user.photographer.frame_base_pricing.classic4x6,
        classic55x55: user.photographer.frame_prices
          ? user.photographer.frame_prices.classic55x55
          : user.photographer.frame_base_pricing.classic55x55,
        classic5x7: user.photographer.frame_prices
          ? user.photographer.frame_prices.classic5x7
          : user.photographer.frame_base_pricing.classic5x7,
        classic8x10: user.photographer.frame_prices
          ? user.photographer.frame_prices.classic8x10
          : user.photographer.frame_base_pricing.classic8x10,
      });
    }
  }, [user]);

  useEffect(() => {
    getBankDetails(user.user.id)
      .then(function (response) {
        if (response.data)
          setBankDetails({
            bankName: response.data.data.bankName,
            accountNumber: response.data.data.accountNumber,
            nameOnAccount: response.data.data.nameOnAccount,
            ifsc: response.data.data.ifsc,
            gst: response.data.data.gst,
            accountType: response.data.data.accountType,
          });
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setBankDetails({
            bankName: "",
            accountNumber: "",
            nameOnAccount: "",
            ifsc: "",
            gst: "",
            accountType: "Current",
          });
          setEditBankDetails(true);
        }
      });
  }, [user.user.id]);

  const handleUpdateBankDetails = (e) => {
    e.preventDefault();
    if (
      bankDetails.bankName === "" ||
      bankDetails.bankName === null ||
      bankDetails.bankName === undefined
    ) {
      setErrors({ ...errors, bankName: "Cannot be empty" });
      return false;
    }
    if (bankDetails.accountNumber === "") {
      setErrors({ ...errors, accountNumber: "Cannot be empty" });
      return false;
    }
    if (bankDetails.nameOnAccount === "") {
      setErrors({ ...errors, nameOnAccount: "Cannot be empty" });
      return false;
    }
    if (bankDetails.ifsc === "") {
      setErrors({ ...errors, ifsc: "Cannot be empty" });
      return false;
    }
    if (bankDetails.accountType === "") {
      setErrors({ ...errors, accountType: "Cannot be empty" });
      return false;
    }

    if (bankDetails.gst) {
      //regex for gst
      if (
        !bankDetails.gst.match(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
        )
      ) {
        setErrors({ ...errors, gst: "Invalid GST" });
        return false;
      }
    }

    if (bankDetails.ifsc) {
      //regex for ifsc
      if (!bankDetails.ifsc.match(/^[A-Z]{4}0[A-Z0-9]{6}$/)) {
        setErrors({ ...errors, ifsc: "Invalid IFSC" });
        return false;
      }
    }

    updateBankDetails(user.user.id, bankDetails)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Bank details updated successfully",
        });
        setEditBankDetails(false);
      })
      .catch(function (error) {
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
        if (error.response?.data?.message?.includes("bankName")) {
          setErrors({
            ...errors,
            bankName: error.response?.data?.message,
          });
        }
        if (error.response?.data?.message?.includes("accountNumber")) {
          setErrors({
            ...errors,
            accountNumber: error.response?.data?.message,
          });
        }
        if (error.response?.data?.message?.includes("nameOnAccount")) {
          setErrors({
            ...errors,
            nameOnAccount: error.response?.data?.message,
          });
        }
        if (error.response?.data?.message?.includes("ifsc")) {
          setErrors({ ...errors, ifsc: error.response?.data?.message });
        }
        if (error.response?.data?.message?.includes("accountType")) {
          setErrors({
            ...errors,
            accountType: error.response?.data?.message,
          });
        }
        if (error.response?.data?.message?.includes("gst")) {
          setErrors({ ...errors, gst: error.response?.data?.message });
        }
      });
  };

  const handlePrintStore = (e) => {
    let status = "";
    e === false ? (status = "0") : (status = "1");
    setIsPrintStoreEnable(e);
    updatePhotographerPrintStore(status)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Print Store Updated Successfully",
        });
        setUser({ ...user, access_token: response.access_token });
      })
      .catch(function (error) {
        e !== false ? (status = "0") : (status = "1");
        setIsPrintStoreEnable(e);
        window.Toast.fire({
          icon: "error",
          title: "Print store could not be updated",
        });
      });
  };

  // below code closes the add tag options popup when clicked outside
  let accountTypeDrpD = useRef();

  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!event.target.contains(accountTypeDrpD.current)) {
        setAccountTypeDropDown(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  });
  // console.log(user,'user')

  const handleKeyDown = (e) => {
    const re = /^[0-9][\.\d]*(,\d+)?$/;
    const checkForMultipleDots =
      e.includes(".") && e.match(/[.]/g || [])?.length > 1;
    if (checkForMultipleDots) return false;
    if (e === "" || re.test(e)) {
      return true;
    }
    return false;
  };

  const headers = [
    "Frame type",
    "Frame size",
    "Base price",
    "Set your own price",
  ];

  function updateCurrency(status) {
    setSelectCurrency(!selectCurrency);
    updatePrintStoreCurrency(status)
      .then(function (response) {
        setCurrency(status);
        localStorage.setItem("access_token", response.access_token);
        setUser({ ...user, access_token: response.access_token });
        window.Toast.fire({
          icon: "success",
          title: "Print Store currency updated",
        });
      })
      .catch(() => {
        window.Toast.fire({
          icon: "error",
          title: "Error in updating print store currency",
        });
        setSelectCurrency(!selectCurrency);
      });
  }

  const updateMillerPrice = async (frame, value, parent) => {
    let postData = {};

    if (parent) {
      postData = {
        ...millerFramePrice,
        [parent]: {
          ...millerFramePrice[parent],
          [frame]: value,
        },
      };
    } else
      postData = {
        ...millerFramePrice,
        [frame]: value,
      };
    Object.keys(postData).forEach((el) => {
      if (typeof postData[el] === "object") {
        Object.keys(postData[el]).forEach((e) => {
          postData[el][e] = +postData[el][e];
        });
      } else postData[el] = +postData[el];
    });
    try {
      postData.soho["s35x5"] = postData.soho["3.5x5"]
      postData.print["p35x5"] = postData.print["3.5x5"]
      const { data } = await postMillerPriceChange(postData);
      window.Toast.fire({
        icon: "success",
        title: "Price updated successfully",
      });
      localStorage.setItem("access_token", data.access_token);
      setUser({ ...user, access_token: data.access_token });
    } catch (error) {
      console.error(error)
      window.Toast.fire({
        icon: "error",
        title: "Internal server error",
      });
    }
  };

  const debounceNestedObjectReq = (frame, value, parent) => {
    if (timer.current[parent][frame]) {
      clearTimeout(timer.current[parent][frame]);
    }
    const id = setTimeout(() => {
      if ((value.match(/[.]/g) || []).length > 1) {
        setMillerError((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [frame]: "Invalid number",
          },
        }));
        return;
      }
      if (BASE_FRAME_USD[parent][frame] > +value) {
        setMillerError((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [frame]: "Cannot be empty or less than base price",
          },
        }));
        return;
      }
      updateMillerPrice(frame, value, parent);
    }, 3000);
    timer.current[parent][frame] = id;
  };

  const updateNestedMiller = (frame, value, parent) => {
    if (
      //Checks for double dot and other regex check if there's any value after dot or not
      (value.match(/[.]/g) || []).length > 1 ||
      !(value.match(/^\d*\.?\d+$/) || []).length > 0
    ) {
      setMillerError((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [frame]: "Invalid number",
        },
      }));
      return;
    }
    if (BASE_FRAME_USD[parent][frame] > +value) {
      setMillerError((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [frame]: "Cannot be empty or less than base price",
        },
      }));
      return;
    }
    updateMillerPrice(frame, value, parent);
    setMillerError((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [frame]: "",
      },
    }));
    setShowSaveButton((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [frame]: false,
      },
    }));
  };

  const updateMiller = (frame, value) => {
    if (
      (value.match(/[.]/g) || []).length > 1 ||
      !(value.match(/^\d*\.?\d+$/) || []).length > 0
    ) {
      setMillerError((prev) => ({
        ...prev,
        [frame]: "Invalid Number",
      }));
      return;
    }
    if (BASE_FRAME_USD[frame] > +value) {
      setMillerError((prev) => ({
        ...prev,
        [frame]: "Cannot be empty or less than base price",
      }));
      return;
    }
    updateMillerPrice(frame, value);
    setMillerError((prev) => ({
      ...prev,
      [frame]: "",
    }));
    setShowSaveButton((prev) => ({
      ...prev,
      [frame]: false,
    }));
  };

  const debounceRequest = (frame, value) => {
    if (timer.current[frame]) {
      clearTimeout(timer.current[frame]);
    }
    const id = setTimeout(() => {
      if ((value.match(/[.]/g) || []).length > 1) {
        setMillerError((prev) => ({
          ...prev,
          [frame]: "Invalid Number",
        }));
        return;
      }
      if (BASE_FRAME_USD[frame] > +value) {
        setMillerError((prev) => ({
          ...prev,
          [frame]: "Cannot be empty or less than base price",
        }));
        return;
      }
      updateMillerPrice(frame, value);
    }, 3000);
    timer.current[frame] = id;
  };

  const connectWithStripe = async () => {
    try {
      setDisableStripeConnect(true);
      const { data } = await getStripeURL();
      window.location.href = data;
    } catch (error) {
      console.log(error);
    } finally {
      setDisableStripeConnect(false);
    }
  };

  const callStripeCallback = async (scope, code, state) => {
    const postData = { scope, code, state };
    try {
      await postStripeConnectDetails(postData);
      window.Toast.fire({
        icon: "sucess",
        title: "Your stripe account is connected successfully",
      });
      refreshAccessToken();
      searchParams.delete("scope");
      searchParams.delete("code");
      searchParams.delete("state");
      setSearchParams(searchParams);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const scope = searchParams.get("scope");
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (scope && code && state) {
      callStripeCallback(scope, code, state);
    }
  }, []);

  return (
    <>
      <Title
        className="pb-4 border-zinc-700 border-b mb-4 mt-4"
        text="Print Store"
      />
      <div className="flex flex-col">
        <SwitchWithTitle
          key={"print-store"}
          className="mb-4 w-[29rem]"
          title="Enable print store"
          value={isPrintStoreEnable}
          onChange={(e) => {
            handlePrintStore(e);
          }}
        />
        {!!isPrintStoreEnable && (
          <div className="flex flex-col mb-8">
            <div className="flex flex-row items-center mb-4">
              <div className="flex flex-col basis-1/2">
                <div className="flex basis-1/2">
                  <Text as="p" className="font-medium">
                    Currency{" "}
                    <span className="text-xs">
                      {" "}
                      (
                      {currency == "usd"
                        ? "If currency is USD. Frames will be delivered only in USA"
                        : "If currency is INR. Frames will be delivered only in INDIA"}
                      )
                    </span>
                  </Text>
                </div>
                <div className="flex w-full" ref={currencyDropDown}>
                  <div className="w-full mb-[50px]">
                    <div className="relative max-w-[28rem]">
                      <Select
                        onClick={toggleCurrencyDropdown}
                        text={
                          currency
                            ? ["usd", "USD"].includes(currency)
                              ? "USD"
                              : "INR"
                            : "Select Currency"
                        }
                        className="mt-4"
                        isOpen={selectCurrency}
                      />
                      {selectCurrency && (
                        <div className="absolute w-full rounded-[10px] overflow-hidden bg-dark-gray shadow-card z-40 right-15 mt-3 top-[99%]">
                          <div className="flex select-none flex-col text-light-gray text-sm">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                updateCurrency("inr");
                              }}
                              className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                            >
                              INR
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                updateCurrency("usd");
                              }}
                              className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                            >
                              USD
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center mb-4">
              <Text as="p" className="text-md pr-4 font-medium">
                Frame Prices
              </Text>
              <span
                className="cursor-pointer text-xs text-primary-green"
                onClick={(e) => {
                  setShowFrames(!showFrames);
                }}
              >
                View all frames
              </span>
            </div>

            {showFrames && (
              <Frames
                showFrames={showFrames}
                setShowFrames={setShowFrames}
                currency={currency}
              />
            )}

            <ul className="flex flex-col mb-4">
              <li className="flex flex-row bg-black1 h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                {headers.map((item) => (
                  <div className="basis-1/6  pr-8" key={item}>
                    <Text as="p" className="text-base font-medium">
                      {item}
                    </Text>
                  </div>
                ))}
              </li>

              {currency === "usd" ? (
                <>
                  {Object.keys(ALL_MILLER_PRODUCT_KEYS.soho).map((el, id) => (
                    <li
                      className={`flex flex-row my-4 font-medium  ${
                        showSaveButton.soho[el] ? "h-[90px] mb-8" : "h-[4rem]"
                      }  items-center text-base px-[1.25rem] rounded-md justify-between
                      ${
                        id + 1 ===
                          Object.keys(ALL_MILLER_PRODUCT_KEYS.soho).length &&
                        showSaveButton.soho[el]
                          ? "!h-[130px] !mt-8 !items-baseline"
                          : ""
                      }
                      ${
                        id + 1 ===
                        Object.keys(ALL_MILLER_PRODUCT_KEYS.soho).length
                          ? "border-b border-black-gray"
                          : ""
                      }`}
                      key={el + id}
                    >
                      <div className="basis-1/6  pr-8">
                        {id === 9 ? "Soho" : ""}
                      </div>
                      <div className="basis-1/6  pr-8">{el}</div>
                      <div className="basis-1/6  pr-8">
                        $ {BASE_FRAME_USD.soho[el]}
                      </div>
                      <div className="basis-1/6 relative pr-8">
                        <InputText
                          className="relative"
                          value={millerFramePrice.soho[el]}
                          error={millerError.soho[el]}
                          onChange={(e) => {
                            if (handleKeyDown(e)) {
                              setMillerError((prev) => ({
                                ...prev,
                                soho: {
                                  ...prev.soho,
                                  [el]: "",
                                },
                              }));
                              setMillerFramePrice((prev) => ({
                                ...prev,
                                soho: {
                                  ...prev.soho,
                                  [el]: e.split(".")[1]
                                    ? parseFloat(e).toFixed(1).toString()
                                    : e,
                                },
                              }));
                              setShowSaveButton((prev) => ({
                                ...prev,
                                soho: {
                                  ...prev.soho,
                                  [el]: true,
                                },
                              }));
                              // debounceNestedObjectReq(el, e, "soho");
                            }
                          }}
                        />
                        <label className="absolute text-error-red text-xs">
                          {millerError.soho[el]}
                        </label>
                        {showSaveButton.soho[el] ? (
                          <button
                            onClick={() =>
                              updateNestedMiller(
                                el,
                                millerFramePrice["soho"][el],
                                "soho"
                              )
                            }
                            className="absolute shadow-button p-2 px-4 rounded-lg top-[calc(100%+36px)] left-1/2 -translate-x-[calc(50%+16px)]"
                          >
                            {" "}
                            Save{" "}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </li>
                  ))}
                  <li
                    className={`flex flex-row my-4 font-medium  ${
                      showSaveButton.minis
                        ? "h-[130px]"
                        : "h-[4rem] items-center"
                    }   text-base px-[1.25rem] rounded-md justify-between border-b border-black-gray`}
                  >
                    <div className="basis-1/6  pr-8">Minis</div>
                    <div className="basis-1/6  pr-8">--</div>
                    <div className="basis-1/6  pr-8">
                      $ {BASE_FRAME_USD.minis}
                    </div>
                    <div className="basis-1/6 relative pr-8">
                      <InputText
                        className="relative"
                        value={millerFramePrice.minis}
                        error={millerError.minis}
                        onChange={(e) => {
                          if (handleKeyDown(e)) {
                            setMillerError((prev) => ({ ...prev, minis: "" }));
                            setMillerFramePrice((prev) => ({
                              ...prev,
                              minis: e.split(".")[1]
                                ? parseFloat(e).toFixed(1).toString()
                                : e,
                            }));
                            setShowSaveButton((prev) => ({
                              ...prev,
                              minis: true,
                            }));

                            // debounceRequest("minis", e);
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {millerError.minis}
                      </label>
                      {showSaveButton.minis ? (
                        <button
                          onClick={() =>
                            updateMiller("minis", millerFramePrice.minis)
                          }
                          className="absolute shadow-button p-2 px-4 bottom-[10px] rounded-lg  left-1/2 -translate-x-[calc(50%+16px)]"
                        >
                          {" "}
                          Save{" "}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                  <li
                    className={`flex flex-row my-4 font-medium  ${
                      showSaveButton.wallets
                        ? "h-[130px] mb-8"
                        : "h-[4rem] items-center"
                    }   text-base px-[1.25rem] rounded-md justify-between border-b border-black-gray`}
                  >
                    <div className="basis-1/6  pr-8">Wallets</div>
                    <div className="basis-1/6  pr-8">--</div>
                    <div className="basis-1/6  pr-8">
                      $ {BASE_FRAME_USD.wallets}
                    </div>
                    <div className="basis-1/6 relative pr-8">
                      <InputText
                        className="relative"
                        value={millerFramePrice.wallets}
                        error={millerError.wallets}
                        onChange={(e) => {
                          if (handleKeyDown(e)) {
                            setMillerError((prev) => ({
                              ...prev,
                              wallets: "",
                            }));
                            setMillerFramePrice((prev) => ({
                              ...prev,
                              wallets: e.split(".")[1]
                                ? parseFloat(e).toFixed(1).toString()
                                : e,
                            }));
                            setShowSaveButton((prev) => ({
                              ...prev,
                              wallets: true,
                            }));
                            // debounceRequest("wallets", e);
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {millerError.wallets}
                      </label>
                      {showSaveButton.wallets ? (
                        <button
                          onClick={() =>
                            updateMiller("wallets", millerFramePrice.wallets)
                          }
                          className="absolute shadow-button p-2 px-4 rounded-lg bottom-[10px] left-1/2 -translate-x-[calc(50%+16px)]"
                        >
                          {" "}
                          Save{" "}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                  {Object.keys(ALL_MILLER_PRODUCT_KEYS.print).map((el, id) => (
                    <li
                      className={`flex flex-row my-4 font-medium  ${
                        showSaveButton.print[el] ? "h-[90px] mb-8" : "h-[4rem]"
                      }  items-center text-base px-[1.25rem] rounded-md justify-between ${
                        id + 1 ===
                          Object.keys(ALL_MILLER_PRODUCT_KEYS.print).length &&
                        showSaveButton.print[el]
                          ? "!h-[130px] !mt-8 !items-baseline"
                          : ""
                      }  ${
                        id + 1 ===
                        Object.keys(ALL_MILLER_PRODUCT_KEYS.print).length
                          ? "border-b border-black-gray"
                          : ""
                      }`}
                      key={el}
                    >
                      <div className="basis-1/6  pr-8 mb-12">
                        {id === 10 ? "Print" : ""}
                      </div>
                      <div className="basis-1/6  pr-8">{el}</div>
                      <div className="basis-1/6  pr-8">
                        $ {BASE_FRAME_USD.print[el]}
                      </div>
                      <div className="basis-1/6 relative pr-8">
                        <InputText
                          className="relative"
                          value={millerFramePrice.print[el]}
                          error={millerError.print[el]}
                          onChange={(e) => {
                            if (handleKeyDown(e)) {
                              setMillerError((prev) => ({
                                ...prev,
                                print: {
                                  ...prev.print,
                                  [el]: "",
                                },
                              }));
                              setMillerFramePrice((prev) => ({
                                ...prev,
                                print: {
                                  ...prev.print,
                                  [el]: e.split(".")[1]
                                    ? parseFloat(e).toFixed(1).toString()
                                    : e,
                                },
                              }));
                              setShowSaveButton((prev) => ({
                                ...prev,
                                print: {
                                  ...prev.print,
                                  [el]: true,
                                },
                              }));

                              // debounceNestedObjectReq(el, e, "print");
                            }
                          }}
                        />
                        <label className="absolute text-error-red text-xs">
                          {millerError.print[el]}
                        </label>
                        {showSaveButton.print[el] ? (
                          <button
                            onClick={() =>
                              updateNestedMiller(
                                el,
                                millerFramePrice["print"][el],
                                "print"
                              )
                            }
                            className="absolute shadow-button p-2 px-4 rounded-lg top-[calc(100%+36px)] left-1/2 -translate-x-[calc(50%+16px)]"
                          >
                            {" "}
                            Save{" "}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">8x8</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.premium8x8}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.premium8x8}
                        onChange={(value) => {
                          setErrors({ ...errors, premium8x8: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  premium8x8: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  premium8x8: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {errors.premium8x8}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">8x10</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.premium8x10}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.premium8x10}
                        onChange={(value) => {
                          setErrors({ ...errors, premium8x10: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  premium8x10: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  premium8x10: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <div>
                        <label className="absolute text-error-red text-xs">
                          {errors.premium8x10}
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8">Premium</div>
                    <div className="basis-1/6  pr-8">12x15</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.premium12x15}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.premium12x15}
                        onChange={(value) => {
                          setErrors({ ...errors, premium12x15: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  premium12x15: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  premium12x15: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {errors.premium12x15}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">12x18</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.premium12x18}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.premium12x18}
                        onChange={(value) => {
                          setErrors({ ...errors, premium12x18: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  premium12x18: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  premium12x18: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {errors.premium12x18}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-14 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between border-b border-black-gray ">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">16x20</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.premium16x20}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.premium16x20}
                        onChange={(value) => {
                          setErrors({ ...errors, premium16x20: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  premium16x20: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  premium16x20: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="absolute text-error-red text-xs">
                        {errors.premium16x20}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 mt-2 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">4x4</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.classic4x4}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.classic4x4}
                        onChange={(value) => {
                          setErrors({ ...errors, classic4x4: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  classic4x4: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  classic4x4: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="text-error-red absolute text-xs">
                        {errors.classic4x4}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">4x6</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.classic4x6}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.classic4x6}
                        onChange={(value) => {
                          setErrors({ ...errors, classic4x6: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  classic4x6: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  classic4x6: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="text-error-red absolute text-xs">
                        {errors.classic4x6}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8">Classic</div>
                    <div className="basis-1/6  pr-8">5.5x5.5</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.classic55x55}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.classic55x55}
                        onChange={(value) => {
                          setErrors({ ...errors, classic55x55: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  classic55x55: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  classic55x55: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="text-error-red absolute text-xs">
                        {errors.classic55x55}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-12 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">5x7</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.classic5x7}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.classic5x7}
                        onChange={(value) => {
                          setErrors({ ...errors, classic5x7: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  classic5x7: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  classic5x7: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="text-error-red absolute text-xs">
                        {errors.classic5x7}
                      </label>
                    </div>
                  </li>
                  <li className="flex flex-row pt-8 pb-14 items-center font-medium text-sm px-[1.25rem] h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md justify-between border-b border-black-gray">
                    <div className="basis-1/6  pr-8"></div>
                    <div className="basis-1/6  pr-8">8x10</div>
                    <div className="basis-1/6  pr-8">
                      Rs. {user.photographer.frame_base_pricing.classic8x10}
                    </div>
                    <div className="basis-1/6  pr-8">
                      <InputText
                        className="relative"
                        value={framePrices.classic8x10}
                        onChange={(value) => {
                          setErrors({ ...errors, classic8x10: null });
                          if (handleKeyDown(value)) {
                            value
                              ? setframePrices({
                                  ...framePrices,
                                  classic8x10: parseInt(value),
                                })
                              : setframePrices({
                                  ...framePrices,
                                  classic8x10: "",
                                });
                            photographerDebounce();
                          }
                        }}
                      />
                      <label className="text-error-red absolute text-xs">
                        {errors.classic8x10}
                      </label>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        <div className="flex flex-col">
          {currency === "inr" ? (
            <div className="flex flex-row justify-start items-center ">
              <Text as="p" className="text-md pr-4 font-medium">
                Bank account details
              </Text>
              <button
                onClick={() => {
                  setEditBankDetails(!editBankDetails);
                }}
              >
                <div className="text-primary-green text-xs font-semibold underline">
                  {editBankDetails ? "Hide" : "Edit"}{" "}
                </div>
              </button>
              <div data-tip data-for="expiryDate">
                <img
                  className="cursor-pointer ml-2 w-4 h-4"
                  src={Information}
                  alt="Info Icon"
                />
              </div>
              <ReactTooltip id="expiryDate" type="info">
                <span className="text-justify">
                  We collect your bank account details to remit print store sale
                  commission to you. <br /> Remittance will be made everytime
                  the commission crosses Rs 10000
                </span>
              </ReactTooltip>
            </div>
          ) : user?.user?.stripe_connect_id ? (
            <Text as="p" className="text-xl pr-4 mb-4 font-bold">
              Payout details
            </Text>
          ) : (
            <>
              <Text as="p" className="text-xl pr-4 mb-4 font-bold">
                Payout details
              </Text>
              <div>
                <Button
                  onClick={connectWithStripe}
                  disabled={disableStripeConnect}
                  text="Connect with Stripe"
                />
              </div>
            </>
          )}
          {editBankDetails ? (
            <div className="flex flex-col justify-between">
              <div className="flex justify-between gap-32">
                <div className="flex flex-col w-full">
                  <InputText
                    className="mt-4"
                    type="text"
                    label="Bank Name"
                    onChange={(e) => {
                      setBankDetails({ ...bankDetails, bankName: e });
                      setErrors({ ...errors, bankName: null });
                    }}
                    value={bankDetails.bankName}
                    error={errors.bankName}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <InputText
                    className="mt-4 "
                    type="text"
                    label="Account Number"
                    onChange={(e) => {
                      setBankDetails({ ...bankDetails, accountNumber: e });
                      setErrors({ ...errors, accountNumber: null });
                    }}
                    value={bankDetails.accountNumber}
                    error={errors.accountNumber}
                  />
                </div>
              </div>

              <div className="flex my-10 justify-between gap-32">
                <div className="flex flex-col w-full">
                  <InputText
                    className="mt-4"
                    type="text"
                    label="Account Name"
                    onChange={(e) => {
                      setBankDetails({ ...bankDetails, nameOnAccount: e });
                      setErrors({ ...errors, nameOnAccount: null });
                    }}
                    value={bankDetails.nameOnAccount}
                    error={errors.nameOnAccount}
                  />
                </div>
                <div className="flex flex-col w-full mt-2">
                  <Text
                    as="p"
                    className="text-gray text-sm flex justify-between pl-4"
                  >
                    Account Type*
                  </Text>
                  <div className="relative" ref={accountTypeDrpD}>
                    <Select
                      text={bankDetails.accountType}
                      className="mt-2 py-3 rounded-lg text-capitalize text-sm max-w-[28rem]"
                      isOpen={accountTypeDropDown}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAccountTypeDropDown((prev) => !prev);
                      }}
                    />

                    <div className="absolute w-full rounded-xl bg-dark-gray shadow-dark-input z-40 right-15 mt-3 top-[99%] overflow-hidden max-w-[28rem]">
                      <div className="flex select-none flex-col  text-light-gray text-sm">
                        {accountTypeDropDown &&
                          accountType.map((item, index) => {
                            return (
                              <div
                                className={`cursor-pointer capitalize px-4 py-3 hover:bg-black ${
                                  bankDetails.accountType === item
                                    ? "bg-black text-primary-green font-medium"
                                    : "text-gray-600"
                                }`}
                                key={index}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBankDetails({
                                    ...bankDetails,
                                    accountType: item,
                                  });
                                  setAccountTypeDropDown(false);
                                }}
                              >
                                {item}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-32">
                <div className="flex flex-col w-full ">
                  <InputText
                    className="mt-4"
                    type="text"
                    label="Bank IFSC"
                    onChange={(e) => {
                      setBankDetails({ ...bankDetails, ifsc: e });
                      setErrors({ ...errors, ifsc: null });
                    }}
                    value={bankDetails.ifsc}
                    error={errors.ifsc}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <InputText
                    className="mt-4"
                    type="text"
                    label="GST Number"
                    onChange={(e) => {
                      setBankDetails({ ...bankDetails, gst: e });
                      setErrors({ ...errors, gst: null });
                    }}
                    value={bankDetails.gst}
                    error={errors.gst}
                    required={false}
                  />
                </div>
              </div>
              <div className="flex mt-8">
                <div className="flex flex-col w-1/2 justify-end">
                  <Button
                    text="Save"
                    onClick={(e) => {
                      handleUpdateBankDetails(e);
                    }}
                    disabled={
                      !bankDetails.bankName ||
                      !bankDetails.accountNumber ||
                      !bankDetails.nameOnAccount ||
                      !bankDetails.accountType ||
                      !bankDetails.ifsc
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {!editBankDetails && currency === "inr" ? (
            <div className="flex flex-row justify-between items-center mb-8 mt-6">
              <div className="flex flex-col">
                <Text as="p" className="text-base font-medium mb-4 ">
                  Account number
                </Text>
                <Text className="text-sm">
                  {bankDetails.accountNumber.replace(/.(?=.{3})/g, "x") || "--"}
                </Text>
              </div>

              <div className="flex flex-col">
                <Text as="p" className="text-base font-medium mb-4">
                  Account Name
                </Text>
                <Text className="text-sm">
                  {bankDetails.nameOnAccount || "--"}
                </Text>
              </div>

              <div className="flex flex-col">
                <Text as="p" className="text-base font-medium mb-4">
                  Bank IFSC
                </Text>
                <Text className="text-sm">
                  {bankDetails.ifsc.replace(/.(?=.{4})/g, "x") || "--"}
                </Text>
              </div>

              <div className="flex flex-col">
                <Text as="p" className="text-base font-medium mb-4">
                  Account Type
                </Text>
                <Text className="text-sm capitalize">
                  {bankDetails.accountType == "Select Account Type"
                    ? ""
                    : bankDetails.accountType}
                </Text>
              </div>
            </div>
          ) : user?.user?.stripe_connect_id ? (
            "Your stripe account is ready to receive payment"
          ) : (
            ""
          )}
        </div>

        {payoutDetails ? (
          <>
            <Text as="span" className="text-md mt-4 pr-4 font-medium">
              Payment Details
            </Text>
            <div className="flex mt-4 gap-20">
              <div className="">
                <div className="text-light-gray  text-base font-medium whitespace-nowrap">
                  Type
                </div>
                <div className=" text-center text-sm text-gray">
                  Frames(INR)
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  Frames(USD)
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  Digital(INR)
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  Digital(USD)
                </div>
              </div>
              <div className="">
                <div className="text-light-gray  text-base font-medium whitespace-nowrap">
                  Total Amount(After Frame Cost)
                </div>
                <div className=" text-center text-sm text-gray">
                  Rs. {payoutDetails?.afterFrameDeduction}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  ${payoutDetails?.afterMillerDeduction}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  Rs. {payoutDetails?.InrDigital}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  ${payoutDetails?.usdDigital}
                </div>
              </div>

              <div className="">
                <div className="text-light-gray  text-base font-medium whitespace-nowrap">
                  Algomage Deduction
                </div>
                <div className=" text-center text-sm text-gray">
                  Rs. {payoutDetails?.framealgomagePayout}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  $ {payoutDetails?.milleralgomagePayout}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  Rs. {payoutDetails?.algomageInrDigitalPayout}
                </div>
                <div className=" text-center text-sm text-gray mt-4">
                  ${payoutDetails?.algomageUsdDigitalPayout}
                </div>
              </div>
              <div className="">
                <div className="text-light-gray  text-base font-medium whitespace-nowrap">
                  Net payable amount
                </div>
                <div className="text-center text-sm text-gray">
                  Rs. {payoutDetails?.framephotographerPayout}
                </div>
                <div className="text-center text-sm text-gray mt-4">
                  ${payoutDetails?.millerphotographerPayout}
                </div>
                <div className="text-center text-sm text-gray mt-4">
                  Rs. {payoutDetails?.digitalInrPhotographerPayout}
                </div>
                <div className="text-center text-sm text-gray mt-4">
                  ${payoutDetails?.digitalUsdPhotographerPayout}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <PrintOrdersList />
      </div>
    </>
  );
};

export default PrintSection;
