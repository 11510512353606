import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import TitleSection from "./Components/TitleSection";
import Title from "../../../../components/common/Title";
import GalleryListView from "./Components/GalleryListView";
import AlbumPreviewSection from "./Components/AlbumPreviewSection";
import { useEvent } from "../../../../Store/Event/Show";
import {
  getEventFavList,
  getFavouriteImages,
  getEmailFavList,
} from "../../../../apis";
import { useParams } from "react-router-dom";
import FavListTitle from "./Components/Title";
import Loading from "../../../Loading";

function EventFavouriteList() {
  const { event } = useEvent();
  const [currentFavList, setCurrentFavList] = useState(
      useParams()["favListId"]
    ),
    [favList, setFavList] = useState([]),
    [images, setImages] = useState([]),
    [favListEmail, setFavListEmail] = useState(),
    [currentListName, setCurrentListName] = useState(""),
    [imageCount, setImageCount] = useState(0);

  const [favListLoader, setFavListLoader] = useState(true);

  useEffect(() => {
    !_.isEmpty(event) &&
      getEmailFavList(currentFavList).then((res) => {
        setFavListEmail(res.email);
        setFavList(res.data);
        setCurrentListName(
          res.data.find((item) => item.id == currentFavList).name
        );
      });
  }, [event, currentFavList]);

  useEffect(() => {
    !_.isEmpty(event) &&
      getFavouriteImages(event.id, currentFavList)
        .then((res) => {
          setImages(res.data.data);
          setImageCount(res.data.data.length);
          setFavListLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setFavListLoader(false);
        });
  }, [event, currentFavList]);

  const handleFavListChange = (e) => {
    setCurrentFavList(e);
    setCurrentListName(favList.find((item) => item.id == e).listName);
  };

  return (
    <div>
      <TitleSection />
      <div className="flex">
        <div>
          <AlbumPreviewSection
            eventId={event.id}
            favList={favList}
            setFavList={setFavList}
            currentFavList={currentFavList}
            handleFavListChange={handleFavListChange}
            favListEmail={favListEmail}
            imageCount={imageCount}
          />
        </div>
        <div className="flex flex-col ml-5 mr-10 mt-5 grow">
          {/* <FavListTitle className="my-4"  text={currentListName}/> */}

          {images.length > 0 ? (
            <GalleryListView
              eventId={event.id}
              favList={favList}
              setFavList={setFavList}
              currentFavList={currentFavList}
              images={images}
              setImages={setImages}
              currentListName={currentListName}
              handleFavListChange={handleFavListChange}
              imageCount={imageCount}
              setImageCount={setImageCount}
            />
          ) : favListLoader ? (
            <Loading />
          ) : (
            <h1>There are no images in this favourite list</h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventFavouriteList;
