import React from 'react';
import {Link} from 'react-router-dom';
import DownArrow from '../../../../assets/icons/DownArrow';
import Text from '../../../../components/common/Text';
import {useUser} from "../../../../Store/User";

const ProfileTitleSection = () => {
    const {dashboardUrl} = useUser();

    return <div className='flex items-center h-[4.6rem] shadow-header'>
        <div className='flex items-center w-full'>
            <Link to={dashboardUrl}>
      <span
          className='flex pl-10 min-w-[20rem] mr-6 items-center font-medium cursor-pointer
           text-primary-green text-base font-semibold underline'>
          <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
            Go Back
      </span>
            </Link>

            <Text
                as='h1'
                className='flex text-3xl text-light-gray'
            >
                Profile
            </Text>
        </div>
    </div>;
};

export default ProfileTitleSection;
