import React,{useState} from "react";
import DialogWrapper from "../../../../components/common/Dialog";
import { Input } from "../../../../components/common/Input";
import { Dialog, Transition } from "@headlessui/react";
import './share.css';
import _ from 'lodash';
import InstagramIcon from "../../../../assets/images/Instagram-icon.png";
import FacebookIcon from "../../../../assets/images/Facebook-icon.png";
import TwitterIcon from "../../../../assets/images/Twitter-icon.png";
import YoutubeIcon from "../../../../assets/images/Youtube-icon.png";
import WhatsappIcon from "../../../../assets/images/Whatsapp-icon.png";
import CloseIcon from "../../../../assets/icons/CloseIcon";

const ShareModal = ({ open, close,shareLink }) => {
    const [opens, setOpens] = useState(true)
    const [show, setShow] = useState(false);

    const socialMediaIcons = [
        {
            name: "Facebook",
            icon: FacebookIcon,
            href: 'https://www.facebook.com/sharer/sharer.php?u='+shareLink
        },
        {
            name: "Twitter",
            icon: TwitterIcon,
            href: 'https://twitter.com/intent/tweet?url='+shareLink
        },
        {
            name: "WhatsappIcon",
            icon: WhatsappIcon,
            href: 'https://wa.me/?text=Check%20Out%20my%20Image '+shareLink
        },
         // {
        //     name: "Instagram",
        //     icon: InstagramIcon,
        // },
         // {
        //     name: "Youtube",
        //     icon: YoutubeIcon,
        // },
    ];

    return (
        <div className="inline-block bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-3 sm:align-middle sm:max-w-lg w-full ">
        <DialogWrapper className="bg-secondary-800" open={open} close={close}  >
            <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-secondary-300">
                      Share <span className="absolute right-6">
                </span>
            </Dialog.Title>
        <div className="sm:flex sm:items-start md:flex md:items-start lg:flex lg:items-start mb-5">
            <div className="mt-3 sm:mt-0 sm:text-left md:text-left lg:text-left w-full">
                <div className="mt-5">
                <div className='shareDiv shadow-inner-all px-2 py-7 rounded-xl flex'> 
                    <p className='overflow-hidden whitespace-nowrap w-[70%]	sm:w-[80%] text-dark-gray1 text-sm dark:text-light-gray' >{shareLink}</p>
                    <a onClick={()=>{setShow(true);navigator.clipboard.writeText(shareLink)}} className='cursor-pointer text-[#00b7b8] overflow-hidden whitespace-nowrap text-right w-[30%]	sm:w-[20%] text-sm font-semibold'>Copy Link</a>
                </div>
                {show?<span className='px-1' style={{fontSize:11,fontWeight:'bold',color:'#708757'}}>Link Copied to Clipboard</span>:<></>}
                </div>
            </div>
        </div>
        
        <div className="grid grid-cols-5 px-1 sm:flex sm:flex-row">
        {socialMediaIcons.map((item,index)  => (
          <div className='mr-6 pb-3' key={index}>
              <a href={item.href} target="_blank"><img
                  className='shadow-xl rounded-md'
                  src={item.icon}
                  alt='Share'
                  width='30'
              /></a>
          </div>
        ))}
        </div>
    </DialogWrapper>
    </div>
    );
};

export default ShareModal;
