import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import DownArrow from "../../../../assets/icons/DownArrow";
import Title from "../../../../components/common/Title";
import { getPreFaceRegistration } from "../../../../apis";
import { useUser } from "../../../../Store/User";
import { createCSVFile } from "../../../../functions";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const PreFaceRegistration = ({
  eventId,
  setRef,
  title,
  guestFilters,
  guestFilter,
  setGuestFilter,
}) => {
  const [guestFilterOptions, setGuestFilterOptions] = useState(false);
  const [guestTraffic, setGuestTraffic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredGuestTraffic, setFilteredGuestTraffic] = useState([]);
  const [guestSearch, setGuestSearch] = useState("");
  const [csvLoader, setCSVLoader] = useState(false);
  const [paginationLoader, setPaginationLoader] = useState(false);

  const maxPageRef = useRef();
  const preRegRef = useRef();
  const currentPage = useRef(1);
  const requestOnGoing = useRef(false);

  useEffect(() => {
    updateData(guestFilter);
    currentPage.current = 1;
  }, [guestFilter]);

  function fetchGuestData(
    startDate,
    endDate,
    timeline = null,
    appendData = false
  ) {
    if (!appendData) {
      setIsLoading(true);
    }
    getPreFaceRegistration(startDate, endDate, eventId, currentPage.current)
      .then((response) => {
        if (response.meta) {
          maxPageRef.current = response.meta.last_page;
        }
        if (appendData) {
          setGuestTraffic((prev) => [...prev, ...response.data]);
          setFilteredGuestTraffic((prev) => [...prev, ...response.data]);

          setPaginationLoader(false);
          requestOnGoing.current = false;
        } else {
          setGuestTraffic(response.data);
          setFilteredGuestTraffic(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const guestFilterToggle = (e) => {
    setGuestFilterOptions(!guestFilterOptions);
  };

  const guestFilterClicked = (e) => {
    setGuestFilter(e);
    updateData(e);
  };

  let filterDorpDownContainer = useRef();
  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!filterDorpDownContainer?.current?.contains(event.target)) {
        setGuestFilterOptions(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  useEffect(() => {
    if (!_.isEmpty(guestTraffic) && !_.isEmpty(guestSearch)) {
      let filterByEmail = guestTraffic.filter((item) =>
        item.email.toLowerCase().includes(guestSearch.toLowerCase())
      );
      let filterByFirstName = guestTraffic.filter((item) =>
        item.firstName.toLowerCase().includes(guestSearch.toLowerCase())
      );
      let filterBylastName = guestTraffic.filter((item) =>
        item.lastName.toLowerCase().includes(guestSearch.toLowerCase())
      );
      let filterByEventName = guestTraffic.filter((item) =>
        item.eventName.toLowerCase().includes(guestSearch.toLowerCase())
      );

      let combinedList = filterByEmail.concat(
        filterByFirstName,
        filterBylastName,
        filterByEventName
      );
      let set = new Set(combinedList);
      setFilteredGuestTraffic(Array.from(set));
    } else {
      setFilteredGuestTraffic(guestTraffic);
    }
  }, [guestSearch]);

  const updateData = (timeline) => {
    switch (timeline) {
      case "Lifetime":
        fetchGuestData(null, null, timeline);

        break;
      case "This Week":
        let start = moment().startOf("week").toDate();
        let end = moment().endOf("week").toDate();

        fetchGuestData(
          moment(start).format("DD MMM YYYY"),
          moment(end).format("DD MMM YYYY"),
          timeline
        );

        break;
      case "This Month":
        let startM = moment().startOf("month").toDate();
        let endM = moment().endOf("month").toDate();

        fetchGuestData(
          moment(startM).format("DD MMM YYYY"),
          moment(endM).format("DD MMM YYYY"),
          timeline
        );

        break;
      default:
    }
  };

  const downloadCSVFile = async () => {
    try {
      setCSVLoader(true);
      await createCSVFile(
        guestFilter,
        eventId,
        "preReg",
        "Pre_Registration_Excel"
      );
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        icon: "error",
        title: `Something went wrong`,
      });
    } finally {
      setCSVLoader(false);
    }
  };
  const { user } = useUser();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    !_.isEmpty(user) &&
      setUrl("https://" + user.photographer.subDomain + ".algomage.com/");
  }, []);
  const { items, requestSort, sortConfig } =
    useSortableData(filteredGuestTraffic);

  const handleObserver = async (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (!requestOnGoing.current) {
        currentPage.current = currentPage.current + 1;
        requestOnGoing.current = true;
        if (maxPageRef.current < currentPage.current) return;
        setPaginationLoader(true);
        try {
          switch (guestFilter) {
            case "Lifetime":
              fetchGuestData(null, null, guestFilter, true);
              break;
            case "This Week":
              let start = moment().startOf("week").toDate();
              let end = moment().endOf("week").toDate();
              fetchGuestData(
                moment(start).format("DD MMM YYYY"),
                moment(end).format("DD MMM YYYY"),
                guestFilter,
                true
              );
              break;
            case "This Month":
              let startM = moment().startOf("month").toDate();
              let endM = moment().endOf("month").toDate();
              fetchGuestData(
                moment(startM).format("DD MMM YYYY"),
                moment(endM).format("DD MMM YYYY"),
                guestFilter,
                true
              );
              break;
            default:
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "500px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (preRegRef.current) observer.observe(preRegRef.current);
    return () => {
      observer.disconnect();
    };
  }, [isLoading]);

  return (
    <div ref={setRef} id="pre-registration">
      <div className="pb-24">
        <div>
          <Title
            className="pb-4 border-zinc-700 border-b mt-10 font-medium"
            text={title}
          />
          <div className="flex justify-between ml-2 mt-6">
            {/* <div className='text-lg'>Guests
                        <span
                            className='block text-sm'>{guestTraffic.length ? guestTraffic.length + ' registrations' : ''}</span>
                    </div> */}
            <div className="relative">
              <i className="absolute top-3 left-4">
                <svg
                  className="w-4 h-4"
                  fill="#eee"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </i>
              <input
                value={guestSearch}
                onChange={(e) => setGuestSearch(e.target.value)}
                type="text"
                placeholder="Search..."
                size={31}
                className={`bg-black text-sm h-10 px-12 rounded-lg`}
                name="search"
              />
            </div>
            <div className="flex justify-end items-center">
              {guestTraffic.length > 0 ? (
                <div
                  className={` underline text-primary-green text-sm font-bold cursor-pointer  ${
                    csvLoader ? "clipPathLoader mr-4" : "pr-8"
                  } `}
                  onClick={downloadCSVFile}
                >
                  {csvLoader ? "" : "Download Excel"}
                </div>
              ) : (
                <></>
              )}

              <div
                ref={filterDorpDownContainer}
                onClick={guestFilterToggle}
                className="flex relative items-center text-sm shadow-button rounded-[10px] p-2 px-3 whitespace-nowrap cursor-pointer"
              >
                {guestFilter}
                <span className="pl-2 cursor-pointer">
                  <DownArrow rotate={guestFilterOptions ? true : false}>
                    {" "}
                  </DownArrow>
                </span>
                {guestFilterOptions && (
                  <div className="absolute rounded-[10px] overflow-hidden bg-secondary-800 shadow-card z-40 w-[200px] right-0 top-[95%] mt-3">
                    <div className="flex select-none flex-col  text-light-gray text-sm">
                      {guestFilters.map(function (guest, key) {
                        return (
                          <div
                            key={key}
                            onClick={() => {
                              guestFilterClicked(guest);
                              guestFilterToggle();
                            }}
                            className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                          >
                            {guest}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3">
              <h1 className="text-center">Loading...</h1>
            </div>
          ) : guestTraffic.length > 0 ? (
            <div className="items-center">
              <div
                className={`flex items-start 3xl:items-center 4xl:items-center grid ${
                  !_.isEmpty(eventId) ? "grid-cols-7" : "grid-cols-8"
                } auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3`}
              >
                <div
                  onClick={() => requestSort("email")}
                  className="flex items-center gap-x-2 col-span-2 cursor-pointer"
                >
                  <span>Email</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>
                <div
                  onClick={() => requestSort("firstName")}
                  className="flex items-center gap-x-2 justify-start cursor-pointer"
                >
                  <span>First Name</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>
                <div
                  onClick={() => requestSort("lastName")}
                  className="flex items-center gap-x-2 justify-start cursor-pointer"
                >
                  <span>Last Name</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>
                <div className="flex items-center justify-start "> Phone</div>
                <div
                  onClick={() => requestSort("imagesDiscovered")}
                  className="flex items-baseline  justify-center cursor-pointer 3xl:whitespace-nowrap 4xl:whitespace-nowrap"
                >
                  <span className="text-center">Images Discovered</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>
                <div
                  onClick={() => requestSort("eventName")}
                  className={
                    !_.isEmpty(eventId)
                      ? "hidden"
                      : "flex items-center gap-x-2 justify-start  cursor-pointer "
                  }
                >
                  <span>Event Name</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>

                <div
                  onClick={() => requestSort("createdAt")}
                  className="flex items-center gap-x-2 justify-start cursor-pointer"
                >
                  <span>Date</span>
                  <img src="/sortIcon.webp" className="w-3 h-3  " alt="" />
                </div>
              </div>

              <div
                id="User_face_pre_search_registration"
                className="max-h-[50vh] overflow-y-auto sm:overflow-x-hidden"
              >
                {items.map(function (guest) {
                  return (
                    <div
                      className={`flex grid auto-cols-max ${
                        !_.isEmpty(eventId) ? "grid-cols-7" : "grid-cols-8"
                      } gap-6 ml-2 mt-3 border-b border-zinc-700 text-sm p-3`}
                      key={guest.id}
                    >
                      <div
                        title={guest.email}
                        className="export col-span-2 truncate"
                      >
                        {guest.email}
                      </div>
                      <div className="export text-left break-words ">{guest.firstName}</div>
                      <div className="export text-left break-words">{guest.lastName}</div>
                      <div className="export text-left">
                        {guest.phone && guest.phone.length > 4
                          ? guest.phone
                          : ""}
                      </div>
                      <div className="export text-center ">
                        {guest.imagesDiscovered}

                        {guest.imagesDiscovered > 0 ? (
                          <a
                            href={
                              url +
                              "facerec/" +
                              guest.eventSlug +
                              "/results/" +
                              guest.searchId
                            }
                            target="_blank"
                            className="block text-primary-green"
                          >
                            View
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={
                          !_.isEmpty(eventId)
                            ? "hidden export"
                            : "export text-left "
                        }
                      >
                        {guest.eventName}
                      </div>
                      <div className="export text-left ">
                        {guest.createdAt
                          ? moment
                              .utc(guest.createdAt)
                              .tz(moment.tz.guess())
                              .format("Do-MMM-YYYY")
                          : "-"}
                        <div className="text-xs">
                          {" "}
                          {guest.createdAt
                            ? moment(guest.createdAt).format("hh:mm A")
                            : "-"}{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {paginationLoader ? (
                  <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-12 mb-12 text-base font-semibold bg-black rounded-lg p-3">
                    <h1 className="text-center">Loading...</h1>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="h-[10px]" ref={preRegRef}></div>
              </div>
            </div>
          ) : (
            <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3">
              <h1 className="text-center">
                No data available for {guestFilter.toLowerCase()}
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreFaceRegistration;
