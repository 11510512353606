import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import Text from "../../../../components/common/Text";
import { InputEditable } from "../../../../components/common/Input";
import SwitchWithTitle from "../../../GeneralSettings/components/SwitchWithTitle";
import InstagramIcon from "../../../../assets/images/Instagram-icon.png";
import FacebookIcon from "../../../../assets/images/Facebook-icon.png";
import TwitterIcon from "../../../../assets/images/Twitter-icon.png";
import YoutubeIcon from "../../../../assets/images/Youtube-icon.png";
import { useEvent } from "../../../../Store/Event/Show";
import { useUser } from "../../../../Store/User";
import {
  updateEventTitle,
  updateEventData,
  updateShowMobileField,
  postEventGuestUploadToggle,
} from "../../../../apis";

import {
  updateEventSocialSharing,
  updateEventPrintStore,
} from "../../../../apis";

const GeneralSection = (props) => {
  const { event } = useEvent();
  let { user } = useUser();
  const [generalSetting, setGeneralSetting] = useState({
    eventName: "",
    socialSharingEnabled: null,
    printStore: null,
    passwordProtection: null,
    emailProtection: null,
  });
  const [showMobileField, setshowMobileField] = useState(false);
  const [socialIcons, setSocialIcons] = useState({
    facebook: null,
    twitter: null,
    youtube: null,
    instagram: null,
  });
  const [successStatus, setSuccessStatus] = useState({ name: null });
  const [errors, setErrors] = useState({});
  const [guestUploadEnable, setGuestUploadEnable] = useState(
    event.isGuestUploadEnabled ? 1 : 0
  );

  const [requiresEmail, setRequiresEmail] = useState(false);

  useEffect(() => {
    setGuestUploadEnable(event.isGuestUploadEnabled ? 1 : 0);
  }, [event.isGuestUploadEnabled]);

  useEffect(() => {
    !_.isEmpty(event) &&
      setGeneralSetting({
        eventName: event.name,
        socialSharingEnabled:
          event.socialSharing == null ? false : event.socialSharing,
      });

    setRequiresEmail(event.requiresEmail);
    setshowMobileField(event.showMobileField);
  }, [event]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setSocialIcons({
        facebook: user.photographer.facebook,
        twitter: user.photographer.twitter,
        youtube: user.photographer.youtube,
        instagram: user.photographer.instagram,
      });
    }
  }, [user]);

  useEffect(() => {
    if (!_.isEmpty(event) && !_.isEmpty(user)) {
      user.photographer.show_print_store
        ? event.showPrintStore == null
          ? setGeneralSetting((prevState) => ({
              ...prevState,
              printStore: true,
            }))
          : setGeneralSetting((prevState) => ({
              ...prevState,
              printStore: event.showPrintStore,
            }))
        : setGeneralSetting((prevState) => ({
            ...prevState,
            printStore: false,
          }));
    }
  }, [event, user]);

  //function to update event Name
  const updateEventName = (e) => {
    let newName = e;
    if (newName === undefined || newName === null || newName === "") {
      setErrors({ ...errors, name: "Event name cannot be Empty" });
      return false;
    }
    setGeneralSetting({ eventName: newName });
    setErrors({ ...errors, name: null });
    setSuccessStatus({ ...successStatus, name: false });
    updateEventTitle(event, newName)
      .then((response) => {
        setSuccessStatus({ ...successStatus, name: true });
      })
      .catch((error) => {
        setErrors({ ...errors, name: error.response.data.errors.name });
      });
  };

  //function to update social sharing
  function updateSocialSharingBtn(e) {
    setGeneralSetting({ ...generalSetting, socialSharingEnabled: e });
    updateEventSocialSharing(event, e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  //function to update social sharing url
  function updateSocialSharingUrl(e) {}

  //function to update printStore
  function updatePrintStore(e) {
    if (user.user.photographer.show_print_store == 0) {
      window.Toast.fire({
        icon: "error",
        title: "First Enable Print Store in General Settings",
      });
      return false;
    }
    setGeneralSetting({
      ...generalSetting,
      printStore: !generalSetting.printStore,
    });

    updateEventPrintStore(event, e)
      .then(function () {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        setGeneralSetting({ ...generalSetting, printStore: false });
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  function updateRequireEmail(e) {
    setRequiresEmail(e);
    updateEventData(event.id, { requiresEmailRegistration: e })
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        setRequiresEmail(!e);
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  function updateAskForMobileNumber(e) {
    setshowMobileField(e);
    updateShowMobileField(event, e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        setshowMobileField(!e);
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  const updateGuestUploadToggle = async (isEnable = false) => {
    try {
      if (!user.photographer.is_guest_upload_enable) {
        window.Toast.fire({
          icon: "error",
          title: "Enable guest upload from general settings",
        });
        return;
      }
      setGuestUploadEnable(isEnable ? 1 : 0);
      await postEventGuestUploadToggle({
        eventId: event.id,
        guestUploadEnabled: isEnable ? 1 : 0,
      });
      window.Toast.fire({
        icon: "success",
        title: "Updated Successfully",
      });
    } catch (error) {
      setGuestUploadEnable(!isEnable);
      window.Toast.fire({
        icon: "error",
        title: "Could Not Update",
      });
    }
  };

  const { className } = props;
  const socialMediaIcons = [
    {
      name: "facebook",
      icon: FacebookIcon,
    },
    {
      name: "instagram",
      icon: InstagramIcon,
    },
    {
      name: "twitter",
      icon: TwitterIcon,
    },
    {
      name: "youtube",
      icon: YoutubeIcon,
    },
  ];

  return (
    <div id="general" className={`${className} `}>
      <div className="my-[10px] mb-[50px]">
        <Text as="p" className="text-secondary-300 mb-4 font-medium">
          Name
        </Text>
        <InputEditable
          value={generalSetting.eventName}
          onChange={(event) => {
            setGeneralSetting({
              ...generalSetting,
              eventName: event.target.value,
            });
          }}
          error={errors.name}
          onUpdate={(value) => updateEventName(value)}
          success={successStatus.name}
        />
      </div>

      <div className="my-[10px] mb-[50px]">
        <SwitchWithTitle
          className="mb-8 font-medium !max-w-[28rem]"
          title="Email Registration"
          subtitle={`${
            requiresEmail
              ? "Email Registrations is enabled for this event"
              : "Email Registrations is disabled for this event"
          }`}
          value={requiresEmail}
          onChange={() => {
            requiresEmail == true
              ? updateRequireEmail(false)
              : updateRequireEmail(true);
          }}
        />
      </div>

      <div id="sharing" className="my-[10px] mb-[50px]">
        <SwitchWithTitle
          className="mb-8 font-medium !max-w-[28rem]"
          title="Social sharing buttons"
          subtitle="Share your work with clients"
          value={generalSetting.socialSharingEnabled}
          onChange={() => {
            generalSetting.socialSharingEnabled == 1
              ? updateSocialSharingBtn(false)
              : updateSocialSharingBtn(true);
          }}
        />

        <ul
          className={
            generalSetting.socialSharingEnabled == true
              ? "flex justify-start mb-3"
              : "flex justify-start mb-3 point-event-none opacity-30"
          }
        >
          {/* <ul   className='flex justify-start mb-3'> */}
          {socialMediaIcons.map((item) => (
            <li key={item.name} className="mr-4">
              <img
                className={
                  socialIcons[item.name]
                    ? "cursor-pointer"
                    : "point-event-none opacity-30"
                }
                src={item.icon}
                alt="side cover"
                width="30"
                onClick={() => {
                  updateSocialSharingUrl(item.name);
                }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div id="print-store" className="my-[10px]">
        <SwitchWithTitle
          className="mb-8 font-medium !max-w-[28rem]"
          title="Print Store"
          subtitle={`${
            user.photographer.show_print_store
              ? generalSetting.printStore
                ? "Print store is enabled for this event"
                : "Print store is disabled for this event"
              : "Enable Print Store in General Settings to access it here."
          }`}
          value={generalSetting.printStore}
          onChange={() => {
            generalSetting.printStore == 1
              ? updatePrintStore(0)
              : updatePrintStore(1);
          }}
        />
      </div>

      <div id="mobile-number" className="mt-10">
        <SwitchWithTitle
          className="mb-8 font-medium !max-w-[28rem]"
          title="Mobile Field"
          subtitle={`Mobile number field is ${
            showMobileField ? "displayed" : "hidden"
          } in face search form`}
          value={showMobileField}
          onChange={() => {
            showMobileField == 1
              ? updateAskForMobileNumber(0)
              : updateAskForMobileNumber(1);
          }}
        />
      </div>

      <div id="guestUpload" className="mt-10">
        <SwitchWithTitle
          className="mb-8 font-medium !max-w-[28rem]"
          title="Guest Upload"
          subtitle={
            user.photographer.is_guest_upload_enable ? (
              `Guest upload option is ${
                guestUploadEnable ? "enabled" : "disabled"
              } in gallery`
            ) : (
              <span>
                Enable guest upload option in{" "}
                <Link
                  className="text-primary-green text-xs font-semibold underline pl-1 pr-2 font-sm"
                  to={"/general-settings"}
                >
                  General Settings
                </Link>{" "}
                to access it here
              </span>
            )
          }
          value={user.photographer.is_guest_upload_enable ? guestUploadEnable : false}
          disable={!user.photographer.is_guest_upload_enable}
          onChange={() => {
            guestUploadEnable === 1
              ? updateGuestUploadToggle()
              : updateGuestUploadToggle(true);
          }}
        />
      </div>
    </div>
  );
};

export default GeneralSection;
