import React from "react";
import Switch from "../../../../components/common/Switch";
import Text from "../../../../components/common/Text";

const SwitchWithTitle = (props) => {
    const { className, title, subtitle, onChange, value, disable = ''} = props
    return (
        <div className={`flex flex-row justify-between max-w-[95%] ${className}`}>
            <div className="flex flex-col">
                <Text className=" text-base text-secondary-300" as="p">{title}</Text>
                <Text className="whitespace-pre-line text-sm text-secondary-300 font-normal max-w-[22.75rem]">{subtitle}</Text>
            </div>
            <div className={`flex ${disable} ${disable? "cursor-not-allowed" :  ""}`}>
                <Switch onChange={onChange} defaultState={value} key={title} disable={disable}/>
            </div>
        </div>
    );
};

export default SwitchWithTitle;
