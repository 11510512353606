import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { ENVIRONMENT, RESIZE_IMAGE_CDN } from "../constants";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sortConfig = {
  "og_image_name+asc": "asc",
  "og_image_name+desc": "desc",
  "size_mb+desc": "biggest",
  "size_mb+asc": "smallest",
  "image_date+desc": "latest",
  "image_date+asc": "oldest",
};

const defaultOptions = {
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
export let fetch = axios.create(defaultOptions);

// Set the AUTH token for any request
fetch.interceptors.request.use(function (config) {
  const token = Cookies.get("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

//Add interceptors to instance
fetch.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("access_token");
      Cookies.remove("access_token", {
        expires: 7,
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      window.location.href = "/login";
    }

    if (error?.response?.status === 403) {
    }

    return Promise.reject(error);
  }
);

/*export const getUserDetails = async () => {

    let result = await axios.post(`${endpoint}login`, {
        email: 'hello@picsniff.com',
        password: 'sk8erboI!picsniff'
    });

    token = result.data.access_token;
    return result.data;
}*/

export const userLogin = async (email, password) => {
  let result = await axios.post(`${endpoint}auth/login`, {
    email: email,
    password: password,
  });
  //token = result.data.access_token;
  return result.data;
};

export const userLogout = async () => {
  let result = await fetch.post(`${endpoint}logout`);
  //token = result.data.access_token;
  return result.data;
};

export const userRegister = async (
  name,
  lastName,
  email,
  password,
  phone,
  dialCode,
  googleId = null,
  facebookId = null,
  userCameFrom = false,
  countryCode = "US",
  source = null,
  medium = null,
  campaign = null
) => {
  let result = await axios.post(`${endpoint}auth/register`, {
    name: name,
    lastName: lastName,
    email: email,
    password: password,
    phone: phone,
    dialCode: dialCode,
    googleId: googleId,
    facebookId: facebookId,
    countryCode: countryCode,
    ...(userCameFrom === "algocull" && { algocullSignup: true }),
    ...(userCameFrom === "algoedit" && { algoeditSignup: true }),
    source,
    medium,
    campaign,
  });
  //token = result.data.access_token;
  return result.data;
};

export const getEventIndexDetails = async (page) => {
  let url = page ? "events?page=" + page : "events";
  let response = await fetch.get(url);
  return response.data;
};

export const getEventByTag = async (tagId) => {
  // let url = tagId ? 'events?tagId=' + tagId : 'events';
  let url = "events?tagId=" + tagId;
  let response = await fetch.get(url);
  return response.data.data;
};

export const getEventBySort = async (sort) => {
  let url = "events?sort=" + sort;
  let response = await fetch.get(url);
  return response.data.data;
};

export const getEventByTagAndSort = async (tagId, sort = "latest", page) => {
  if (tagId == null) {
    let url = page
      ? "events?sort=" + sort + "&page=" + page
      : "events?sort=" + sort;
    let response = await fetch.get(url);
    return response.data;
  }

  let url = page
    ? "events?sort=" + sort + "&tagId=" + tagId + "&page=" + page
    : "events?sort=" + sort + "&tagId=" + tagId;

  let response = await fetch.get(url);
  return response.data;
};

export const getExpiredEventIndexDetails = async (page) => {
  let url = page
    ? "events?status=expired&page=" + page
    : "events?status=expired";
  let response = await fetch.get(url);
  return response.data;
};

export const getSearchedEventIndexDetails = async (query, page, signal) => {
  let url = page
    ? "events?search=" + query + "&page=" + page
    : "events?search=" + query;
  let response = await fetch.get(url, { signal });
  return response.data;
};

export const getEventDetails = async (eventId) => {
  let response = await fetch.get(`events/details/${eventId}`);
  return response.data.data;
};

export const getAllTags = async () => {
  let response = await fetch.get(`users/tags`);
  return response.data.data;
};

export const getPortfolioDetails = async () => {
  let response = await fetch.get(`portfolios`);
  return response.data.data;
};

export const deletePortfolio = async (id) => {
  let response = await fetch.delete(`portfolios/${id}`);
  return response.data;
};

export const updatePortfolioOrder = async (newOrder) => {
  // console.log(newOrder,new);
  let response = await fetch.post("portfolios/update-order", {
    portfolios: newOrder,
  });
  return response.data;
};

// Not used
export const getInvoice = async (id) => {
  //     let response = await axios.get({
  //         url:`mobileapp-invoice/download?userId=${id}`,
  //         responseType: 'arraybuffer',
  //         header :'Content-Type: application/pdf',

  //     }).then(response => {
  //       const type = response.headers['content-type']
  //       const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
  //       const link = document.createElement('a')
  //       link.href = window.URL.createObjectURL(blob)
  //       link.download = 'file.pdf'
  //       link.click()
  //   });
  // console.log('response',response);

  let response = await fetch.get(`mobileapp-invoice/download?userId=${id}`, {
    responseType: "arraybuffer",
  });
  return response.data;
};

// NOt user
export const getVimeoThumbnail = async (id) => {
  let response = await fetch.get(`https://vimeo.com/api/v2/video/${id}.json'`);
  return response.data;
};

export const getNotifications = async (id) => {
  let response = await fetch.get("/photographers/notifications");
  return response.data;
};

// Not used
export const sendAppPurchaseMail = async (id, freeApp = false) => {
  let response = await fetch.get(`app-purchase/send-mail-admin`, {
    params: {
      userId: id,
      freeApp: freeApp,
    },
  });
  return response.data;
};

export const updatePortfolioAppName = async (data) => {
  let response = await fetch.patch("photographers/update", {
    mobileAppName: data,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const algocullVersion = async (data) => {
  let response = await fetch.get("algocull/version", {
    params: {
      os: data,
    },
  });
  return response.data;
};

export const updatePhotographerAbout = async (data) => {
  let response = await fetch.patch("photographers/update", {
    about: data,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updateShowPortfolioAppLink = async (status) => {
  let response = await fetch.patch("photographers/update", {
    showAppDownloadUrl: status,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const getImages = async (
  folderId,
  sort = "image_date",
  order = "asc"
) => {
  let response = await fetch.get(`folders/images/all`, {
    params: {
      folderId: folderId,
      sortField: sort,
      sortCond: order,
    },
  });
  return response.data;
};

export const getEventImagesCount = async (event) => {
  let response = await fetch.get(`events/images/count`, {
    params: {
      id: event.id,
    },
  });
  return response.data;
};

export async function createNewEvent(params) {
  let response = await fetch.post(`events/create`, {
    name: params.name,
    liveness: params.liveness,
    watermark: params.watermark,
    date: params.startDate,
    expiryDate: params.endDate,
    coverImage: params.coverImage,
  });
  // console.log("response",response);
  return response.data.data;
}

export async function createNewFolder(event, name, order) {
  let response = await fetch.post(`folders/create`, {
    folderName: name,
    eventId: event.id,
    order: order,
  });
  return response.data.data;
}

export async function markEventAsExpired(event, hasExpired = true) {
  let response = await fetch
    .patch(`events/update`, {
      id: event.id,
      expiryDate: hasExpired
        ? moment().subtract(1, "days").format("YYYY-MM-DD")
        : null,
    })
    .catch(function (error) {});
  return response.data.data;
}

export async function updateEventData(eventId, arrayToUpdate) {
  let response = await fetch
    .patch(`events/update`, {
      id: eventId,
      ...arrayToUpdate,
    })
    .catch(function (error) {});
  return response.data.data;
}

export async function publishEvent(event, isPublished = true) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      published: isPublished,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

export async function deleteEvent(event) {
  let response = await fetch.post("events/delete", {
    id: event.id,
  });

  return response;
}

export async function updateEventTitle(event, name, slug = null) {
  let response = await fetch.patch("events/update", {
    id: event.id,
    name: name,
    slug: slug,
  });
  return response.data.data;
}

export async function updateEventTag(id, TagName, tagColour) {
  let response = await fetch.patch("events/update", {
    id: id,
    tag: TagName,
    tagColour: tagColour,
  });
  return response.data.data;
}

export async function removeEventTag(eventID, tagId) {
  let response = await fetch.patch("events/update", {
    id: eventID,
    tagId: tagId,
  });
  return response.data.data;
}

export async function updateEventDescription(event, description) {
  let response = await fetch.patch("events/update/description", {
    id: event.id,
    description: description,
  });
  return response.data;
}

export const getFolderImagesCount = async (folder, eventId) => {
  let response = await fetch.get("folders/images/count", {
    params: {
      id: folder.id,
      eventId: eventId,
    },
  });
  return response.data;
};

export const updateFolderOrder = async (folder, order) => {
  let response = await fetch.patch("folders/update", {
    id: folder.id,
    order: order,
  });
  return response.data;
};

export const updateFolderName = async (folder, name) => {
  let response = await fetch.patch("folders/update", {
    id: folder.id,
    name: name,
    eventId: folder.eventId,
  });
  return response.data;
};

export const updateFolderSort = async (folder, sortCond, sortField) => {
  let sortType = sortConfig[sortField + "+" + sortCond];
  //console.log("sortType", sortType)
  let response = await fetch.patch("folders/update", {
    id: folder.id,
    sortType: sortType,
    eventId: folder.eventId,
  });
  return response.data;
};

export const deleteFolder = async (folder, slug) => {
  let response = await fetch.delete("folders/delete", {
    params: {
      id: folder.id,
      slug: slug,
    },
  });
  return response.data;
};

export const privateFolder = async (folder, status) => {
  let response = await fetch.patch("folders/update", {
    id: folder.id,
    showOnlyOnFaceSearch: status,
  });
  return response.data;
};

export const updateGalleryLayout = async (event, galleryLayout) => {
  let response = await fetch.patch("events/update", {
    id: event.id,
    gridLayout: galleryLayout.gridLayout,
    backgroundColor: galleryLayout.backgroundColor,
    thumbnailSize: galleryLayout.thumbnailSize,
    gridSpacing: galleryLayout.gridSpacing,
  });
};

export const getPresignedUrl = async (file, type) => {
  const url =
    ENVIRONMENT === "staging"
      ? "presigned-url-gcp"
      : "photographers/presigned-url";
  let response = await fetch.post(url, {
    type: "photographer_favicon",
    fileName: file.name,
  });

  return response.data;
};

export const uploadFile = async (file, url) => {
  let response = await axios.put(url, file, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });

  return response.data;
};

export const removePhotographerFavicon = async () => {
  let response = await fetch.patch("photographers/update/favicon", {
    faviconUrl: null,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerFavicon = async (file) => {
  if (!file) {
    let response = await fetch.patch("photographers/update", {
      faviconUrl: null,
    });
    localStorage.setItem("access_token", response.data.access_token);
    return response.data;
  }

  let presignedUrlResponse = await getPresignedUrl(
    file,
    "photographer_favicon"
  );

  await uploadFile(file, presignedUrlResponse.preSignedUrl);

  let response = await fetch.patch("photographers/update", {
    faviconUrl: presignedUrlResponse.destinationUrl,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const deleteBrandLogo = async () => {
  let response = await fetch.patch("photographers/delete/brand-logo", {
    logo_url: null,
  });
  return response.data;
};

export const updatePhotographerLogoTextAtt = async (
  text,
  font,
  fontColor,
  logoUrl
) => {
  let response = await fetch.patch("photographers/update", {
    logoText: text,
    logoTextFont: font,
    logoTextColour: fontColor,
    // logoUrl: logoUrl
  });
  return response.data;
};

export const getPresignedUrlLogo = async (file, type) => {
  const url =
    ENVIRONMENT === "staging"
      ? "presigned-url-gcp"
      : "photographers/presigned-url";
  let response = await fetch.post(url, {
    type: "photographer_logo",
    fileName: file.name,
  });

  return response.data;
};

export const updatePhotographerLogo = async (file) => {
  let presignedUrlResponse = await getPresignedUrlLogo(
    file,
    "photographer_logo"
  );

  await uploadFile(file, presignedUrlResponse.preSignedUrl);

  let response = await fetch.patch("photographers/update", {
    logoUrl: presignedUrlResponse.destinationUrl,
    // logoText: null,
    // logoTextFont: null,
    // logoTextColour: null
  });

  return response.data;
};

export const updateMobilePortfolioLogo = async (file, uploadType) => {
  let presignedUrlResponse = await getPresignedUrlLogo(
    file,
    "photographer_logo"
  );

  await uploadFile(file, presignedUrlResponse.preSignedUrl);

  let response = await fetch.patch("photographers/update/app-logo", {
    // MobilelogoUrl: presignedUrlResponse.destinationUrl,
    // logoText: null,
    // logoTextFont: null,
    // logoTextColour: null
    [uploadType]: presignedUrlResponse.destinationUrl,
  });

  return response.data;
};

export const updateGlobalChatBot = async (status) => {
  let response = await fetch.patch("photographers/update", {
    showChatBot: status,
  });

  return response.data;
};

export const updatePhotographerTrackingId = async (trackingId) => {
  let response = await fetch.patch("photographers/update", {
    gaTrackingId: trackingId,
  });

  return response.data;
};

export async function updateSocialSharing(
  event,
  facebook,
  twitter,
  instagram,
  youtube
) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      // enable: isEnable,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

// function to update event date
export async function updateEventDate(event, date, isExpiredDate = false) {
  let response = await fetch
    .patch(
      "events/update",
      isExpiredDate
        ? {
            id: event.id,
            expiryDate: date,
          }
        : {
            id: event.id,
            date: date,
          }
    )
    .catch(function (error) {
      console.log(error);
    });

  return response.data.data;
}

export async function updateCoverTemplate(event, coverTemplateDesign) {
  let response = await fetch.patch("events/update", {
    id: event.id,
    coverImageTemplate: coverTemplateDesign,
  });
  return response.data.data;
}

// function to update event Fonts
export async function updateEventFonts(event, fonts) {
  let response = await fetch.patch("events/update", {
    id: event.id,
    font: fonts,
  });
  return response.data.data;
}

//function to update bulk download
export async function updateDownloadSettings(event, highResSiz) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      downloadBulkImageSize: highResSiz,
      // downloadBulkImagesWebSize: webSize,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update bulk download
export async function updateDigitalDownloads(id, status, currency, price) {
  let response = await fetch.patch("events/update/digital-download", {
    id: id,
    isChargeable: status,
    currency: currency,
    price: price,
  });
  return response.data.data;
}

//function to update single download settings
export async function updateSingleDownloadSettings(event, size) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      downloadSingleImageSize: size,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update social sharing of event
export async function updateEventSocialSharing(event, isEnable = false) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      socialSharing: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update social sharing of event
export async function updateEventPrintStore(event, isEnable = false) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      showPrintStore: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update social sharing of event
export async function updateRoundedImage(event, isEnable) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      isImageRounded: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//functiont to geting event fav list
export async function getEventFavList(event) {
  let response = await fetch
    .get("events/favourite/lists", {
      params: {
        eventId: event.id,
      },
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update event fav list
export async function updateEventFavList(favListId, count) {
  let response = await fetch
    .post("events/favourite/lists/update", {
      id: favListId,
      albumLimit: count,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to delete event fav list
export async function deleteEventFavouriteList(favListId) {
  let response = await fetch
    .post("events/favourite/lists/delete", {
      id: favListId,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//functiont to geting event fav list
export async function getEmailFavList(listId) {
  let response = await fetch
    .get("favourite-list/detail", {
      params: {
        listId: listId,
      },
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data;
}

export async function getFavouriteImages(
  eventId,
  favListId,
  downloadCsv = 0,
  lightroomDownload = 0
) {
  let response = await fetch
    .get("favourite-list/images", {
      params: {
        eventId: eventId,
        favListId: favListId,
        downloadCsv: downloadCsv,
        lightroomDownload: lightroomDownload,
      },
    })
    .catch(function (error) {});
  return response;
}

export async function favouriteDownload(
  eventId,
  email,
  image_type,
  listId,
  type,
  userId
) {
  let response = await fetch.post("downloads", {
    eventId: eventId,
    email: email,
    quality: "original",
    favouriteListId: listId,
    type: "favourites",
  });
  return response.data;
}

export async function coupon(coupon, paymentType, planId) {
  let response = await fetch.post("coupons/apply", {
    code: coupon,
    paymentMode: paymentType ? "upi" : "card",
    planId: planId,
  });
  return response;
}

export async function couponMobile(coupon, paymentType) {
  let response = await fetch.post("coupons/mobile", {
    code: coupon,
    paymentMode: paymentType,
  });
  return response;
}

export const getDownlaodFolder = async (downId, requestNo) => {
  try {
    if (requestNo === 1) {
      var result = await fetch.get(`downloads/url?id=` + downId);
    } else {
      var result = await fetch.get(`downloads/url?id=` + downId, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      });
    }
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};

export async function deleteFavList(eventId, listId, type = "event-settings") {
  let response = await fetch.post("favourite-list/delete", {
    _method: "delete",
    eventId: eventId,
    listId: listId,
    type: type,
  });
  return response.data.data;
}

export async function removeFavImage(imageId, favListId, eventId) {
  let response = await fetch.post("favourite-list/remove-image", {
    imageId: imageId,
    favListId: favListId,
    eventId: eventId,
  });
  return response.data.data;
}

export async function getEventGuestEmail(event, type = null) {
  let response = await fetch
    .get("event/guests", {
      params: {
        eventId: event.id,
        guestType: type,
      },
    })
    .catch(function (error) {});
  return response.data.data;
}

export async function getEventGuest(event) {
  let response = await fetch.get("guest/all-guests", {
    params: {
      eventId: event.id,
    },
  });
  return response.data.data;
}

//function to restrict email from event
export async function restrictEmailForEvent(event, email, access) {
  let response = await fetch.post("guest/update", {
    eventId: event.id,
    email: email,
    access: access,
  });
  return response.data.data;
}

//function to update watermark preset in event table
export async function updateWatermarkEvent(event, preset) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      watermarkId: preset,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update chatbot on event level
export async function updateEventChatBot(event, isEnable = false) {
  let response = await fetch
    .patch("events/update/chat-print-access", {
      id: event.id,
      showChatBot: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

export async function updateEventImageShare(event, isEnable) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      showImageShare: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

export async function updateEventWhatsapp(id, value) {
  let response = await fetch
    .post("events/update", {
      id: id,
      whatsAppMessageEnabled: value,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update ask mobileNumber
export async function updateShowMobileField(event, isEnable) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      showMobileField: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

//function to update ask mobileNumber
export async function togglePinField(event, isEnable) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      pinDisabled: isEnable,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

export async function deleteWatermark(id) {
  let response = await fetch.delete("watermark/delete/" + id);
  return response.data.data;
}

export async function getFoldersOfEvent(eventId) {
  let response = await fetch.get("folders", {
    params: {
      eventId: eventId,
    },
  });
  return response.data.data;
}

//function to handle folder download
export async function handleFolderDownload(folderId, type) {
  let response = await fetch.patch("folders/update", {
    id: folderId,
    isDownloadAble: type,
  });
  return response.data.data;
}

//function to update bulk folder restrictions
export async function updateFolderRestrictions(event, isDownloadAble) {
  let response = await fetch.post("folders/restriction", {
    eventId: event.id,
    isDownloadAble: isDownloadAble,
    slug: event.slug,
  });
  return response.data.data;
}

//function to handle email restrition for email for event
export async function updateEmailRestriction(event, status) {
  let response = await fetch
    .patch("events/update", {
      id: event.id,
      allowAllUsersToDownload: status,
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data.data;
}

export const getWatermarkPresets = async (url) => {
  let response = await fetch.get("watermark");
  return response.data.data;
};

export const moveImagesToFolder = async (
  imageIds,
  currentFolderId,
  newFolderId,
  type,
  handleDuplicates
) => {
  let response = await fetch.post("images/move", {
    currentFolderId: currentFolderId,
    newFolderId: newFolderId,
    imageIds: imageIds,
    type: type,
    handleDuplicates: handleDuplicates,
  });
  return response.data;
};

export const addWatermarkPresets = async (params, type) => {
  const url =
    ENVIRONMENT === "staging"
      ? "presigned-url-gcp"
      : "photographers/presigned-url";

  if (type === "file") {
    const fileName = crypto.randomUUID() + params.logo.name.split(".");
    const headers =
      ENVIRONMENT === "staging"
        ? {
            "Content-Type": "application/octet-stream",
          }
        : {
            "Content-Type": params.logo.file,
            "x-amz-storage-class": "INTELLIGENT_TIERING",
          };
    let response = await fetch.post(url, {
      type: "watermark",
      fileName: fileName,
    });
    let logoUrl = response.data.destinationUrl;
    let config = {
      method: "put",
      url: response.data.preSignedUrl,
      headers: headers,
      data: params.logo.file,
    };
    await axios(config);
    let data = {
      title: params.title,
      type: "file",
      logoUrl: fileName,
      scale: params.scale,
      opacity: params.opacity,
      xOffset: params.xOffset,
      yOffset: params.yOffset,
      landscapeImagePosition: params.landscapeImagePosition,
      portraitImagePosition: params.portraitImagePosition,
      fullWatermark: params.fullWatermark,
    };
    let watermark = await fetch.post("watermark/create", data);
    return watermark.data.data;
  }

  let data = {
    title: params.title,
    type: "text",
    font: params.font,
    fontColor: params.fontColor,
    logoText: params.logoText,
    scale: params.scale,
    opacity: params.opacity,
    xOffset: params.xOffset,
    yOffset: params.yOffset,
    landscapeImagePosition: params.landscapeImagePosition,
    portraitImagePosition: params.portraitImagePosition,
    fullWatermark: params.fullWatermark,
  };

  let response = await fetch.post(`watermark/create`, data);
  return response.data.data;
};

export const updateWatermarkPresets = async (params, logoType) => {
  if (logoType === "file") {
    let logoUrl = null;
    let fileName = "";
    if (params.logo) {
      fileName = crypto.randomUUID() + params.logo.name.split(".");
      const url =
        ENVIRONMENT === "staging"
          ? "presigned-url-gcp"
          : "photographers/presigned-url";
      const headers =
        ENVIRONMENT === "staging"
          ? {
              "Content-Type": "application/octet-stream",
            }
          : {
              "Content-Type": params.logo.file,
              "x-amz-storage-class": "INTELLIGENT_TIERING",
            };
      let response = await fetch.post(url, {
        type: "watermark",
        fileName: fileName,
      });
      logoUrl = response.data.destinationUrl;
      let config = {
        method: "put",
        url: response.data.preSignedUrl,
        headers: headers,
        data: params.logo.file,
      };
      await axios(config);
    }
    let data = {
      id: params.id,
      title: params.title,
      type: "file",
      logoUrl: fileName,
      scale: params.scale,
      opacity: params.opacity,
      xOffset: params.xOffset,
      yOffset: params.yOffset,
      landscapeImagePosition: params.landscapeImagePosition,
      portraitImagePosition: params.portraitImagePosition,
      fullWatermark: params.fullWatermark,
    };
    let watermark = await fetch.patch("watermark/update", data);
    return watermark.data.data;
  }

  let data = {
    id: params.id,
    title: params.title,
    type: "text",
    font: params.font,
    fontColor: params.fontColor,
    logoText: params.logoText,
    scale: params.scale,
    opacity: params.opacity,
    xOffset: params.xOffset,
    yOffset: params.yOffset,
    landscapeImagePosition: params.landscapeImagePosition,
    portraitImagePosition: params.portraitImagePosition,
    fullWatermark: params.fullWatermark,
  };

  let response = await fetch.patch("watermark/update", data);
  return response.data.data;
};

export const changeCoverImage = async (event, file, isMobile = false) => {
  const url =
    ENVIRONMENT === "staging"
      ? "presigned-url-gcp"
      : "photographers/presigned-url";
  const headers =
    ENVIRONMENT === "staging"
      ? {
          "Content-Type": "application/octet-stream",
        }
      : {
          "Content-Type": file,
          "x-amz-storage-class": "INTELLIGENT_TIERING",
        };
  let response = await fetch.post(url, {
    type: "event_cover_image",
    fileName: file.name,
    eventId: event.id,
  });

  let coverImageUrl = response.data.destinationUrl;
  let config = {
    method: "put",
    url: response.data.preSignedUrl,
    headers: headers,
    data: file,
  };

  await axios(config);
  let data = {
    id: event.id,
    ...(!isMobile && { desktopCoverImage: coverImageUrl }),
    ...(isMobile && { mobileCoverImage: coverImageUrl }),
  };
  let coverImage = await fetch.patch("events/update", data);
  return coverImage.data.data;
};

export const cropCoverImage = async (event, x, y, width, height, isMobile) => {
  if (isMobile) {
    let url = event.mobileCoverImageUrl.replace(RESIZE_IMAGE_CDN, ""),
      cropValues = url.split("?cr=").pop(),
      coverImageUrl = url.replace("?cr=" + cropValues, "");

    let response = await fetch.patch("events/update", {
      id: event.id,
      mobileCoverImage:
        coverImageUrl + "?cr=" + x + "," + y + "," + width + "," + height,
    });

    return response.data.data;
  }

  let url = event.desktopCoverImageUrl.replace(RESIZE_IMAGE_CDN, ""),
    cropValues = url.split("?cr=").pop(),
    coverImageUrl = url.replace("?cr=" + cropValues, "");

  let response = await fetch.patch("events/update", {
    id: event.id,
    desktopCoverImage:
      coverImageUrl + "?cr=" + x + "," + y + "," + width + "," + height,
  });

  return response.data.data;
};

export const updateUserEmail = async (email) => {
  let response = await fetch.patch("users/update", {
    email: email,
  });
  return response.data;
};

export const updateUserPassword = async (password, currentPassword) => {
  let response = await fetch.patch("users/update/password", {
    password: password,
    currentPassword: currentPassword,
  });
  return response.data;
};

export const updatePhotographerDetails = async (data) => {
  let response = await fetch.patch("photographers/update", data);
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerWatermarkSettings = async (data) => {
  let response = await fetch.patch("photographers/update", data);
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerFramePrices = async (data) => {
  let response = await fetch.patch("photographers/update/frame-prices", data);
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerSocialMedia = async (socialMedia, value) => {
  let response = await fetch.patch("photographers/update", {
    [socialMedia]: value,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerCountry = async (country, countryDialCode) => {
  let response = await fetch.patch("photographers/update", {
    country: country,
    countryDialCode: countryDialCode,
  });
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const updatePhotographerDomain = async (data) => {
  let response = await fetch.post("photographers/domain", data);
  localStorage.setItem("access_token", response.data.access_token);
  return response.data;
};

export const getFaceSearchAnalytics = async (
  startDate = null,
  endDate = null,
  frequency,
  eventId = null
) => {
  let response = await fetch.get("analytics/face-search", {
    params: {
      startDate: startDate,
      endDate: endDate,
      frequency: frequency,
      eventId: eventId,
    },
  });
  return response.data;
};

export const getImpressionAnalytics = async (
  startDate = null,
  endDate = null,
  frequency,
  eventId = null
) => {
  let response = await fetch.get("analytics/impressions", {
    params: {
      startDate: startDate,
      endDate: endDate,
      frequency: frequency,
      eventId: eventId,
    },
  });

  return response.data;
};

export const getDownloadRequestAnalytics = async (
  startDate = null,
  endDate = null,
  frequency,
  eventId = null
) => {
  let response = await fetch.get("analytics/download-requests", {
    params: {
      startDate: startDate,
      endDate: endDate,
      frequency: frequency,
      eventId: eventId,
    },
  });

  return response.data;
};

export const getGuestTraffic = async (
  startDate = null,
  endDate = null,
  eventId = null
) => {
  let response = await fetch.get("analytics/guest", {
    params: {
      startDate: startDate,
      endDate: endDate,
      eventId: eventId,
    },
  });

  return response.data;
};

export const getUserFaceRegistration = async (
  startDate = null,
  endDate = null,
  eventId = null,
  page = 1
) => {
  let response = await fetch.get("analytics/user-face-search-registration", {
    params: {
      startDate: startDate,
      endDate: endDate,
      eventId: eventId,
      page: page,
    },
  });

  return response.data;
};

export const getPreFaceRegistration = async (
  startDate = null,
  endDate = null,
  eventId = null,
  page = 1
) => {
  let response = await fetch.get("analytics/pre-registration", {
    params: {
      startDate: startDate,
      endDate: endDate,
      eventId: eventId,
      page: page,
    },
  });

  return response.data;
};

export const getDownloadRequestsList = async (
  startDate = null,
  endDate = null,
  eventId = null,
  page = 1
) => {
  let response = await fetch.get("analytics/download-requests-details", {
    params: {
      startDate: startDate,
      endDate: endDate,
      eventId: eventId,
      page: page,
    },
  });

  return response.data;
};

export const changePassword = async (
  email,
  password,
  token,
  confirmPassword
) => {
  let result = await axios.post(`${endpoint}users/password/reset`, {
    email: email,
    password: password,
    token: token,
    password_confirmation: confirmPassword,
  });
  return result;
};

export const forgotEmail = async (email) => {
  let result = await axios.post(`${endpoint}users/password/email`, {
    email: email,
  });
  return result;
};
export const waitlist = async (data) => {
  let result = await axios.post(`${endpoint}waitlists`, {
    ...data,
  });
  return result;
};

//Not used
export const getAllOrders = async () => {
  let response = await fetch.get("order/list");
  return response.data;
};

//function to get bank details
export const getBankDetails = async (userId) => {
  let response = await fetch.get("photographers/bank/details", {
    params: {
      userId: userId,
    },
    // validateStatus: () => true
  });
  return response;
};

//function to update bank details
export const updateBankDetails = async (userId, bankDetails) => {
  let response = await fetch.post("photographers/bank/update", {
    userId: userId,
    // bankDetails: bankDetails
    bankName: bankDetails.bankName,
    nameOnAccount: bankDetails.nameOnAccount,
    accountNumber: bankDetails.accountNumber,
    ifsc: bankDetails.ifsc,
    gst: bankDetails.gst,
    accountType: bankDetails.accountType,
  });
  return response;
};

export const updatePhotographerPrintStore = async (status) => {
  let response = await fetch.patch("photographers/update", {
    printStore: status,
  });
  return response.data;
};

export const updatePrintStoreCurrency = async (currency) => {
  let response = await fetch.patch("photographers/update", {
    currency: currency,
  });
  return response.data;
};

export const getPlans = async (type) => {
  return await fetch.get(`${endpoint}sub/get-plans/${type}`);
};
export const checkCoupon = async (cp_name) => {
  return await fetch.get(`${endpoint}sub/check-coupon/${cp_name}`);
};
export const createStripeCutomer = async (billingDetails) => {
  let result = await fetch.post(
    `${endpoint}sub/create-st-customer`,
    billingDetails
  );
  localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};
export const getStripeCustomer = async () => {
  let result = await fetch.get(`${endpoint}sub/get-customer`);
  return result.data;
};
export const createSub = async (data) => {
  let result = await fetch.post(`${endpoint}sub/create-sub`, data);
  return result.data;
};

export const createPayment = async (data) => {
  let result = await fetch.post(`${endpoint}sub/create-single`, data);
  return result.data;
};

export const createrazorSub = async (data) => {
  let result = await fetch.post(`${endpoint}rsub/create-sub`, data);
  return result.data;
};

export const completeRazor = async (data) => {
  let result = await fetch.post(`${endpoint}rsub/complete-sub`, data);
  if (result.data.access_token)
    localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const completeSub = async (data) => {
  let result = await fetch.post(`${endpoint}sub/complete-sub`, data);
  if (result.data.access_token)
    localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};
export const cancelSub = async (data) => {
  let result = await fetch.post(`${endpoint}sub/cancel-sub`, data);
  localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const getPendingPayment = async (data) => {
  let result = await fetch.post(`${endpoint}get_pending_payment`, data);

  return result.data;
};

export const checkPlanUpgrade = async (data) => {
  let result = await fetch.post(`${endpoint}sub/check-sub-upgrade`, data);

  return result.data;
};

export const refreshToken = async () => {
  let result = await fetch.post(`${endpoint}photographers/refresh-token`);
  localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};
export const upgradeNow = async (data) => {
  let result = await fetch.post(`${endpoint}sub/upgrade-now`, data);

  return result.data;
};
export const CompleteUpgrade = async (data) => {
  let result = await fetch.post(`${endpoint}sub/upgrade-complete`, data);
  if (result.data.access_token)
    localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const cancelDowngrade = async () => {
  let result = await fetch.get(`${endpoint}sub/cancel-downgrade`);
  localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const completeStripeSingle = async (data) => {
  let result = await fetch.post(`${endpoint}sub/complete-single`, data);
  if (result.data.access_token)
    localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const completeRazorSingle = async (data) => {
  let result = await fetch.post(`${endpoint}rsub/complete-razor`, data);
  if (result.data.access_token)
    localStorage.setItem("access_token", result.data.access_token);
  return result.data;
};

export const createSingleOrder = async () => {
  let result = await fetch.post(`${endpoint}rsub/create-single-order`);

  return result.data;
};

export const sendEmailInvites = async (data) => {
  let result = await fetch.post(
    `${endpoint}photographers/sendEmailInvites`,
    data
  );
  return result;
};

export const getOrderlist = async (type) => {
  return await fetch.get(`${endpoint}order/list`);
};
export const getOrderlistEventWise = async (eventId) => {
  return await fetch.get(`${endpoint}order/list`, {
    params: {
      eventId: eventId,
    },
  });
};
export const getPhotographerQuota = async (type) => {
  return await fetch.get(`${endpoint}photographers/quota`);
};

export const getAppliedCoupons = async () => {
  let response = await fetch.get(`${endpoint}coupons`);
  return response.data.data;
};

export const getSubscription = async (type) => {
  let x = await fetch.get(`${endpoint}photographers/subscription`);
  return x.data;
};

export const getCountry = async (type) => {
  let x = await axios.get(`https://country.pixnif.com/`);
  return x.data;
};

export const getPendingStripe = async (data) => {
  let result = await fetch.post(`${endpoint}sub/get-pending`, data);

  return result.data;
};
export const addGuest = async (data) => {
  let result = await fetch.post(
    `${endpoint}photographers/share/guest-add`,
    data
  );

  return result.data;
};

export const newsLetterSignup = async (email) => {
  let result = await fetch.post(`${endpoint}store/newsLetter`, {
    email: email,
  });
  return result.data;
};

export const postOrderDetails = (id) => {
  return fetch.get(`${endpoint}order/detail`, {
    params: {
      id: id,
    },
  });
};

export const getPayout = () => fetch.get(`${endpoint}photographers/payout`);

export const getAlgoEditRelease = (os) => {
  return fetch.get(`algoedit/version?os=${os}`);
};

export const postChangeCoverImage = (data) =>
  fetch.post(`${endpoint}events/cover-image`, data);

export const postVectorInit = (eventName) =>
  fetch.get("/photographers/refresh-event", {
    params: { awsEventName: eventName },
  });

export const getResendOrderDownload = (orderId) =>
  fetch.get("order/resend-download-email", {
    params: {
      orderId: orderId,
    },
  });
export const postMillerPriceChange = (data) =>
  fetch.post("photographers/update/frame-prices-usd", data);

export const getMillerBasePrice = () => fetch.get("photographers/miller-price");

export const getStripeURL = () =>
  fetch.get("photographers/stripe-connect/link");

export const postStripeConnectDetails = (postData) =>
  fetch.post("photographers/stripe-connect/callback", postData);

export const postDownloadGallery = (eventId, folderId) => {
  const data = new FormData();
  data.append("eventId", eventId);
  folderId.forEach((item, index) => data.append(`folderIds[${index}]`, item));

  return fetch.post("photographers/full-download", data);
};

export const postCreateLead = (data) => fetch.post(`lead/create`, data);

export const postDeleteAll = (data) => fetch.post(`images/delete/all`, data);

export const postEventGuestUploadToggle = (payload) =>
  fetch.post("events/update/guest-upload", payload);

export const getAnalyticsDownload = (payload) =>
  fetch.get(
    "analytics/download-csv",
    { params: payload },
    {
      responseType: "blob",
    }
  );
