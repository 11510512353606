import _, {isEmpty} from 'lodash';
import Swal from 'sweetalert2';
import {useUser} from "../../../../Store/User";
import Text from '../../../../components/common/Text';
import Title from '../../../../components/common/Title';
import isURL from 'validator/lib/isURL';
import {
    updatePhotographerDetails,
    updatePhotographerCountry,
    updateUserEmail,
    updateUserPassword
} from "../../../../apis";
import React, {useEffect, useState, useCallback} from 'react';
import {InputEditable, InputPhone, TextareaEditable} from '../../../../components/common/Input';
import {countries} from "../../../../json/country_codes";
import Loading from "../../../Loading";
import CountryDropDown from "../../../../components/common/DropDown/CountryDropDown";


const BusinessDetails = (props) => {
    const {user, setUser} = useUser(),
        [email, setEmail] = useState(),
        [countryCode, setCountryCode] = useState(),
        [phone, setPhone] = useState(),
        [photographer, setPhotographer] = useState({
            isUpdated: false,
        }),
        [password, setPassword] = useState(''),
        [showCountries, setShowCountries] = useState(false),
        [errors, setErrors] = useState({}),
        [successStatus, setSuccessStatus] = useState({
            ...photographer,
            portfolioWebsite: false,
            phone: false,
            about: false,
            country: false,
            countryDialCode: false,
        }),
        [processingStatus, setProcessingStatus] = useState({
            ...photographer,
            portfolioWebsite: false,
            phone: false,
            about: false,
            country: false,
            countryDialCode: false,
        });

    useEffect(async () => {
        setEmail(user.user.email);
        setPhone(user.user.phone);
        setCountryCode(user.photographer.country_dial_code);
        if (user.photographer) {
            setPhotographer({
                ...photographer,
                portfolioWebsite: user.photographer.portfolio_website ?? '',
                phone: user.photographer.phone ? (user.photographer.country_dial_code ? '+' + user.photographer.country_dial_code + user.photographer.phone : '+91' + user.photographer.phone) : '',
                about: user.photographer.about ?? '',
                country: user.photographer.country ?? '',
                countryDialCode: user.photographer.country_dial_code ?? '',
            });
        }
    }, [user]);

    function updatePortFolioWebsite() {
        setErrors({...errors, portfolioWebsite: null});
        setSuccessStatus({...successStatus, portfolioWebsite: false});
        setProcessingStatus({...processingStatus, portfolioWebsite: true});
        updatePhotographerDetails({portfolioWebsite: photographer.portfolioWebsite}).then(response => {
            setSuccessStatus({...successStatus, portfolioWebsite: true});
            setProcessingStatus({...processingStatus, portfolioWebsite: false});
        }).catch(error => {
            setErrors({...errors, portfolioWebsite: error.response?.data?.message});
            setProcessingStatus({...processingStatus, portfolioWebsite: false});
        });
    }


    function updateEmail(email) {
        setErrors({...errors, email: null});
        setSuccessStatus({...successStatus, email: false});
        updateUserEmail(email).then(response => {
            setSuccessStatus({...successStatus, email: true});
        }).catch(error => {
            setErrors({...errors, email: error.response?.data?.message});
        });
    }

    function updatePhone() {
        setErrors({...errors, phone: null});
        setSuccessStatus({...successStatus, phone: false});
        updatePhotographerDetails({
            phone: photographer.phone.replace(photographer.countryDialCode, ''),
            countryDialCode: photographer.countryDialCode
        }).then(response => {
            setSuccessStatus({...successStatus, phone: true});
        }).catch(error => {
            setErrors({...errors, phone: error.response?.data?.message});
        });
    }

    function updateBio() {
        setErrors({...errors, about: null});
        setSuccessStatus({...successStatus, about: false});
        updatePhotographerDetails({about: photographer.about}).then(response => {
            setSuccessStatus({...successStatus, about: true});
            window.Toast.fire({
                icon: 'success',
                title: 'Bio updated successfully'
            });
        }).catch(error => {
            window.Toast.fire({
                icon: 'success',
                title: 'Error updating Bio'
            });
            setErrors({...errors, about: error.response?.data?.message});
        });
    }

    function validateEmail(email) {
        setEmail(email);
        const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
        if (!regex.test(email)) {
            setErrors({...errors, email: "The email is not valid"});
        } else {
            setErrors({...errors, email: null});
        }
    }

    function updatePassword() {
        if (_.size(password) < 6) {
            setErrors({...errors, password: "Password should have a min. of 6 characters."});
            return false;
        }
        setErrors({...errors, password: null});
        setSuccessStatus({...successStatus, password: false});
        Swal.fire({
            title: 'Enter your current password',
            input: 'password',
            inputLabel: 'Password',
            inputPlaceholder: 'Enter your current password',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        }).then(key => {
            if (key.isConfirmed) {
                updateUserPassword(password, key.value).then(response => {
                    setSuccessStatus({...successStatus, password: true});
                    setErrors({...errors, password: null});
                }).catch(error => {
                    setErrors({
                        ...errors,
                        password: error.response.data.message
                    });
                });
            }
        })
    }

    function updatedSelectedCountry(country) {
        let code = countries.find(el=> el.name === country).code
        let selectedCountry = country.replace(/[^a-z]/gi, '');

        updatePhotographerCountry(selectedCountry, code).then(response => {
            setSuccessStatus({...successStatus, country: true, countryDialCode: true});
        }).catch(error => {
            setErrors({...errors, country: error.response?.data?.message});
        });
    }

    function validateWebsite(url) {
        setProcessingStatus({...processingStatus, portfolioWebsite: false});
        setSuccessStatus({...successStatus, portfolioWebsite: false});
        let updatedUrl = _.isEmpty(url.trim()) ? null : 'https://' + url.trim();
        setPhotographer({...photographer, portfolioWebsite: updatedUrl, isUpdated: false});
        if (!isURL(updatedUrl)) {
            setErrors({...errors, portfolioWebsite: "Please provide a valid url."});
            return false;
        }
        setErrors({...errors, portfolioWebsite: null});
        setPhotographer({...photographer, portfolioWebsite: updatedUrl, isUpdated: true});
    }

    return (
        !user || !user.photographer ? <Loading/> :
            <div ref={props.setRef} id="business-details" className="mt-24">
                <Title
                    className='pb-4 border-secondary-600 border-b mt-4'
                    text="Business Details"
                />
                <div className='mt-7 flex justify-between gap-32'>
                    <div className="flex flex-col gap-y-8 w-full">
                        <div>
                            <InputEditable
                                label="Contact email"
                                type="email"
                                onChange={(event) => validateEmail(event.target.value)}
                                value={email}
                                error={errors.email}
                                onUpdate={(value) => updateEmail(value)}
                                required={true}
                                success={successStatus.email}
                                readOnly={true}
                            />
                            {/*<span>Resend Verification Email</span>*/}
                        </div>

                        <InputPhone
                            onChange={(value, country, e, formattedValue) => {
                                setPhotographer({...photographer, phone: value, countryDialCode: country.dialCode});
                            }}
                            value={photographer.phone}
                            error={errors.phone}
                            onUpdate={(value) => updatePhone()}
                            success={successStatus.phone}
                        />

                        {
                            user.user.account_type && user.user.account_type.email ?
                                <InputEditable
                                    label="Change Your Password"
                                    type="password"
                                    placeholder="Enter your new password"
                                    name="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    error={errors.password}
                                    onUpdate={(value) => updatePassword()}
                                    required={true}
                                    success={successStatus.password}
                                /> : <div className="flex flex-col w-full">
                                    <Text as="label"
                                          className="flex justify-between items-baseline text-base">
                                        <span className="inline-block">Password</span>
                                    </Text>
                                    <div
                                        className="text-light-gray mt-3 shadow-card pr-5 rounded-[8px] bg-dark-gray text-xs flex items-center">
                                        <span className="bg-primary-error-red rounded-l-[8px] p-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
                                    </span>
                                        <span className="pl-2.5">Password is not editable for social logins</span>
                                    </div>
                                </div>
                        }
                        <div>
                            <Text as="p" className='mb-4'>
                                Country
                            </Text>
                            <div className="relative max-w-[28rem]">
                                <CountryDropDown items={countries} selectedOption={updatedSelectedCountry}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-8 w-full">
                        <InputEditable
                            label="Portfolio website"
                            type="url"
                            prefix="https://"
                            onChange={(event) => validateWebsite(event.target.value.replaceAll('https://', '').replaceAll('http://', '').trim())}
                            value={photographer.portfolioWebsite ? photographer.portfolioWebsite.replaceAll('https://', '').replaceAll('http://', '').trim() : ''}
                            error={errors.portfolioWebsite}
                            onUpdate={(value) => updatePortFolioWebsite()}
                            success={successStatus.portfolioWebsite}
                            processing={processingStatus.portfolioWebsite}
                        />

                        <TextareaEditable
                            placeholder="Maximum 250 characters"
                            className="max-w-[28rem]"
                            label="Bio"
                            onChange={(e) => {
                                setPhotographer({
                                    ...photographer,
                                    about: e.target.value.substring(0, 250)
                                });
                            }}
                            value={photographer.about}
                            error={errors.about}
                            onUpdate={(value) => updateBio()}
                            success={successStatus.about}
                            required={true}
                        />
                    </div>
                </div>
            </div>
    );
};

export default BusinessDetails;
