import { useEffect } from "react";
import Cookies from "js-cookie";

import { userLogout } from "../../apis";
import Loading from "../Loading";

const ManualLogout = () => {
  const logoutUser = async (e) => {
    // await userLogout();
    Cookies.remove("access_token", {
      expires: 7,
      secure: true,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("defaultView");
    window.location.reload();
  };

  useEffect(() => {
    logoutUser();
  }, []);
  return (
    <div>
      <Loading text="Logging user out." />
    </div>
  );
};

export default ManualLogout;
