import React, {useState} from "react";
import _ from 'lodash';

export const Input = props => {
    const {
        id = null,
        className = "",
        label = "",
        required = true,
        type = "",
        onChange,
        value = '',
        error = '',
        placeholder = '',
        onKeyDown,
        readOnly,
        autoFocus = false,
        prefix = null
    } = props;
    const isPhoneInput = type === "tel";
    const showIsRequired = required ? "*" : "";
    return (
        <div className={`flex flex-col max-w-[28rem] ${className}`}>
            {label && <label htmlFor={_.kebabCase(label)}
                             className='text-gray text-sm flex justify-between pl-4'>
                <span className="inline-block">{label}{showIsRequired}</span>
                {error && <span className="inline-block text-error-red text-xs pr-4 mb-[0.438rem]">{`*${error}`}</span>}
            </label>}

            <div className={`flex justify-between items-center pr-5 rounded-[10px] bg-dark-gray mt-1 text-light-gray
               ${!value || readOnly ? 'shadow-input-empty' : 'shadow-input-filled'}  
               
               ${error ? 'outline outline-1 outline-error-red' : 'outline-none'}`
            }>

                {prefix ?
                    <span className="inline-block text-xs font-semibold whitespace-nowrap text-secondary-500 pl-5">
                        {prefix}
                    </span> : null
                }

                {!isPhoneInput && (
                    <input
                        id={_.isNull(id) ? _.kebabCase(label) : id}
                        value={value}
                        type={type}
                        placeholder={placeholder}
                        className={` ${!prefix ? 'pl-4' : ''} bg-transparent py-2 placeholder:text-secondary-500 placeholder:text-sm outline-none w-full text-light-gray text-sm`}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onKeyDown(e)
                            }
                        }}
                        readOnly={props.readOnly}
                        autoComplete="off"
                        autoFocus={autoFocus}
                    />
                )}
            </div>
        </div>
    );
};
