import React, { useEffect, useState } from "react";
import moment from "moment";

import Title from "../../../../components/common/Title";
import AlgoSelectLogo from "../../../../assets/images/logo-on-dark-bg-as-monogram@3x.png";
import AlogCullLogo from "../../../../assets/images/logo-ac.svg";
import { useUser } from "../../../../Store/User";
import {
  getPhotographerQuota,
  getPlans,
  getSubscription,
} from "../../../../apis";
import Loading from "../../../Loading";
import { bytesToSize } from "../../../../functions";

const YourPlan = (props) => {
  const [userSub, setUserSub] = useState([]);
  const { user } = useUser();

  const [userSubData, setUserSubData] = useState(false);

  const fetchDetails = async () => {
    // const plans = await getPlans(user.user.country_code=="IN"?2:1);
    const getSub = await getSubscription();
    setUserSubData(getSub);
    const quota = await getPhotographerQuota();
    updatePlan(getSub, quota.data);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const updatePlan = (subscriptionData, quota) => {
    const currentPlan = Object.keys(subscriptionData).map((el) => {
      return {
        planName:
          el +
          "<div class='text-sm'> " +
          (subscriptionData[el].currentPlan
            ? subscriptionData[el].currentPlan.name ?? "Free"
            : "Free") +
          "</div>",
        ...(el === "algoshare" && { progressLabel: "Images" }),
        ...(el === "algoshare" && {
          bandwidth: `${bytesToSize(
            (quota.consumedSize * 1000 * 1000).toFixed(2)
          )} /${bytesToSize(user.photographer.allowed_size * 1000 * 1000)} `,
        }),
        ...(el === "algoshare" && {
          percentage: `${
            (quota.consumedSize / user.photographer.allowed_size) * 100
          }%`,
        }),
        id: el,
        logoImg: el === "algoshare" ? AlgoSelectLogo : AlogCullLogo,
      };
    });

    setUserSub(currentPlan);
  };

  return (
    <div ref={props.setRef} id="plans" className=" mt-24">
      <div className="flex justify-between items-center border-zinc-700 border-b mb-4 pb-4">
        <Title text="Your Plan" />
      </div>

      <ul className="mb-10">
        {userSub ? (
          userSub.map(
            ({
              logoImg,
              planName,
              progressLabel,
              bandwidth,
              percentage,
              id,
            }) => {
              return (
                <div key={planName}>
                  <li
                    key={`plannn${id}`}
                    className="flex justify-between basis-full items-center pt-4 pb-1 md:w-11/12"
                  >
                    <div className="flex items-center ">
                      <div className="flex items-center rounded-lg w-14 h-14 bg-black p-2">
                        <img src={logoImg} />
                      </div>
                      <div
                        className="ml-4 capitalize"
                        dangerouslySetInnerHTML={{ __html: planName }}
                      ></div>
                    </div>
                    <div className="w-1/2 px-5">
                      <div className="relative pt-1 w-full">
                        <div className="flex justify-between py-2 text-sm">
                          {progressLabel}
                          <span className="text-stone-400">{bandwidth}</span>
                        </div>
                        <div
                          className={`overflow-hidden h-2 mb-4 text-xs flex rounded bg-black ${
                            bandwidth ? "" : "invisible"
                          }`}
                        >
                          <div
                            style={{ width: percentage }}
                            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-fuchsia-500 ${
                              bandwidth ? "" : "invisible"
                            }`}
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        className="rounded-[10px] whitespace-nowrap shadow-button font-semibold py-2 px-4 bg-button text-light-gray"
                        href={`//payments.${process.env.REACT_APP_URL}/plans/manage?product=${id}`}
                      >
                        Manage Plan
                      </a>
                    </div>
                  </li>
                  {planName.includes("algoshare") ? (
                    <div className="text-xs pb-4">
                      * After your subscription ends, a 15-day countdown will
                      commence before removing your data securely.{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            }
          )
        ) : (
          <Loading className="w-full" noFullScreen={true} />
        )}
      </ul>

      {userSubData &&
      userSubData.app_start_date &&
      userSubData.with_app == 1 ? (
        <div className={"mt-7 ml-3"}>
          <p className="font-medium mb-2">App purchased</p>
          <div className="text-sm">
            Will expire on :{" "}
            {moment(userSubData.app_start_date.split(" ")[0], "YYYY-MM-DD")
              .add(1, "y")
              .format("Do MMM YYYY")}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default YourPlan;
