import React, {useState, useEffect} from "react";
import {InputEmail, InputText} from "../../components/common/Input";
import Button from "../../components/common/Button";
import {forgotEmail, userLogin} from "../../apis";
import _ from "lodash";


const ForgotEmail = () => {
    const [email, setEmail] = useState()
    const [errors, setErrors] = useState({});
    const [buttonDisable, setButtonDisable] = useState(true);

    useEffect(() => {
        if (!_.isEmpty(email)) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [email]);

    const handleEmail = (e) => {
        if (!/\S+@\S+\.\S+/.test(email)) {
            setErrors({...errors, email: "Email is invalid"});
            return
        }

        forgotEmail(email).then(function (response) {
                window.Toast.fire({
                    icon: 'success',
                    title: 'Email sent'
                });
                setEmail("")
            }
        ).catch((error) => {
            window.Toast.fire({
                icon: 'error',
                title: 'Something went wrong'
            });

            if (error?.response?.data?.message?.includes("email") || error?.response?.data?.message?.toLocaleLowerCase().includes("user")) {
                setErrors({ ...errors, email: error.response.data.message });
              }
        });
    }

    return (
        <div className="flex items-center align-middle text-white min-h-[calc(100vh_-_80px)]">
            <div className="container mx-auto max-w-screen-lg xl:max-w-screen-xl xl:px-10 px-10 ">
                <div
                    className="grid grid-cols-1 xl:grid-cols-1 flex items-center sm:w-[50%] sm:m-auto">
                    <div className='boxShadow lg:m-5 lg:p-8 rounded-lg shadow-button py-[9px] px-[16px] text-center'>
                        <h1 className='text-secondary-300 text-2xl font-medium'>Password Reset</h1>
                        <div className="mt-5">
                            <div className='flex flex-col pb-4 mx-auto max-w-[28rem]'>
                                <InputEmail
                                    onChange={(e) => {
                                        setEmail(e);
                                        setErrors({...errors, email: null})
                                    }}
                                    value={email}
                                    label='Email ID'
                                    error={errors.email}
                                    autoFocus={true}

                                />
                            </div>
                        </div>
                        <div className='flex justify-center mt-5'>
                            <Button
                                disabled={buttonDisable}
                                onClick={(e) => {
                                    handleEmail(e)
                                }} text='Send Email'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotEmail;
