import _ from "lodash";
import Text from "../../../../../../components/common/Text";
import {ReactSortable} from "react-sortablejs";
import {updateFolderOrder} from "../../../../../../apis";
import React, {useState, useEffect, useRef, useCallback} from "react";
import {getEventImagesCount, createNewFolder} from "../../../../../../apis";
import {useEvent} from "../../../../../../Store/Event/Show";
import {updateEventDescription, updateEventTag} from "../../../../../../apis";
import FolderShow from "../../../../../../components/Folder/Show";
import {InputEditable, InputEmail, InputText} from "../../../../../../components/common/Input";
import {Link} from "react-router-dom";
import {useUser} from "../../../../../../Store/User";
import {useUploader} from "../../../../../../Store/Uploader";
import {useFolder} from "../../../../../../Store/Folder/Show";
import {json} from "d3";

function AlbumPreviewSection({  eventId,
                                 favList,
                                 setFavList,
                                 currentFavList,
                                 handleFavListChange,
                                 favListEmail,
                                 imageCount
                             }) {

    const changeFavList = (e) => {
        handleFavListChange(e)
    }

    return (
        <div className='h-full'>
            <div className='min-w-[20rem] shadow-sidebar pl-10 pt-7 pr-6 pb-20 h-full sticky top-0 rounded-tr-3xl rounded-br-[10px]'>
                <div className='pt-4 pb-2 w-64'>
                    <div className=' flex justify-between items-center'>
                        <p className='text-lg font-medium text-light-gray truncate' title={favListEmail}>
                            {favListEmail}
                        </p>
                        {/* <Link linkName={"+ Add New"}/> */}
                        {/* <div className="relative cursor-pointer">
                                <span onClick={(e) => {
                                    setAddFolderPopUp(!addFolderPopUp)
                                }}>
                                    <Text as='p' className='text-sm underline
                                    text-primary-green font-medium'>
                                        + Add New
                                    </Text>
                                </span>
                            {addFolderPopUp &&
                            <div
                                className='absolute p-4 mt-3 bg-dark-gray shadow-dark-input w-[300px] z-40 right-0.5 rounded-[10px] top-[95%]'
                                ref={addNewFolderContainer}>

                                <div className='flex flex-col gap-3 cursor-pointer text-light-gray text-sm'>
                                    <InputEditable
                                        actionText="Add"
                                        value={folderName}
                                        error={folderNameError}
                                        processing={false}
                                        // onUpdate={(value) => {
                                        //     addNewFolder(value);
                                        //     setFolderNameError('');
                                        // }}
                                        onChange={(e) => {
                                            setFolderName(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            }
                        </div> */}

                    </div>
                    <ul className="mt-3" id="sortable">
                        {!_.isEmpty(favList) && favList.map((list, index) => {
                            return (
                                <li key={list.id} onClick={()=>{changeFavList(list.id)}} className={`${currentFavList == list.id ? 'bg-black text-primary-green font-medium' : ''} flex flex-row relative rounded-[10px] justify-between cursor-pointer hover:bg-black px-5 items-center py-3 h-[40px] text-light-gray truncate`}>
                                    <span>{list.name}</span>
                                    {/* <span className="flex rounded-md justify-center px-3 items-center text-xs bg-black-gray w-4 h-[1.15rem] mr-2" >{imageCount}</span> */}

                                </li>
                            )}
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AlbumPreviewSection;
