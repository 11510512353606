import React from "react";

const EditIcon = () => {
    return (
        <svg className="cursor-pointer"
             width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.567 1.11a1.996 1.996 0 1 1 2.823 2.823l-.791.791-2.823-2.823.791-.791zM8.364 3.313 0 11.677V14.5h2.823l8.365-8.364-2.825-2.823h.001z" fill="#EEE" fillRule="nonzero" />
        </svg>
    );
};

export default EditIcon;
