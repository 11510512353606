import React from "react";

import DownArrow from "../../../../../assets/icons/DownArrow";
import {  restrictEmailForEvent } from "../../../../../apis";
import { useEvent } from "../../../../../Store/Event/Show";

const DetailComponent = (props) => {
  const { event } = useEvent();
  const { guest, selectSharingPerson, setSelectSharingPerson, setGuests } =
    props;

  const showDropDownFunct = () => {
    if (selectSharingPerson === guest.id) {
      setSelectSharingPerson(null);
    } else {
      setSelectSharingPerson(guest.id);
    }
  };

  const updateGuestStatus = (status) => {
    restrictEmailForEvent(event, guest.email, status).then(function (response) {
      /*const filteredResponse = response.filter(function (response) {
                return response.status != 0;
            });*/
      setGuests(response);
    });
  };
  return (
    <div className="flex justify-between gap-x-16 w-4/5 2xl:w-[30%]">
      <div className="flex items-center">
        <div className="p-2 rounded-md bg-neutral-600 text-xl uppercase font-medium cursor-pointer text-light-gray">
          {guest.name
            ? guest.name.substring(0, 2)
            : guest.email.substring(0, 2)}
        </div>
        <div className="grid grid-rows-2 grid-flow-col pl-4">
          <div
            title={guest.name}
            className="font-semibold text-light-gray truncate"
          >
            {guest.name}
          </div>
          <div
            title={guest.email}
            className="text-xs text-neutral-400 truncate"
          >
            {guest.email}
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          showDropDownFunct();
        }}
        className="flex items-center shrink-0 justify-between whitespace-nowrap text-primary-green"
      >
        <div className="relative flex items-center mr-5 text-xs underline underline-offset-4 cursor-pointer sharing-card">
          <span className="font-semibold">
            {guest.status === 2
              ? "Co-Admin"
              : guest.status === 1
              ? "Viewer"
              : "Blocked"}
          </span>
          <span className="ml-2">
            <DownArrow rotate={selectSharingPerson === guest.id} />
          </span>
          {selectSharingPerson === guest.id && (
            <div className="absolute light:bg-theme-light bg-dark-gray shadow-dark-input w-[200px] right-[0px] top-[30px] rounded-[10px] overflow-hidden z-40 mt-3">
              <div className="flex flex-col  text-light-gray text-sm">
                <div
                  onClick={() => {
                    updateGuestStatus(2);
                  }}
                  className="whitespace-nowrap px-4 py-3 cursor-pointer hover:bg-black hover:text-primary-green "
                >
                  Co-Admin
                </div>
                <div
                  onClick={() => {
                    updateGuestStatus(1);
                  }}
                  className="whitespace-nowrap px-4 py-3 hover:bg-black hover:text-primary-green "
                >
                  Viewer
                </div>
                <div className="border-t border-secondary-600 px-10 h-1"> </div>
                <div
                  onClick={() => {
                    updateGuestStatus(0);
                  }}
                  className="whitespace-nowrap px-4 py-3 hover:bg-black hover:text-red-400 hover:b"
                >
                  Block User
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailComponent;
