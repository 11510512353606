import { Link } from "react-router-dom";

import CopyIcon from "../../assets/icons/CopyIcon";
import FacebookIcon from "../../assets/images/Facebook-icon.png";
import InstagramIcon from "../../assets/images/Instagram-icon.png";
import SharkTankIcon from "../../assets/icons/sharkTankInida.svg";
import AlgocullIcon from "../../assets/images/logo-ac.svg";
import AlgoeditIcon from "../../assets/images/logo-ae.svg";
import AlgoshareIcon from "../../assets/images/logo-as.svg";
import Button from "../../components/common/Button";

const CapturingWow = () => {
  return (
    <main className="text-center h-screen p-10 md:p-20 bg-dark-gray dark text-white flex flex-col">
      <div className="md:flex md:relative">
        <div className="flex items-center gap-2">
          <Link to="/" className="block">
            <img src="/images/logo.svg" className="App-logo" alt="logo" />
          </Link>
          <span className="text-xs"> As seen on</span>
          <img src={SharkTankIcon} alt="Share Tank icon" />
        </div>
        <h1 className="leading-11 text-[36px] lg:text-[56px] font-oswald  mt-8 md:mt-0  font-medium md:absolute md:left-1/2 md:-translate-x-1/2 md:top-1/2 md:-translate-y-1/2">
          Capturing Wow Offer{" "}
        </h1>
      </div>
      <div className="self-center m-auto">
        <div className="font-medium md:mt-20 flex gap-2 items-center">
          <img src={AlgoshareIcon} width={40} alt={"AlgoshareIcon"} />
          <b>25 percent off</b> on Algoshare
        </div>
        <div className="mt-10 font-medium flex gap-2 items-center">
          <img src={AlgocullIcon} width={40} alt={"AlgocullIcon"} />
          Get algocull free for a month
        </div>
        <div className="mt-10 font-medium flex gap-2 items-center">
          <img src={AlgoeditIcon} width={40} alt={"AlgoeditIcon"} />
          Get algoedit free for a month
        </div>
        <div
          className="p-4 text-[36px] font-bold py-8 border-dashed border-[10px] mt-20 mb-10 border-white flex justify-between items-center cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText("CW25");
            window.Toast.fire({
              title: "Code copied successfully!",
              icon: "success",
            });
          }}
          role="button"
        >
          {" "}
          CW25 <CopyIcon />
        </div>
        <Button
          text={<Link to={"/register"}>Get Started</Link>}
          className="!px-8 !py-2 !w-auto !h-auto"
        />
      </div>
      <div className="mt-10 flex flex-col gap-4">
        <a
          href=" https://www.facebook.com/groups/algomage/?ref=share&mibextid=KtfwRi"
          target="_blank"
          rel="noreferrer"
          className="flex gap-4 text-sm md:text-base md:gap-8 items-center justify-center"
        >
          Join our community on Facebook{" "}
          <img src={FacebookIcon} alt="FacebookIcon" width={20} />{" "}
        </a>
        <a
          href="https://www.instagram.com/algomage_official/"
          className="flex gap-4 text-sm md:text-base md:gap-8 items-center justify-center"
          target="_blank"
          rel="noreferrer"
        >
          Follow us on Instagram{" "}
          <img src={InstagramIcon} alt="InstagramIcon" width={20} />{" "}
        </a>
      </div>
    </main>
  );
};

export default CapturingWow;
