import React, {useEffect, useState} from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './slider.scss';

function SliderComponent(props) {
    const {defaultValue, marks, onChange ,min, max,sliderValue,handleStyle,maximumTrackStyle,minimumTrackStyle,step,disabled,dots,dotStyle} = props
    let [v,setV] = useState(sliderValue)
    const changeHandler = (val) => {
        setV(val)
    }
    const parentChangeHandler = (val) => {
        onChange(val)
    }
    useEffect(()=>{
        setV(sliderValue)
    },[sliderValue])
    return (
        <Slider
            onChange={changeHandler}
            onAfterChange={parentChangeHandler}
            marks={marks}
            min={min}
            step={step}
            max={max}
            defaultValue={defaultValue}
            value={v}
            disabled={disabled}
            minimumTrackStyle={minimumTrackStyle}
            maximumTrackStyle={maximumTrackStyle}
            handleStyle={handleStyle}
            dots ={dots}
            dotStyle={dotStyle}
        />
    );
}

export default SliderComponent;
