/* eslint-disable react-hooks/exhaustive-deps */
import Swal from "sweetalert2";
import _ from "lodash";
import { Steps } from "intro.js-react";
import React, { useState, useEffect, useRef } from "react";

import SettingsTitleSection from "../../components/common/SettingsTitleSection";
import Title from "../../components/common/Title";
import GeneralSection from "./components/GeneralSection";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import IconButton from "../../components/common/IconButton";
import useOnScreen from "../../components/common/Sidebar/IntersectionObserver";
import { DIGITAL_DOWNLOAD_ENABLED } from "../../constants";
import { getCookie, setCookie, titleUpdate } from "../../functions";
import { useEvent } from "../../Store/Event/Show";
import { useUser } from "../../Store/User";
import CoverImageSection from "./components/CoverImageSection";
import DigitalDownloadSetting from "./components/DigitalDownloadSetting";
import DownloadSettings from "./components/DownloadSettings";
import FavouriteListSettings from "./components/FavouriteListSettings";
import { publishEvent, deleteEvent } from "../../apis";
import GalleryLayout from "./components/GalleryLayout";
import GeneralSectionRight from "./components/GeneralSectionRight";
import Sharing from "./components/Sharing";
import { useHelpContext } from "../../Store/Help";

const steps = [
  {
    element: "#watermark",
    title: "Watermark",
    intro:
      "Add a watermark dynamically to your images. You can change anytime.",
    position: "bottom",
  },
  {
    element: "#upload-cover-image",
    title: "Upload Cover Image",
    intro: "Change cover image of the event.",
    position: "bottom",
  },
  {
    element: "#crop-desktop-cover",
    title: "Crop for desktop",
    intro: "Crop the cover image for wide screens",
    position: "bottom",
  },
  {
    element: "#crop-mobile-cover",
    title: "Crop for mobile",
    intro: "Crop the cover image for mobile screens.",
    position: "bottom",
  },
  {
    element: "#cover-image-design",
    title: "Cover Design",
    intro: "Chose a style of the cover of the online gallery.",
    position: "bottom",
  },
  {
    element: "#gallery-layout",
    title: "Client Gallery Design",
    intro:
      "Choose how you want to show the gallery. Colour mode, thumbnail margins, thumbnail sizes & thumbnail alignments.",
    position: "bottom",
  },
  {
    element: "#downloads-size",
    title: "Download Size",
    intro: "Choose the size of images that your guests can download.",
    position: "bottom",
  },
  {
    element: "#downloads-restrictions",
    title: "Restrict Downloads",
    intro: "Don't allow specific email ids to bulk download the images.",
    position: "bottom",
  },
  {
    element: "#favourite-list-settings",
    title: "Favourite List",
    intro: "See all the favourite lists created by the users here.",
    position: "bottom",
  },
  // {
  //     element: "#sharing-section",
  //     title: 'Sharing',
  //     intro: 'Adds watermark, archive or delete event etc.',
  //     position: 'bottom',
  // },
];

const EventSettings = () => {
  const [isBulkDownloadEnable, setIsBulkDownloadEnable] = useState(false);
  const [isSingleDownloadEnable, setIsSingleDownloadEnable] = useState(false);
  const [shareImage, setShareImage] = useState(null);
  const [activeMenu, setActiveMenu] = useState("general"),
    [showTour, setShowTour] = useState(false),
    generalRef = useRef(),
    isGeneralRefVisible = useOnScreen(generalRef),
    sharingRef = useRef(),
    isSharingVisible = useOnScreen(sharingRef),
    coverImgRef = useRef(),
    isCoverImgVisible = useOnScreen(coverImgRef),
    galleryRef = useRef(),
    isGalleryVisible = useOnScreen(galleryRef),
    downloadsRef = useRef(),
    isDownloadsVisible = useOnScreen(downloadsRef),
    digitalDownloadsRef = useRef(),
    isDigitalDownloadsVisible = useOnScreen(digitalDownloadsRef),
    favouriteRef = useRef(),
    isFavouriteVisible = useOnScreen(favouriteRef);

  const { user } = useUser();

  const { showSteps, setShowSteps } = useHelpContext();

  useEffect(() => {
    let menu = window.location.hash.split("#")[1];
    if (menu) {
      setActiveMenu(menu);
      if (menu === "cover-image") {
        coverImgRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (menu === "sharing-section") {
        sharingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    setTimeout(() => setShowTour(true), 3000);
  }, []);

  useEffect(() => {
    if (isGeneralRefVisible) {
      setActiveMenu("general");
    }

    if (isCoverImgVisible && !isGeneralRefVisible) {
      setActiveMenu("cover-image");
    }

    if (isGalleryVisible && !isCoverImgVisible) {
      setActiveMenu("gallery-layout");
    }

    if (isDownloadsVisible && !isGalleryVisible) {
      setActiveMenu("downloads");
    }

    if (
      DIGITAL_DOWNLOAD_ENABLED.includes(user.photographer.id) &&
      isDigitalDownloadsVisible &&
      !isDownloadsVisible
    ) {
      setActiveMenu("digital-downloads");
    }

    if (isFavouriteVisible && !isDigitalDownloadsVisible) {
      setActiveMenu("favourites-list");
    }

    if (isSharingVisible && !isFavouriteVisible) {
      setActiveMenu("sharing-section");
    }
  }, [
    isGeneralRefVisible,
    isCoverImgVisible,
    isGalleryVisible,
    isDownloadsVisible,
    isDigitalDownloadsVisible,
    isFavouriteVisible,
    isSharingVisible,
  ]);

  const { event } = useEvent();
  const [isPublish, setIsPublish] = useState(false);
  const [selectSharingPerson, setSelectSharingPerson] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(event)) {
      setIsPublish(event.isPublished);
    }
  }, [event]);

  useEffect(() => {
    titleUpdate(`Event Settings`);
  }, []);

  //function to publish event
  const markEventAsPublished = (e) => {
    publishEvent(event, true)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Event published successfully",
        });
        setIsPublish(true);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
    e.stopPropagation();
  };

  //function to Unpulish event
  const markEventAsUnpublished = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to unpublish ${event.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Unpublish ${event.name}`,
    }).then(function (key) {
      if (key.isConfirmed) {
        publishEvent(event, false)
          .then(function (response) {
            window.Toast.fire({
              icon: "success",
              title: "Event Unpublished Successfully",
            });
            setIsPublish(false);
          })
          .catch((errors) => {
            window.Toast.fire({
              icon: "error",
              title: "Event could not be unpublished",
            });
          });
      }
    });
  };

  //function to delete event
  function deleteCurrentEvent() {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete ${event.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete ${event.name}`,
    }).then(function (key) {
      if (key.isConfirmed) {
        deleteEvent(event)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Event deleted successfully",
            });
            window.location.href = "/";
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Event could not be deleted",
            });
          });
      }
    });
  }

  const closeDropDown = (e) => {
    if (typeof e.target.className.indexOf === "function") {
      if (e.target.className.indexOf("sharing-card") < 0) {
        setSelectSharingPerson(0);
      }
    }
  };

  const displaySteps =
    user.subscription.plan_type === "free"
      ? !getCookie("eventSettingsPage") || showSteps
      : showSteps;

  return (
    <div>
      {displaySteps && showTour && (
        <Steps
          enabled={true}
          steps={steps}
          initialStep={0}
          onExit={(step) => {
            setCookie("eventSettingsPage", 1);
            setShowSteps(false);
          }}
          options={{
            doneLabel: "Got it",
          }}
        />
      )}
      <SettingsTitleSection
        text="Go back to Event"
        headerText={`Settings for ${event.name}`}
        backLink={"/events/" + event.id}
      />
      <div
        className="flex"
        onClick={(e) => {
          closeDropDown(e);
        }}
      >
        <ul className="min-w-[20rem] mr-6 shadow-sidebar pl-10 pt-7 pr-6 h-screen sticky top-0 rounded-tr-3xl rounded-br-[10px]">
          {[
            {
              title: "General",
              id: "general",
            },
            {
              title: "Cover Image",
              id: "cover-image",
            },
            {
              title: "Gallery layout",
              id: "gallery-layout",
            },
            {
              title: "Downloads",
              id: "downloads",
            },
            DIGITAL_DOWNLOAD_ENABLED.includes(user.photographer.id) && {
              title: "Digital Downloads",
              id: "digital-downloads",
            },
            {
              title: "Favourites List",
              id: "favourites-list",
            },
            {
              title: "Sharing",
              id: "sharing-section",
            },
          ].map(({ id, title }) => {
            return (
              <li
                className={`${
                  title ? "h-10 mb-4" : ""
                }  text-base text-light-gray px-6 flex items-center w-full whitespace-nowrap ${
                  activeMenu === id
                    ? "text-primary-green bg-black rounder-lg"
                    : "text-secondary-300"
                } hover:bg-black rounded-lg`}
                key={id + title}
              >
                <a
                  className="w-full"
                  href={`#${id}`}
                  onClick={() => {
                    setActiveMenu(id);
                  }}
                >
                  {title}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="flex flex-col pr-8 grow pb-44">
          <div id="logo" className="pb-[6.25rem]">
            <div
              className="flex justify-between align-baseline items-baseline mb-4 border-secondary-600 border-b"
              ref={generalRef}
              id="general"
            >
              <Title className="text-2xl" text="General" />

              <div className="flex justify-between my-4">
                <IconButton
                  className="mr-3 h-[40px]"
                  onClick={(e) => {
                    deleteCurrentEvent();
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                {isPublish ? (
                  <button
                    onClick={(e) => {
                      markEventAsUnpublished(e);
                    }}
                    className="text-sm text-secondary-300 shadow-button-new rounded-lg py-[9px] px-[16px] w-[11rem] h-[40px]"
                  >
                    Unpublish
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      markEventAsPublished(e);
                    }}
                    className="text-sm text-secondary-300 shadow-button-new rounded-lg py-[9px] px-[16px] w-[11rem] h-[40px]"
                  >
                    Publish Event
                  </button>
                )}
              </div>
            </div>
            <div className="flex flex-wrap 2xl:flex-nowrap justify-between gap-x-32 gap-y-8">
              <div className="flex flex-col w-full">
                <GeneralSection />
              </div>
              <div className="flex flex-col w-full">
                <GeneralSectionRight
                  shareImage={shareImage}
                  setShareImage={setShareImage}
                />
              </div>
            </div>
          </div>
          <div id="cover-image" className=" pb-[6.25rem]" ref={coverImgRef}>
            <CoverImageSection />
          </div>
          <div id="gallery-layout" className=" pb-[6.25rem]" ref={galleryRef}>
            <GalleryLayout />
          </div>
          <div id="downloads" ref={downloadsRef} className=" pb-[6.25rem]">
            <DownloadSettings
              isBulkDownloadEnable={isBulkDownloadEnable}
              setIsBulkDownloadEnable={setIsBulkDownloadEnable}
              isSingleDownloadEnable={isSingleDownloadEnable}
              setIsSingleDownloadEnable={setIsSingleDownloadEnable}
            />
          </div>
          <div id="digital-downloads" ref={digitalDownloadsRef}>
            {DIGITAL_DOWNLOAD_ENABLED.includes(user.photographer.id) ? (
              <DigitalDownloadSetting
                isBulkDownloadEnable={isBulkDownloadEnable}
                setIsBulkDownloadEnable={setIsBulkDownloadEnable}
                isSingleDownloadEnable={isSingleDownloadEnable}
                setIsSingleDownloadEnable={setIsSingleDownloadEnable}
                shareImage={shareImage}
                setShareImage={setShareImage}
              />
            ) : (
              <></>
            )}
          </div>
          <div id="favourites-list" ref={favouriteRef} className="pb-[6.25rem]">
            <FavouriteListSettings />
          </div>
          <div id="sharing-section" ref={sharingRef} className="pb-72">
            <Sharing
              selectSharingPerson={selectSharingPerson}
              setSelectSharingPerson={setSelectSharingPerson}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSettings;
