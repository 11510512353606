import React, { useState } from "react";

const RadioButton = ({ label, checked, handleOnClick , width }) => (

  <div className={`items-center ${checked ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md ${!width ? 'w-[200px]':width} px-3 py-2.5 cursor-pointer `} onClick={handleOnClick} key={label}>
    <div className="flex items-center">
        { !checked ?
            <span className="rounded-full border border-cyan-500 w-[18px] h-[18px] inline-block"> </span>
                :
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter x="-16.7%" y="-16.7%" width="133.3%" height="133.3%" filterUnits="objectBoundingBox" id="enmw30siwb">
                        <feGaussianBlur stdDeviation="2" in="SourceAlpha" result="shadowBlurInner1"/>
                        <feOffset dx="2" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1"/>
                        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0" in="shadowInnerInner1" result="shadowMatrixInner1"/>
                        <feGaussianBlur stdDeviation="2" in="SourceAlpha" result="shadowBlurInner2"/>
                        <feOffset dx="-2" dy="-2" in="shadowBlurInner2" result="shadowOffsetInner2"/>
                        <feComposite in="shadowOffsetInner2" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner2"/>
                        <feColorMatrix values="0 0 0 0 0.22745098 0 0 0 0 0.22745098 0 0 0 0 0.22745098 0 0 0 0.5 0" in="shadowInnerInner2" result="shadowMatrixInner2"/>
                        <feMerge>
                            <feMergeNode in="shadowMatrixInner1"/>
                            <feMergeNode in="shadowMatrixInner2"/>
                        </feMerge>
                    </filter>
                    <rect id="rw2rsfri0a" x="0" y="0" width="18" height="18" rx="9"/>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <use fill="#1A1A1A" />
                    <use fill="#000" filter="url(#enmw30siwb)" />
                    <rect stroke="#00B7B8" strokeWidth=".5" strokeLinejoin="square" x=".25" y=".25" width="17.5" height="17.5" rx="8.75"/>
                    <rect fill="#00B7B8" x="3.375" y="3.375" width="11.25" height="11.25" rx="5.625"/>
                </g>
            </svg>
        }
      <label htmlFor={label} className="pl-2 cursor-pointer whitespace-nowrap text-light-gray">{label}</label>
    </div>
  </div>
);

export default RadioButton;
