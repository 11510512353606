import React from "react";

const DownloadIcon = props => {
    const { height = "1rem", width = "1rem", color = "white" } = props;
    return (
        <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z"/>
                <path d="M1 15a1 1 0 0 1 1-1h12a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1zm3.293-7.707a1 1 0 0 1 1.414 0L7 8.586V1a1 1 0 1 1 2 0v7.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z" fill="#EEE" fillRule="nonzero"/>
            </g>
        </svg>
    );
};

export default DownloadIcon;
