import React, {Fragment, useRef, useState, useContext, useEffect} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import _ from 'lodash';
import {Input} from "../../../../../components/common/Input";
import {updateEventTitle} from "../../../../../apis";
import isURL from 'validator/lib/isURL';
import {useUser} from "../../../../../Store/User";
import slugify from "slugify";


export default function UpdateTitlePopup({setShowTitleModal, event, setEvent}) {
    let {user} = useUser();
    const [errors, setErrors] = useState({name: null, slug: null}),
        [eventName, setEventName] = useState(null),
        [eventSlug, setEventSlug] = useState(null),
        [baseUrl, setBaseUrl] = useState(null),
        [isWorking, setIsWorking] = useState(false),
        cancelButtonRef = useRef(null),
        [open, setOpen] = useState(true);

    useEffect(async () => {
        if (!_.isEmpty(event) && !_.isEmpty(user.photographer)) {
            setEventName(event.name);
            setEventSlug(event.slug);

            let baseUrl = user.photographer.branding_allowed && !_.isEmpty(user.photographer.domain_name) ?
                'https://' +user.photographer.domain_name.toLowerCase()+'/view/' : 'https://' +user.photographer.subDomain.toLowerCase()+ '.' + process.env.REACT_APP_URL+'/view/';
            setBaseUrl(baseUrl);
        }
    }, [event, user]);

    const handleClose = (e) => {
        setOpen(false);
        setShowTitleModal(false);
        setErrors({name: null, slug: null});
    }

    function updateEvent(){
        setIsWorking(true);
        updateEventTitle(event, eventName, eventSlug).then(function (response) {
            setEvent(response);
            setIsWorking(false);
            window.Toast.fire({
                icon: 'success',
                title: 'Event name updated successfully'
            });
            handleClose();
        }).catch((response) => {
            setIsWorking(false);
            let error = response.response.data.errors;
            if(error.name){
                setErrors({...errors, name: error.name});
            }
            if(error.slug){
                setErrors({...errors, slug: error.slug});
            }
            window.Toast.fire({
                icon: 'error',
                title: 'Event name could not updated successfully'
            });
        });
    }

    function validateWebsite(slug) {
        let name = slugify(slug, {
            replacement: '_',
            remove: /[\\*~.()/'_"!:@]/g,
            lower: true,
            strict: false,
            trim: true ,
        });

        setEventSlug(name);
        let updatedUrl = baseUrl + name;
        if (!isURL(updatedUrl)) {
            setErrors({...errors, slug: "Please provide a valid url."});
            return false;
        }
        setErrors({...errors, slug: null});
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    onClose={(e) => {
                        handleClose(e)
                    }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                     ref={cancelButtonRef}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"

                    >
                        <div
                            className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="p-8">
                                <div className="sm:items-start">
                                    <div className="text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300">
                                            Update Event Name
                                            <span onClick={(e) => {
                                                handleClose(e)
                                            }} className="cursor-pointer"><CloseIcon fill='#eee'/></span>
                                        </Dialog.Title>

                                        <div className="mt-10">
                                            <Input
                                                label='Event Name'
                                                type="text"
                                                error={errors.name}
                                                onChange={(value) => {setEventName(value); setErrors({...errors, name: null})}}
                                                value={eventName}
                                                required={true}
                                            />

                                            <Input
                                                className="mt-5"
                                                label='Event URL'
                                                type="text"
                                                error={errors.slug}
                                                onChange={(value) => validateWebsite(value.trim().toLowerCase())}
                                                value={eventSlug}
                                                prefix={baseUrl}
                                            />

                                            <div className='flex gap-5 justify-center mt-10'>
                                                <button
                                                    className={`rounded-lg shadow-button font-semibold py-2 px-5 shadow-button
                                                     ${_.isEmpty(eventName) || _.isEmpty(eventSlug) || isWorking || !_.isNull(errors.name) || !_.isNull(errors.slug) ? 'bg-button-disabled' : "bg-button"} text-light-gray`}
                                                    disabled={_.isEmpty(eventName) || _.isEmpty(eventSlug) || isWorking}
                                                    onClick={() => {
                                                        updateEvent()
                                                    }}
                                                >
                                                    {isWorking ? 'Working...' : 'Update Event Name'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
