/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";

import SwitchWithTitle from "../SwitchWithTitle";
import Text from "../../../../components/common/Text";
import Button from "../../../../components/common/Button";
import Title from "../../../../components/common/Title";
import { InputText } from "../../../../components/common/Input";
import Tabs from "../Tabs";
import horizontal from "../../../../assets/images/horizontal.jpg";
import vertical from "../../../../assets/images/vertical.jpg";
import SliderComponent from "../../../../components/common/Slider";
import LogoPositionManager from "../logoPositionManager";
import WatermarkUploadSection from "./WatermarkUploadSection";
import {
  addWatermarkPresets,
  getWatermarkPresets,
  updatePhotographerWatermarkSettings,
  updateWatermarkPresets,
} from "../../../../apis";
import { useUser } from "../../../../Store/User";
import { defaultPreset, logoTextColors, waterMarkPlacement } from "./configs";
import Checkbox from "../../../../components/common/Checkbox";

const WatermarkSection = ({ logoType, setLogoType }) => {
  const { user, setUser } = useUser();
  const [presets, setPresets] = useState(null);
  const [currentPreset, setCurrentPreset] = useState({});
  const [error, setError] = useState({});
  const [watermarkStyle, setWatermarkStyle] = useState({});
  const [watermarkSettings, setWatermarkSettings] = useState({
    watermarkGalleryImages:
      user.photographer.watermark_settings.watermark_gallery_images,
    watermarkBulkDownloadedImages:
      user.photographer.watermark_settings.watermark_bulk_downloaded_images,
    watermarkSingleDownloadedImages:
      user.photographer.watermark_settings.watermark_single_downloaded_images,
  });
  const [bannerOrientation, setBannerOrientation] = useState(1);
  const [savePresetDisabled, setSavePresetDisabled] = useState(true);

  useEffect(async () => {
    getWatermarkPresets().then((response) => {
      setPresets(response);
    });

    setCurrentPreset(defaultPreset);
  }, []);

  function changePreset(value) {
    setError({});
    if (value == null) {
      setCurrentPreset(defaultPreset);
      setLogoType({ ...logoType, type: "file", text: "" });
      return;
    }
    if (value.id) {
      var pre = { ...value };
      if (value.logoText) {
        setLogoType({
          ...logoType,
          type: "text",
          text: value.logoText,
          logoUrl: "",
        });
      }
      if (value.logoUrl) {
        pre = { ...pre, font: "Arial", fontColor: "White" };
        setLogoType({ ...logoType, type: "file", logoText: "" });
      }
      setCurrentPreset(pre);
    } else {
      if (!_.isEmpty(logoType.text)) {
        setCurrentPreset({
          ...currentPreset,
          logoText: logoType.text,
          [Object.keys(value)]: Object.values(value).toString(),
        });
      } else {
        setCurrentPreset({
          ...currentPreset,
          [Object.keys(value)]: Object.values(value).toString(),
        });
      }
    }
  }

  useEffect(() => {
    if (logoType.type == "text") {
      if (!_.isEmpty(currentPreset.logoUrl)) {
        setCurrentPreset({
          ...currentPreset,
          logoUrl: null,
        });
      }
      setCurrentPreset({
        ...currentPreset,
        logoText: logoType.text,
      });
    }
  }, [logoType]);

  useEffect(async () => {
    resizeWater();
  }, [currentPreset]);

  useEffect(async () => {
    if (currentPreset.title === undefined) {
      delete error.title;
    }
  }, []);

  useEffect(async () => {
    var but_disabled = true;
    if (!_.isEmpty(currentPreset.title)) {
      if (
        (logoType.type == "text" && !_.isEmpty(currentPreset.logoText)) ||
        (logoType.type == "file" && !_.isEmpty(currentPreset.logoUrl))
      ) {
        but_disabled = false;
      }
    }
    setSavePresetDisabled(but_disabled);
  }, [logoType, currentPreset]);

  const presetAdd = () => {
    setSavePresetDisabled(true);
    let error = {};
    if (_.isEmpty(currentPreset.title)) error.title = "Title is required";
    if (logoType.type === "text" && _.isEmpty(currentPreset.logoText))
      error.logoText = "Logo text is required";
    if (logoType.type === "file" && _.isEmpty(currentPreset.logoUrl))
      error.logo = "Logo is required";
    if (!_.isEmpty(error)) {
      setError(error);
      setSavePresetDisabled(false);
      return;
    }

    if (!_.isEmpty(currentPreset.title)) {
      if (currentPreset.id) {
        updateWatermarkPresets(currentPreset, logoType.type)
          .then((response) => {
            setSavePresetDisabled(false);
            setPresets(response);
            window.Toast.fire({
              icon: "success",
              title: "Preset updated successfully",
            });
          })
          .catch((errors) => {
            console.log(errors)
            window.Toast.fire({
              icon: "error",
              title: "Error updating your preset",
            });
          });
      } else {
        addWatermarkPresets(currentPreset, logoType.type)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Watermark preset added successfully",
            });
            setSavePresetDisabled(true);
            changePreset(null);
            setPresets(response);
          })
          .catch((errors) => {
            setSavePresetDisabled(true);
            window.Toast.fire({
              icon: "error",
              title: errors?.response?.data?.message ?? "Internal server error",
            });
          });
      }
    }
  };

  function updatePhotographer(settings) {
    updatePhotographerWatermarkSettings(settings)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Watermark settings updated successfully",
        });
        setUser({ ...user, access_token: response.access_token });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error updating watermark settings",
        });
      });
  }

  // Watermark position code
  const resizeWater = () =>
    setWatermarkStyle(waterMarkPlacement(currentPreset, logoType.type));

  return (
    <div>
      <Title
        className="pb-4 border-zinc-700 border-b mb-4 mt-4"
        text="Watermark"
      />
      <div className="flex flex-row justify-between gap-32 ">
        <div id="watermark-enable" className="flex flex-col w-full">
          <SwitchWithTitle
            className="font-medium"
            title="Viewing"
            key={"viewing"}
            subtitle="Show watermark on gallery images."
            value={watermarkSettings.watermarkGalleryImages}
            onChange={(value) => {
              setWatermarkSettings((prev) => ({
                ...prev,
                watermarkGalleryImages: value,
              }));
              updatePhotographer({
                ...watermarkSettings,
                watermarkGalleryImages: value,
              });
            }}
          />
          <SwitchWithTitle
            className="mb-8 font-medium mt-10"
            title="Single download"
            subtitle="Show watermark on single downloaded images."
            value={watermarkSettings.watermarkSingleDownloadedImages}
            // onChange={updateSingleDownload}
            onChange={(value) => {
              setWatermarkSettings((prev) => ({
                ...prev,
                watermarkSingleDownloadedImages: value,
              }));
              updatePhotographer({
                ...watermarkSettings,
                watermarkSingleDownloadedImages: value,
              });
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <SwitchWithTitle
            className="mb-8 font-medium"
            title="Bulk download"
            subtitle="Show watermark on bulk downloaded images."
            value={watermarkSettings.watermarkBulkDownloadedImages}
            // onChange={updateBulkDownload}
            onChange={(value) => {
              setWatermarkSettings((prev) => ({
                ...prev,
                watermarkBulkDownloadedImages: value,
              }));
              updatePhotographer({
                ...watermarkSettings,
                watermarkBulkDownloadedImages: value,
              });
            }}
          />
        </div>
      </div>
      <div id="watermark-preset" className="mt-8">
        <Text as="p" className="text-xl font-medium text-secondary-300 mb-6">
          Add new watermark
        </Text>
        <Tabs
          logoType={logoType}
          setLogoType={setLogoType}
          presets={presets}
          setPresets={setPresets}
          changePreset={changePreset}
          currentPreset={currentPreset}
        />
        <div className="shadow-card flex flex-row p-6 mt-8 rounded-2xl justify-between">
          <div className="flex flex-col w-full rounded-xl pr-8 relative">
            <div className="sticky inset-y-3 ">
              <div className="relative overflow-hidden">
                <img
                  className="rounded-2xl shadow-card"
                  src={!bannerOrientation ? vertical : horizontal}
                />
                {/* <Text as="p" className="text-sm my-4">
                                    Click to change view
                                </Text> */}
                {logoType.type === "file" ? (
                  <img
                    id="waterdimage"
                    className={
                      !!currentPreset.fullWatermark &&
                      currentPreset.fullWatermark !== "0"
                        ? "w-full h-full !translate-x-0 !translate-y-0 !left-0 !top-0"
                        : "w-[40%]"
                    }
                    src={currentPreset.logoUrl}
                    style={watermarkStyle}
                  />
                ) : (
                  <h1
                    className="whitespace-nowrap truncate max-w-full"
                    style={watermarkStyle}
                  >
                    {currentPreset.logoText}
                  </h1>
                )}
              </div>
              <div className="mt-10">
                <p className="text-white text-sm">Click to change view</p>
                <div className="w-full flex justify-start mt-3">
                  <div
                    onClick={(e) => setBannerOrientation(1)}
                    className={`cursor-pointer shadow-card border-[1px]  border-zinc-700 h-[2.4rem] w-[3rem] p-1`}
                  >
                    {bannerOrientation ? (
                      <div className="h-full w-full bg-primary-green"></div>
                    ) : null}
                  </div>
                  <div
                    onClick={(e) => setBannerOrientation(0)}
                    className={`cursor-pointer border-[1px] border-zinc-700 ml-3 h-[3rem] w-[2.4rem] shadow-card p-1`}
                  >
                    {!bannerOrientation ? (
                      <div className="h-full w-full bg-primary-green"></div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="watermark-create-form" className="flex flex-col w-full">
            <Text
              as="p"
              className="mb-4 text-base text-secondary-300 font-medium"
            >
              Preset Name
            </Text>
            <InputText
              id="presetName"
              value={currentPreset ? currentPreset.title : ""}
              onChange={(e) => changePreset({ title: e })}
            />
            {_.isEmpty(error) ? (
              ""
            ) : (
              <label className="text-gray text-sm text-red-700">
                {error.title}
              </label>
            )}

            <WatermarkUploadSection
              logoTextColors={logoTextColors}
              logoType={logoType}
              setLogoType={setLogoType}
              fontColor={
                currentPreset.fontColor ? currentPreset.fontColor : "Black"
              }
              colorClicked={(e) => changePreset({ fontColor: e })}
              font={currentPreset.font ? currentPreset.font : "Arial"}
              styleClicked={(e) => changePreset({ font: e })}
              onLogoTextChange={(e) => changePreset({ logoText: e })}
              value={currentPreset.logoText ? currentPreset.logoText : ""}
              error={error}
              fileName={currentPreset.logo ? currentPreset.logo.name : ""}
              onLogoFileChange={(e) => {
                setCurrentPreset({
                  ...currentPreset,
                  logoUrl: URL.createObjectURL(e),
                  logo: { file: e, name: e.name },
                });
              }}
              className="my-8"
            />
            <div className="flex flex-row justify-between gap-8 mr-7">
              <div className="flex flex-col w-full  relative">
                <Text
                  as="p"
                  className="mb-4 text-base text-secondary-300 font-medium"
                >
                  {logoType.type === "file" ? "Scale" : "Font Size"}
                </Text>

                <SliderComponent
                  sliderValue={
                    currentPreset.scale ? parseInt(currentPreset.scale) : 5
                  }
                  marks={
                    logoType.type === "file"
                      ? {
                          0: {
                            style: {
                              color: "#555",
                            },
                            label: "0%",
                          },
                          5: {
                            style: {
                              color: "#555",
                            },
                            label: "50%",
                          },
                          9: {
                            style: {
                              color: "#555",
                            },
                            label: "100%",
                          },
                        }
                      : {
                          10: {
                            style: {
                              color: "#555",
                            },
                            label: "10px",
                          },
                          75: {
                            style: {
                              color: "#555",
                            },
                            label: "75px",
                          },
                        }
                  }
                  min={logoType.type === "file" ? 0 : 10}
                  max={logoType.type === "file" ? 9 : 75}
                  onChange={(e) => changePreset({ scale: e })}
                />
              </div>
              <div className="flex flex-col w-full">
                <Text as="p" className="mb-4">
                  Opacity
                </Text>
                <SliderComponent
                  sliderValue={
                    currentPreset.opacity ? currentPreset.opacity : 5
                  }
                  marks={{
                    0: {
                      style: {
                        color: "#555",
                      },
                      label: "0%",
                    },
                    5: {
                      style: {
                        color: "#555",
                      },
                      label: "50%",
                    },
                    10: {
                      style: {
                        color: "#555",
                      },
                      label: "100%",
                    },
                  }}
                  min={0}
                  max={10}
                  onChange={(e) => changePreset({ opacity: e })}
                />
              </div>
            </div>
            <br />
            <div className="flex flex-row justify-between gap-8 mr-7 mt-8">
              <div className="flex flex-col w-full ">
                <Text
                  as="p"
                  className="mb-4 text-base text-secondary-300 font-medium"
                >
                  Horizontal Offset
                </Text>
                <SliderComponent
                  sliderValue={
                    currentPreset.xOffset ? currentPreset.xOffset : 50
                  }
                  marks={{
                    0: {
                      style: {
                        color: "#555",
                      },
                      label: "0%",
                    },
                    50: {
                      style: {
                        color: "#555",
                      },
                      label: "50%",
                    },
                    99.99: {
                      style: {
                        color: "#555",
                      },
                      label: "100%",
                    },
                  }}
                  min={0}
                  max={99.99}
                  onChange={(e) => changePreset({ xOffset: e })}
                />
              </div>
              <div className="flex flex-col w-full">
                <Text
                  as="p"
                  className="mb-4 text-base text-secondary-300 font-medium"
                >
                  Vertical Offset
                </Text>
                <SliderComponent
                  sliderValue={
                    currentPreset.yOffset ? currentPreset.yOffset : 50
                  }
                  marks={{
                    0: {
                      style: {
                        color: "#555",
                      },
                      label: "0%",
                    },
                    50: {
                      style: {
                        color: "#555",
                      },
                      label: "50%",
                    },
                    99.99: {
                      style: {
                        color: "#555",
                      },
                      label: "100%",
                    },
                  }}
                  min={0}
                  max={99.99}
                  onChange={(e) => changePreset({ yOffset: e })}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <Text as="p" className="mt-8 mb-4">
                  Choose position
                </Text>

                <LogoPositionManager
                  landScapeClicked={(e) =>
                    changePreset({ landscapeImagePosition: e })
                  }
                  portraitClicked={(e) =>
                    changePreset({ portraitImagePosition: e })
                  }
                  lvalue={
                    currentPreset.landscapeImagePosition
                      ? currentPreset.landscapeImagePosition
                      : ""
                  }
                  pvalue={
                    currentPreset.portraitImagePosition
                      ? currentPreset.portraitImagePosition
                      : ""
                  }
                />
              </div>
              <div className="flex-1">
                {logoType.type === "file" ? (
                  <Checkbox
                    className="mt-8 mb-4 flex-1"
                    id="watermark_on_full_image"
                    label=" Watermark on full image"
                    value={
                      ["0", false, 0].includes(currentPreset.fullWatermark)
                        ? false
                        : true
                    }
                    onChange={(value) => {
                      changePreset({ fullWatermark: value ? 1 : 0 });
                    }}
                    removeDisable
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex justify-center mt-8">
              <Button
                disabled={savePresetDisabled}
                onClick={() => {
                  presetAdd();
                }}
                text="Save Preset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatermarkSection;
