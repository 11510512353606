import React, { useEffect } from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import { useUser } from "../../Store/User";

function PrivateRoute() {
  let { user } = useUser();
  const location = useLocation();

  const isLogged = user.access_token;
  const pathname = location.pathname;
  let search = window.location.search;
  let getParam = new URLSearchParams(search);
  let source = getParam.get("source");

  let jwt = getParam.get("jwt");
  if (jwt && !isLogged) {
    Cookies.set("access_token", jwt, {
      expires: 7,
      secure: true,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    window.location.reload();
  }

  useEffect(() => {
    if (user.access_token && source === "algocull") {
      window.location = "algocull://?jwt=" + user.access_token;
    } else if (user.access_token && source === "algoedit") {
      window.location = "algoedit://?jwt=" + user.access_token;
    } else if (user.access_token && source === "uploader") {
      window.location = "uploader://?jwt=" + user.access_token;
    }
  }, [user.access_token]);

  const redirectUser =
    user &&
    user.address &&
    !user.address?.state &&
    user.subscription?.status === "paid" &&
    new Date(user.subscription?.next_sub) > new Date();

  if (isLogged && source === "download") {
    return <Navigate to="/download" />;
  } else if (isLogged && pathname === "/download") {
    return <Outlet />;
  } else if (isLogged && user.photographer && redirectUser) {
    return <Navigate to="/add-address" />;
  } else if (isLogged && user.photographer && user.photographer.subDomain) {
    return <Outlet />;
  } else if (isLogged && user.photographer && !user.photographer.subDomain) {
    return <Navigate to="/add-subdomain" />;
  } else {
    return pathname === "/download" ? (
      <Navigate to="/login?source=download" />
    ) : (
      <Navigate to="/login" />
    );
  }
}

export default PrivateRoute;
