import { set } from "lodash";
import React, { useRef, useState, useEffect } from "react";

const GridChooser = ({ alignment, landScapeClicked, portraitClicked, lvalue, pvalue }) => {
  const [currentBlock, setCurrentBlock] = useState(0);
  const [val, setVal] = useState("");

  useEffect(async () => {
    if(lvalue || pvalue ){
      lvalue ? setVal(lvalue) : setVal(pvalue)
      switch(val) {
        case 'northwest':
          setCurrentBlock(0)
        break;
        case 'north': 
        setCurrentBlock(1)
        break;
        case 'northeast':
          setCurrentBlock(2)
        break;
        case 'west':
          setCurrentBlock(3)
        break;
        case 'center':
          setCurrentBlock(4)
        break;
        case 'east':
          setCurrentBlock(5)
        break;
        case 'southwest':
          setCurrentBlock(6)
        break;
        case 'south':
          setCurrentBlock(7)
        break;
        case 'southeast':
          setCurrentBlock(8)
        break;
      }
    }
  });

  
  function setPosition(i){
    let pos = 'northwest';
      switch(i) {
        case 0:
          pos='northwest';
        break;
        case 1: 
          pos='north';
        break;
        case 2:
          pos='northeast';
        break;
        case 3:
          pos='west';
        break;
        case 4:
          pos='center';
        break;
        case 5:
          pos='east';
        break;
        case 6:
          pos='southwest';
        break;
        case 7:
          pos='south';
        break;
        case 8:
          pos='southeast';
        break;
      }
    alignment === "horizontal" ? landScapeClicked(pos) : portraitClicked(pos)
  }


  const getGrid = (alignment) => {
    const totalBlocks = 9;
    let width = "29",
      height = "36",
      Component = [];
    if (alignment === "horizontal") {
      width = "36";
      height = "29";
    }
    
    const gridSizeClass =
      alignment === "horizontal" ? "w-[36px] h-[29px]" : "h-[36px] w-[29px]";
    for (let i = 0; i < totalBlocks; i++) {
      let block = (
        <div
            key={i}
            onClick={() => {
              setCurrentBlock(i);
              setPosition(i)
            }}
          className={`${gridSizeClass} bg-dark-gray`}
        >
          {currentBlock === i ? (
            <div className="h-[100%] bg-blue border-dark-gray border-2"></div>
          ) : null}
        </div>
      );
      Component = [...Component, block];
    }
    return Component;
  };
  return (
    <div className="inline-grid grid-cols-3 shadow-card gap-px bg-zinc-700 border-zinc-700 border mr-8 rounded overflow-hidden">
      {getGrid(alignment)}
    </div>
  );
};

export default GridChooser;
