import { useParams } from "react-router-dom";
import { useUser } from "../../Store/User";
import Error from "../Errors";
import Analytics from "./Analytics";

const GlobalAnalytics = () => {
  const { user } = useUser();

  const eventId = useParams()["eventId"];

  const date = new Date();

  const paymentLink = `//payments.${process.env.REACT_APP_URL}`;

  return (user.subscription.next_sub &&
    new Date(user.subscription.next_sub) > date.setDate(date.getDate() - 5)) ||
    eventId ? (
    <Analytics />
  ) : (
    <Error
      message="Upgrade your plan"
      text={
        <>
          <a className="text-primary-green underline mr-1" href={paymentLink}>
            Upgrade
          </a>{" "}
          <span>to view analytics</span>
        </>
      }
    />
  );
};

export default GlobalAnalytics;
