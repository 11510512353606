import React, { Fragment, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Colors } from "../../../../constants/Color";
import { color } from "d3";

export default function Frames({ showFrames, setShowFrames, currency }) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const showMillerProducts = currency !== "inr";

  const [distressedFrame, setDistressedFrame] = useState("Brown");
  const [naturalWoodFrame, setNaturalWoodFrame] = useState("Brown");
  const [classicWoodFrame, setClassicWoodFrame] = useState("Black");
  const [classicThinFrame, setClassicThinFrame] = useState("Black");
  const [modernWoodFrame, setModernWoodFrame] = useState("Black");
  const [metallicBrushFrame, setMetallicBrushFrame] = useState("Tortoise");
  const [classicFrame, setClassicFrame] = useState(showMillerProducts ? "black" : "Black");

  const handleClose = (e) => {
    setOpen(false);
    setShowFrames(!showFrames);
  };


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl">
              <div className="p-8">
                <div className="sm:items-start">
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300"
                    >
                      All Frames
                      <span
                        onClick={(e) => {
                          handleClose(e);
                        }}
                        className="cursor-pointer"
                      >
                        <CloseIcon fill="#eee" />
                      </span>
                    </Dialog.Title>
                    <div className="mt-5 flex grid grid-cols-2 divide-x">
                      {showMillerProducts ? (
                        <></>
                      ) : (
                        <div>
                          <h3 className="text-2xl leading-6 flex justify-between font-medium text-secondary-300 mb-6 ">
                            Premium Frames
                          </h3>
                          <ul className="flex grid grid-cols-3 gap-y-6">
                            {React.Children.toArray(
                              [
                                {
                                  id: 1,
                                  productName: "Premium Frame Distressed",
                                  fType: "Distressed",
                                  initialFrameColor: "Brown",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Brown",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/8x8-2photo-grid-brown.png",
                                    },
                                    {
                                      color: "Green",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style1_8x8/8x8-2photo-grid-green.png",
                                    },
                                  ],
                                },
                                {
                                  id: 2,
                                  productName: "Premium Frame Natural Wood",
                                  fType: "Natural Wood",
                                  initialFrameColor: "Brown",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Brown",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style2_8x8/8x8-2photo-grid-brown.png",
                                    },
                                    {
                                      color: "Golden",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style2_8x8/8x8-2photo-grid-golden-ombre.png",
                                    },
                                    {
                                      color: "White",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style2_8x8/8x8-2photo-grid-white.png",
                                    },
                                  ],
                                },
                                {
                                  id: 3,
                                  productName:
                                    "Premium Frame Classic Wood Frames",
                                  fType: "Classic Wood Frames",
                                  initialFrameColor: "Black",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Black",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style3_8x8/8x8-2photo-grid-black.png",
                                    },
                                    {
                                      color: "Tan",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style3_8x8/8x8-2photo-grid-tan.png",
                                    },
                                    {
                                      color: "Walnut",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style3_8x8/8x8-2photo-grid-walnut.png",
                                    },
                                  ],
                                },
                                {
                                  id: 4,
                                  productName: "Premium Frame Classic thin",
                                  fType: "Classic thin",
                                  initialFrameColor: "Black",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Black",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style4_8x8/8x8-2photo-grid-black.png",
                                    },
                                    {
                                      color: "White",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style4_8x8/8x8-2photo-grid-white.png",
                                    },
                                    {
                                      color: "Walnut",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style4_8x8/8x8-2photo-grid-white.png",
                                    },
                                  ],
                                },
                                {
                                  id: 5,
                                  productName:
                                    "Premium Frame Modern Wood Frame",
                                  fType: "Modern Wood Frame",
                                  initialFrameColor: "Black",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Black",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style5_8x8/8x8-2photo-grid-black.png",
                                    },
                                    {
                                      color: "White",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style5_8x8/8x8-2photo-grid-white.png",
                                    },
                                    {
                                      color: "Honey",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style5_8x8/8x8-2photo-grid-honey.png",
                                    },
                                  ],
                                },
                                {
                                  id: 6,
                                  productName: "Premium Frame Metallic Brush",
                                  fType: "Metallic Brush",
                                  initialFrameColor: "Tortoise",
                                  initialFrameSize: "8x8",
                                  frame: [
                                    {
                                      color: "Tortoise",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style6_8x8/8x8-2photo-grid-tortoise.png",
                                    },
                                    {
                                      color: "Green",
                                      imgSrc:
                                        "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framed_prints_style6_8x8/8x8-2photo-grid-green-speck.png",
                                    },
                                  ],
                                },
                              ].map(({ id, productName, fType, frame }) => {
                                return (
                                  <li className="mr-[24px]">
                                    {frame.map((c) => {
                                      switch (id) {
                                        case 1:
                                          if (distressedFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                        case 2:
                                          if (naturalWoodFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                        case 3:
                                          if (classicWoodFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                        case 4:
                                          if (classicThinFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                        case 5:
                                          if (modernWoodFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                        case 6:
                                          if (metallicBrushFrame == c.color) {
                                            return (
                                              <img
                                                className="shadow-dark-input-bottom-right dark:shadow-dark-input"
                                                style={{ borderRadius: "10px" }}
                                                src={c.imgSrc}
                                              />
                                            );
                                          }
                                          break;
                                      }
                                    })}
                                    <h1 className="mt-4 text-xs md:text-xs lg:text-xs font-medium pl-4">
                                      {fType}
                                    </h1>
                                    <ul className="flex lg:mr-36 md:mr-36 pl-4 mt-2">
                                      {frame.map((c) => {
                                        return (
                                          <li
                                            className="cursor-pointer mr-[30px]"
                                            onClick={() => {
                                              switch (id) {
                                                case 1:
                                                  setDistressedFrame(c.color);
                                                  break;
                                                case 2:
                                                  setNaturalWoodFrame(c.color);
                                                  break;
                                                case 3:
                                                  setClassicWoodFrame(c.color);
                                                  break;
                                                case 4:
                                                  setClassicThinFrame(c.color);
                                                  break;
                                                case 5:
                                                  setModernWoodFrame(c.color);
                                                  break;
                                                case 6:
                                                  setMetallicBrushFrame(
                                                    c.color
                                                  );
                                                  break;
                                              }
                                            }}
                                            key={c.color}
                                          >
                                            <span
                                              className="dot w-6 h-6 shadow-dark-input-bottom-right dark:shadow-dark-input"
                                              style={{
                                                backgroundColor:
                                                  Colors[c.color],
                                                border:
                                                  (distressedFrame == c.color &&
                                                    id == 1) ||
                                                  (naturalWoodFrame ==
                                                    c.color &&
                                                    id == 2) ||
                                                  (classicWoodFrame ==
                                                    c.color &&
                                                    id == 3) ||
                                                  (classicThinFrame ==
                                                    c.color &&
                                                    id == 4) ||
                                                  (modernWoodFrame == c.color &&
                                                    id == 5) ||
                                                  (metallicBrushFrame ==
                                                    c.color &&
                                                    id == 6)
                                                    ? "1px solid #06b6d4"
                                                    : "",
                                                borderRadius: "50%",
                                                display: "inline-block",
                                              }}
                                              title={c.color}
                                            ></span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                );
                              })
                            )}
                          </ul>
                        </div>
                      )}
                      <div>
                        <h3 className="text-2xl leading-6 flex justify-between font-medium text-secondary-300 mb-6 ml-[24px]">
                          {showMillerProducts ? "Soho Frame" : "Classic Frames"}
                        </h3>
                        <ul
                          className={`${
                            showMillerProducts
                              ? ""
                              : "flex grid grid-cols-3 gap-y-6"
                          }`}
                        >
                          {React.Children.toArray(
                            [
                              {
                                id: 1,
                                productName: "Classic",
                                initialFrameColor: "Black",
                                initialFrameSize: "4x4",
                                frame: showMillerProducts
                                  ? [
                                      {
                                        color: "gold",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_gold.png",
                                      },
                                      {
                                        color: "black",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_black.png",
                                      },
                                      {
                                        color: "brown",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_brown.png",
                                      },
                                      {
                                        color: "white",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_white.png",
                                      },
                                    ]
                                  : [
                                      {
                                        color: "Black",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_black.png",
                                      },
                                      {
                                        color: "Brown",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_gold.png",
                                      },
                                      {
                                        color: "White",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_white.png",
                                      },
                                      {
                                        color: "Beige",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_beige.png",
                                      },
                                      {
                                        color: "Gold",
                                        imgSrc:
                                          "https://picsniff.s3.ap-south-1.amazonaws.com/frames/framedprint_6_4/6_4_gold.png",
                                      },
                                    ],
                              },
                            ].map(({ id, productName, frame }) => {
                              return (
                                <li className="ml-[24px]">
                                  {frame.map((c) => {
                                    switch (id) {
                                      case 1:
                                        if (classicFrame == c.color) {
                                          return (
                                            <img
                                              className={`shadow-dark-input-bottom-right dark:shadow-dark-input ${
                                                showMillerProducts
                                                  ? "w-[300px]"
                                                  : ""
                                              }`}
                                              style={{ borderRadius: "10px" }}
                                              src={c.imgSrc}
                                              alt="Frame"
                                            />
                                          );
                                        }
                                        break;
                                      default:
                                        return <></>;
                                    }
                                  })}

                                  {showMillerProducts ? (
                                    <></>
                                  ) : (
                                    <h1 className="mt-4 text-xs md:text-xs lg:text-xs font-medium pl-4">
                                      {productName}
                                    </h1>
                                  )}
                                  <ul className="flex lg:mr-36 md:mr-36 pl-4 mt-2">
                                    {frame.map((c) => {
                                      return (
                                        <li
                                          className="cursor-pointer mr-[30px]"
                                          onClick={() => {
                                            switch (id) {
                                              case 1:
                                                setClassicFrame(c.color);
                                                break;
                                            }
                                          }}
                                          key={c.color}
                                        >
                                          <span
                                            className="dot w-6 h-6 shadow-dark-input-bottom-right dark:shadow-dark-input"
                                            style={{
                                              backgroundColor: Colors[c.color],
                                              border:
                                                classicFrame == c.color &&
                                                id == 1
                                                  ? "1px solid #06b6d4"
                                                  : "",
                                              borderRadius: "50%",
                                              display: "inline-block",
                                            }}
                                            title={c.color}
                                          ></span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
