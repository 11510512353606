import Text from '../Text';
import React, {useEffect, useState} from "react";
import _ from "lodash";

export const InputEditable = props => {
    const {
        name = (Math.random() + 1).toString(36).substring(7),
        className = "",
        label = "",
        required = false,
        type = "",
        onChange,
        value = '',
        error = '',
        placeholder = '',
        onKeyDown,
        processing = false,
        success = false,
        onUpdate,
        readOnly = false,
        actionText  ,
        prefix = null,
        showCancelButton = false,
        setCancel
    } = props;

    const [isProcessing, setIsProcessing] = useState(false),
        [isSuccess, setIsSuccess] = useState(false);

    useEffect(async () => {
        setIsProcessing(processing);
    }, [processing]);

    useEffect(async () => {
        setIsSuccess(success);
    }, [success]);

    const showIsRequired = required ? "*" : "";
    return (
        <div className={`flex flex-col max-w-[28rem] ${className}`}>
            {label && <Text as="label"
                            className={`${error ? '' : 'mb-4'} flex justify-between items-baseline text-base`}>
                <span className="inline-block">{label}</span>
            </Text>}
            {error && <span className="inline-block text-right text-xs pr-4 text-error-red">{error}</span>}
            <div className={`flex justify-between items-center pr-5 rounded-[10px] bg-dark-gray mt-1 text-light-gray
               ${!value || readOnly ? 'shadow-input-empty' : 'shadow-input-filled'}  
               
               ${error ? 'outline outline-1 outline-error-red' : 'outline-none'}`
            }>
                {prefix ?
                    <span className="inline-block text-xs font-semibold whitespace-nowrap text-secondary-500 pl-4">
                        {prefix}
                    </span> : null
                }

                <input
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    autoComplete="off"
                    name={name}
                    readOnly={readOnly}
                    className={` ${!prefix ? 'pl-4' : ''} bg-transparent py-2 placeholder:text-secondary-500 placeholder:text-sm outline-none w-full text-light-gray text-sm`}
                    onChange={(event) => onChange(event)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            setIsSuccess(false);
                            setIsProcessing(true);
                            onUpdate(value);
                        }
                    }}
                />
                {success && !error ?
                    <div className="checkmark-wrapper opacity-0 animate-fadeOut">
                        <svg
                            className="checkmark mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div> : null
                }

                {isProcessing && !success && !error ?
                    <svg className="animate-spin mr-3 h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4">
                        </circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg> : null
                }

                {(required && value && !readOnly) || !required ?
                    <span onClick={(event) => {
                        setIsSuccess(false);
                        setIsProcessing(true);
                        onUpdate(value);
                    }}
                          className="inline-block text-xs font-semibold whitespace-nowrap underline cursor-pointer text-primary-green">{actionText ? actionText :'Update'}</span> : null
                }

                {showCancelButton ?
                    <svg onClick={(event) => {
                        setCancel(true);
                    }}
                         className="h-6 w-6 ml-2 text-error-red cursor-pointer"
                         xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    : null
                }
            </div>
        </div>
    );
};
