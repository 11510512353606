import Swal from "sweetalert2";

export const GUEST_UPLOAD_SLUG = "guest-uploads";

// Checking against Photographer id
export const DIGITAL_DOWNLOAD_ENABLED = [2869, 992, 764, 72];

export const MAX_SUB_EVENT_IMAGE_REACHED_ERROR =
  "Maximum Images Per Folder Limit Reach";

export const IMAGE_LIMIT_REACHED = "Limit reached";

export const MAX_EVENT_REACHED_MESSAGE = (noOfEvents = "undefined") =>
  Swal.fire({
    title: `Your subscriptions allow only ${noOfEvents} number of events.`,
    html: `Please contact <a href="mailto:hello@algomage.com" class="text-primary-green underline">sales<a> to upgrade your plan`,
    icon: "error",
    confirmButtonText: `Ok`,
  });

export const OG_IMAGE_CDN = process.env.REACT_APP_OG_CDN;

export const RESIZE_IMAGE_CDN = process.env.REACT_APP_FILE_ENDPOINT;

export const RESIZE_IMAGE_SUBDOMAIN =
  process.env.REACT_APP_RESIZE_IMAGE_SUBDOMAIN;
export const OG_IMAGE_SUBDOMAIN = process.env.REACT_APP_OG_IMAGE_SUBDOMAIN;

export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const REFERRAL_KEY = "referralInfo";

export const MAX_FILE_TO_UPLOAD = "50";

/**Photographer id :- 71 :- Info@amouraffairs.in , 764 :- sunpune@gmail.com ,
 * 35471 :-aelisaphotos@gmail.com,
 * 2138 :-jeetmohnaniphotography@gmail.com ,
 * 38542 :- dolly.algomage@gmail.com ,
 * 20317 :-  sanyograjephotography@gmail.com ,
 * 16098 picturebaskett@gmail.com ,
 * 4844  :-pavankagrawaal@gmail.com,
 * 32730:- shree10patil@gmail.com ,
 * 6261:- agwlmohit11@gmail.com,
 * 39637:- Artmancappturez@gmail.com,
 * 56042 :- lagnaavsar@gmail.com
 * 16426:- rahuldarakcaptures@gmail.com
 * 21704:- pccreations07@gmail.com
 * 12622:- fotointernational@gmail.com
 * 18969:- rohitstudiosphotography27@gmail.com
 * 3287 :- shadowgraphystudio1@gmail.com
 * 21643 :- seethroughet@gmail.com
 * 1768 :- pikselperfektphotography@gmail.com
 * 72762:- theweddingklicks7@gmail.com
 * */
export const PHOTOGRAPHER_ACCESS_TO_WEB_UPLOAD = [
  71, 764, 35471, 2138, 38542, 20317, 16098, 4844, 32730, 68255, 39637, 56042,
  16426, 21704, 12622, 18969, 3287, 21643, 1768,72762
];
