import React, { useEffect, useState, useRef } from "react";
import DownArrow from "../../../../assets/icons/DownArrow";
import Title from "../../../../components/common/Title";
import OverviewImg from "../../../../assets/images/overview.png";
import {
  getDownloadRequestAnalytics,
  getFaceSearchAnalytics,
  getImpressionAnalytics,
} from "../../../../apis";
import Chart from "react-apexcharts";
import moment from "moment-timezone";
import getStarted from "../../../../assets/images/illustration-get-started.png";
import Text from "../../../../components/common/Text";
import _ from "lodash";

const Overview = ({
  eventId,
  setRef,
  chartFilters,
  chartFilter,
  setChartFilter,
}) => {
  const [chartFilterOptions, setChartFilterOptions] = useState(false),
    [graph, setGraph] = useState("Impressions"),
    [dat, setDat] = useState({}),
    [xAxisLable, setXaxisLable] = useState(),
    [impressionTotal, setImpressionTotal] = useState(0),
    [faceSearchTotal, setFaceSearchTotal] = useState(0),
    [downloadRequestTotal, setDownloadRequestTotal] = useState(0),
    [isHide, setIsHide] = useState(true),
    [isLoading, setIsLoading] = useState(true),
    [hasError, setHasError] = useState(false),
    [lifeTimeData, setLifetimeData] = useState(null),
    [monthData, setMonthData] = useState(null),
    [weekData, setWeekData] = useState(null);

  setTimeout(() => setIsHide(false), 10);

  const chartData = {
    options: {
      chart: {
        id: "Analytics-chart",
        toolbar: {
          show: false,
        },
      },
      colors: ["#a64492"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.2,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: false,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 20, 50],
          colorStops: [],
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth:
            dat.length < 3
              ? 7 + "%"
              : 20 + 60 / (1 + 30 * Math.exp(-dat.length / 3)) + "%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      xaxis: {
        categories: xAxisLable,
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        marker: {
          show: true,
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    series: [
      {
        name:
          graph === "Impressions"
            ? "Images"
            : graph === "Face-search"
            ? "Face searches"
            : "Download requests",
        data: dat,
      },
    ],
  };

  useEffect(async () => {
    updateData(chartFilter);
  }, [graph, chartFilter]);

  function fetchGraphData(startDate, endDate, frequency) {
    setIsLoading(true);

    getImpressionAnalytics(startDate, endDate, frequency, eventId)
      .then((response) => {
        setImpressionTotal(response.total);
        if (graph === "Impressions") {
          if (frequency === "daily") {
            let array = {};
            _.forEach(response.chart, function (value, key) {
              let date = moment
                .utc(key)
                .tz(moment.tz.guess())
                .format("D-MMM-YYYY");
              return (array[date] =
                date in array ? array[date] + value : value);
            });
            setDat(Object.values(array));
            setXaxisLable(Object.keys(array));
            setIsLoading(false);
          } else {
            setDat(Object.values(response.chart));
            setXaxisLable(Object.keys(response.chart));
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setHasError(true);
      });

    getFaceSearchAnalytics(startDate, endDate, frequency, eventId)
      .then((response) => {
        setFaceSearchTotal(response.total);
        if (graph == "Face-search") {
          if (frequency === "daily") {
            let array = {};
            _.forEach(response.chart, function (value, key) {
              let date = moment
                .utc(key)
                .tz(moment.tz.guess())
                .format("D-MMM-YYYY");
              return (array[date] =
                date in array ? array[date] + value : value);
            });

            setDat(Object.values(array));
            setXaxisLable(Object.keys(array));
            setIsLoading(false);
          } else {
            setDat(Object.values(response.chart));
            setXaxisLable(Object.keys(response.chart));
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setHasError(true);
      });

    getDownloadRequestAnalytics(startDate, endDate, frequency, eventId)
      .then((response) => {
        setDownloadRequestTotal(response.total);
        if (graph == "Download-request") {
          if (frequency === "daily") {
            let array = {};
            _.forEach(response.chart, function (value, key) {
              let date = moment
                .utc(key)
                .tz(moment.tz.guess())
                .format("D-MMM-YYYY");
              return (array[date] =
                date in array ? array[date] + value : value);
            });
            setDat(Object.values(array));
            setXaxisLable(Object.keys(array));
            setIsLoading(false);
          } else {
            setDat(Object.values(response.chart));
            setXaxisLable(Object.keys(response.chart));
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setHasError(true);
      });
  }

  const chartFilterToggle = (e) => {
    setChartFilterOptions(!chartFilterOptions);
  };

  const chartFilterClicked = (e) => {
    setChartFilter(e);
    updateData(e);
  };

  // below code closes the fliter options popup when clicked outside
  let filterDorpDownContainer = useRef();

  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!filterDorpDownContainer?.current?.contains(event.target)) {
        setChartFilterOptions(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const updateData = (timeline) => {
    switch (timeline) {
      case "Lifetime":
        fetchGraphData(null, null, "monthly");
        break;
      case "This Week":
        let start = moment().startOf("week").toDate();
        let end = moment().endOf("week").toDate();
        fetchGraphData(
          moment(start).format("DD MMM YYYY"),
          moment(end).format("DD MMM YYYY"),
          "daily"
        );
        break;
      case "This Month":
        let startM = moment().startOf("month").toDate();
        let endM = moment().endOf("month").toDate();
        fetchGraphData(
          moment(startM).format("DD MMM YYYY"),
          moment(endM).format("DD MMM YYYY"),
          "daily"
        );
        break;
      default:
    }
  };

  return (
    <div id="overview" ref={setRef} className="pb-24">
      <div className="mt-7 pb-12">
        <Title
          className="pb-4 mb-6 border-zinc-700 border-b font-medium"
          text="Overview"
        />
        <div className="flex justify-between ml-2">
          <div className="flex lg:basis-9/12 grid lg:grid-cols-3 lg:grid-flow-col grid-flow-row gap-4">
            <div
              className={`rounded-xl cursor-pointer  ${
                graph === "Impressions" ? "shadow-inner-all" : "shadow-card"
              }`}
              onClick={() => {
                setGraph("Impressions");
              }}
            >
              <div className="w-full p-8">
                <div className="text-sm pb-2">Impressions</div>
                <div className="flex justify-between text-2xl font-bold">
                  {impressionTotal}
                  {/* <span className='pt-3 text-xs inline-block text-green-400'>+ 2.51 %</span> */}
                </div>
              </div>
            </div>
            <div
              className={`rounded-xl cursor-pointer ${
                graph === "Face-search" ? "shadow-inner-all" : "shadow-card"
              }`}
              onClick={() => {
                setGraph("Face-search");
              }}
            >
              <div className="w-full p-8">
                <div className="text-sm text-stone-400 pb-2">Face Search</div>
                <div className="flex justify-between text-2xl font-bold">
                  {faceSearchTotal}
                  {/* <span className='pt-3 text-xs inline-block text-green-400'>+ 2.51 %</span> */}
                </div>
              </div>
            </div>
            <div
              className={`rounded-xl cursor-pointer ${
                graph === "Download-request"
                  ? "shadow-inner-all"
                  : "shadow-card"
              }`}
              onClick={() => {
                setGraph("Download-request");
              }}
            >
              <div className="w-full p-8">
                <div className="text-sm text-stone-400 pb-2">
                  Download Request
                </div>
                <div className="flex justify-between text-2xl font-bold">
                  {downloadRequestTotal}
                  {/* <span className='pt-3 text-xs inline-block text-red-400'>- 2.51 %</span> */}
                </div>
              </div>
            </div>
          </div>
          <div
            ref={filterDorpDownContainer}
            className="flex items-center p-2 px-4 text-sm dark:shadow-dark-input h-[36px] cursor-pointer relative shadow-button whitespace-nowrap rounded-lg cursor-pointer"
            onClick={chartFilterToggle}
          >
            {chartFilter}
            <span className="pl-2 ">
              <DownArrow rotate={chartFilterOptions ? true : false}>
                {" "}
              </DownArrow>
            </span>
            {chartFilterOptions && (
              <div className="absolute rounded-lg bg-secondary-800 shadow-card overflow-hidden z-40 w-[200px] right-0 top-[95%] mt-3">
                <div className="flex select-none flex-col  text-light-gray text-sm">
                  {chartFilters.map(function (chart, key) {
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          chartFilterClicked(chart);
                          chartFilterToggle();
                        }}
                        className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                      >
                        {chart}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-xs mt-2 pl-2">
          *Metrics are generated every 24 hours
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center item-center flex-col border border-2 border-slate-700">
          <Text
            as="p"
            className="text-xl text-light-gray pt-4 pb-2 text-center"
          ></Text>
          <Text as="p" className="text-base text-light-gray pb-4 text-center">
            Loading...
          </Text>
        </div>
      ) : (
        <div className="ml-2">
          {!isHide ? (
            dat.length > 0 ? (
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
              />
            ) : (
              <div className="flex justify-center item-center flex-col border border-2 border-slate-700">
                <Text
                  as="p"
                  className="text-xl text-light-gray pt-4 pb-2 text-center"
                ></Text>
                <Text
                  as="p"
                  className="text-base text-light-gray pb-4 text-center"
                >
                  No data available for {chartFilter.toLowerCase()}
                </Text>
              </div>
            )
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Overview;
