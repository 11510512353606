import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import {
  getFavouriteImages,
  favouriteDownload,
  deleteFavList,
} from "../../../../../../apis";
import FavoritiesDialog from "../FavoritiesDialog";
import { useEvent } from "../../../../../../Store/Event/Show";
import { useUser } from "../../../../../../Store/User";

const DownloadOptions = ({
  guestTraffic,
  setGuestTraffic,
  eventId,
  currentFavList,
  showLightroom,
  setShowLightroom,
  setLightRoomData,
  selectedFavList,
  setSelectedFavList,
  totalImages,
  favListName,
}) => {
  const { event } = useEvent();
  const { user } = useUser();
  const [downloadFavListOpen, setDownloadFavListOpen] = useState(false);

  const exportAsCsv = useCallback(() => {
    getFavouriteImages(eventId, currentFavList, 1, 0).then((res) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "favourite_images.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }, []);

  const handleLightRoomClick = () => {
    getFavouriteImages(eventId, currentFavList, 0, 1).then((res) => {
      setLightRoomData(res.data);
      setShowLightroom(!showLightroom);
    });
  };

  const deleteFavouriteList = () => {
    deleteFavList(eventId, currentFavList, "event-analytics")
      .then((res) => {
        window.Toast.fire({
          icon: "success",
          title: "Favourite list deleted successfully",
        });
        setGuestTraffic((prev) =>
          prev.filter((item) => item.id !== currentFavList)
        );
      })
      .catch((err) => {
        window.Toast.fire({
          icon: "error",
          title: "Favourite list could not be deleted",
        });
      });
  };

  const showDropDownFunct = () => {
    if (selectedFavList == currentFavList) {
      setSelectedFavList(0);
    } else {
      setSelectedFavList(currentFavList);
    }
  };

  const submitDownload = async () => {
    var res = await favouriteDownload(
      event.id,
      user.user.email,
      1,
      currentFavList,
      "favourites",
      user.user.id
    );

    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: res.message
          ? res.message
          : "Download in process, please wait for it to complete you wil receive an email shortly.",
      });
      localStorage.setItem("downloadInitiatedFrom", window.location.href);
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.e.message[0],
      });
    }
    if (res.downloadRequestId) {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/downloadNow" +
        "/" +
        event.id +
        "/" +
        event.slug +
        "/" +
        res.downloadRequestId;

      window.open(url, "_blank").focus();
    }
  };

  const showDeleteFavWarning = () => {
    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: `Are you sure you want to permanently delete favourite list ${favListName}?`,
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then((key) => {
      if (key.isConfirmed) deleteFavouriteList();
    });
  };

  return (
    <>
      <div
        className="relative rotate-90 fav-options"
        onClick={() => {
          showDropDownFunct();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={"h-6 w-6 fill-current text-secondary-300 "}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
          />
        </svg>
      </div>

      {selectedFavList === currentFavList ? (
        <div className="absolute bg-dark-gray shadow-card w-[200px] right-[0px] rounded-[10px] overflow-hidden z-50">
          {totalImages > 0 ? (
            <div className="flex flex-col cursor-pointer text-light-gray text-left text-sm">
              <Link to={`/event/${eventId}/favourite/${currentFavList}`}>
                <div
                  className={`cursor-pointer px-4 py-2 hover:text-primary-green hover:bg-black`}
                >
                  View
                </div>
              </Link>
              <div
                onClick={() => {
                  exportAsCsv();
                }}
                className={`cursor-pointer  px-4 py-3 hover:text-primary-green hover:bg-black`}
              >
                Export CSV File
              </div>
              <div
                onClick={() => {
                  handleLightRoomClick();
                }}
                className={`cursor-pointer px-4 py-3 hover:text-primary-green hover:bg-black`}
              >
                Lightroom Copy List
              </div>
              <div
                onClick={() => {
                  submitDownload();
                }}
                className={`cursor-pointer px-4 py-3 hover:text-primary-green hover:bg-black`}
              >
                Download
              </div>
              <div className="border-t border-secondary-600 px-10 h-1"> </div>
              <div
                onClick={showDeleteFavWarning}
                className={`cursor-pointer px-4 py-3 hover:text-red-500 hover:bg-black`}
              >
                Delete
              </div>
            </div>
          ) : (
            <div className="flex flex-col cursor-pointer text-light-gray text-left text-sm">
              <div
                onClick={showDeleteFavWarning}
                className={`cursor-pointer px-4 py-3 hover:text-red-500 hover:bg-black`}
              >
                Delete
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {downloadFavListOpen && (
        <FavoritiesDialog
          favList={currentFavList}
          currentFavList={currentFavList}
          showDownloadOptions={downloadFavListOpen}
          setShowDownloadOptions={setDownloadFavListOpen}
        />
      )}
    </>
  );
};

export default DownloadOptions;
