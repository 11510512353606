import React from "react";

const EyeSlashIcon = props => {
    const { height = "1rem", width = "1rem", color = "white" } = props;
    return (
        <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z"/>
                <g fill={color} fillRule="nonzero">
                    <path d="M1.549 1.549a.838.838 0 0 1 1.175-.01l1.493 1.493A8.387 8.387 0 0 1 16 8a8.396 8.396 0 0 1-2.773 4.041l1.235 1.235a.838.838 0 0 1-1.186 1.186L1.538 2.724a.838.838 0 0 1 .01-1.175zM6.47 5.015l-.173.095 1.269 1.27A1.68 1.68 0 0 1 9.62 8.434l1.27 1.27a3.354 3.354 0 0 0-4.42-4.689zM10.057 13.615 7.79 11.347A3.354 3.354 0 0 1 4.653 8.21l-3.08-3.079A8.367 8.367 0 0 0 0 8a8.388 8.388 0 0 0 10.057 5.615z"/>
                </g>
            </g>
        </svg>
    );
};

export default EyeSlashIcon;
