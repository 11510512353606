import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import AnalyticsTitleSection from "./components/AnalyticsTitleSection";
import Overview from "./components/Overview";
import Traffic from "./components/Traffic";
import UserFaceRegistration from "./components/UserFaceRegistration";
import { titleUpdate } from "../../functions";
import { getEventDetails } from "../../apis";
import useOnScreen from "../../components/common/Sidebar/IntersectionObserver";
import DownloadRequests from "./components/DownloadRequests";
import PreFaceRegistration from "./components/PreFaceRegistration";
import PrintOrdersList from "../GeneralSettings/components/PrintSection/PrintOrdersList";

const Analytics = () => {
  const [activeMenu, setActiveMenu] = useState("overview"),
    [eventId, setEventId] = useState(useParams()["eventId"]),
    [menuItems, setMenuItems] = useState([
      {
        title: "Overview",
        id: "overview",
      },
      {
        title: "Face Searches",
        id: "userFaceSearchRegistrations",
      },
      {
        title: "Pre Registration",
        id: "pre-registration",
      },
      {
        title: "Download Requests",
        id: "downloads",
      },
      {
        title: "Print Store",
        id: "print-store",
      },
    ]),
    overviewRef = useRef(),
    isOverviewVisible = useOnScreen(overviewRef),
    userFaceSearchRegistrationsRef = useRef(),
    isUserFaceSearchRegistrationsVisible = useOnScreen(
      userFaceSearchRegistrationsRef
    ),
    preFaceSearchRegistrationsRef = useRef(),
    isPreFaceSearchRegistrationsVisible = useOnScreen(
      preFaceSearchRegistrationsRef
    ),
    trafficRef = useRef(),
    isTrafficVisible = useOnScreen(trafficRef),
    downloadsRef = useRef(),
    isDownloadsVisible = useOnScreen(downloadsRef),
    printStoreRef = useRef(),
    isPrintStoreVisible = useOnScreen(printStoreRef),
    chartFilters = ["Lifetime", "This Month", "This Week"],
    [chartFilter, setChartFilter] = useState("Lifetime"),
    [page, setPage] = useState({
      name: "Global Analytics",
    });
  const [selectedFavList, setSelectedFavList] = useState(0);

  useEffect(() => {
    if (isOverviewVisible) {
      setActiveMenu("overview");
    }
    if (isUserFaceSearchRegistrationsVisible && !isOverviewVisible) {
      setActiveMenu("userFaceSearchRegistrations");
    }
    if (
      isPreFaceSearchRegistrationsVisible &&
      !isUserFaceSearchRegistrationsVisible
    ) {
      setActiveMenu("pre-registration");
    }
    if (isDownloadsVisible && !isPreFaceSearchRegistrationsVisible) {
      setActiveMenu("downloads");
    }
    if (isPrintStoreVisible) {
      setActiveMenu("print-store");
    }
    if (isTrafficVisible && !isPrintStoreVisible) {
      setActiveMenu("favourites");
    }
  }, [
    isOverviewVisible,
    isUserFaceSearchRegistrationsVisible,
    isPreFaceSearchRegistrationsVisible,
    isDownloadsVisible,
    isTrafficVisible,
    isPrintStoreVisible,
  ]);

  useEffect(async () => {
    let title = `Global Analytics`;
    if (eventId) {
      setMenuItems([
        ...menuItems,
        ...[
          {
            title: "Favourite Lists",
            id: "favourites",
          },
        ],
      ]);
      let event = await getEventDetails(eventId);
      setPage({
        ...page,
        name: "Insights - " + event.name,
      });
      title = "Insights - " + event.name;
    }
    titleUpdate(title);
  }, []);

  const closeFavListDropDown = (e) => {
    if (typeof e.target.className.indexOf === "function") {
      if (e.target.className.indexOf("fav-options") < 0) {
        setSelectedFavList(0);
      }
    }
  };

  return (
    <div>
      <div
        onClick={(e) => {
          closeFavListDropDown(e);
        }}
      >
        <AnalyticsTitleSection eventId={eventId} page={page} />
        <div className="flex">
          <ul className="min-w-[20rem] mr-6 shadow-sidebar pl-10 pt-7 pr-6 h-screen sticky top-0 rounded-tr-3xl rounded-br-[10px]">
            {menuItems.map(({ id, title }) => {
              return (
                <li
                  onClick={() => setActiveMenu(id)}
                  className={`h-[40px] mb-2 text-base text-light-gray pl-4 flex items-center w-full whitespace-nowrap hover:bg-black rounded-lg cursor-pointer
                          ${
                            activeMenu === id
                              ? "text-primary-green bg-black rounder-lg"
                              : "text-secondary-300"
                          }`}
                  key={id}
                >
                  <a className="w-full" href={`#${id}`}>
                    {title}
                  </a>
                </li>
              );
            })}
          </ul>

          <div className="flex flex-col pr-10 grow">
            <Overview
              setRef={overviewRef}
              chartFilters={chartFilters}
              chartFilter={chartFilter}
              setChartFilter={setChartFilter}
              eventId={eventId ? eventId : null}
            />

            <UserFaceRegistration
              setRef={userFaceSearchRegistrationsRef}
              guestFilters={chartFilters}
              guestFilter={chartFilter}
              setGuestFilter={setChartFilter}
              title={"Face Searches"}
              key={"userFaceSearchRegistrations"}
              eventId={eventId ? eventId : null}
            />

            <PreFaceRegistration
              setRef={preFaceSearchRegistrationsRef}
              guestFilters={chartFilters}
              guestFilter={chartFilter}
              setGuestFilter={setChartFilter}
              title={"Pre Registrations"}
              key={"pre-registration"}
              eventId={eventId}
            />

            <DownloadRequests
              setRef={downloadsRef}
              guestFilters={chartFilters}
              guestFilter={chartFilter}
              setGuestFilter={setChartFilter}
              title={"Download Requests"}
              key={"downloads"}
              id={"downloads"}
              eventId={eventId}
            />

            <PrintOrdersList
              setRef={printStoreRef}
              id="print-store"
              showAsTitle
              eventId={eventId}
            />

            <Traffic
              selectedFavList={selectedFavList}
              setSelectedFavList={setSelectedFavList}
              setRef={trafficRef}
              eventId={eventId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
