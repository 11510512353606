import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Text from '../../../../components/common/Text';
import Title from '../../../../components/common/Title';
import {InputEditable, InputText} from '../../../../components/common/Input';
import {useUser} from "../../../../Store/User";
import {getUserDetails, updatePhotographerDomain} from "../../../../apis";
import Swal from "sweetalert2";

const Domain = (props) => {
    const {user, setUser} = useUser(),
        navigate = useNavigate(),
        [subDomain, setSubDomain] = useState('sub-domain'),
        [errors, setErrors] = useState({}),
        [customDomain, setCustomDomain] = useState(),
        [successStatus, setSuccessStatus] = useState({
            'subDomain': false,
            'customDomain': false,
        });

    useEffect(async () => {
        if (user.photographer) {
            setSubDomain(user.photographer.subDomain);
            setCustomDomain(user.photographer.domain_name);
        }
    }, [user]);

    function updateSubdomain() {
        const pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]+$/;
        if (!pattern.test(subDomain)) {
            setErrors({...errors, subDomain: "Invalid Sub Domain"});
            return false;
        }
        if ((subDomain).length > 54) {
            setErrors({...errors, subDomain: "Sub Domain must be less than 54 characters"});
            return false;
        }
        setErrors({...errors, subDomain: null});
        setSuccessStatus({...successStatus, 'subDomain': false});

        Swal.fire({
            title: 'Warning!',
            text: 'Changing your Sub-domain will affect all your existing links. Are you sure?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change it!',
            denyButtonText: 'Cancel',
            allowEscapeKey : false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                updatePhotographerDomain({
                    subDomain: subDomain
                }).then(response => {
                    setSuccessStatus({...successStatus, 'subDomain': true});
                    setUser({...user, access_token: response.access_token})
                }).catch(error => {
                    setErrors({...errors, subDomain: error.response.data.message});
                });
            } else if (result.isDenied) {
                setErrors({...errors, subDomain: 'Cancelled by user'});
            }
        }).catch();
    }

    function updateCustomDomain() {
        setErrors({...errors, customDomain: null});
        setSuccessStatus({...successStatus, 'customDomain': false});
        Swal.fire({
            title: 'Warning!',
            text: 'Changing your Domain will affect all your existing links. Are you sure?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes, Change it!',
            denyButtonText: 'Cancel',
            allowEscapeKey : false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                updatePhotographerDomain({
                    domain: customDomain
                }).then(response => {
                    setSuccessStatus({...successStatus, 'customDomain': true});
                    setUser({...user, access_token: response.access_token})
                }).catch(error => {
                    setErrors({...errors, customDomain: error.response.data.message});
                });
            } else if (result.isDenied) {
                setErrors({...errors, customDomain: 'Cancelled by user'});
            }
        }).catch();
    }

    return (
        (!user || !user.photographer || !user.subscription) ? ('Loading...') :
            <div id="domain" ref={props.setRef}>
                <div >
                    <Title
                        className='pb-4 my-4 border-secondary-600 border-b'
                        text="Domain"
                    />

                    <div className='mt-7 flex justify-between gap-32'>
                        <div className='flex flex-col w-full'>
                            <InputEditable
                                label="User name / domain name"
                                type="text"
                                onChange={(e) => setSubDomain(e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase())}
                                value={subDomain}
                                error={errors.subDomain}
                                onUpdate={(value) => updateSubdomain()}
                                required={true}
                                success={successStatus.subDomain}
                            />


                            <Text as="p" className='mb-4 mt-3'>
                                Your preview gallery urls will start with <br/>
                                <span
                                    className='text-primary-green underline pt-2'>https://{subDomain.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}.algomage.com</span>
                            </Text>
                        </div>
                        <div id="custom-domain" className='flex flex-col w-full'>
                            {user.subscription.status !== '6' ?
                                <>
                                    <InputEditable
                                        label="Custom domain name"
                                        type="url"
                                        placeholder="e.g. sub-domain.your-domain.com"
                                        onChange={(e) => setCustomDomain(e.target.value)}
                                        value={customDomain}
                                        error={errors.customDomain}
                                        onUpdate={(value) => updateCustomDomain()}
                                        required={true}
                                        success={successStatus.customDomain}
                                    />

                                    {customDomain ?
                                    <div className='flex flex-col w-full'>
                                        <Text as="p" className='mb-4 mt-3'>
                                            Your client gallery urls will start with <br/>
                                            <span
                                                className='text-primary-green underline pt-2'>{customDomain ? `https://${customDomain}` : null}</span>
                                        </Text>
                                    </div> :<div className='flex flex-col w-full'>
                                        <Text as="p" className='mb-4 mt-3'>
                                        Point your CNAME entry record to <span className='text-primary-green' >domains.algomage.com</span>
                                        </Text>
                                    </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='flex flex-col w-full'>
                                        <Text as="p" className='mb-4'>
                                            Custom domain name
                                        </Text>
                                    </div>
                                    <>
                                        <div
                                            className="flex justify-between items-center max-w-[28rem] pr-5 rounded-xl bg-dark-gray mt-1 text-light-gray mb-6 shadow-dark-input outline-none">
                                            <input
                                                readOnly
                                                type="text"
                                                className="bg-transparent p-2 pl-4 placeholder:text-secondary-500
                                                 placeholder:text-sm outline-none w-full"
                                            />
                                            <span onClick={() => {
                                                navigate('/manage-sub')
                                            }}
                                                  className="inline-block text-xs font-semibold whitespace-nowrap underline cursor-pointer text-blue text-primary-green">Upgrade Now</span>
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <Text as="p" className='mb-4'>
                                                Use your own custom domain for your client galleries. <br/>
                                                This feature is available with an upgraded account.
                                            </Text>
                                        </div>
                                    </>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Domain;
