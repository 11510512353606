/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";

import DownArrow from "../../assets/icons/DownArrow";
import Button from "../../components/common/Button";
import Switch from "../../components/common/Switch";
import EmailTemplateDark from "./components/EmailTemplateDark";
import EmailTemplateGradient from "./components/EmailTemplateGradient";
import { copyText, titleUpdate } from "../../functions";
import {
  getEventDetails,
  restrictEmailForEvent,
  sendEmailInvites,
  togglePinField,
  updateEventData,
  updateEventWhatsapp,
} from "../../apis";

import Loading from "../Loading";
import "./share.scss";
import { useUser } from "../../Store/User";
import { QrCodeComponent } from "./components/QrcodeComponent";
import { SelectTemplate } from "./components/SelectTemplate";
import { AddEmail } from "./components/AddEmail";
import { InputEditable } from "../../components/common/Input";
import SwitchWithTitle from "../GeneralSettings/components/SwitchWithTitle";
import { useEvent } from "../../Store/Event/Show";

const ShareEventEmail = ({ shareType = 1 }) => {
  const { user } = useUser();
  const { event } = useEvent();
  let photographer = user.photographer;

  const [isLoading, setIsLoading] = useState(true);
  const emailInput = useRef(null);
  const { eventId } = useParams();
  const [accessType, setAccessType] = useState(shareType);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showShareModal, setShowShareModal] = useState(0);

  const [globalViewerState, setGlobalViewerState] = useState(0);

  const [emailListFace, setEmailListFace] = useState([]);
  const [emailListFull, setEmailListFull] = useState([]);

  const [passwordTextUpdate, setPasswordTextUpdate] = useState(false);
  const [isPinDisable, setIsPinDisable] = useState(event.isPinDisable);

  const copyTextAreaRef = useRef();

  const [faceEmailDetails, setFaceEmailDetails] = useState({
    liveness: 0,
    layout: 1,
    published: 0,
    pin: "",
    event_id: eventId,
    date: "",
    banner_image:
      "https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg",
    message: ``,
    password_enabled: false,
    password_text: "",
    requiresEmail: false,
    subject: "",
    template: 1,
  });

  var messageTemplates = [
    `I'd love to invite you to view the photos from %event_name%.\n\nClick on the View Photos button to view your personalized gallery. Feel free to share this gallery with your family and friends.`,
    `I'd love to invite you to pre-register to view your photos from %event_name%. Click on 'Register' button to register yourself for receiving the images when they are ready`,
  ];
  const faceMessage = `I'd like to invite you to search your images  from %event_name% using  face recognition.\n\nClick on the link below and enter pin, follow the \ninstructions on screen and discover yourself in seconds!`;

  const [pageErros, setPageErrors] = useState({
    email: false,
    subject: false,
    message: false,
    password_text: false,
  });

  const url =
    photographer.branding_allowed && photographer.domain_name
      ? "https://" + photographer.domain_name
      : "https://" + photographer.subDomain + "." + process.env.REACT_APP_URL;
  const [qrCodeText, setQRCodeText] = useState(url);
  const [eventName, setEventName] = useState("");
  const [gloablDetails, setGloablDetails] = useState({
    name: photographer.brand_name,
    email: user.user.email,
    facebook: photographer.facebook,
    twitter: photographer.twitter,
    youtube: photographer.youtube,
    linkedin: photographer.linkedin,
    url: photographer.portfolio_website ? photographer.portfolio_website : "",
    insta: photographer.instagram,
    logo: photographer.logo_url,
    branding_allowed: photographer.branding_allowed,
    domain: photographer.domain_name,
    subDomain: photographer.subDomain,
    vimeo: photographer.vimeo,
    phone: photographer.phone,
  });

  useEffect(async () => {
    getEventDetails(eventId).then((x) => {
      setFaceEmailDetails({
        ...faceEmailDetails,
        event_id: x.id,
        liveness: x.isLivenessEnabled,
        published: x.isPublished,
        pin: x.pin,
        banner_image: x.coverImageUrl,
        date: x.eventDate,
        event_name: x.name,
        password_enabled: x.requiresPassword,
        password_text: x.passwordText,
        requiresEmail: x.requiresEmail,
        slug: x.slug,
        message: x.isPublished
          ? faceMessage
          : messageTemplates[1].replace("%event_name%", x.name),
        subject: x.isPublished
          ? `Photos from ${x.name} are ready`
          : `Register for ${x.name}`,
        accessType: accessType,
        faceEmailList: emailListFace,
        fullEmailList: emailListFull,
        isWhatsappEnabled: x.whatsapp_message_enable,
      });

      setGlobalViewerState(!x.published);
      titleUpdate(`Share Event ${x.name}`);
      setEventName(x.name);
      setIsLoading(false);
    });
  }, []);

  const updateLiveness = (livenesState) => {
    setFaceEmailDetails({
      ...faceEmailDetails,
      liveness: livenesState,
    });
    updateEventData(eventId, { liveness: livenesState })
      .then(() => {
        window.Toast.fire({
          icon: "success",
          title: `Liveness ${livenesState ? "Enabled" : "Disabled"}`,
        });
      })
      .catch(function (error) {
        setFaceEmailDetails({
          ...faceEmailDetails,
          liveness: !livenesState,
        });
        window.Toast.fire({
          icon: "error",
          title: `Something went wrong`,
        });
      });
  };
  const updatePassword = (password, pass_text) => {
    setFaceEmailDetails({
      ...faceEmailDetails,
      password_enabled: password,
    });
    if (!password) {
      updateEventData(eventId, { hasPassword: password, password: "" })
        .then((x) => {
          setFaceEmailDetails({
            ...faceEmailDetails,
            password_enabled: password,
            password_text: "",
          });
          window.Toast.fire({
            icon: "success",
            title: `Password Protection ${password ? "Enabled" : "Disabled"}`,
          });
        })
        .catch(function (error) {
          window.Toast.fire({
            icon: "error",
            title: `Something went wrong`,
          });
        });
    }

    if (
      password &&
      faceEmailDetails.password_text &&
      !pageErros.password_text
    ) {
      updateEventData(eventId, {
        hasPassword: password,
        password: faceEmailDetails.password_text,
      })
        .then((x) => {
          setPasswordTextUpdate(true);

          setFaceEmailDetails({
            ...faceEmailDetails,
            password_text: faceEmailDetails.password_text,
            password_enabled: password,
          });
          window.Toast.fire({
            icon: "success",
            title: `Password Protection ${password ? "Enabled" : "Disabled"}`,
          });
        })
        .catch(function (error) {
          // setFaceEmailDetails({
          //   ...faceEmailDetails,
          //   liveness: livenesState,
          // })
          window.Toast.fire({
            icon: "error",
            title: `Something went wrong`,
          });
        });
    }
  };

  useEffect(() => {
    if (!faceEmailDetails.password_enabled) setPasswordTextUpdate(false);
  }, [faceEmailDetails.password_enabled]);

  const updateEmails = (emailkey) => {
    setFaceEmailDetails({
      ...faceEmailDetails,
      requiresEmail: emailkey,
    });
    updateEventData(eventId, { requiresEmailRegistration: emailkey })
      .then((x) => {
        window.Toast.fire({
          icon: "success",
          title: `Email registration ${emailkey ? "Enabled" : "Disabled"}`,
        });
      })
      .catch(function (error) {
        // setFaceEmailDetails({
        //   ...faceEmailDetails,
        //   liveness: livenesState,
        // })
        setFaceEmailDetails({
          ...faceEmailDetails,
          requiresEmail: !emailkey,
        });
        window.Toast.fire({
          icon: "error",
          title: `Something went wrong`,
        });
      });
  };

  const updateWhatsapp = async (key) => {
    setFaceEmailDetails({
      ...faceEmailDetails,
      isWhatsappEnabled: key,
    });

    try {
      await updateEventWhatsapp(event.id, key);
      window.Toast.fire({
        icon: "success",
        title: "Whatsapp message settings updated",
      });
    } catch (error) {
      console.error(error);
      setFaceEmailDetails({
        ...faceEmailDetails,
        isWhatsappEnabled: !key,
      });
      window.Toast.fire({
        icon: "error",
        title: "Whatsapp message settings not updated.",
      });
    }
  };

  const updateEventDetailsKey = (e) => {
    let resStatus = ValidateEmail(e.target.name, e.target.value);
    setPageErrors({
      ...pageErros,
      [e.target.name]: resStatus,
    });
    setFaceEmailDetails({
      ...faceEmailDetails,
      [e.target.name]: e.target.value,
    });
  };

  const ValidateEmail = (field, value) => {
    let status = false;
    switch (field) {
      case "subject":
        if (value.trim() == "") {
          status = "Subject is required";
        }
        if (value.trim() && value.length < 4) {
          status = "Subject too Small";
        }
        break;
      case "message":
        if (value.trim() == "") {
          status = "Message is required";
        }
        break;
      case "password_text":
        if (faceEmailDetails.password_enabled && !accessType) {
          if (value.trim() == "") {
            status = "Password is required";
          }
          if (value.trim() && value.length <= 5) {
            status = "Password to small, 6 characters or more";
          }
          if (/\s/.test(value)) {
            status = "Password Cant contain spaces";
          }
        }
    }
    return status;
  };

  const sendMail = () => {
    const {
      subject,
      event_id,
      fullEmailList,
      accessType,
      message,
      layout,
      banner_image,
      faceEmailList,
    } = faceEmailDetails;
    const postData = {
      subject,
      event_id,
      fullEmailList,
      accessType,
      message,
      layout,
      banner_image,
      faceEmailList,
    };
    sendEmailInvites(postData)
      .then((x) => {
        Swal.fire({
          title: "Emails Sent",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          setEmailListFull([]);
          setEmailListFace([]);
        });
      })
      .catch(function (error) {
        window.Toast.fire({
          icon: "error",
          title: `Something went wrong`,
        });
      });
  };

  useEffect(() => {
    setButtonDisabled(true);
    var checks = 0;
    if (faceEmailDetails.subject && faceEmailDetails.subject.length >= 4)
      checks++;

    if (faceEmailDetails.message) checks++;

    if (accessType == 0) {
      if (emailListFull.length) checks++;
      if (faceEmailDetails.password_enabled) {
        if (faceEmailDetails.password_text) checks++;
      } else {
        checks++;
      }

      if (checks == 4) setButtonDisabled(false);
    }
    if (accessType == 1) {
      if (emailListFace.length) checks++;

      if (checks == 3) setButtonDisabled(false);
    }
  }, [faceEmailDetails, emailListFace, emailListFull, accessType]);

  useEffect(() => {
    if (accessType == 1) {
      setFaceEmailDetails({
        ...faceEmailDetails,
        faceEmailList: emailListFace.toString(),
      });
    }
    if (!accessType) {
      setFaceEmailDetails({
        ...faceEmailDetails,
        fullEmailList: emailListFull,
      });
    }
  }, [emailListFace, emailListFull]);

  const updateUrl = (urlToChange, type) => {
    setAccessType(type);
    setFaceEmailDetails({
      ...faceEmailDetails,
      accessType: type,
    });
  };
  const changeStatusType = (e, key, val) => {
    e.preventDefault();
    var list = [...emailListFull];
    list[key].admin = val;
    setEmailListFull(list);

    restrictEmailForEvent({ id: eventId }, list[key].email, val ? 2 : 1).then(
      function (response) {
        window.Toast.fire({
          icon: "success",
          title: `${list[key].email} updated to  ${
            val ? "Co-admin" : "viewer"
          }`,
        });
      }
    );
  };

  useEffect(() => {
    let urBack = "";
    if (accessType) {
      urBack = url + "/facerec/" + faceEmailDetails.slug;
    } else {
      urBack = url + "/view/" + faceEmailDetails.slug;
    }

    setQRCodeText(urBack);
  }, [showShareModal, accessType, faceEmailDetails]);

  useEffect(() => {
    let message = messageTemplates[accessType];
    if (faceEmailDetails.published && accessType) {
      message = faceMessage;
    }
    message = message.replace("%event_name%", eventName);
    setFaceEmailDetails({
      ...faceEmailDetails,
      message: message,
    });
  }, [accessType]);

  useEffect(() => {
    setIsPinDisable(!event.isPinDisable);
  }, [event.isPinDisable]);

  function updatePinField(e) {
    setIsPinDisable((prev) => !prev);
    togglePinField(event, e)
      .then(function () {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        setIsPinDisable(e);
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  return isLoading ? (
    <Loading />
  ) : !faceEmailDetails.pin ? null : (
    <div className="">
      {user.permissions.share_event ? (
        <>
          <div
            className={
              "flex shadow-header items-center justify-start px-10  h-[75px]  "
            }
          >
            <Link to={"/events/" + eventId}>
              <div className="flex items-center">
                <span className="font-medium mr-3 cursor-pointer">
                  <DownArrow className={"rotate-90"} />
                </span>
              </div>
            </Link>
            <h1 className="text-2xl ml-6 text-light-gray">
              {faceEmailDetails.published ? "Share" : "Pre-share"} event with
              people
            </h1>
          </div>
          <div className="lg:flex px-10  my-10 container mx-auto">
            <div className="grid grid-rows-1 gap-3 lg:w-1/2 text-slate-50 text-xs lg:text-sm">
              <div>
                <div className="text-lg">
                  {" "}
                  {faceEmailDetails.published ? "Select access" : "Access"} type
                </div>
                <div className="grid grid-cols-2 grid-flow-row gap-4 pt-3 lg:w-10/12">
                  <div
                    className={`flex items-center   ${
                      accessType ? "shadow-inner-all" : "shadow-dark-input"
                    } rounded-md p-3 `}
                  >
                    <label className="w-full cursor-pointer flex items-center ">
                      <input
                        type="radio"
                        name="access_type"
                        checked={accessType == 1}
                        onChange={() =>
                          updateUrl("/share/find-image/" + eventId, 1)
                        }
                        className="accent-cyan-500 shareinput"
                      />
                      <span className="pl-2">Face search access</span>
                    </label>
                  </div>

                  <div
                    className={`flex items-center ${
                      !accessType ? "shadow-inner-all" : "shadow-dark-input"
                    } rounded-md p-3 `}
                  >
                    <label className="w-full cursor-pointer flex items-center">
                      <input
                        type="radio"
                        name="access_type"
                        checked={accessType == 0}
                        onChange={() => updateUrl("/share/full/" + eventId, 0)}
                        className="accent-cyan-500 shareinput"
                      />
                      <span className="pl-2">Full event access</span>
                    </label>
                  </div>
                </div>

                <div className="text-lg mt-12">
                  Choose how you want to share
                </div>
                <div className="grid grid-cols-2 grid-flow-row gap-4 lg:w-10/12 pt-3">
                  <div
                    className={`flex items-center ${
                      !showShareModal ? "shadow-inner-all" : "shadow-dark-input"
                    } rounded-md p-3 `}
                  >
                    <label className="w-full cursor-pointer flex items-center">
                      <input
                        type="radio"
                        checked={showShareModal == 0}
                        onChange={() => setShowShareModal(0)}
                        name="share_method"
                        className="accent-cyan-500 shareinput"
                      />
                      <span className="pl-2">Custom email</span>
                    </label>
                  </div>
                  <div
                    className={` flex items-center ${
                      showShareModal ? "shadow-inner-all" : "shadow-dark-input"
                    } rounded-md p-3 `}
                  >
                    <label className="w-full cursor-pointer flex items-center">
                      <input
                        type="radio"
                        checked={showShareModal == 1}
                        className="accent-cyan-500 shareinput"
                        onChange={() => setShowShareModal(1)}
                        name="share_method"
                      />
                      <span className="pl-2">QR code / Direct link</span>
                    </label>
                  </div>
                </div>

                {showShareModal ? (
                  <QrCodeComponent
                    qrCodeText={qrCodeText}
                    faceEmailDetails={faceEmailDetails}
                    accessType={accessType}
                  />
                ) : null}
                <div>
                  {!showShareModal ? (
                    <Fragment>
                      <SelectTemplate
                        faceEmailDetails={faceEmailDetails}
                        updateEventDetailsKey={updateEventDetailsKey}
                      />
                      <AddEmail
                        updateEventDetailsKey={updateEventDetailsKey}
                        emailListFull={emailListFull}
                        setEmailListFull={setEmailListFull}
                        setEmailListFace={setEmailListFace}
                        setPageErrors={setPageErrors}
                        emailListFace={emailListFace}
                        accessType={accessType}
                        globalViewerState={globalViewerState}
                        setGlobalViewerState={setGlobalViewerState}
                        emailInput={emailInput}
                        pageErros={pageErros}
                        changeStatusType={changeStatusType}
                        faceEmailDetails={faceEmailDetails}
                      />

                      <div className="lg:mr-16 mt-12 relative">
                        <div className="text-lg mb-4">
                          {!showShareModal ? "Email message" : "Copy message"}{" "}
                          {showShareModal ? (
                            <span className="ml-4">
                              <button
                                onClick={(e) =>
                                  copyText(e, faceEmailDetails.message)
                                }
                                className="pr-2 text-[16px] underline text-primary-green"
                              >
                                Copy
                              </button>
                            </span>
                          ) : null}{" "}
                        </div>
                        {pageErros.message && (
                          <div className="mb-1 ml-3 text-[12px] text-right text-[#e16a6a] absolute right-2 top-1 ">
                            *{pageErros.message}
                          </div>
                        )}

                        <textarea
                          value={faceEmailDetails.message.replace(
                            "%event_name%",
                            eventName
                          )}
                          name="message"
                          rows="7"
                          className={`w-full overflow-auto text-white  ${
                            faceEmailDetails.message
                              ? "shadow-inner-all"
                              : "shadow-dark-input"
                          }  rounded-2xl bg-transparent p-2 pt-3 pl-4 pb-10 `}
                          onChange={(e) => updateEventDetailsKey(e)}
                        />
                      </div>
                    </Fragment>
                  ) : null}
                  {accessType ? (
                    <>
                      <div className="grid grid-cols-2 gap-4 mt-12">
                        <div className="mr-8">
                          <div>
                            <SwitchWithTitle
                              className="mb-8 font-medium !max-w-[28rem]"
                              title="Face Search Pin"
                              value={isPinDisable}
                              onChange={() => {
                                isPinDisable != 1
                                  ? updatePinField(0)
                                  : updatePinField(1);
                              }}
                            />
                          </div>
                          {!!isPinDisable ? (
                            <>
                              <div className="text-lg mt-10">
                                {" "}
                                {!showShareModal
                                  ? "PIN for access"
                                  : "Copy PIN"}
                              </div>
                              <div className="flex justify-between  shadow-inner-all text-xs p-2 mt-4 rounded-xl">
                                <div className="pl-2">
                                  {faceEmailDetails.pin}
                                </div>
                                <button
                                  onClick={(e) =>
                                    copyText(e, faceEmailDetails.pin)
                                  }
                                  className="pr-2 underline text-primary-green"
                                >
                                  Copy
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="text-lg lg:mr-16">
                          <div className="flex justify-between items-center">
                            <div>Liveness</div>
                            <Switch
                              onChange={() =>
                                updateLiveness(!faceEmailDetails.liveness)
                              }
                              defaultState={faceEmailDetails.liveness}
                            />
                          </div>
                          <div className="text-sm mt-4">
                            Contributors and viewers will be asked to capture
                            gestures to confirm their identity during face
                            recognition.
                          </div>
                        </div>
                      </div>
                      {user.subscription.with_app == 1 ? (
                        <div className="grid mt-12 pr-7">
                          <div className="mr-8">
                            <div className="text-lg">
                              Event code for Mobile App
                            </div>

                            <div className="flex justify-between shadow-dark-input text-xs p-2 mt-4 rounded-xl">
                              <div className="pl-2">
                                {accessType === 1
                                  ? event.faceSearchId
                                  : eventId}
                              </div>
                              <button
                                onClick={(e) =>
                                  copyText(
                                    e,
                                    accessType === 1
                                      ? event.faceSearchId
                                      : eventId
                                  )
                                }
                                className="pr-2 underline text-primary-green"
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div>
                      <div className="grid lg:grid-cols-2 mt-10">
                        <div className=" col-span-2">
                          <div className="flex w-1/2 justify-between items-center">
                            <div className="text-lg">Password protection</div>
                            <Switch
                              onChange={() =>
                                updatePassword(
                                  !faceEmailDetails.password_enabled
                                )
                              }
                              defaultState={faceEmailDetails.password_enabled}
                            />
                          </div>
                          {faceEmailDetails.password_enabled ? (
                            <div className=" mt-4 w-1/2">
                              <InputEditable
                                type="text"
                                placeholder="Enter password"
                                name="password_text"
                                value={faceEmailDetails.password_text}
                                onChange={(e) => updateEventDetailsKey(e)}
                                error={pageErros.password_text}
                                onUpdate={(e) => updatePassword(1)}
                                required={true}
                                success={passwordTextUpdate}
                              />
                              {/* <input type={'text'} placeholder={'Enter password'} className='  w-full p-2 pl-4 shadow-dark-input rounded-2xl bg-transparent lg:pr-16 text-white' name='password_text' value= {faceEmailDetails.password_text} onChange={e=>updateEventDetailsKey(e)} /> */}
                            </div>
                          ) : null}
                          {faceEmailDetails.password_enabled ? (
                            <p className="text-sm mt-2 col-span-2 text-[#aaaaaa]">
                              This password needs to be letter or numbers with a
                              minimum 6 characters
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {/* {!showShareModal ? ( */}
                      <div className="grid lg:grid-cols-2 mt-10">
                        <div className="col-span-2">
                          <div className="flex w-1/2 justify-between items-center">
                            <div className="text-lg">Email registration</div>
                            <Switch
                              onChange={() => {
                                updateEmails(!faceEmailDetails.requiresEmail);
                              }}
                              defaultState={faceEmailDetails.requiresEmail}
                            />
                          </div>
                        </div>
                      </div>

                      {user?.user?.photographer?.whatsapp_template_id ||
                      (user?.user?.photographer?.is_whatsapp_by_algomage &&
                        user?.subscription?.next_sub &&
                        new Date(user?.subscription?.next_sub) > new Date()) ? (
                        <div className="grid lg:grid-cols-2 mt-10">
                          <div className="col-span-2">
                            <div className="flex w-1/2 justify-between items-center">
                              <div className="text-lg">Enable Whatsapp</div>
                              <Switch
                                onChange={() => {
                                  updateWhatsapp(
                                    !faceEmailDetails.isWhatsappEnabled
                                  );
                                }}
                                defaultState={
                                  faceEmailDetails.isWhatsappEnabled
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* ) : null} */}
                    </div>
                  )}
                  {!showShareModal ? (
                    <div className="flex justify-center mx-auto m-10">
                      <Button
                        disabled={buttonDisabled}
                        text="Share Event"
                        className={" h-[42px]"}
                        onClick={sendMail}
                      />
                    </div>
                  ) : (
                    <div
                      className={`lg:mr-16 mt-12 relative ${
                        showShareModal ? "mb-12" : null
                      } `}
                    >
                      <div className="text-lg mb-4">
                        {!showShareModal ? "Email message" : "Custom message"}{" "}
                        {showShareModal ? (
                          <span className="ml-4">
                            <button
                              onClick={(e) =>
                                copyText(
                                  e,
                                  faceEmailDetails.message.replace(
                                    "%event_name%",
                                    eventName
                                  ) +
                                    `\n\n${
                                      accessType
                                        ? "LINK:" +
                                          qrCodeText +
                                          `\nPIN:${faceEmailDetails.pin}`
                                        : "LINK:" + qrCodeText
                                    }
                          
                          `
                                )
                              }
                              className="pr-2 text-xs underline text-primary-green"
                            >
                              Copy
                            </button>
                          </span>
                        ) : null}{" "}
                      </div>
                      {pageErros.message && (
                        <div className="mb-1 ml-3 text-[12px] text-right text-[#e16a6a] absolute right-2 top-1 ">
                          *{pageErros.message}
                        </div>
                      )}
                      <textarea
                        ref={copyTextAreaRef}
                        value={
                          faceEmailDetails.message.replace(
                            "%event_name%",
                            eventName
                          ) +
                          `\n\n${
                            accessType
                              ? "LINK:" +
                                qrCodeText +
                                `\n${
                                  !!isPinDisable
                                    ? "PIN:" + faceEmailDetails.pin
                                    : ""
                                }`
                              : "LINK:" + qrCodeText
                          }                    
                    `
                        }
                        readOnly
                        name="message"
                        rows="8"
                        className={`w-full overflow-auto text-white  ${
                          faceEmailDetails.message
                            ? "shadow-inner-all"
                            : "shadow-dark-input"
                        }  rounded-2xl bg-transparent p-2  pt-3 pl-4 pb-10 `}
                        onChange={(e) => updateEventDetailsKey(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:w-1/2 text-light-gray ">
              <div className="">
                <div className="text-lg mb-3">Preview email</div>
                {[1, 2].includes(parseInt(faceEmailDetails.layout)) ? (
                  <EmailTemplateDark
                    mailData={faceEmailDetails}
                    photographer={gloablDetails}
                    accessType={accessType}
                    url={qrCodeText}
                    isPinEnabled={!!isPinDisable}
                  />
                ) : (
                  <EmailTemplateGradient
                    mailData={faceEmailDetails}
                    photographer={gloablDetails}
                    accessType={accessType}
                    url={qrCodeText}
                    isPinEnabled={!!isPinDisable}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full h-[100vh] justify-center items-center">
          <h2 className="text-center">Upgrade Your Subscription to Proceed.</h2>
        </div>
      )}
    </div>
  );
};

export default ShareEventEmail;
