import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import CloseIcon from "../../../../assets/icons/CloseIcon";
import { InputText } from "../../../../components/common/Input";
import Loading from "../../../Loading";

export default function CreateEvent({
  showAddSubEvent,
  setShowAddSubEvent,
  addNewFolder,
  folderName,
  folderNameError,
  setFolderNameError,
  setFolderName,
  loader,
}) {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const handleClose = (e) => {
    setOpen(false);
    setShowAddSubEvent(!showAddSubEvent);
    setFolderNameError("");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="p-8">
                <div className="sm:items-start">
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300"
                    >
                      Add New Sub-Event
                      <span
                        onClick={(e) => {
                          handleClose(e);
                        }}
                        className="cursor-pointer"
                      >
                        <CloseIcon fill="#eee" />
                      </span>
                    </Dialog.Title>
                    <div className="mt-5">
                      <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                        <div className="flex items-center !w-full">
                          <InputText
                            value={folderName}
                            error={folderNameError}
                            processing={false}
                            onUpdate={(value) => {
                              addNewFolder(value);
                              setFolderNameError("");
                            }}
                            onChange={(e) => {
                              setFolderName(e);
                              setFolderNameError("");
                            }}
                            placeholder="Sub Event Name"
                            label="Sub Event Name"
                            onKeyDown={(e) => {
                              addNewFolder(e);
                            }}
                            className="w-full"
                          />
                        </div>
                      </div>

                      <div className="flex justify-center pt-10">
                        {loader ? (
                          <Loading noFullScreen />
                        ) : (
                          <button
                            className="rounded-lg shadow-button font-semibold w-[11rem] py-2 bg-button text-light-gray"
                            disabled={!folderName}
                            onClick={(e) => {
                              addNewFolder(e);
                            }}
                          >
                            Create Sub-Event
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
