import React from 'react';
import {Link} from 'react-router-dom';
import DownArrow from '../../../../../assets/icons/DownArrow';
import Text from '../../../../../components/common/Text';

const MobilePortfolioTitleSection = () => {
    return <div className='flex items-center h-[5rem] shadow-header'>
        <div className='flex items-center w-full'>
            {/* <div className='flex' > */}
                <Link to={'/'}>
            <span
                className='flex pl-10  mr-6 items-center font-medium cursor-pointer
                text-primary-green text-base font-semibold underline'>
                <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
                    Go Back
                </span>
                </Link>
            
                <Link to={'/profile'}>
            <span
                className='flex  mr-6 items-center font-medium cursor-pointer
                text-primary-green text-base font-semibold underline'>
                <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
                    Go Back to Profile
                </span>
                </Link>
                <Text
                as='h1'
                className='flex text-3xl text-light-gray'
            >
                Mobile App
                </Text>
            {/* </div> */}
            

            
        </div>
        
    </div>;
};

export default MobilePortfolioTitleSection;
