import { Link } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-date-picker";

import Text from "../../../../components/common/Text";
import { InputEditable } from "../../../../components/common/Input/input-editable";
import Select from "../../../../components/common/Select";
import { useEvent } from "../../../../Store/Event/Show";
import {
  updateEventDate,
  getWatermarkPresets,
  updateWatermarkEvent,
  updateEventChatBot,
  updateEventData,
  updateEventImageShare,
  updateEventWhatsapp,
} from "../../../../apis";
import { useUser } from "../../../../Store/User";
import SwitchWithTitle from "../../../GeneralSettings/components/SwitchWithTitle";
import Information from "../../../../assets/images/information.png";
import "../../../../assets/Calendar.css";
import "../../../../assets/DatePicker.css";

const GeneralSectionRight = ({ shareImage, setShareImage }) => {
  let user = useUser();
  const { event, setEvent } = useEvent();
  const [generalSettingRight, setGeneralSettingRight] = useState({
    eventDate: "",
    eventExpiryDate: "",
    chatBot: "",
    imageShare: "",
  });
  const [watermarkPresets, setWatermarkPresets] = useState([]);
  const [selectedWatermarkPreset, setSelectedWatermarkPreset] = useState(false);
  const [openExpiryDateHelp, setOpenExpiryDateHelp] = useState(false);
  const [currentWatermarkPreset, setCurrentWatermarkPreset] = useState(null);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [passwordText, setPasswordText] = useState(null);
  const [chatBot, setChatBot] = useState(null);
  const [pageErrors, setPageErrors] = useState({ password_text: false });
  const [success, setSuccess] = useState({
    password_text: false,
  });

  const [isWhatsappEnabled, setIsWhatsappEnabled] = useState(
    event.whatsapp_message_enable
  );

  useEffect(() => {
    if (!_.isEmpty(event)) {
      setGeneralSettingRight({
        //check if event date is null
        eventDate: !_.isNull(event.date)
          ? moment(event.eventDate).format("YYYY-MM-DD")
          : null,
        eventExpiryDate: !_.isNull(event.expiryDate)
          ? moment(event.expiryDate).format("YYYY-MM-DD")
          : null,
      });
      setRequiresPassword(event.requiresPassword);
      setPasswordText(event.passwordText);
      setShareImage(event.showImageShare);
    }
    getWatermarkPresets().then((response) => {
      setWatermarkPresets(response);
      setCurrentWatermarkPreset(
        response.filter((watermark) => watermark.id === event.watermarkId)[0]
      );
    });
  }, [event]);

  useEffect(() => {
    setIsWhatsappEnabled(event.whatsapp_message_enable);
  }, [event.whatsapp_message_enable]);

  useEffect(() => {
    if (!_.isEmpty(event) && !_.isEmpty(user)) {
      user.user.photographer.show_chat_bot
        ? event.showChatBot == null
          ? setChatBot(1)
          : setChatBot(event.showChatBot)
        : setChatBot(0);
    }
  }, [event, user]);

  const handleEventDate = (e) => {
    if (e == null || e == "" || e == undefined) {
      window.Toast.fire({
        icon: "error",
        title: "Event date cannot be empty",
      });
      return false;
    }

    setGeneralSettingRight({
      ...generalSettingRight,
      eventDate: moment(e).format("Y-M-D"),
    });
    updateEventDate(event, moment(e).format("Y-M-D"))
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Event date Updated",
        });
      })
      .catch((err) => {
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };
  //function to update eventExpiryDate
  const handleExpiryDate = (e) => {
    if (e === null || e === "" || e === undefined) {
      setGeneralSettingRight({
        ...generalSettingRight,
        eventExpiryDate: e,
      });
      updateEventDate(event, e, true)
        .then((response) => {
          window.Toast.fire({
            icon: "success",
            title: "Expiry date removed",
          });
        })
        .catch((err) => {
          window.Toast.fire({
            icon: "error",
            title: "Something went wrong",
          });
        });
      return false;
    }
    if (moment(e).isBefore(moment(generalSettingRight.eventDate))) {
      window.Toast.fire({
        icon: "error",
        title: "Event expiry date cannot be before event date",
      });
      return false;
    }
    setGeneralSettingRight({
      ...generalSettingRight,
      eventExpiryDate: moment(e).format("Y-M-D"),
    });
    updateEventDate(event, moment(e).format("Y-M-D"), true)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Expiry date Updated",
        });
      })
      .catch((err) => {
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  function toggleWaterPreset() {
    setSelectedWatermarkPreset(!selectedWatermarkPreset);
  }

  function updateWatermark(id) {
    updateWatermarkEvent(event, id)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: "Watermark Updated Successfully",
        });
        setSelectedWatermarkPreset(false);
        setCurrentWatermarkPreset(
          watermarkPresets.filter((watermark) => watermark.id === id)[0]
        );
      })
      .catch((err) => {
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  }

  // below code closes the add tag options popup when clicked outside
  let waterMarkDropDownContainer = useRef();

  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!waterMarkDropDownContainer.current.contains(event.target)) {
        setSelectedWatermarkPreset(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  function RemoveWatermark(id) {
    if (event.isDownloadChargeable) {
      window.Toast.fire({
        icon: "error",
        title: "Disable Digital Downloads",
      });
      return;
    }
    updateWatermarkEvent(event, 0)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: "Watermark Updated Successfully",
        });
        setSelectedWatermarkPreset(false);
        setCurrentWatermarkPreset(
          watermarkPresets.filter((watermark) => watermark.id === id)[0]
        );
      })
      .catch((err) => {
        window.Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  }

  function updateChatBot(e) {
    if (user.user.photographer.show_chat_bot == 0) {
      window.Toast.fire({
        icon: "error",
        title: "First Enable ChatBot in General Settings",
      });
      return false;
    }

    setChatBot(!chatBot);
    updateEventChatBot(event, e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
        setChatBot(chatBot);
      });
  }

  function updateImageShare(e) {
    if (e) {
      if (event.isDownloadChargeable) {
        window.Toast.fire({
          icon: "error",
          title: "Disable Digital Downloads",
        });
        return;
      }
    }
    setShareImage(!shareImage);
    updateEventImageShare(event, e)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
        setShareImage(shareImage);
      });
  }

  function updateRequirePassword(e) {
    if (e) {
      if (_.isEmpty(passwordText) && _.isNull(passwordText)) {
        setPageErrors({
          ...pageErrors,
          password_text: "Password cannot be empty",
        });
        return false;
      }
      if (passwordText.length < 6) {
        setPageErrors({
          ...pageErrors,
          password_text: "Password must be atleast 6 characters",
        });
        return false;
      }
      updateEventData(event.id, {
        hasPassword: requiresPassword,
        password: passwordText,
      })
        .then(function (response) {
          window.Toast.fire({
            icon: "success",
            title: "Updated Successfully",
          });
          setRequiresPassword(e);
          setPasswordText(passwordText);
          setSuccess({ ...success, password_text: true });
        })
        .catch((error) => {
          window.Toast.fire({
            icon: "error",
            title: "Could Not Update",
          });
          setRequiresPassword(!e);
        });
    } else {
      setRequiresPassword(e);
      setPasswordText("");
      updateEventData(event.id, { hasPassword: e, password: "" })
        .then(function (response) {
          window.Toast.fire({
            icon: "success",
            title: "Updated Successfully",
          });
        })
        .catch((error) => {
          setRequiresPassword(!e);
          window.Toast.fire({
            icon: "error",
            title: "Could Not Update",
          });
          // setGeneralSettingRight({...generalSettingRight, chatBot: false});
        });
    }
  }

  const updateWhatsapp = async (value) => {
    setIsWhatsappEnabled(value);
    try {
      await updateEventWhatsapp(event.id, value);
      window.Toast.fire({
        icon: "success",
        title: "Whatsapp message settings updated",
      });
    } catch (error) {
      console.error(error);
      setIsWhatsappEnabled(!value);
      window.Toast.fire({
        icon: "error",
        title: "Unable to update whatsapp settings.",
      });
    }
  };

  return (
    <div className="mb-9">
      <>
        <div className="flex flex-row mt-[10px] mb-[40px] ">
          <div className="flex flex-col basis-1/2">
            <Text as="p" className="text-secondary-300 mb-2 font-medium">
              Event Date
            </Text>
            <div className="datepicker mb-3 mr-4">
              <DatePicker
                onChange={(e) => handleEventDate(e)}
                format={"dd-MM-y"}
                onKeyDown={(e) => e.preventDefault()}
                clearIcon={null}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                value={
                  generalSettingRight.eventDate
                    ? new Date(
                        generalSettingRight.eventDate.replaceAll("-", "/")
                      )
                    : null
                }
                className={`bg-dark-gray mt-1 text-light-gray px-2 w-full py-1.5 rounded-lg transition ease-in-out
                            ${
                              !generalSettingRight.eventDate
                                ? "shadow-input-empty"
                                : "shadow-input-filled"
                            }  
                            `}
              />
            </div>
          </div>
          <div className="flex flex-col basis-1/2">
            <div className="flex ">
              <Text
                as="p"
                className="text-secondary-300 mb-2 font-medium flex items-center"
              >
                Expiry date
              </Text>
              <a data-tip data-for="expiryDate">
                <img
                  className="cursor-pointer ml-2 w-6 h-6"
                  onClick={() => {
                    setOpenExpiryDateHelp(true);
                    document.body.style.overflow = "hidden";
                  }}
                  src={Information}
                  alt="Info Icon"
                />
              </a>
            </div>
            <ReactTooltip id="expiryDate" type="error">
              <span>
                Event gallery become inaccessible publicly post expiry date
              </span>
            </ReactTooltip>
            <div className="datepicker mb-3 pr-1">
              <DatePicker
                onChange={(e) => handleExpiryDate(e)}
                dayPlaceholder="dd"
                format={"dd-MM-y"}
                onKeyDown={(e) => e.preventDefault()}
                minDate={
                  generalSettingRight.eventDate
                    ? new Date(
                        generalSettingRight.eventDate.replaceAll("-", "/")
                      )
                    : new Date()
                }
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                value={
                  generalSettingRight.eventExpiryDate
                    ? new Date(generalSettingRight.eventExpiryDate)
                    : null
                }
                className={`bg-dark-gray mt-1 text-light-gray px-2 w-full py-1.5 rounded-lg transition ease-in-out
                            ${
                              !generalSettingRight.eventExpiryDate
                                ? "shadow-input-empty"
                                : "shadow-input-filled"
                            }  
                            `}
              />
            </div>
          </div>
        </div>

        {openExpiryDateHelp ? (
          <div className="fixed w-full z-10 h-screen top-0 left-0 bg-bb-500 flex items-center  ">
            <div className="w-1/2 max-w-[50rem] mx-auto bg-[#1a1a1a] py-5 px-7 rounded-xl">
              <div className="flex justify-between w-full">
                <h1 className="text-3xl font-medium mb-1">Warning!</h1>
                <a
                  className="text-3xl font-medium mb-1"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenExpiryDateHelp(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  X
                </a>
              </div>
              <p className="text-[1rem]">
                Event gallery won't be accessible after the expiry date
              </p>

              <div className="flex justify-center mt-10 mb-7">
                <button
                  className="rounded-lg dark:shadow-button font-semibold py-3 px-10 min-w-[150px] bg-button flex items-center justify-center"
                  onClick={(e) => {
                    setOpenExpiryDateHelp(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div id="event_password" className="text-xl mb-[50px]">
          <SwitchWithTitle
            className="mb-4 font-medium !max-w-[28rem]"
            title="Password Protection"
            subtitle={`${
              requiresPassword
                ? "Password is enabled for this event"
                : "Password is disable for this event"
            }`}
            value={requiresPassword}
            onChange={() => {
              requiresPassword == 1
                ? updateRequirePassword(0)
                : setRequiresPassword(true);
            }}
          />
          {requiresPassword ? (
            <div className=" mt-2 w-full">
              <InputEditable
                type="text"
                placeholder="Enter password"
                name="password_text"
                value={passwordText}
                onChange={(e) => {
                  setPasswordText(e.target.value.replace(/[^a-zA-Z0-9-]/g, ""));
                  setPageErrors({ ...pageErrors, password_text: "" });
                }}
                error={pageErrors.password_text}
                onUpdate={(e) => updateRequirePassword(1)}
                required={true}
                success={success.password_text}
              />
              {requiresPassword ? (
                <p className="text-sm mt-2 col-span-2 text-[#aaaaaa]">
                  This password needs to be letter or numbers with a minimum 6
                  characters
                </p>
              ) : null}
            </div>
          ) : null}
        </div>

        <div id="watermark" className="text-xl mb-[20px]">
          <Text as="p" className="font-medium">
            Watermark
          </Text>
          <Text as="p" className="text-sm my-4">
            Go to{" "}
            <Link
              className="text-primary-green text-xs font-semibold underline pl-1 pr-2 font-sm"
              to={"/general-settings"}
            >
              General Settings
            </Link>
            to edit watermark settings.
          </Text>
        </div>

        {!_.isEmpty(watermarkPresets) ? (
          <div className="mb-[50px]">
            <Text as="p" className="font-medium">
              Select watermark preset
            </Text>
            <div
              className="relative max-w-[28rem]"
              ref={waterMarkDropDownContainer}
            >
              <Select
                onClick={() => toggleWaterPreset()}
                text={
                  currentWatermarkPreset
                    ? currentWatermarkPreset.title
                    : "Select Watermark Preset"
                }
                className="mt-4"
                isOpen={selectedWatermarkPreset}
              />

              {
                <div className="absolute w-full rounded-[10px] overflow-hidden bg-dark-gray shadow-card z-40 right-15 mt-3 top-[99%]">
                  <div className="flex select-none flex-col text-light-gray text-sm">
                    {selectedWatermarkPreset &&
                      watermarkPresets.map(function (preset, key) {
                        let {
                          id,
                          title,
                          logoUrl,
                          logoText,
                          font,
                          fontColor,
                          scale,
                          opacity,
                          xOffset,
                          yOffset,
                          isDefault,
                          landscapeImagePosition,
                          portraitImagePosition,
                        } = preset;

                        return (
                          <div
                            key={id}
                            onClick={() => {
                              updateWatermark(id);
                            }}
                            className="cursor-pointer capitalize px-4 py-3 hover:bg-black hover:text-primary-green"
                          >
                            {title}
                          </div>
                        );
                      })}
                    {selectedWatermarkPreset && (
                      <div
                        onClick={() => {
                          RemoveWatermark();
                        }}
                        className="cursor-pointer px-4 py-3 capitalize hover:bg-black hover:text-primary-error-red border-t-2 border-zinc-700 hover:bg-black"
                      >
                        Remove Watermark
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        ) : (
          <div className="text-light-gray mt-3 shadow-card pr-5 rounded-[8px] bg-dark-gray text-xs flex items-center">
            <span className="bg-primary-error-red rounded-l-[8px] p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <span className="pl-2.5">You do not have any watermarks</span>
          </div>
        )}
        {/* <div
          id="print-store"
          className={`${
            _.isEmpty(watermarkPresets) ? "mt-[50px]" : "mt-[10px]"
          } my-[10px]`}
        >
          <SwitchWithTitle
            className="mb-8 font-medium !max-w-[28rem]"
            title="Chat Bot"
            subtitle={
              user.user.photographer.show_chat_bot ? (
                chatBot ? (
                  "Chat Bot is enabled for this event"
                ) : (
                  "Chat Bot is disabled for this event"
                )
              ): (
                <span>
                  Enable Chat Bot in{" "}
                  <Link
                    className="text-primary-green text-xs font-semibold underline pl-1 pr-2 font-sm"
                    to={"/general-settings"}
                  >
                    General Settings
                  </Link>{" "}
                  to access it here.
                </span>
              )
            }
            disable={!user.user.photographer.show_chat_bot}
            value={chatBot}
            onChange={() => {
              chatBot == 1 ? updateChatBot(0) : updateChatBot(1);
            }}
          />
        </div> */}

        <div
          id="print-store"
          className={`${
            _.isEmpty(watermarkPresets) ? "mt-[50px]" : "mt-[10px]"
          } my-[10px]`}
        >
          <SwitchWithTitle
            className="mb-8 font-medium !max-w-[28rem]"
            title="Image Share"
            subtitle={` Share option  is ${
              shareImage ? "displayed" : "hidden"
            } on gallery`}
            value={shareImage}
            onChange={() => {
              shareImage == 1 ? updateImageShare(0) : updateImageShare(1);
            }}
          />
        </div>

        {user?.user?.photographer?.whatsapp_template_id ||
        (user?.user?.photographer?.is_whatsapp_by_algomage &&
          user?.user?.subscription?.next_sub &&
          new Date(user?.user?.subscription?.next_sub) > new Date()) ? (
          <div id="enable-whatsapp">
            <SwitchWithTitle
              className="mb-8 font-medium !max-w-[28rem]"
              title="Enable Whatsapp"
              subtitle={`Send Registration & Face Search Messages on Whatsapp`}
              value={isWhatsappEnabled}
              onChange={() => {
                isWhatsappEnabled
                  ? updateWhatsapp(false)
                  : updateWhatsapp(true);
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default GeneralSectionRight;
