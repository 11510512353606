import React from "react";
import UploadContent from "../UploadContent";
import {EventProvider} from "../../Store/Event/Show";
import {FolderProvider} from "../../Store/Folder/Show";
import { UploaderProvider } from "../../Store/Uploader";


export default function () {
    return (
        <EventProvider>
            <FolderProvider>
                    <UploadContent />
            </FolderProvider>
        </EventProvider>
    );
}
