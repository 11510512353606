import React, { useState } from "react";
import DownArrow from "../../../../assets/icons/DownArrow";
import Text from "../../../../components/common/Text";
import { useNavigate } from 'react-router-dom';
import {useUser} from "../../../../Store/User";

function AnalyticsTitleSection({eventId,page}) {
    const navigate = useNavigate(),
        {dashboardUrl} = useUser();
    return (
        <div className='flex items-center h-[4.6rem] shadow-header'>
            <div className='flex items-center w-full'>
                <span
                    className='flex min-w-[20rem] px-10 mr-6 items-center font-medium  text-primary-green text-base font-semibold underline'
                    
                >   
                    <div className="flex items-center cursor-pointer" onClick={() => eventId ? navigate(`/events/${eventId}`) : navigate(dashboardUrl)} >
                    <DownArrow className="rotate-90 mr-2.5" color="#00b7b8" />
                    <button className="text-base font-semibold">{eventId ? 'Go Back to Event' : 'Go Back'}</button>
                    </div>
                </span>
                <Text
                    as='h1'
                    className='flex text-3xl  text-light-gray lg:basis-5/6 font-medium'
                >
                    {page.name}
                </Text>
            </div>
        </div>
    );
}

export default AnalyticsTitleSection;
