import React from "react";

const ThumbnailSmall = ({galleryLayout}) => {
    return (
        <svg width='104px' height='104px' viewBox='0 0 104 104' version='1.1'>
            <title>9D8BF044-46BA-47D4-8C25-BD01B79231A2</title>
            <g
                id='Event-Settings'
                stroke='none'
                strokeWidth='1'
                fill='none'
                stroke={galleryLayout.thumbnailSize  =='small' ? '#00B7B8' :'#555555'}
                fillRule='evenodd'
            >
                <g
                    id='Algodistribute_EventSettings_02'
                    transform='translate(-359.000000, -2083.000000)'
                    stroke={galleryLayout.thumbnailSize  =='small' ? '#00B7B8' :'#555555'}
                >
                    <g
                        id='Group-18'
                        transform='translate(359.000000, 2083.000000)'
                    >
                        <g id='Group-14-Copy-2'>
                            <rect
                                id='Rectangle-Copy-13'
                                x='0.5'
                                y='0.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-14'
                                x='18.5'
                                y='0.5'
                                width='14'
                                height='9'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-45'
                                x='18.5'
                                y='13.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-15'
                                x='36.5'
                                y='0.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-16'
                                x='0.5'
                                y='24.5'
                                width='14'
                                height='25'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-19'
                                x='18.5'
                                y='37.5'
                                width='14'
                                height='13'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-21'
                                x='36.5'
                                y='44.5'
                                width='14'
                                height='6'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-23'
                                x='36.5'
                                y='24.5'
                                width='14'
                                height='16'
                                rx='1'
                            ></rect>
                        </g>
                        <g
                            id='Group-14-Copy-4'
                            transform='translate(0.000000, 53.000000)'
                        >
                            <rect
                                id='Rectangle-Copy-13'
                                x='0.5'
                                y='1.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-14'
                                x='18.5'
                                y='0.5'
                                width='14'
                                height='9'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-45'
                                x='18.5'
                                y='13.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-15'
                                x='36.5'
                                y='1.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-16'
                                x='0.5'
                                y='25.5'
                                width='14'
                                height='25'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-19'
                                x='18.5'
                                y='37.5'
                                width='14'
                                height='13'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-21'
                                x='36.5'
                                y='44.5'
                                width='14'
                                height='6'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-23'
                                x='36.5'
                                y='25.5'
                                width='14'
                                height='16'
                                rx='1'
                            ></rect>
                        </g>
                        <g
                            id='Group-14-Copy-3'
                            transform='translate(53.000000, 0.000000)'
                        >
                            <rect
                                id='Rectangle-Copy-13'
                                x='0.5'
                                y='0.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-14'
                                x='18.5'
                                y='0.5'
                                width='14'
                                height='9'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-45'
                                x='18.5'
                                y='13.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-15'
                                x='36.5'
                                y='0.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-16'
                                x='0.5'
                                y='24.5'
                                width='14'
                                height='25'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-19'
                                x='18.5'
                                y='37.5'
                                width='14'
                                height='13'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-21'
                                x='36.5'
                                y='44.5'
                                width='14'
                                height='6'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-23'
                                x='36.5'
                                y='24.5'
                                width='14'
                                height='16'
                                rx='1'
                            ></rect>
                        </g>
                        <g
                            id='Group-14-Copy-5'
                            transform='translate(53.000000, 53.000000)'
                        >
                            <rect
                                id='Rectangle-Copy-13'
                                x='0.5'
                                y='1.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-14'
                                x='18.5'
                                y='0.5'
                                width='14'
                                height='9'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-45'
                                x='18.5'
                                y='13.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-15'
                                x='36.5'
                                y='1.5'
                                width='14'
                                height='20'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-16'
                                x='0.5'
                                y='25.5'
                                width='14'
                                height='25'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-19'
                                x='18.5'
                                y='37.5'
                                width='14'
                                height='13'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-21'
                                x='36.5'
                                y='44.5'
                                width='14'
                                height='6'
                                rx='1'
                            ></rect>
                            <rect
                                id='Rectangle-Copy-23'
                                x='36.5'
                                y='25.5'
                                width='14'
                                height='16'
                                rx='1'
                            ></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ThumbnailSmall;
