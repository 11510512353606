/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useEvent } from "../Event/Show";
import { getEventDetails, getImages } from "../../apis/index";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useUploader } from "../Uploader";

const Folder = React.createContext();
Folder.displayName = "Folder";

var merge = (a, b, key) =>{
  const newImages = a.filter((aa) => !b.find((bb) => aa[key] === bb[key]))
  return [...b , ...newImages]
}

export const youtube_parser = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export const useFolder = () => React.useContext(Folder);

export const FolderProvider = ({ children }) => {
  let { event } = useEvent();
  let currView = localStorage.getItem("defaultView") || "tile";
  let { completedImages } = useUploader();
  let [folder, setFolder] = useState();
  let [imageSortField, setImageSortFields] = useState("");
  let [imageSortCond, setImageSortCond] = useState("");
  let [imageFilters, setImageFilters] = useState(null);
  let [folderId, setFolderId] = useState();
  let [currentView, setCurrentView] = useState(currView);
  const [images, setImages] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    if (
      images !== null &&
      completedImages.length > 0 &&
      folder &&
      folder.id === completedImages[completedImages.length - 1].folderId
    ) {
      setImages(merge(completedImages, images, "originalImageName"));
    }
  }, [completedImages]);

  useEffect(async () => {
    if (event.id) {
      if (folderId) {
        let folders = event.folders.filter(
          (folder) => folder.id === parseInt(folderId)
        );
        if (folders.length < 1) {
          setFolderId(event.folders[0].id);
          setFolder(event.folders[0]);
          setImageSortCond(event.folders[0]?.imageSortCondition);
          setImageSortFields(event.folders[0]?.imageSortField);
          setImages(null)
          return;
        }
        setFolder(folders[0]);
        setImageSortCond(folders[0]?.imageSortCondition);
        setImageSortFields(folders[0]?.imageSortField);
      } else {
        setFolderId(event.folders[0].id);
        setFolder(event.folders[0]);
        setImageSortCond(event.folders[0]?.imageSortCondition);
        setImageSortFields(event.folders[0]?.imageSortField);
        setImages(null)
      }
    }
  }, [event, folderId]);

  useEffect(async () => {
    if (folderId > 0 && images === null) {
      const response = await getImages(folderId, imageSortField, imageSortCond);
      const imagesFromAPI = response.data;
      const guestObject = {};
      if(response.guests){
        response.guests.forEach((el) => (guestObject[el.id] = el.email));
      }
      setGuests(guestObject);
      let imagesArray = imagesFromAPI.map((i) => {
        if (i.isVideo) {
          if (i.src.indexOf("vimeo") > -1) {
            i.orginalImageUrl =
              decodeURIComponent(i.src).replace("vimeo", "vumbnail") +
              "_large.jpg";
            i.height = 360;
            i.width = 640;
          } else {
            i.orginalImageUrl = `https://img.youtube.com/vi/${youtube_parser(
              decodeURIComponent(i.src)
            )}/0.jpg`;
            i.height = 360;
            i.width = 480;
          }
          i.src = decodeURIComponent(i.src);
        } else {
          i.orginalImageUrl = i.url;
          i.src += "?v=" + i.version + "&w=1600";
          i.height = Number(i.height);
          i.width = Number(i.width);
        }
        return i;
      });
      if (!_.isEmpty(searchTerm))
        imagesArray = imagesArray.filter((i) =>
          i.originalImageName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      if (imageFilters === "private")
        imagesArray = imagesArray.filter((i) => i.isPrivate === 1);
      else if (imageFilters === "public")
        imagesArray = imagesArray.filter((i) => i.isPrivate === 0);
      else if (imageFilters === "videos")
        imagesArray = imagesArray.filter((i) => i.isVideo === 1);

      setImages(imagesArray);
    }
  }, [folder, images, imageSortField, imageSortCond, imageFilters, searchTerm]);

  return (
    <Folder.Provider
      value={{
        folder,
        images,
        setImages,
        setFolderId,
        setImageSortFields,
        setImageSortCond,
        imageSortCond,
        imageSortField,
        imageFilters,
        setImageFilters,
        currentView,
        setCurrentView,
        setSearchTerm,
        searchTerm,
        guests,
        setGuests
      }}
    >
      {children}
    </Folder.Provider>
  );
};
