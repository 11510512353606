/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import Hamburger from "../../../assets/icons/HamburgerIcon";
import LogoAlgo from "../../../assets/images/algomagelogo.svg";
import LogoAlgoShare from "../../../assets/images/algosharelogo.svg";
import { getNotifications } from "../../../apis";
import { useUser } from "../../../Store/User";
import NavAvatarDropDown from "../DropDown/NavAvatarDropDown";
import BellIcon from "../../../assets/bell_icon.webp";
import QuestionMarkIcon from "../../../assets/icons/QuestionMarkIcon";
import { useHelpContext } from "../../../Store/Help";

const NavbarAvatar = () => {
  const { user, setUser, refreshAccessToken } = useUser();
  const location = useLocation();
  const { setShowSteps } = useHelpContext();

  const [showProfileOption, setShowProfileOption] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showCloseableNotification, setShowCloseableNotification] =
    useState(true);

  let notificationDropDownContainer = useRef();
  // function to close the dropdown when clicked outside

  useEffect(() => {
    let handler = (event) => {
      if (!notificationDropDownContainer.current.contains(event.target)) {
        setShowNotification(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onPathChange = async () => {
    if (location.pathname === "/profile") {
      await refreshAccessToken();
    } else {
      setShowSteps(false);
      const notifications = await getNotifications();
      setUser(prev=>({
        ...prev,
        notifications: notifications.data,
        permissions: notifications.permissions,
      }));
    }
  };

  useEffect(onPathChange, [location]);

  return (
    <div>
      <div className="navbar__text-dark text-light-gray py-5 shadow-header text-center">
        <div className="px-10 flex flex-wrap justify-between items-center mx-auto">
          <div>
            <div className="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mr-1">
              <Link to="/">
                <img
                  src={LogoAlgo}
                  alt="Logo"
                  style={{ height: "2.5rem" }}
                  className="cursor-pointer"
                />
              </Link>
              <span className="border-neutral-600 border-l-2 mx-[1.875rem]">
                &nbsp;
              </span>

              <span className="font-medium">
                <img src={LogoAlgoShare} width="40" height="20" alt="Logo" />
              </span>
            </div>
          </div>
          <div className="flex justify-end items-center md:order-2">
            <button onClick={() => setShowSteps(true)} className="mr-4">
              <QuestionMarkIcon />
            </button>
            <div
              onClick={() => {
                setShowNotification(!showNotification);
              }}
              className="relative cursor-pointer"
              ref={notificationDropDownContainer}
            >
              <img src={BellIcon} className="w-[1.5rem]" alt="" />
              {showNotification && user.notifications.length > 0 && (
                <div className="absolute rounded-lg bg-dark-gray shadow-dark-input z-[9999] w-[22.5rem] right-1 top-[99%] mt-5 px-5 py-[1.8rem]">
                  <div className="flex flex-col justify-start items-start ">
                    <div className="flex justify-center items-center gap-x-1">
                      <p className="capitalize font-poppins text-primary-green font-medium">
                        Inbox
                      </p>
                      <span className=" rounded-full w-2 h-2 mr-2 bg-primary-green"></span>
                    </div>
                    {/* <p className="mt-6 text-xs text-white" >Unread Message</p> */}
                    {user.notifications.map((notification, index) => {
                      return (
                        <div
                          className="border-b border-zinc-700 pb-4 text-left"
                          key={index + notification}
                        >
                          <p className="font-poppins font-medium text-sm text-secondary-300 mt-5">
                            {notification.message}
                          </p>
                          <Link
                            to={notification.url.replace(
                              "https://dashboard.algomage.com",
                              ""
                            )}
                            className="text-xs font-poppins font-semibold underline text-primary-green"
                          >
                            {notification.actionText}
                          </Link>
                          <p className="text-xs text-secondary-600 mt-1">
                            {notification.date}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <a
              href="https://zfrmz.com/9NybGxuWImtRCYZy8kZF"
              target="_blank"
              className="font-medium text-sm px-5 py-2.5 text-center hidden md:block lg:block"
              rel="noreferrer"
            >
              Contact Us
            </a>
            <a
              href="https://help.algomage.ai"
              target="_blank"
              className="font-medium text-sm px-5 py-2.5 text-center hidden md:block lg:block"
              rel="noreferrer"
            >
              Help
            </a>

            <div
              id="profile-menu"
              onClick={(e) => {
                setShowProfileOption(!showProfileOption);
              }}
              className="flex relative flex-gap avatar items-center cursor-pointer"
            >
              <div className="pl-2">
                {/* <DownArrow rotate={showProfileOption && true} /> */}
                <NavAvatarDropDown />
              </div>
              {/* {showProfileOption && <div className='absolute rounded-lg bg-dark-gray shadow-dark-input z-40 w-[200px] right-10 top-[99%]'>
                                <div className='flex select-none flex-col gap-3 text-light-gray text-sm'>
                                    <Link to="/profile" className="cursor-pointer capitalize px-4 py-2 hover:bg-black">
                                        Show Profile
                                    </Link>
                                    <div onClick={(e)=>{logoutUser(e)}} className="cursor-pointer capitalize px-4 py-2 hover:bg-black">
                                        Logout
                                    </div>
                                </div>
                            </div>} */}
            </div>
            <button
              data-collapse-toggle="mobile-menu-4"
              type="button"
              className="inline-flex items-center p-2 text-sm md:hidden"
              aria-controls="mobile-menu-4"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <Hamburger></Hamburger>
            </button>
          </div>
        </div>
      </div>
      <div className="w-[70%] mx-auto">
        <div className="fixed top-20 z-[999]">
          <>
            {user.notifications && user.notifications.length > 0
              ? user.notifications.map((notification) => {
                  return (
                    <div key={notification.message}>
                      {notification.type === "danger" ? (
                        <>
                          <div
                            className={`text-light-gray mt-3 shadow-card pr-5 rounded-[8px] bg-dark-gray text-xs flex items-center justify-between  pointer-events-auto`}
                          >
                            <span className="bg-primary-error-red rounded-l-[8px] p-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>

                            <span className="text-base pl-2.5 pr-10">
                              {notification.message}
                            </span>
                            <a
                              className="underline"
                              href={notification.url.replace(
                                "https://dashboard.algomage.com",
                                ""
                              )}
                            >
                              {notification.actionText}
                            </a>
                          </div>
                        </>
                      ) : (notification.type === "closeable" ||
                          notification.type === "imp") &&
                        showCloseableNotification == true ? (
                        <>
                          <div
                            className={`text-light-gray mt-3 shadow-card pr-5 rounded-[8px] bg-dark-gray text-xs flex justify-between items-center pointer-events-auto`}
                          >
                            {notification.icon === "info" ? (
                              <span className="bg-primary-green rounded-l-[8px] p-6">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                  />
                                </svg>
                              </span>
                            ) : (
                              <span className="bg-primary-error-red rounded-l-[8px] p-6">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-7 w-7"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </span>
                            )}

                            <span className="text-base pl-2.5 pr-10">
                              {notification.message}
                            </span>
                            <a
                              className="underline mr-4"
                              href={notification.url.replace(
                                "https://dashboard.algomage.com",
                                ""
                              )}
                            >
                              {notification.actionText}
                            </a>
                            <span
                              onClick={() => {
                                setShowCloseableNotification(false);
                              }}
                              className="cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-7 h-7"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </>
        </div>
      </div>
    </div>
  );
};

export default NavbarAvatar;
