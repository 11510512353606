import { createContext, useContext, useState } from "react";

const HelpContext = createContext();

export const useHelpContext = () => useContext(HelpContext);

export const HelpContextProvider = ({ children }) => {
  const [showSteps, setShowSteps] = useState(false);

  return (
    <HelpContext.Provider value={{ showSteps, setShowSteps }}>
      {children}
    </HelpContext.Provider>
  );
};
