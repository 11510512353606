import Facebook from "../../../../assets/images/Facebook-icon.png";
import Instagram from "../../../../assets/images/Instagram-icon.png";
import Phone from "../../../../assets/images/Phone-icon.png";
import Youtube from "../../../../assets/images/Youtube-icon.png";
import Vimeo from "../../../../assets/images/Vimeo-icon.png";
import Mail from "../../../../assets/images/Mail-icon.png";
import React, {useEffect, useState} from "react";
import {getPortfolioDetails} from "../../../../apis";
import _ from "lodash";
import {useUser} from "../../../../Store/User";
import { OG_IMAGE_CDN } from "../../../../constants";

const IPhoneTemplate = () => {
    const {user, setUser} = useUser();
    const [about, setAbout] = useState('');
    const [appName, setAppName] = useState('');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        getPortfolioDetails().then(res => {
            let imagesArray = []
            let videoArray = []
            res.map((item, index) => {
                if (item.isVideo === 0) {
                    imagesArray.push({src: item.url, width: 100, height: 100, order: item.order, id: item.id})
                } else {
                    videoArray.push({src: item.thumbnailUrl, width: 100, height: 100, order: item.order, id: item.id})
                }
            });
            setImages(imagesArray)
            setVideos(videoArray)
        });

        !_.isEmpty(user) && setAppName(user.photographer.mobile_app_name ? user.photographer.mobile_app_name : user.photographer.brand_name);
        !_.isEmpty(user) && setAbout(user.photographer.about);

    }, []);

    return <div className="max-w-[336px]">
        <h2 className="text-base text-secondary-300 text-center">{appName ?? 'Mobile App'}</h2>
        <div className="marvel-device iphone-x max-w-[17.75rem] max-h-[38rem] mt-[0.75rem]">
            <div className="notch !left-[66px]">
                <div className="camera"></div>
                <div className="speaker"></div>
            </div>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="bottom-bar"></div>
            <div className="volume"></div>
            <div className="overflow">
                <div className="shadow shadow--tr"></div>
                <div className="shadow shadow--tl"></div>
                <div className="shadow shadow--br"></div>
                <div className="shadow shadow--bl"></div>
            </div>
            <div className="inner-shadow"></div>
            <div className="screen !bg-transparent">
                <div className="pt-7 w-full h-full rounded-[10px] overflow-hidden bg-secondary-800">
                    <div className="shadow-header p-2">
                        {user.photographer.logo_url ?
                            <img src={OG_IMAGE_CDN + user.photographer.logo_url}
                                 className='w-1/6'/>
                            :
                            "Your Logo Here"}
                    </div>
                    <div className="relative h-[12.375rem] overflow-hidden">
                        <img
                            src={images[0] ? images[0].src : 'https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg?w=440'}/>
                        <div className="absolute w-full h-10 bottom-0 bg-black opacity-50 blur-md"></div>
                        <p className="absolute w-full bottom-2 px-2 text-lg">
                            {appName ? appName : 'App Name'}
                        </p>
                    </div>
                    <div className="flex justify-between bg-black p-2 mt-2">
                        <img src={Facebook} width="30px" alt="Facebook"/>
                        <img src={Instagram} width="30px" alt="Instagram"/>
                        <img src={Youtube} width="30px" alt="Youtube"/>
                        <img src={Vimeo} width="30px" alt="Vimeo"/>
                        <img src={Mail} width="30px" alt="Mail"/>
                        {/*<img src={Phone} width="30px" alt="Phone"/>*/}
                    </div>
                    <h2 className="mt-2 text-xs px-2">About Us</h2>
                    <p className="text-xs text-left mt-2 px-2">
                        {about ? about.substring(0, 125) + "..." : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'}
                        <span className="block underline text-primary-green">Read more</span>
                    </p>
                    <div className="flex justify-between mt-4 px-6">
                        <p className="flex text-xs">
                            <span className="text-primary-green">Images</span>
                            {images.length ?
                                <span
                                    className='flex rounded-md justify-center px-2 items-center text-xs bg-black-gray min-w-4 h-[1.15rem] ml-2'>{images.length}</span> : ''}
                        </p>
                        <p className="flex text-xs">
                            Videos
                            {videos.length ?
                                <span
                                    className='flex rounded-md justify-center px-2 items-center text-xs bg-black-gray min-w-4 h-[1.15rem] ml-2'>{videos.length}</span> : ''}
                        </p>
                    </div>
                    {/* Row - 1 */}
                    <div className="flex justify-between mt-4 px-2">
                        <div className="relative w-[48%] h-0 pb-[6.5rem]">
                            <img
                                className="absolute w-full h-full inset-0 object-cover shadow-card rounded-[10px]"
                                src={images[0] ? images[0].src : 'https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg?w=440'}
                            />
                        </div>
                        <div className="relative w-[48%] h-0 pb-[6.5rem]">
                            <img
                                className="absolute w-full h-full inset-0 object-cover shadow-card rounded-[10px]"
                                src={images[1] ? images[1].src : 'https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg?w=440'}
                            />
                        </div>
                    </div>
                    {/* Row - 2 */}
                    <div className="flex justify-between mt-4 px-2">
                        <div className="relative w-[48%] h-0 pb-[6.5rem]">
                            <img
                                className="absolute w-full h-full inset-0 object-cover shadow-card rounded-[10px]"
                                src={images[2] ? images[2].src : 'https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg?w=440'}
                            />
                        </div>
                        <div className="relative w-[48%] h-0 pb-[6.5rem]">
                            <img
                                className="absolute w-full h-full inset-0 object-cover shadow-card rounded-[10px]"
                                src={images[3] ? images[3].src : 'https://picsniff.s3.ap-south-1.amazonaws.com/assets/images/banners/dummy.jpg?w=440'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};


export default IPhoneTemplate;
