import React, {useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import NavbarAvatar from "./NavbarAvatar";
import NavbarAdmin from "./NavbarAdmin";
import {useUploader} from "../../Store/Uploader";
import BottomUploadModal from "../../pages/UploadContent/components/BottomUploadModal";

function WithNavBar() {
    const location = useLocation();
    const {showUploader} = useUploader();
    const urls = ['/login', '/register', '/forgot-password', '/change-password'];
    return (
        <div className="bg-dark-gray dark text-white min-h-screen container mx-auto shadow-card">
            <div>
                {urls.includes(location.pathname) ? <NavbarAdmin/> : <NavbarAvatar/>}
                <div>
                    <Outlet/>
                </div>
            </div>
            {showUploader ? <BottomUploadModal/> : <></>}
        </div>


    );
}

export default WithNavBar;
