import React from "react";
import Swal from "sweetalert2";
import { postDeleteAll } from "../../../../apis";

import { deleteImg, flag } from "../../../../apis/upload";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EyeSlashIcon from "../../../../assets/icons/EyeSlashIcon";
import { useFolder } from "../../../../Store/Folder/Show";

const Bulkactions = ({ selectedImages, setSelectedImages, eventId, slug }) => {
  let { folder, images, setImages } = useFolder();

  const handleBulkDelete = () => {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want permanently delete ${selectedImages.length} images?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if (folder.imagesCount === selectedImages.length) {
          const payload = {
            folderId: folder.id,
            imageCount: folder.imagesCount,
            eventId,
            slug,
          };
          return postDeleteAll(payload)
            .then(() => {
              window.Toast.fire({
                icon: "success",
                title:
                  folder.imagesCount > 1000
                    ? "All images will be deleted in few minutes"
                    : "All images deleted successfully",
              });
              setImages([]);
              setSelectedImages([]);
            })
            .catch((error) => {
              window.Toast.fire({
                icon: "error",
                title: "Folder could not be deleted",
              });
            });
        } else {
          return deleteImg(selectedImages, folder.id, eventId)
            .then((res) => {
              if ([200, 201, 204].includes(res.status)) {
                window.Toast.fire({
                  icon: "success",
                  title:
                    selectedImages.length > 999
                      ? "First 999 images deleted successfully"
                      : "Images deleted successfully",
                });
                let newImages = [];
                if (selectedImages.length > 999) {
                  const selectedImagesCopy = selectedImages.slice(0, 999);
                  newImages = images.filter(
                    (i) => !selectedImagesCopy.includes(i.id)
                  );
                } else {
                  newImages = images.filter(
                    (i) => !selectedImages.includes(i.id)
                  );
                }
                setImages([...newImages]);
                setSelectedImages([]);
              } else {
                window.Toast.fire({
                  icon: "error",
                  title: "Image could not be deleted",
                });
              }
            })
            .catch((error) => {
              window.Toast.fire({
                icon: "error",
                title: "Image could not be deleted",
              });
            });
        }
      },
    });
  };

  const handleBulkPrivate = () => {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want change privacy for ${selectedImages.length} images?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Change`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let res = await flag([...selectedImages], 1, folder.id, eventId);
        if (res.status >= 200 && res.status <= 204) {
          setImages(null);
          window.Toast.fire({
            icon: "success",
            title: `Successfully changed privacy of ${selectedImages.length} images`,
          });
        } else {
          window.Toast.fire({
            icon: "error",
            title: `Unable to change privacy of ${selectedImages.length} images.`,
          });
        }
      },
    });
  };

  return (
    <div>
      <div className="fixed flex w-full bottom-2 left-0 right-0 h-[100px] mx-auto">
        <div className="min-w-[20rem] pr-6 pb-20"></div>
        <div className="flex-col flex w-full ml-5 mr-10">
          <div className="bg-dark-gray mx-auto w-[95%] content-start items-center rounded-lg p-6 grid grid-cols-4 gap-4 shadow-button">
            <div className="col-span-2 whitespace-nowrap flex items-center justify-items-center">
              <span>
                {selectedImages.length} Image
                {selectedImages.length > 1 ? "s" : ""} Selected{" "}
              </span>
              <span
                onClick={() => {
                  setSelectedImages(images.map((i) => i.id));
                }}
                className="text-sm select-none cursor-pointer text-center text-light-gray shadow-button-new rounded-lg py-[9px] px-[16px] font-semibold w-[11rem] h-[40px] mx-4"
              >
                Select All
              </span>
              <span
                className="cursor-pointer underline text-primary-green text-sm whitespace-nowrap"
                onClick={() => {
                  setSelectedImages([]);
                }}
              >
                De-Select All
              </span>
            </div>
            <div className="flex items-center">
              <span className="h-[1.5rem] mr-2.5 block border-l-2 border-secondary-600">
                {" "}
              </span>
              {/* <span onClick={() => setShowMoveFolder(true)}
                                className="text-sm select-none cursor-pointer text-center text-light-gray shadow-button-new rounded-lg py-[9px] px-[16px] font-semibold w-[11rem] h-[40px] mx-4">Move To</span> */}
            </div>
            <div className="flex justify-end space-x-4">
              <span
                className="cursor-pointer"
                title="Mark images as private"
                onClick={handleBulkPrivate}
              >
                <EyeSlashIcon width={24} height={24} />
              </span>
              <span
                className="cursor-pointer"
                title="Delete images permanently"
                onClick={handleBulkDelete}
              >
                <DeleteIcon width={24} height={24} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bulkactions;
