import React, {useRef, useState} from "react";
import "./styles/input-file.scss";

export const InputFile = ({className, overlapLabel, label, fileName, onFileChange, onFileDelete, allowedFileTypes,name,disabled,deleteBtn=false}) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const file = event.target.files[0];
        // console.log(file)
        // setImage(URL.createObjectURL(event.target.files[0]))
        onFileChange(file);
    };

    const handleDelete = () => {
        onFileDelete();
    }

    return (
        <div className={`${className} max-w-[28rem]`}>
            <div className="flex justify-between" >
                <p className="text-secondary-300 text-base mb-4 font-medium">{label}</p>
                {deleteBtn && <p className="text-red-400 cursor-pointer text-sm mb-4 font-medium" onClick={()=>{handleDelete()}}>Delete</p>}
            </div>
            <div
                className="flex justify-between rounded-xl  bg-transparent px-4 py-2 shadow-inner-all text-light-gray">
                <input
                    name={name}
                    ref={hiddenFileInput}
                    className="hidden"
                    type="file"
                    disabled={disabled ? disabled : false}
                    accept={allowedFileTypes ?? ".jpg, .jpeg"}
                    onChange={handleChange}
                />
                <div className="truncate w-1/2 inline-block">{fileName ?? 'No file selected.'}</div>
                <div>
                <span onClick={handleClick}
                      className="text-primary-green cursor-pointer text-sm underline">
                    {fileName ? 'Change' : overlapLabel}
                </span>
                </div>
            </div>
        </div>
    );
};
