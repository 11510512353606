/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import {Link,useNavigate, useParams} from "react-router-dom";
// import {  } from "react-router-dom";

import {useEffect, useState} from "react";
import EventsMore from "./Popups/EventsMore/index";
import {getAllTags, getEventImagesCount, updateEventTag} from "../../apis";
import {InputEmail, InputText} from "../../components/common/Input";
import {copyText} from "../../functions";


export default function ({
                             event,
                             tags,
                             setTags,
                             selectedEvent,
                             setSelectedEvent,
                             showAddTagOptions,
                             setShowAddTagOptions,
                             updateEvents
                         }) {
    const [eventImagesCount, setEventImagesCount] = useState(null),
        [showAddTag, setShowAddTag] = useState(false),
        [showAddTagInput, setShowAddTagInput] = useState(false),
        [newTag, setNewTag] = useState(''),
        [openCopyEventCode,setOpenCopyEventCode] = useState(false);
    
        // console.log('tags',tags)

    useEffect(async () => {
        getEventImagesCount(event).then((response) => {
            setEventImagesCount(response);
        }).catch((error) => {
        });
    }, [])

    const eventSelected = e => {
        if (selectedEvent === event.id)
            setSelectedEvent(0)
        else
            setSelectedEvent(event.id)
    };

    const showAddTagOptionsFunc = e => {
        if (showAddTagOptions == event.id) {
            setShowAddTagOptions(0)
        } else {
            setShowAddTagOptions(event.id)
        }
    }


    const addTag = (e, eventId) => {
        setShowAddTagInput(!showAddTagInput);
    }
    const navigate = useNavigate();
    const {current_id} = useParams();

    //function to add new customize tag
    
    
    const updateNewTag = (e, eventId) => {
        if (newTag == '' || newTag == null) {
            window.Toast.fire({
                icon: 'error',
                title: 'Tag Name cannot be Empty'
            });
            return;
        }
        let randomColor = "#" + ('00000'+(Math.random()*(1<<24)|0).toString(16)).slice(-6);
        updateEventTag(eventId, newTag, randomColor).then((response) => {
            window.Toast.fire({
                icon: 'success',
                title: 'Tag Added Successfully'
            });
            getFreshTags();
            event.tags = [{title: newTag, colour: randomColor}];
            setShowAddTag(false);
            setShowAddTagInput(false);
            setNewTag('');
        }).catch((error) => {
            window.Toast.fire({
                icon: 'error',
                title: 'Could not add the tag'
            });
        });
    }
    

    function getFreshTags() {
        getAllTags().then(response => {
                setTags(response.filter(tag => !['Wedding', 'Birthday', 'Corporate'].includes(tag.title)))
            }
        ).catch(errors => {

        });
    }
    
    function UpdatePreDefineTag(e, eventId, tagName, tagColour) {
        e.preventDefault();
        if (!_.isEmpty(event.tags)) {
            event.tags[0].title = tagName;
            event.tags[0].colour = tagColour;
        } else {
            event.tags = [{title: tagName, colour: tagColour}];
        }
        updateEventTag(eventId, tagName, tagColour).then((response) => {
            // updateEvents();
            setNewTag('');
            setShowAddTag(false);
            setShowAddTagInput(false);


        }).catch((error) => {
            window.Toast.fire({
                icon: 'error',
                title: 'Add Tag Failed'
            });
        });
    }
    

    return (
        <div
            className={`flex justify-center rounded-[10px] shadow-card relative ${selectedEvent === event.id ? 'border border-cyan-500' : ''}`}>
            <div className="w-full rounded-t-[10px]">
                <div className="relative w-full aspect-video">
                    {(event.isPublished === 0) ?
                        <div
                            className='absolute m-4 rounded-lg bg-secondary-600 text-secondary-300 flex items-center text-sm py-1 px-2 z-10'>
                            Draft
                        </div> : ''
                    }
                    <Link to={`events/${event.id}`}>
                        <img
                            className='absolute rounded-t-[10px] w-full h-full object-cover object-center'
                            src={event.coverImageUrl.includes('?cr=') ? event.coverImageUrl : event.coverImageUrl + '?w=440'}
                            alt=""/>
                    </Link>
                </div>
                <div className="w-full aspect-bottom-card">
                    <div className="p-4">
                        <div className='flex justify-between text-secondary-500 text-xs'>
                            <div className='flex flex-row items-center'>
                                <div className='capitalize text-sm'>
                                    {_.isEmpty(event.tags) ?
                                        <div className="flex relative justify-start items-center">
                                            <span
                                                className='rounded-full w-3 h-3 add-tag-oval mr-2 cursor-pointer'> </span>
                                            <span className="cursor-pointer text-sm text-secondary-500 show-tags"
                                                  onClick={showAddTagOptionsFunc}>Add Tag</span>

                                            {showAddTagOptions === event.id ?
                                                <div
                                                    className='absolute bg-dark-gray shadow-dark-input z-50 w-[12.375rem] right-15 top-[95%] overflow-hidden rounded-[10px] mt-2 '>
                                                    <div
                                                        className='flex flex-col cursor-pointer text-light-gray text-sm'>
                                                        <div className="max-h-32 overflow-y-scroll">
                                                            <div onClick={(e) => {
                                                                UpdatePreDefineTag(e, event.id, "Wedding", '#da1ea5')
                                                            }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span
                                                        className='rounded-full w-3 h-3 wedding-oval mr-2 mt-2'> </span>
                                                                <span
                                                                    className="hover:text-primary-green mt-1">Wedding</span>
                                                            </div>
                                                            <div onClick={(e) => {
                                                                UpdatePreDefineTag(e, event.id, "Birthday", '#871eda')
                                                            }} className='flex relative px-4 py-3 hover:bg-black '>
                                                    <span
                                                        className='rounded-full w-3 h-3 birthday-oval mr-2 mt-2'> </span>
                                                                <span
                                                                    className="hover:text-primary-green mt-1">Birthday</span>
                                                            </div>
                                                            <div onClick={(e) => {
                                                                UpdatePreDefineTag(e, event.id, "Corporate", '#1ea1da')
                                                            }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span
                                                        className='rounded-full w-3 h-3 corporate-oval mr-2 mt-2'> </span>
                                                                <span
                                                                    className="hover:text-primary-green mt-1">Corporate</span>
                                                            </div>
                                                             
                                                            {
                                                                tags.length ? tags.map(function (tag) {
                                                                    return <div onClick={(e) => {
                                                                        UpdatePreDefineTag(e, event.id, tag.title, tag.colour)
                                                                    }}
                                                                                className='flex   relative px-4 py-3 hover:bg-black'>
                                                    <span style={{backgroundColor: tag.colour }}
                                                         className={`justify-self-end rounded-full w-3 h-3  mr-2 mt-2`}> </span>
                                                                        <span
                                                                            className="hover:text-primary-green mt-1">{tag.title}</span>
                                                                    </div>
                                                                }) : <></>
                                                            }
                                                        </div>

                                                        <div className='border-t border-zinc-700 px-10 pt-1 h-1'>

                                                        </div>
                                                        {showAddTagInput ?
                                                            <>
                                                                <div className="px-2 mb-4 show-tags">
                                                                    <input
                                                                        onChange={(e) => {
                                                                            setNewTag(e.target.value)
                                                                        }}
                                                                        value={newTag}
                                                                        placeholder='Add New Tag'
                                                                        className={`show-tags mb-3 w-full text-secondary-300 shadow-dark-input outline-none rounded-2xl bg-transparent p-3 `}
                                                                    />
                                                                    <div className="px-5 mb-2 flex justify-between">
                                                                    <span
                                                                        onClick={(e) => {
                                                                            updateNewTag(e, event.id)
                                                                        }}
                                                                        className="whitespace-nowrap underline cursor-pointer text-blue text-base font-semibold">Add Tag
                                                            </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                setShowAddTagInput(false)
                                                                            }}
                                                                            className="whitespace-nowrap underline cursor-pointer text-red-400 show-tags text-base font-semibold">Cancel</span>
                                                                    </div>
                                                                </div>
                                                            </> :
                                                            <div
                                                                onClick={(e) => {
                                                                    addTag(e, event.id)
                                                                }}
                                                                className='underline font-semibold text-base underline-offset-4 text-primary-green px-4 py-3 hover:bg-black show-tags'>Customize
                                                            </div>}

                                                    </div>
                                                </div> : <></>}

                                        </div>
                                        :

                                        <div className="flex relative justify-start items-center">
                                        <span className='rounded-full w-3 h-3 mr-2'
                                              style={{backgroundColor: event.tags[0].colour}}> </span>
                                            <span className="cursor-pointer show-tags"
                                                  onClick={showAddTagOptionsFunc}>{event.tags[0].title}</span>
                                             
                                            {showAddTagOptions === event.id ? <div
                                                className='absolute  bg-dark-gray shadow-dark-input z-50 w-[12.375rem] right-15 top-[95%] overflow-hidden rounded-[10px] mt-2'>
                                                <div
                                                    className='flex flex-col  cursor-pointer text-light-gray text-sm'>
                                                    <div className="max-h-32 overflow-y-scroll">
                                                        <div onClick={(e) => {
                                                            UpdatePreDefineTag(e, event.id, "Wedding", '#da1ea5')
                                                        }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span
                                                        className='rounded-full w-3 h-3 wedding-oval mr-2 mt-2'> </span>
                                                            <span className="hover:text-primary-green mt-1">Wedding</span>
                                                        </div>
                                                        <div onClick={(e) => {
                                                            UpdatePreDefineTag(e, event.id, "Birthday", '#871eda')
                                                        }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span
                                                        className='rounded-full w-3 h-3 birthday-oval mr-2 mt-2'> </span>
                                                            <span className="hover:text-primary-green mt-1">Birthday</span>
                                                        </div>
                                                        <div onClick={(e) => {
                                                            UpdatePreDefineTag(e, event.id, "Corporate", '#1ea1da')
                                                        }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span
                                                        className='rounded-full w-3 h-3 corporate-oval mr-2 mt-2'> </span>
                                                            <span className="hover:text-primary-green mt-1">Corporate</span>
                                                        </div>

                                                        {
                                                            tags.length ? tags.map(function (tag) {
                                                                return <div onClick={(e) => {
                                                                    UpdatePreDefineTag(e, event.id, tag.title, tag.colour)
                                                                }} className='flex relative px-4 py-3 hover:bg-black'>
                                                    <span style={{backgroundColor: tag.colour}}
                                                        className='rounded-full w-3 h-3  mr-2 mt-2'> </span>
                                                                    <span
                                                                        className="hover:text-primary-green mt-1">{tag.title}</span>
                                                                </div>
                                                            }) : <></>
                                                        }
                                                    </div>
                                                    <div className='border-t border-zinc-700 px-10 pt-1 h-1'></div>
                                                    {showAddTagInput ?
                                                        <>
                                                            <div className="px-2 mb-4 show-tags">
                                                                <input
                                                                    onChange={(e) => {
                                                                        setNewTag(e.target.value)
                                                                    }}
                                                                    value={newTag}
                                                                    placeholder='Add New Tag'
                                                                    className={`show-tags mb-3 w-full text-secondary-300 shadow-dark-input outline-none rounded-2xl bg-transparent p-3 `}
                                                                />
                                                                <div className="px-5 mb-2 flex justify-between">
                                                                <span
                                                                    onClick={(e) => {
                                                                        updateNewTag(e, event.id)
                                                                    }}
                                                                    className="whitespace-nowrap underline underline-offset-4 cursor-pointer text-blue text-base font-semibold">Add Tag
                                                            </span>
                                                                    <span
                                                                        onClick={(e) => {
                                                                            setShowAddTagInput(false)
                                                                        }}
                                                                        className="whitespace-nowrap underline underline-offset-4 cursor-pointer text-red-400 show-tags text-base font-semibold">Cancel</span>
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <div
                                                            onClick={(e) => {
                                                                addTag(e, event.id)
                                                            }}
                                                            className='underline font-semibold text-base underline-offset-4 text-primary-green px-4 py-3 hover:bg-black show-tags'>Customise
                                                        </div>}

                                                </div>
                                            </div> : <></>}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='text-secondary-600 text-xs'>{event.date}</div>
                        </div>
                        <div className="py-1">
                            <h1 className='text-xl font-medium capitalize text-secondary-300 truncate'>
                                <Link to={`events/${event.id}`} title={event.name}>
                                    {event.name}
                                </Link>
                            </h1>
                        </div>
                        <div className='flex justify-between text-secondary-500 text-xs'>
                            <div className='flex flex-row items-center'>
                                {(!_.isNull(eventImagesCount)) ?
                                    <div>{eventImagesCount} Photos</div>
                                    : <div>Loading...</div>
                                }
                                <div className='px-1'>|</div>
                                <div>{event.foldersCount} Folders</div>
                            </div>
                            <div className='flex flex-row items-center cursor-pointer' onClick={eventSelected}>
                                {selectedEvent === event.id ?
                                    <EventsMore updateEvents={updateEvents} event={event} openCopyEventCode={openCopyEventCode} setOpenCopyEventCode={setOpenCopyEventCode}/> : <></>}
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className={selectedEvent === event.id ? "h-6 w-6 fill-current  text-primary-green" : "h-6 w-6 fill-current text-secondary-300 "}
                                     fill="none"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openCopyEventCode ?
        <div className="fixed w-full z-50 h-screen top-0 left-0 bg-bb-500 flex items-center  ">
          <div className="w-1/2 max-w-[50rem] mx-auto bg-[#1a1a1a] py-5 px-7 rounded-xl mb-10">
            <div className="flex justify-between w-full">
              <h1 className="text-2xl font-medium mb-1">Copy Event Code</h1>
            <a className="text-3xl font-medium mb-1" href="#" onClick={e=>{e.preventDefault();setOpenCopyEventCode(false);document.body.style.overflow = "auto";}}>X</a>
            </div>
            <div className="w-full mt-5 mb-4 h-[1px] bg-[#555555]"></div>
                <div className="w-full flex justify-center items-center" >
                    <div className='shareDiv shadow-inner-all px-2 py-7 rounded-xl flex w-3/4'> 
                        <p className=' whitespace-nowrap w-full	 text-dark-gray1 text-sm dark:text-light-gray' >{event.id}</p>
                        <a onClick={(e)=>{copyText(e,event.id)}} className='cursor-pointer text-[#00b7b8]  whitespace-nowrap text-right w-[30%]	 text-sm font-semibold'>Copy</a>
                    </div>
                </div>
          </div>
          </div>
          :null }
        </div>
    );
}
