/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Steps } from "intro.js-react";

import Button from "../../components/common/Button";
import getStarted from "../../assets/images/illustration-get-started.png";
import CreateEvent from "../../components/common/CreateEvent";
import { getCookie, setCookie } from "../../functions";
import { useUser } from "../../Store/User";
import { useHelpContext } from "../../Store/Help";

export default ({ handleEventModal, addEvent, buttonDisabled }) => {
  const user = useUser();
  const { showSteps, setShowSteps } = useHelpContext();

  const [showEventCreateModal, setShowEventCreateModal] = useState(false);

  const steps = [
    {
      title: "Welcome",
      intro:
        "Welcome to Algoshare. This is your dashboard. You can create and view your events here.",
    },
    {
      element: "#create-first-event",
      title: "Create events",
      intro: "To create a new event click Create Event button.",
      position: "bottom",
    },
    {
      element: "#general-settings",
      title: "General settings",
      intro: "Add your logo, watermarks, and much more in the General Settings",
    },
    {
      element: "#global-analytics",
      title: "Global Analytics",
      intro: "Check how your events are performing in the Global Analytics",
    },
    {
      element: "#profile-menu",
      title: "Profile Settings",
      intro:
        "Update your domain name, add your social links, and create a mobile app in the Profile Page",
    },
  ];

  function handleEventCreateModal() {
    handleEventModal(true);
    setShowEventCreateModal(true);
  }

  function handleEventCreateModalClose(isModalOpen) {
    setShowEventCreateModal(isModalOpen);
  }

  const displaySteps =
    user.user.subscription.plan_type === "free"
      ? !getCookie("tourEmptyDashboard") || showSteps
      : showSteps;

  return (
    <div className="grid border-t border-secondary-600 w-full justify-center mx-auto">
      {displaySteps ? (
        <Steps
          enabled={true}
          steps={steps}
          initialStep={0}
          onExit={(step) => {
            setCookie("tourEmptyDashboard", 1);
            setShowSteps(false);
          }}
          options={{
            doneLabel: "Got it",
          }}
        />
      ) : (
        <></>
      )}
      <div className="flex flex-col justify-center min-h-[calc(100vh_-_220px)]">
        <div className="flex justify-center">
          <img
            src={getStarted}
            className="w-[6.25rem] aspect-auto"
            alt="Get Started"
          />
        </div>
        <div className="text-center text-2xl font-medium pt-8">
          Get Started!
        </div>
        <div className="text-center text-base pt-2">
          Create an event and start sharing them instantly.
        </div>
        <div className="flex justify-center mt-16">
          <Button
            id="create-first-event"
            title={!buttonDisabled ? "" : "Trial expired"}
            className="font-medium shadow-button text-base h-[2.5rem] text-center"
            onClick={() => {
              handleEventCreateModal();
            }}
            disabled={buttonDisabled}
            text="Create Event"
          />
          {showEventCreateModal ? (
            <CreateEvent
              handleEventCreateModalClose={handleEventCreateModalClose}
              handleEventModal={handleEventModal}
              addEvent={addEvent}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
