/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { Steps } from "intro.js-react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import EventCard from "../../components/Event/Card";
import { useEventIndex } from "../../Store/Event/Index";
import EventEmpty from "../../components/Event/Empty";
import SearchEvents from "../SearchEvents";
import {
  getSearchedEventIndexDetails,
  getEventByTagAndSort,
  getEventIndexDetails,
  getExpiredEventIndexDetails,
  getAllTags,
} from "../../apis";
import Loading from "../Loading";
import Error from "../Errors";
import Pagination from "./components/Pagination";
import { getCookie, setCookie, titleUpdate } from "../../functions";
import { useUser } from "../../Store/User";
import { useHelpContext } from "../../Store/Help";

export default function () {
  const {
      events,
      setEvents,
      isLoaded,
      setIsLoaded,
      hasError,
      pagination,
      setPagination,
      setError,
      expiredEvents,
      setExpiredEvents,
      isLoadedExpiredEvents,
      expiredEventsPagination,
      setExpiredEventsPagination,
    } = useEventIndex(),
    [searchQuery, setSearchedQuery] = useState(""),
    [searchedEvents, setSearchedEvents] = useState(null),
    [isSelectedTag, setIsSelectedTag] = useState(null),
    [isSelectedSort, setIsSelectedSort] = useState(null),
    [selectedTag, setSelectedTag] = useState(null),
    [sort, setSort] = useState(null),
    [createModal, setCreateModal] = useState(false),
    [selectedEvent, setSelectedEvent] = useState(0),
    [searchPagination, setSearchPagination] = useState({}),
    [sortPagination, setSortPagination] = useState({}),
    [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false),
    [showAddTagOptions, setShowAddTagOptions] = useState(0),
    [searchParams] = useSearchParams(),
    { setDashboardUrl } = useUser();
  const { user } = useUser();
  const [photographerTags, setPhotographerTags] = useState([]);

  const { showSteps, setShowSteps } = useHelpContext();

  const searchReqRef = useRef(new AbortController());

  const trail_date = moment().diff(
    moment(user.photographer.created_at),
    "days"
  );
  const buttonDisabled =
    user.photographer.branding_allowed == "0"
      ? trail_date > 30
        ? true
        : false
      : false;

  const steps = [
    {
      title: "Welcome",
      intro:
        "Welcome to Algoshare. This is your dashboard. You can create and view your events here.",
    },
    {
      element: "#create-event",
      title: "Create events",
      intro: "To create a new event click Create Event button.",
      position: "bottom",
    },
    {
      element: "#general-settings",
      title: "General settings",
      intro: "Add your logo, watermarks, and much more in the General Settings",
    },
    {
      element: "#global-analytics",
      title: "Global Analytics",
      intro: "Check how your events are performing in the Global Analytics",
    },
    {
      element: "#profile-menu",
      title: "Profile Settings",
      intro:
        "Update your domain name, add your social links, and create a mobile app in the Profile Page",
    },
  ];

  const update = (e) => {
    let livePage = searchParams.get("live");
    getEventIndexDetails(livePage ?? null)
      .then((response) => {
        setEvents(response.data);
        setPagination(response.meta);
        setIsLoaded(true);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: `There was an error fetching your events`,
        });
        setIsLoaded(true);
      });

    let expiredPage = searchParams.get("expired");
    getExpiredEventIndexDetails(expiredPage ?? null)
      .then((response) => {
        setExpiredEvents(response.data);
        setExpiredEventsPagination(response.meta);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: `There was an error fetching your events`,
        });
      });

    closeAllDropdown(null);
  };

  useEffect(() => {
    getAllTags()
      .then((response) => {
        setPhotographerTags(
          response.filter(
            (tag) => !["Wedding", "Birthday", "Corporate"].includes(tag.title)
          )
        );
      })
      .catch((errors) => {});
  }, []);

  useEffect(() => {
    setDashboardUrl(window.location.pathname + window.location.search);
  }, [window.location.search]);

  useEffect(() => {
    if (!_.isEmpty(searchQuery)) {
      if (searchReqRef.current) {
        searchReqRef.current.abort();
      }

      searchReqRef.current = new AbortController();
      setIsLoadingSearchResults(true);
      getSearchedEventIndexDetails(
        searchQuery,
        null,
        searchReqRef.current.signal
      )
        .then((response) => {
          setSearchedEvents(response.data);
          setSearchPagination(response.meta);
          setIsLoadingSearchResults(false);
        })
        .catch((error) => {
          if (error.message === "canceled") return;
          window.Toast.fire({
            icon: "error",
            title: `There was an error fetching your events`,
          });
          setIsLoadingSearchResults(false);
        });
      return;
    }
    setSearchedQuery("");
    setSearchedEvents(null);
  }, [searchQuery]);

  useEffect(() => {
    titleUpdate(`Dashboard`);
  }, []);

  function getSearchedEvents(query) {
    if (!_.isEmpty(query.trim())) {
      setSearchedQuery(query);
    } else {
      setSearchedQuery("");
      setSearchedEvents(null);
    }
  }

  function getSelectedTag(tag) {
    if (tag === "All Tags" && sort == null) {
      setIsSelectedTag(null);
      setIsSelectedSort(null);
      setSelectedTag(null);
      setSort(null);
      setSearchedEvents(null);
      return;
    }

    if (tag === "All Tags" && sort === "latest") {
      setIsSelectedTag(null);
      setIsSelectedSort(null);
      setSelectedTag(null);
      setSort(null);
      setSearchedEvents(null);
      return;
    }

    if (tag === "All Tags" && sort !== "latest") {
      setIsLoadingSearchResults(true);
      setSelectedTag(null);
      getEventByTagAndSort((tag = null), sort).then((response) => {
        setSearchedEvents(response.data);
        setSortPagination(response.meta);
        setIsLoadingSearchResults(false);
      });
    }

    setIsSelectedTag(true);
    setSelectedTag(tag);
    setIsLoadingSearchResults(true);
    getEventByTagAndSort(tag, sort).then((response) => {
      setSearchedEvents(response.data);
      setSortPagination(response.meta);
      setIsLoadingSearchResults(false);
    });
  }

  function sortEvents(sortBy) {
    if (sortBy === "latest" && selectedTag == null) {
      setIsSelectedSort(null);
      setIsSelectedTag(null);
      setSelectedTag(null);
      setSort(null);
      setSearchedEvents(null);
      return;
    }
    if (sortBy === "latest" && selectedTag === "All Tags") {
      setIsSelectedSort(null);
      setIsSelectedTag(null);
      setSelectedTag(null);
      setSort(null);
      setSearchedEvents(null);
      return;
    }
    setIsSelectedSort(true);
    setSort(sortBy);
    setIsLoadingSearchResults(true);
    getEventByTagAndSort(selectedTag, sortBy).then((response) => {
      setSearchedEvents(response.data);
      setSortPagination(response.meta);
      setIsLoadingSearchResults(false);
    });
  }

  function handleEventModal(isOpen) {
    setCreateModal(isOpen);
  }

  function closeAllDropdown(e) {
    if (_.isEmpty(e) || _.isNull(e)) {
      setSelectedEvent(0);
      return true;
    }
    if (typeof e.target.className.indexOf === "function") {
      if (e.target.className.indexOf("event-card") < 0) {
        setSelectedEvent(0);
      }
      if (e.target.className.indexOf("show-tags") < 0) {
        setShowAddTagOptions(0);
      }
    }
  }

  function addEvent(created_event) {
    setSearchedEvents([...searchedEvents, created_event]);
    setCreateModal(false);
  }

  const displaySteps =
    user.subscription.plan_type === "free"
      ? !getCookie("tourDashboardPage") || showSteps
      : showSteps;

  return hasError ? (
    <Error />
  ) : !isLoaded || !isLoadedExpiredEvents ? (
    <Loading />
  ) : (
    <div
      onClick={(e) => {
        closeAllDropdown(e);
      }}
    >
      <div
        className={
          !_.isEmpty(expiredEvents) || !_.isEmpty(events)
            ? "pb-44 container mx-auto xl:max-w-[85rem]"
            : "container mx-auto"
        }
      >
        {displaySteps ? (
          <Steps
            enabled={true}
            steps={steps}
            initialStep={0}
            onExit={() => {
              setCookie("tourDashboardPage", 1);
              setShowSteps(false);
            }}
            options={{
              doneLabel: "Got it",
            }}
          />
        ) : (
          <></>
        )}

        <div className={createModal ? "mx-auto px-10" : "mx-auto mt-5 px-10"}>
          <SearchEvents
            searchQuery={searchQuery}
            buttonDisabled={buttonDisabled}
            tagClicked={getSelectedTag}
            onKeyUp={getSearchedEvents}
            sortEvents={sortEvents}
            handleEventModal={handleEventModal}
            addEvent={addEvent}
          />
          <div>
            {!_.isEmpty(searchQuery) &&
            _.isEmpty(searchedEvents) &&
            !isLoadingSearchResults
              ? "No events found based on your search"
              : ""}
            {isLoadingSearchResults ? (
              <Loading />
            ) : !_.isEmpty(searchedEvents) ? (
              <div className="mt-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
                  {searchedEvents.map(function (event, key) {
                    return (
                      <EventCard
                        event={event}
                        key={event.id}
                        tags={photographerTags}
                        setTags={setPhotographerTags}
                        setEvents={setEvents}
                        updateEvents={update}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        showAddTagOptions={showAddTagOptions}
                        setShowAddTagOptions={setShowAddTagOptions}
                      />
                    );
                  })}
                </div>
                {searchPagination.last_page > 1 ? (
                  <Pagination
                    links={searchPagination.links}
                    key="search"
                    name="search"
                    setPagination={setSearchPagination}
                    setIsLoaded={setIsLoaded}
                    setError={setError}
                    setEventIndex={setSearchedEvents}
                    searchQuery={searchQuery}
                  />
                ) : (
                  ""
                )}
                {sortPagination.last_page > 1 ? (
                  <Pagination
                    links={sortPagination.links}
                    key="sort"
                    name="sort"
                    setPagination={setSortPagination}
                    setIsLoaded={setIsLoaded}
                    setError={setError}
                    setEventIndex={setSearchedEvents}
                    sortSelect={sort}
                    tagSelect={selectedTag}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <></>
            )}

            {!_.isEmpty(events) &&
            _.isEmpty(searchQuery) &&
            !isSelectedTag &&
            !isSelectedSort ? (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
                  {events.map(function (event, key) {
                    return (
                      <EventCard
                        event={event}
                        key={event.id}
                        tags={photographerTags}
                        setTags={setPhotographerTags}
                        setEvents={setEvents}
                        updateEvents={update}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        showAddTagOptions={showAddTagOptions}
                        setShowAddTagOptions={setShowAddTagOptions}
                      />
                    );
                  })}
                </div>
                {pagination.last_page > 1 ? (
                  <Pagination
                    links={pagination.links}
                    key="live"
                    name="live"
                    setPagination={setPagination}
                    setIsLoaded={setIsLoaded}
                    setError={setError}
                    setEventIndex={setEvents}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {!_.isEmpty(expiredEvents) &&
            _.isEmpty(searchQuery) &&
            !isSelectedTag &&
            !isSelectedSort ? (
              <div className="mt-10">
                <div className="flex w-full mb-5">
                  <h2 className="text-3xl text-secondary-300">
                    Expired Events
                  </h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
                  {expiredEvents.map(function (event, key) {
                    return (
                      <EventCard
                        event={event}
                        key={event.id}
                        tags={photographerTags}
                        setTags={setPhotographerTags}
                        setEvents={setEvents}
                        updateEvents={update}
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        showAddTagOptions={showAddTagOptions}
                        setShowAddTagOptions={setShowAddTagOptions}
                      />
                    );
                  })}
                </div>
                {expiredEventsPagination.last_page > 1 ? (
                  <Pagination
                    links={expiredEventsPagination.links}
                    key="expired"
                    name="expired"
                    setIsLoaded={setIsLoaded}
                    setPagination={setExpiredEventsPagination}
                    setError={setError}
                    setEventIndex={setExpiredEvents}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {_.isEmpty(expiredEvents) && _.isEmpty(events) ? (
              <EventEmpty
                buttonDisabled={buttonDisabled}
                handleEventModal={handleEventModal}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
