import React, {useEffect, useState} from 'react';
import {getEventIndexDetails, getExpiredEventIndexDetails} from '../../apis/index';
import {useSearchParams} from "react-router-dom";

const Index = React.createContext();
Index.displayName = 'EventIndex';

export const useEventIndex = () => React.useContext(Index);

export const EventIndexProvider = ({children, initialState, reducer}) => {
    const [globalState, dispatch] = React.useReducer(reducer, initialState),
        [error, setError] = useState(null),
        [isLoaded, setIsLoaded] = useState(false),
        [isLoadedExpiredEvents, setIsLoadedExpiredEvents] = useState(false),
        [eventIndex, setEventIndex] = useState({}),
        [expiredEvents, setExpiredEvents] = useState({}),
        [pagination, setPagination] = useState({}),
        [expiredEventsPagination, setExpiredEventsPagination] = useState({}),
        [searchParams, setSearchParams] = useSearchParams();

    useEffect(async () => {
        let livePage = searchParams.get("live");
        getEventIndexDetails(livePage ?? null).then((response) => {
            setEventIndex(response.data);
            setPagination(response.meta);
            setIsLoaded(true);
        }).catch((error) => {
            setIsLoaded(true);
            setError(error);
        });

        let expiredPage = searchParams.get("expired");
        getExpiredEventIndexDetails(expiredPage ?? null).then((response) => {
            setExpiredEvents(response.data);
            setExpiredEventsPagination(response.meta);
            setIsLoadedExpiredEvents(true);
        }).catch((error) => {
            setIsLoadedExpiredEvents(true);
            setError(error);
        });
    }, [])

    return (
        <Index.Provider value={{
            events: eventIndex,
            setEvents: setEventIndex,
            isLoaded: isLoaded,
            hasError: error,
            pagination: pagination,
            setPagination: setPagination,
            setIsLoaded: setIsLoaded,
            setError: setError,
            // Expired Events
            expiredEvents: expiredEvents,
            setExpiredEvents: setExpiredEvents,
            expiredEventsPagination: expiredEventsPagination,
            setExpiredEventsPagination: setExpiredEventsPagination,
            isLoadedExpiredEvents: isLoadedExpiredEvents,
        }}>{children}</Index.Provider>
    );
};
