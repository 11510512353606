import React from "react";
import DownArrow from "../../../assets/icons/DownArrow";

function Select(props) {
    const {onClick, className, text = "", isOpen = false} = props;
    return (
        <div role="button" onClick={onClick}
            className={`flex capitalize items-center justify-between text-sm text-light-gray shadow-card rounded-xl p-2 px-3 cursor-pointer ${className}`}
        >
            {text}
            <span className='pl-2'>
                <DownArrow className={`${isOpen ? 'rotate-180' : ''}`}   />
            </span>
        </div>
    );
}

export default Select;
