import React from "react";

import Twitter from "../../../../assets/images/Twitter-icon.png";
import Linkedin from "../../../../assets/images/Linkedin-icon.png";
import Mail from "../../../../assets/images/Mail-icon.png";
import { OG_IMAGE_CDN } from "../../../../constants";

const EmailTemplateDark = ({
  mailData,
  photographer,
  accessType,
  url,
  isPinEnabled,
}) => {
  return (
    <div>
      <div
        className={
          mailData.layout == 2
            ? "bg-white  text-black shadow-dark-input rounded-md p-6 mb-10"
            : "bg-dark-grey shadow-dark-input rounded-md p-6 mb-10"
        }
      >
        <div className="bg-stone-600 relative rounded-t-3xl rounded-b-xl">
          <img
            className="w-full max-w-none rounded-xl"
            src={mailData.banner_image}
          />
          <div className="absolute bottom-2 pt-6 pl-6 pr-6">
            <h1
              className={
                mailData.layout == 1
                  ? "text-xl font-semibold"
                  : "text-xl text-white font-semibold"
              }
            >
              {mailData.event_name}
            </h1>
            <p
              className={
                mailData.layout == 1
                  ? "text-base font-medium"
                  : "text-base text-white font-medium"
              }
            >
              {mailData.date}
            </p>
            <p
              className={
                mailData.layout == 1
                  ? "text-base font-medium"
                  : "text-base text-white font-medium"
              }
            >
              {photographer.logo ? photographer.name : photographer.name}
            </p>
          </div>
        </div>
        <div className="py-14 md:px-7">
          <div className="text-base ">
            <h1 className="text-2xl block pb-6 font-bold">Hi,</h1>
            <p className="whitespace-pre-wrap mb-4 ">
              {mailData.message.replace("%event_name%", mailData.event_name)}
            </p>
            {accessType ? (
              <>
                {isPinEnabled ? (
                  <>
                    <p className="text-sm">
                      Use this four digit pin when asked -
                    </p>
                    <h1 className="text-2xl block font-bold">
                      PIN: {mailData.pin}
                    </h1>{" "}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              mailData.password_enabled &&
              mailData.password_text && (
                <h1 className="text-2xl block font-bold">
                  Password: {mailData.password_text}
                </h1>
              )
            )}
          </div>
          <div className="flex justify-center p-8">
            <a
              target="_blank"
              className="rounded-lg dark:shadow-button py-[9px] px-[16px]  text-center font-semibold w-[11rem] bg-button text-light-gray"
              href={url}
            >
              {accessType
                ? mailData.published
                  ? "Find Photos"
                  : "Register"
                : "View Photos"}
            </a>
          </div>
          <div className="border-t border-dashed border-stone-600">
            <p className="flex justify-center text-center text-sm mt-10">
              Questions? Contact{" "}
            </p>
            <a
              className="flex justify-center text-center text-sm mt-1 mb-4"
              href={`mailto:${photographer.email}`}
            >
              {photographer.email.toLowerCase()}
            </a>
            <ul className="flex flex-row justify-center px-20 items-center py-4">
              {photographer.facebook && photographer.branding_allowed == "1" ? (
                <li className="px-3">
                  <a target="_blank" href={photographer.facebook}>
                    <img
                      src={
                        OG_IMAGE_CDN + "emailers/mailer-1/images/Facebook.png"
                      }
                      width="21"
                      alt="Facebook logo"
                    />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.insta && photographer.branding_allowed == "1" ? (
                <li className="px-3">
                  <a target="_blank" href={photographer.insta}>
                    <img
                      src={
                        OG_IMAGE_CDN + "emailers/mailer-1/images/insta.png"
                      }
                      width="20"
                      alt="Insta logo"
                    />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.twitter && photographer.branding_allowed == "1" ? (
                <li className="px-3">
                  <a target="_blank" href={photographer.twitter}>
                    <img src={Twitter} width="20" alt="Twitter icon" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.linkedin && photographer.branding_allowed == "1" ? (
                <li className="px-3">
                  <a target="_blank" href={photographer.linkedin}>
                    <img src={Linkedin} width="20" alt="Linkedin icon" />
                  </a>{" "}
                </li>
              ) : null}
              {photographer.email && photographer.branding_allowed == "1" ? (
                <li className="px-3">
                  <a target="_blank" href={`mailto:${photographer.email}`}>
                    <img src={Mail} width="20" alt="Mail icon" />
                  </a>{" "}
                </li>
              ) : null}
            </ul>
            {photographer.url && photographer.branding_allowed == "1" ? (
              <a
                target={"_blank"}
                href={photographer.url}
                className="flex justify-center text-sm"
              >
                {photographer.url}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateDark;
