/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState,useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DownArrow from "../../../../assets/icons/DownArrow";
import {useEventIndex} from "../../../../Store/Event/Index";
import _, { filter } from 'lodash';
import {useUser} from "../../../../Store/User";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example({items,className,onSelect,dropDownFor}) {
  const {user,setUser} = useUser();
  var initialSelected = "";
  var colorCode =""
    if (dropDownFor === 'logo-text-color') {
        !_.isEmpty(user.photographer) && (initialSelected = user.photographer.logo_text_colour);
    }
  const [item,setItem] = useState(items);
  const [selected, setSelected] = useState(initialSelected);
  const [selectedColorCode, setSelectedColorCode] = useState(initialSelected);


  const onOptionChange = (e) => {
      onSelect(e);
      setSelected(e);
      setSelectedColorCode(e);
  }


  return (
    <Listbox value={selected} onChange={(e)=>{onOptionChange(e)}}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative">
          <Listbox.Button className={`flex relative items-center justify-between text-sm shadow-button rounded-lg p-2 px-3 whitespace-nowrap ${className}`}>
              <span className="flex items-center">
              <span className={`rounded-full w-4 h-4 mr-3 bg-black`} style={{backgroundColor:selectedColorCode}}  > </span>
                <span className="block truncate">{!_.isNull(selected) ? selected : item[0].colorName}</span>
              </span>
              <span className="inset-y-0 right-0 flex items-center pointer-events-none ">
                {/* <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                <DownArrow className=" h-3 w-3 ml-3 text-gray-400" aria-hidden="true" rotate={open ? true : false} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={`absolute z-50 mt-[0.625rem] bg-dark-gray shadow-dark-input max-h-56 rounded-xl r-2 top-[95%] text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${className}`}>

                {item.map((tags) => (
                  <Listbox.Option
                    key={tags.colorName}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-secondary-300 bg-black' : 'text-secondary-300',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }

                    value={tags.colorName}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span className="rounded-full w-4 h-4 mr-3" style={{backgroundColor: tags.colorCode}} > </span>
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                          >

                            {tags.colorName}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
