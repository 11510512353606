import _ from "lodash";
import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";

import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import EyeSlashIcon from "../../../../assets/icons/EyeSlashIcon";
import DownloadIcon from "../../../../assets/icons/DownloadIcon";
import ShareIcon from "../../../../assets/icons/ShareIcon";
import Checkboxgen from "../../../../components/common/Checkboxgen";
import { deleteImg, flag } from "../../../../apis/upload";
import { useEvent } from "../../../../Store/Event/Show";
import { useFolder } from "../../../../Store/Folder/Show";
import ShareModal from "../ShareModal";
import Bulkactions from "../../../../components/Event/Popups/Bulkactions";
import {
  GUEST_UPLOAD_SLUG,
  OG_IMAGE_SUBDOMAIN,
  RESIZE_IMAGE_SUBDOMAIN,
} from "../../../../constants";

const GalleryListView = ({
  setShowMoveFolder,
  selectedImages,
  setSelectedImages,
}) => {
  const { event } = useEvent();
  let { folder, images, setImages, guests } = useFolder();
  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const [shareLink, setShareLink] = useState();

  const changePrivacy = useCallback(
    async (image, index) => {
      let res = await flag(
        image.id,
        image.isPrivate ? 0 : 1,
        folder.id,
        event.id
      );
      if (res.status >= 200 && res.status <= 204) {
        window.Toast.fire({
          icon: "success",
          title: `Image is now ${image.isPrivate ? "public" : "private"}`,
        });
        let newImages = images;
        newImages[index].isPrivate = !image.isPrivate;
        setImages([...newImages]);
      } else {
        window.Toast.fire({
          icon: "error",
          title: `Unable to make image ${
            image.isPrivate ? "public" : "private"
          }.`,
        });
      }
    },
    [images]
  );
  const shareImage = (image) => {
    setShareLink(
      image.url
        .replace(RESIZE_IMAGE_SUBDOMAIN, OG_IMAGE_SUBDOMAIN)
        .replace("+", "%2B") +
        "?v=" +
        image.version
    );
    setShowSharePopUp(true);
  };

  const downloadImage = (image) => {
    fetch(
      image.url
        .replace(RESIZE_IMAGE_SUBDOMAIN, OG_IMAGE_SUBDOMAIN)
        .replace("+", "%2B"),
      {
        method: "GET",
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", image.originalImageName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {});
  };

  function deleteImage(image) {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete ${image.originalImageName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(function (key) {
      if (key.isConfirmed) {
        deleteImg(image.id, folder.id, event.id)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Image deleted successfully",
            });
            let newImages = images.filter((i) => i.id !== image.id);
            setImages([...newImages]);
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Image could not be deleted",
            });
          });
      }
    });
  }

  const handleImageSelect = useCallback(
    (e) => {
      let id = e.target.value;
      if (selectedImages.includes(id)) {
        setSelectedImages(selectedImages.filter((i) => i != id));
      } else {
        setSelectedImages([...selectedImages, id]);
      }
    },
    [images, selectedImages]
  );

  const handleSelectAll = (e) => {
    if (e.target.checked) setSelectedImages(images.map((i) => i.id));
    else setSelectedImages([]);
  };

  return (
    <>
      {_.isEmpty(images) ? (
        "Loading..."
      ) : (
        <div className="text-light-gray pt-0 relative">
          <ul className="flex flex-col">
            <li className="flex flex-row bg-black1 h-[40px] items-center text-base px-[1.25rem] rounded-md">
              <div className="basis-0 pr-8">
                <Checkboxgen
                  onChange={handleSelectAll}
                  checked={selectedImages.length === images.length}
                />
              </div>
              <div className="basis-2/5">
                <span className="flex items-center">Name</span>
              </div>
              <div className="basis-1/5">
                <span className="flex items-center">Date Created</span>
              </div>
              <div className="basis-1/5">File Size</div>
              <div className="basis-1/5"></div>
            </li>
            {images.map((image, index) => {
              return (
                <li className="border-b border-black-gray py-2" key={image.id}>
                  <div
                    className={`${
                      selectedImages.includes(image.id)
                        ? "bg-black/80 border border-solid border-[#00b7b8] rounded-2xl"
                        : ""
                    } flex py-7 px-[1.25rem] flex-row items-start`}
                  >
                    <div className="basis-0 pr-8">
                      <Checkboxgen
                        onChange={handleImageSelect}
                        checked={selectedImages.includes(image.id)}
                        value={image.id}
                      />
                    </div>
                    <div className="flex  basis-2/5 items-start">
                      <div className="relative w-20 rounded-[10px] overflow-hidden h-0 pb-[45px]">
                        <img
                          className="absolute inset-0 w-full h-full object-cover"
                          src={
                            image.isVideo ? image.orginalImageUrl : image.src
                          }
                          alt={image.originalImageName}
                        />
                      </div>
                      <div>
                        <div className="px-4 break-all w-full items-start">
                          {image.originalImageName}
                        </div>
                        {folder.slug === GUEST_UPLOAD_SLUG &&
                        image.guestId &&
                        guests[image.guestId] ? (
                          <div className=" mt-1 px-4 break-all w-full items-start text-xs">
                            Uploaded by : {guests[image.guestId]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="basis-1/5 flex items-start">
                      {image.uploadedDate}
                    </div>
                    <div className="basis-1/5 flex items-center">
                      {image.isVideo ? "-" : image.size + " MB"}
                    </div>
                    <div className="flex flex-row gap-8 basis-1/5">
                      <span
                        className="cursor-pointer"
                        title="Change privacy"
                        onClick={() => {
                          changePrivacy(image, index);
                        }}
                      >
                        <EyeSlashIcon
                          color={!image.isPrivate ? "#fff" : "#05b1b2"}
                          height="16"
                          width="16"
                        />
                      </span>
                      {event.isPublished && !image.isVideo ? (
                        <span
                          title="Share"
                          className="cursor-pointer"
                          onClick={() => {
                            shareImage(image);
                          }}
                        >
                          <ShareIcon height="16" width="16" />
                        </span>
                      ) : (
                        <></>
                      )}

                      {!image.isVideo && (
                        <span
                          className="cursor-pointer"
                          title="Download"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadImage(image);
                          }}
                        >
                          <DownloadIcon height="16" width="16" />
                        </span>
                      )}

                      <span
                        className="cursor-pointer"
                        title="Delete"
                        onClick={() => {
                          deleteImage(image);
                        }}
                      >
                        <DeleteIcon height="16" width="16" />
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <ShareModal
            open={showSharePopUp}
            close={() => {
              setShowSharePopUp(false);
            }}
            shareLink={shareLink}
          />
        </div>
      )}

      {!!selectedImages.length && (
        <Bulkactions
          setSelectedImages={setSelectedImages}
          setShowMoveFolder={setShowMoveFolder}
          selectedImages={selectedImages}
          eventId={event.id}
          slug={event.slug}
        />
      )}
    </>
  );
};

export default GalleryListView;
