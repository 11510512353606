import React, { useEffect, useState, useRef } from "react";
import { Steps } from "intro.js-react";
import _ from "lodash";

import Title from "../../components/common/Title";
import Text from "../../components/common/Text";
import PrintSection from "./components/PrintSection";
import LogoSection from "./components/LogoSection";
import Checkbox from "../../components/common/Checkbox";
import WatermarkSection from "./components/WatermarkSection";
import AnalyticsSection from "./components/AnalyticsSection";
import { InputFile } from "../../components/common/Input";
import GeneralSettingTitle from "../../components/common/SettingsTitleSection/GeneralSettingTitle";
import { getCookie, setCookie, titleUpdate } from "../../functions/index.js";
import { useUser } from "../../Store/User";
import {
  updatePhotographerDetails,
  updatePhotographerFavicon,
  updatePhotographerLogo,
  updateGlobalChatBot,
  removePhotographerFavicon,
} from "../../apis";
import Vimeo from "../../assets/images/Vimeo-icon.png";
import Facebook from "../../assets/images/Facebook-icon.png";
import Instagram from "../../assets/images/Instagram-icon.png";
import Linkedin from "../../assets/images/Linkedin-icon.png";
import Youtube from "../../assets/images/Youtube-icon.png";
import Twitter from "../../assets/images/Twitter-icon.png";
import useOnScreen from "../../components/common/Sidebar/IntersectionObserver";
import Loading from "../Loading";
import SwitchWithTitle from "../GeneralSettings/components/SwitchWithTitle";
import { OG_IMAGE_CDN } from "../../constants";
import { useHelpContext } from "../../Store/Help";
import GuestUpload from "./components/GuestUpload";

const steps = [
  {
    element: "#logo",
    title: "Brand",
    intro: "Update your brand name and logo.",
    position: "bottom",
  },
  {
    element: "#favicon",
    title: "Favicon",
    intro: "Update the favicon for your galleries.",
    position: "bottom",
  },
  {
    element: "#watermark-enable",
    title: "Watermark - Enable / Disable",
    intro: "Enable or Disable watermark for viewing & downloads.",
    position: "left",
  },
  {
    element: "#watermark-preset",
    title: "Watermark Preset",
    intro: "Create your own watermark.",
    position: "right",
  },
  {
    element: "#analytics",
    title: "GA",
    intro: "Track your gallery visits on your own google analytics account.",
    position: "left",
  },
  {
    element: "#guestUpload",
    title: "Guest Upload",
    intro: "Let your guest upload images they click through their device",
    position: "left",
  },
  {
    element: "#print-store",
    title: "Print Store",
    intro: "Allow your clients to purchase the beautiful photos with frames..",
    position: "left",
  },
];

const GeneralSettings = () => {
  const { user, setUser } = useUser();
  const [photographer, setPhotographer] = useState({ isLoaded: false });
  const [showTour, setShowTour] = useState(false);
  const [activeMenu, setActiveMenu] = useState("logo");
  const logoRef = useRef();
  const isLogoRefVisible = useOnScreen(logoRef);
  const watermarkRef = useRef();
  const isWatermarkVisible = useOnScreen(watermarkRef);
  const analyticsRef = useRef();
  const isAnalyticsVisible = useOnScreen(analyticsRef);
  const guestUploadRef = useRef();
  const isGuestUploadVisible = useOnScreen(guestUploadRef);
  const printStoreRef = useRef();
  const isPrintStoreVisible = useOnScreen(printStoreRef);

  const { showSteps, setShowSteps } = useHelpContext();

  useEffect(() => {
    if (isLogoRefVisible) {
      setActiveMenu("logo");
    }
    if (isWatermarkVisible && !isLogoRefVisible) {
      setActiveMenu("watermark");
    }
    if (isAnalyticsVisible && !isWatermarkVisible) {
      setActiveMenu("analytics");
    }
    if (isGuestUploadVisible && !isAnalyticsVisible) {
      setActiveMenu("guestUpload");
    }
    if (isPrintStoreVisible && !isGuestUploadVisible) {
      setActiveMenu("print-store");
    }
  }, [
    isLogoRefVisible,
    isWatermarkVisible,
    isAnalyticsVisible,
    isPrintStoreVisible,
    isGuestUploadVisible,
  ]);

  const [photographerFavicon, setPhotographerFavicon] = useState(false);
  const [chatbot, setChatbot] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowTour(true), 3000);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setChatbot(user.photographer.show_chat_bot ? true : false);

      if (!_.isEmpty(user.photographer.favicon_url)) {
        setPhotographerFavicon(true);
      }
    }
  }, []);

  useEffect(() => {
    // isLoaded is used to prevent triggering photographer update on page load
    if (!Object.hasOwn(photographer, "isLoaded")) {
      updatePhotographerDetails(photographer)
        .then((response) => {
          setUser({ ...user, access_token: response.access_token });
        })
        .catch((error) => {
          window.Toast.fire({
            icon: "error",
            title: "Error updating your details",
          });
        });
    }

    titleUpdate(`General settings`);
  }, [photographer]);

  const [logoFileName, setLogoFileName] = useState(
    _.isEmpty(user) || _.isEmpty(user.photographer)
      ? "No file Selected"
      : user.photographer.logo_url
  );
  const [isUploadLogoDisable, setIsUploadLogoDisable] = useState(false);
  const DefaultAtt = {
    type: "file",
    name: "",
    fontColor: "Black",
    fontFamily: "Arial",
    text: "",
  };
  const [globalBranding, setGlobalBranding] = useState({
    ...DefaultAtt,
  });
  const [waterMarkSettings, setWaterMarkSettings] = useState({
    ...DefaultAtt,
  });

  const updateLogo = (e) => {
    setLogoFileName(e.name);
    setIsUploadLogoDisable(true);
    updatePhotographerLogo(e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Brand Icon updated successfully",
        });
        setUser({ ...user, access_token: response.access_token });
        setIsUploadLogoDisable(false);
        // window.location.reload();
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error processing Brand Icon",
        });
      });
  };

  function updateFavicon(state) {
    if (state == false) {
      setPhotographerFavicon(false);
      removePhotographerFavicon()
        .then((response) => {
          window.Toast.fire({
            icon: "success",
            title: "Favicon deleted successfully",
          });
          setUser({ ...user, access_token: response.access_token });
        })
        .catch((error) => {
          window.Toast.fire({
            icon: "error",
            title: "Error processing Favicon",
          });
          setPhotographerFavicon(true);
        });
    } else {
      setPhotographerFavicon(true);
    }
  }

  function updateChatBot(e) {
    setChatbot(e);
    updateGlobalChatBot(e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        });
        setUser({ ...user, access_token: response.access_token });
      })
      .catch((error) => {
        setChatbot(!e);
        window.Toast.fire({
          icon: "error",
          title: "Could Not Update",
        });
      });
  }

  const handleFavIconFileChange = (file) => {
    const newFileName = file.name.replaceAll(" ", "");
    const newFile = new File([file], newFileName, { type: file.type });
    updatePhotographerFavicon(newFile)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Favicon updated successfully",
        });
        setUser({ ...user, access_token: response.access_token });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error processing Favicon",
        });
      });
  };

  const displaySteps =
    user.subscription.plan_type === "free"
      ? !getCookie("generalSettingsPage") || showSteps
      : showSteps;

  return !user || !user.photographer ? (
    <Loading />
  ) : (
    <div>
      {displaySteps && showTour && (
        <Steps
          enabled={true}
          steps={steps}
          initialStep={0}
          onExit={(step) => {
            setCookie("generalSettingsPage", 1);
            setShowSteps(false);
          }}
          options={{
            doneLabel: "Got it",
          }}
        />
      )}
      <GeneralSettingTitle />
      <div className="flex">
        <ul className="min-w-[20rem] mr-6 shadow-sidebar pl-10 pt-7 pr-6 h-screen sticky top-0 rounded-tr-3xl rounded-br-[10px]">
          {[
            {
              title: "Logo & Branding",
              id: "logo",
            },
            {
              title: "Watermark",
              id: "watermark",
            },
            {
              title: "Analytics",
              id: "analytics",
            },
            {
              title: "Guest Upload",
              id: "guestUpload",
            },

            {
              title: "Print Store",
              id: "print-store",
            },
          ].map(({ id, title }) => {
            return (
              <li
                onClick={() => setActiveMenu(id)}
                className={`h-[40px] mb-2 text-base text-light-gray pl-4 flex items-center w-full whitespace-nowrap hover:bg-black rounded-lg cursor-pointer
                                ${
                                  activeMenu === id
                                    ? "text-primary-green bg-black rounder-lg"
                                    : "text-secondary-300"
                                }`}
                key={id}
              >
                <a href={`#${id}`} className="w-full">
                  {title}
                </a>
              </li>
            );
          })}
        </ul>
        <div className=" flex flex-col pr-10 grow pb-44 lg:w-full lg:overflow-hidden">
          <div id="logo" ref={logoRef}>
            <Title
              className="pb-4 mt-[1.875rem] mb-4 border-secondary-600 border-b"
              text="Logo & Branding"
            />

            <div className="mt-7 flex justify-between flex-wrap 2xl:flex-nowrap gap-x-32 gap-y-8">
              <div className="flex flex-col w-full">
                <Text
                  as="p"
                  className="mb-6 text-lg font-medium text-secondary-300"
                >
                  Show the following information on gallery:
                </Text>
                <LogoSection
                  logoType={globalBranding}
                  setLogoType={setGlobalBranding}
                  onLogoFileChange={updateLogo}
                  fileName={logoFileName}
                  isUploadLogoDisable={isUploadLogoDisable}
                />
                <Checkbox
                  className="mb-8"
                  id="email"
                  label="Contact Email"
                  desc={!!user.user.email ? user.user.email : ""}
                  value={user.photographer.show_contact_email}
                  onChange={(value) => {
                    setPhotographer({ showContactEmail: value });
                  }}
                />
                <Checkbox
                  className="mb-8"
                  label="Phone Number"
                  id="phone_number"
                  desc={
                    !!user.photographer.phone ? user.photographer.phone : ""
                  }
                  value={user.photographer.show_phone_number}
                  onChange={(value) => {
                    setPhotographer({ showPhoneNumber: value });
                  }}
                />
                <Checkbox
                  className="mb-8"
                  id="website_link"
                  label="Website Link"
                  desc={
                    !!user.photographer.portfolio_website
                      ? user.photographer.portfolio_website
                      : ""
                  }
                  value={user.photographer.show_website_link}
                  onChange={(value) => {
                    setPhotographer({ showWebsiteLink: value });
                  }}
                />
                <Checkbox
                  className=""
                  id="social_link"
                  label="Social Links"
                  desc={
                    <div className="mt-3">
                      <ul className="flex items-center ">
                        <li className="pr-2">
                          <img
                            height={"30"}
                            width="30"
                            className={`rounded-md ${
                              !user.photographer.facebook ? "opacity-30" : ""
                            }`}
                            src={Facebook}
                            alt="facebook icon"
                          />
                        </li>
                        <li className="pr-2">
                          <img
                            height={"30"}
                            width="30"
                            className={`rounded-md ${
                              !user.photographer.twitter ? "opacity-30" : ""
                            }`}
                            src={Twitter}
                            alt="Twitter icon"
                          />
                        </li>
                        <li className="pr-2">
                          <img
                            height={"30"}
                            width="30"
                            className={`rounded-md ${
                              !user.photographer.instagram ? "opacity-30" : ""
                            }`}
                            src={Instagram}
                            alt="Instagram icon"
                          />
                        </li>
                        <li className="pr-2">
                          <img
                            height={"30"}
                            width="30"
                            className={`rounded-md ${
                              !user.photographer.linkedin ? "opacity-30" : ""
                            }`}
                            src={Linkedin}
                            alt="Linkedin icon"
                          />
                        </li>
                        <li className="pr-2">
                          <img
                            height={"30"}
                            width="30"
                            className={`rounded-md ${
                              !user.photographer.vimeo ? "opacity-30" : ""
                            }`}
                            src={Vimeo}
                            alt="Vimeo icon"
                          />
                        </li>
                        <li className="pr-2">
                          <img
                            height={"35"}
                            width="35"
                            className={`rounded-md ${
                              !user.photographer.youtube ? "opacity-30" : ""
                            }`}
                            src={Youtube}
                            alt="Youtube icon"
                          />
                        </li>
                      </ul>
                    </div>
                  }
                  value={user.photographer.show_social_links}
                  onChange={(value) => {
                    setPhotographer({ showSocialLinks: value });
                  }}
                />
              </div>

              <div className="flex flex-col w-full ">
                <div id="favicon">
                  <Text
                    as="p"
                    className="mb-6 text-secondary-300 font-medium text-lg"
                  >
                    Show your logo on browser bar:
                  </Text>

                  <div
                    className={`flex flex-row justify-between ${
                      _.isNull(user.photographer.favicon_url) ? "mb-8" : null
                    }`}
                  >
                    <div className="flex flex-col ">
                      <Text className="mb-4 font-medium text-base" as="p">
                        Custom favicon
                      </Text>
                      <Text className="whitespace-pre-line text-sm text-secondary-300 font-normal">
                        {
                          "Upload your logo as  GIF, PNG or ICO file up to 32x32 pixels"
                        }
                      </Text>
                      {/* <img className='h-[100px] w-[100px] mt-5' src={process.env.REACT_APP_FILE_ENDPOINT+user.photographer.favicon_url} /> */}
                      {!_.isNull(user.photographer.favicon_url) && (
                        <img
                          className="h-[32px] w-[32px] mt-5"
                          src={OG_IMAGE_CDN + user.photographer.favicon_url}
                          alt="favicon"
                        />
                      )}
                    </div>
                    <div className="flex ">
                      <SwitchWithTitle
                        className="mb-8 font-medium !max-w-[28rem]"
                        value={photographerFavicon}
                        onChange={() => {
                          photographerFavicon == true
                            ? updateFavicon(false)
                            : updateFavicon(true);
                        }}
                      />
                    </div>
                  </div>

                  {photographerFavicon && (
                    <>
                      <InputFile
                        className={`flex flex-col mt-5`}
                        label="Upload favicon"
                        overlapLabel="Upload"
                        allowedFileTypes={["png", "ico", "jpg", "jpeg"]}
                        fileName={
                          _.truncate(user.photographer.favicon_url) ?? null
                        }
                        onFileChange={(file) => {
                          handleFavIconFileChange(file);
                        }}
                      />
                    </>
                  )}
                </div>

                {/* <div
                  id="chatbot"
                  className={`${photographerFavicon ? "mt-10" : ""}`}
                >
                  <div className={`flex justify-between `}>
                    <div className="flex flex-col ">
                      <Text className="mb-4 font-medium text-base" as="p">
                        Chat Bot
                      </Text>
                      <Text className="whitespace-pre-line text-sm text-secondary-300 font-normal">{`${
                        user.photographer.show_chat_bot
                          ? "Chatbot enabled globally for all events"
                          : "Chatbot disabled for all events"
                      }`}</Text>
                    </div>
                    <div className="flex flex-col">
                      <SwitchWithTitle
                        className="mb-8 font-medium !max-w-[28rem]"
                        value={chatbot}
                        onChange={() => {
                          chatbot == true
                            ? updateChatBot(false)
                            : updateChatBot(true);
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div id="watermark" className="mt-[6.25rem]" ref={watermarkRef}>
            <WatermarkSection
              logoType={waterMarkSettings}
              setLogoType={setWaterMarkSettings}
            />
          </div>

          <div id="analytics" className="mt-[6.25rem]" ref={analyticsRef}>
            <AnalyticsSection />
          </div>

          <div id="guestUpload" className="mt-[6.25rem]" ref={guestUploadRef}>
            <GuestUpload />
          </div>

          <div id="print-store" className="mt-[6.25rem]" ref={printStoreRef}>
            <PrintSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
