import Classic from "../../../assets/images/classic.png";
import Dark from "../../../assets/images/dark.png";
import Light from "../../../assets/images/light.png";
export const SelectTemplate = ({updateEventDetailsKey,faceEmailDetails}) =>{
    return(
        <div>
            <div className="text-lg mt-12">Choose email template</div>
                  
                  <div className="lg:w-10/12 grid grid-cols-3 grid-flow-row gap-4">
                    <div className="flex grid grid-flow-cols justify-center p-3 cursor-pointer">
                    <input type='radio' id="des1" checked={faceEmailDetails.layout==1} className='hidden designLayout' name="layout" value='1' onChange={e=>updateEventDetailsKey(e)} />
                      <label htmlFor="des1" className="cursor-pointer" >
                      <div className={`${(faceEmailDetails.layout == 1)?'shadow-inner-all': 'shadow-dark-input'}  rounded-2xl p-3`}>
                      <img
                        src={Dark}
                           className={`rounded-2xl ${(faceEmailDetails.layout == 1)?'border-teal-500 border-[1px]': ''}`}
                      />
                      </div>
                      <span className="flex justify-center mt-3">Dark</span>
                      </label>
                      
                    </div>
              
                    <div className=" grid grid-flow-cols justify-center p-3 cursor-pointer">
                    <input type='radio' id="des2" checked={faceEmailDetails.layout==2} className='hidden designLayout' name="layout" value='2' onChange={e=>updateEventDetailsKey(e)} />
                    <label htmlFor="des2" className="cursor-pointer" >
                      <div className={`${(faceEmailDetails.layout == 2)?'shadow-inner-all': 'shadow-dark-input'}  rounded-2xl p-3`}>
                      <img
                        src={Light}
                         className={`rounded-2xl ${(faceEmailDetails.layout == 2)?'border-teal-500 border-[1px]': ''}`}
                      />
                      </div>
                      <span className="flex justify-center mt-3">Light</span>
                      </label>
                     
                    </div>
                    
                    {/* <div className="flex grid grid-flow-cols justify-center p-3 cursor-pointer">
                    <input type='radio' id="des3" checked={faceEmailDetails.layout==3} className='hidden designLayout' name="layout" value='3' onChange={e=>updateEventDetailsKey(e)} />
                    <label htmlFor="des3" className="cursor-pointer" >
                      <div className={`${(faceEmailDetails.layout == 3)?'shadow-inner-all': 'shadow-dark-input'}  rounded-2xl p-3`}>
                      <img
                        src={Classic}
                         className={`rounded-2xl ${(faceEmailDetails.layout == 3)?'border-teal-500 border-[1px]': ''} `}
                      />
                      </div>
                      <span className="flex justify-center mt-3">Gradient</span>
                      </label>
                     
                    </div> */}
                  </div>
        </div>
    )
}