import React, { useState } from "react";
import Swal from "sweetalert2"

import DialogWrapper from "../../../../../components/common/Dialog";
import { useUploader } from "../../../../../Store/Uploader";
import AddImages from "../../../../UploadContent/components/AddImages";
import AddVideoLink from "../../../../UploadContent/components/AddVideoLink";

const AddMoreOptions = ({
  setShowAddMoreOptions,
  setShowImageUploader,
  setShowVideoUploader,
}) => {
  const { isUploading } = useUploader();
  const handleImageUpload = () => {
    if (isUploading) return false;
    setShowImageUploader(true);
    setShowAddMoreOptions(false);
    // Swal.fire({
    //     icon: "warning",
    //     title: "Maintenance Alert",
    //     text: "Image Uploads are temporarily disabled on web application due to maintenance activity. Please use Algoshare desktop app for seamless image uploads",
    //   });
  };
  const handleVideoUpload = () => {
    if (isUploading) return false;
    setShowVideoUploader(true);
    setShowAddMoreOptions(false);
  };
  return (
    <div className="absolute bg-dark-gray shadow-dark-input w-[170px] right-[0px] top-[35px] rounded-xl z-40 mt-3 overflow-hidden">
      <div className="flex flex-col cursor-pointer light:text-dark-gray1 text-light-gray text-sm">
        <div
          className={`${
            isUploading
              ? "cursor-not-allowed text-slate-500"
              : "cursor-pointer hover:text-cyan-500 hover:bg-black"
          }  px-4 py-3`}
          onClick={handleImageUpload}
        >
          Images
        </div>
        <div
          className={`${
            isUploading
              ? "cursor-not-allowed text-slate-500"
              : "cursor-pointer hover:text-cyan-500 hover:bg-black"
          }  px-4 py-3`}
          onClick={handleVideoUpload}
        >
          Video Link
        </div>
      </div>
    </div>
  );
};

export default AddMoreOptions;
