import ReactGA from "react-ga4";
import moment from "moment-timezone";

import {
  getAnalyticsDownload,
  updatePhotographerFavicon,
  updatePhotographerLogo,
  updatePhotographerTrackingId,
} from "../apis";

export const getCookie = (name) => {
  let cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="));
  if (!cookie) {
    return null;
  }
  return cookie.split("=")[1];
};

export const setCookie = (name, value) => {
  document.cookie = name + "=" + value + ";path=/";
};

export const getOptimizedImage = (url, size = 1600) => {
  if (url.includes("?cr=")) {
    return url;
  }
  return url + "?w=" + size;
};

export const getFileUrl = (url) => {
  return process.env.REACT_APP_FILE_ENDPOINT + url;
};

export const onFavIconFileChange = (file) => {
  updatePhotographerFavicon(file).then(function (response) {
    //console.log(response);
  });
};

export const onLogoFileChange = (file) => {
  updatePhotographerLogo(file).then(function (response) {
    //console.log(response);
  });
};

export const onGoogleAnalyticsChange = (url) => {
  updatePhotographerTrackingId(url)
    .then((response) => {
      window.Toast.fire({
        icon: "success",
        title: url
          ? "Tracking ID added successfully"
          : "Tracking ID deleted successfully",
      });
    })
    .catch((error) => {
      window.Toast.fire({
        icon: "error",
        title: "Tracking ID could not be added",
      });
    });

  return true;
};

export const deleteTrackingId = () => {
  onGoogleAnalyticsChange(null);
};

export const validateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
export const copyText = (e, text) => {
  e.preventDefault();
  e.target.innerHTML = "Copied";
  setTimeout(() => {
    e.target.innerHTML = "Copy";
  }, 1500);
  navigator.clipboard.writeText(text);
};

export const initialiseGA = () => {
  const TRACKING_ID = "G-R7VZ0BBRF9";
  ReactGA.initialize(TRACKING_ID);
};

export const titleUpdate = (title, description = false) => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });

  document.title = title + " | AlgoShare";
  // document.getElementsByTagName('title')
};

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  return (bytes / Math.pow(1000, i)).toFixed(2) + " " + sizes[i];
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const createCSVFile = async (filter, eventId, type, fileName) => {
  const startDate =
    filter === "Lifetime"
      ? null
      : filter === "This Week"
      ? moment().startOf("week").toDate()
      : filter === "This Month"
      ? moment().startOf("month").toDate()
      : null;
  const endDate =
    filter === "Lifetime"
      ? null
      : filter === "This Week"
      ? moment().endOf("week").toDate()
      : filter === "This Month"
      ? moment().endOf("month").toDate()
      : null;

  const payload = {
    type,
    startDate,
    endDate,
    eventId,
  };

  const { data } = await getAnalyticsDownload(payload);
  const blob = new Blob([data], {
    type: "text/csv"
  });
  const url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.style = "display: none; visibility: hidden"; //safari needs visibility hidden
  a.href = url
  a.download = fileName + ".csv";
  document.body.appendChild(a);
  a.click();
  a.remove();
};
