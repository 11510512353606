import React, {useCallback, useEffect, useState} from 'react';
import {InputEditable, InputText} from '../../../../components/common/Input';
import Title from '../../../../components/common/Title';
import Vimeo from '../../../../assets/images/Vimeo-icon.png';
import Facebook from '../../../../assets/images/Facebook-icon.png';
import Instagram from '../../../../assets/images/Instagram-icon.png';
import Linkedin from '../../../../assets/images/Linkedin-icon.png';
import Youtube from '../../../../assets/images/Youtube-icon.png';
import Twitter from '../../../../assets/images/Twitter-icon.png';
import Loading from "../../../Loading";
import {updatePhotographerDetails,updatePhotographerSocialMedia} from "../../../../apis";
import {useUser} from "../../../../Store/User";
import _ from 'lodash';

const SocialLinks = (props) => {
    const {user, setUser} = useUser();

    const [photographer, setPhotographer] = useState({}),
        [errors, setErrors] = useState({}),
        [successStatus, setSuccessStatus] = useState({
            'vimeo': false,
            'facebook': false,
            'instagram': false,
            'linkedin': false,
            'youtube': false,
            'twitter': false,
        });


    useEffect(async () => {
        if (user.photographer) {
            setPhotographer({
                ...photographer,
                facebook: user.photographer.facebook ? user.photographer.facebook.replace('https://', '') : '',
                instagram: user.photographer.instagram ? user.photographer.instagram.replace('https://', '') : '',
                youtube: user.photographer.youtube ? user.photographer.youtube.replace('https://', '') : '',
                vimeo: user.photographer.vimeo ? user.photographer.vimeo.replace('https://', '') : '',
                linkedin: user.photographer.linkedin ? user.photographer.linkedin.replace('https://', '') : '',
                twitter: user.photographer.twitter ? user.photographer.twitter.replace('https://', '') : '',
            });
        }
    }, [user]);

    function validateWebsite(website, url) {
        let object = {...photographer};
        object[website] = url;
        setPhotographer(object);
        try {
            new URL('https://'+ url);
            if (!url.includes(website)) {
                let error = {...errors};
                error[website] = 'Not a valid ' + website + ' url';
                setErrors(error);
                return false;
            }
        } catch (_) {
            let error = {...errors};
            error[website] = 'Please provide a valid url.';
            setErrors(error);
            return false;
        }
        let error = {...errors};
        error[website] = null;
        setErrors(error);
        setPhotographer({...object});
        return true;

    }

    function updateSocialLink(website, value) {
        let websiteName = website.replace('.com', '');

        setSuccessStatus({...successStatus, [websiteName]: false});
        if(_.isEmpty(value)){
            updatePhotographerSocialMedia(websiteName, "null").then(response => {
                setSuccessStatus({...successStatus, [websiteName]: true});
                setErrors({...errors, [websiteName]: null});
            }).catch(error => {
                setErrors({
                    ...errors, [websiteName]:
                        error?.response?.data?.message
                });
            });
            return;
        }

        if (validateWebsite(website, value) === true) {
            updatePhotographerDetails(photographer).then(response => {
                setSuccessStatus({...successStatus, [websiteName]: true});
                setErrors({...errors, [websiteName]: null});
            }).catch(error => {
                setErrors({
                    ...errors, [websiteName]:
                    error?.response?.data?.message
                });
            });
        } else {
            setSuccessStatus({...successStatus, [websiteName]: false});
            setErrors({...errors, [websiteName]: 'Please provide a valid url.'});
        }
    }


    return !user || !user.photographer ? <Loading/> : <div>
        <div ref={props.setRef} id="social-links" className="mt-24">
            
            <Title
                className='pb-4 pt-4 border-secondary-600 border-b '
                text="Social Links"
            />
            

            <div className='mt-7 flex flex-wrap 2xl:flex-nowrap justify-between gap-y-8 gap-x-32 '>
                <div className='flex flex-col gap-y-8 w-full'>
                    <div className="flex items-center justify-start">
                        <img src={Vimeo} width="30px"/>
                        <InputEditable
                            prefix='https://'
                            key="vimeo"
                            type="url"
                            onChange={(e) => setPhotographer({...photographer, vimeo: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                            value={photographer.vimeo}
                            error={errors.vimeo}
                            placeholder={'vimeo.com/username'}
                            onUpdate={(value) => updateSocialLink('vimeo.com', value)}
                            required={false}
                            success={successStatus.vimeo}
                            className='min-w-[25rem] max-w-[25rem] ml-5'
                        />
                    </div>
                    <div className="flex items-center justify-start">
                        <img src={Facebook} width="30px"/>
                        <InputEditable
                            key="facebook"
                            className='min-w-[25rem] max-w-[25rem] ml-5'
                            prefix='https://'
                            type="url"
                            onChange={(e) => setPhotographer({...photographer, facebook: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                            value={photographer.facebook}
                            error={errors.facebook}
                            placeholder={'facebook.com/username'}
                            onUpdate={(value) => updateSocialLink('facebook.com', value)}
                            required={false}
                            success={successStatus.facebook}
                        />
                    </div>
                    <div className="flex items-center justify-start">
                        <div className='flex items-center w-full'>
                            <img src={Instagram} width="30px "/>
                            <InputEditable
                                key="instagram"
                                className='min-w-[25rem] max-w-[25rem] ml-5'
                                prefix='https://'
                                type="url"
                                onChange={(e) => setPhotographer({...photographer, instagram: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                                value={photographer.instagram}
                                error={errors.instagram}
                                placeholder={'instagram.com/username'}
                                onUpdate={(value) => updateSocialLink('instagram.com', value)}
                                required={false}
                                success={successStatus.instagram}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-y-8 w-full'>
                    <div className="flex items-center w-full">
                        <div className='flex items-center w-full'>
                            <img src={Linkedin} width="30px"/>
                            <InputEditable
                                key="linkedin"
                                className='min-w-[25rem] max-w-[25rem] ml-5'
                                prefix='https://'
                                type="url"
                                onChange={(e) => setPhotographer({...photographer, linkedin: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                                value={photographer.linkedin}
                                error={errors.linkedin}
                                placeholder={'linkedin.com/username'}
                                onUpdate={(value) => updateSocialLink('linkedin.com', value)}
                                required={false}
                                success={successStatus.linkedin}
                            />
                        </div>
                    </div>
                    <div className="flex w-full items-center">
                        <div className='flex items-center w-full'>
                            <img src={Youtube} width="30px "/>
                            <InputEditable
                                key="youtube"
                                className='min-w-[25rem] max-w-[25rem] ml-5'
                                prefix='https://'
                                type="url"
                                onChange={(e) => setPhotographer({...photographer, youtube: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                                value={photographer.youtube}
                                error={errors.youtube}
                                placeholder={'youtube.com/username'}
                                onUpdate={(value) => updateSocialLink('youtube.com', value)}
                                required={false}
                                success={successStatus.youtube}
                            />
                        </div>
                    </div>
                    <div className="flex items-center w-full">
                        <div className='flex items-center w-full'>
                            <img src={Twitter} width="30px"/>
                            <InputEditable
                                key="twitter"
                                className='min-w-[25rem] max-w-[25rem] ml-5'
                                prefix='https://'
                                type="url"
                                onChange={(e) => setPhotographer({...photographer, twitter: e.target.value.replaceAll('https://', '').replaceAll('http://', '').trim()})}
                                value={photographer.twitter}
                                error={errors.twitter}
                                placeholder={'twitter.com/username'}
                                onUpdate={(value) => updateSocialLink('twitter.com', value)}
                                required={false}
                                success={successStatus.twitter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SocialLinks;
