import React, {useCallback, useEffect, useRef, useState} from 'react';
import Text from '../../../../../components/common/Text';
import Title from '../../../../../components/common/Title';
import {InputEditable, InputText,TextareaEditable} from '../../../../../components/common/Input';
import {useUser} from "../../../../../Store/User";
import {getUserDetails, updatePhotographerDomain} from "../../../../../apis";
import ProfileTitleSection from '../../../components/ProfileTitleSection';
import MobilePortfolioTitleSection from '../MobilePortfolioTitleSection';
import DownArrow from '../../../../../assets/icons/DownArrow';
import Button from '../../../../../components/common/Button';
import MobilePortfolioPreview from '../../../../../assets/images/MobilePortfolioPreview_img.png';
import GuestVisits from '../../../../../assets/images/GuestVisits.png';
import { Link } from "react-router-dom";
import photo from "../../../../../assets/images/photo.png";
import _ from 'lodash';
import {getPortfolioDetails,updatePhotographerAbout, updatePortfolioAppName} from "../../../../../apis";
import SliderComponent from "../../../../../components/common/Slider";

const EditPortfolio = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const {user, setUser} = useUser();
    const [about,setAbout]=useState('');
    const [appName,setAppName]=useState('');
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [isImageSectionActive, setIsImageSectionActive] = useState(true);
    const [isVideoSectionActive, setIsVideoSectionActive] = useState(false);
    const [skipDuplicate, setSkipDuplicate] = useState(true);
    const [overWriteDuplicate, setOverWriteDuplicate] = useState(false);
    // console.log(user);
    useEffect(() => {
        !_.isEmpty(user) && setAppName(user.photographer.mobile_app_name ? user.photographer.mobile_app_name : user.photographer.brand_name );
        !_.isEmpty(user) && setAbout(user.photographer.about);
    }, [user]);

    const updateAppName = e => {
        updatePortfolioAppName(e).then(response => {
            window.Toast.fire({
                icon: 'success',
                title: 'Details updated successfully'
            });
            setUser({...user, "access_token": response.access_token})
            setActiveStep(activeStep + 1);
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });
    };

    const updateBio = e => {
        if(e.length>500){
            window.Toast.fire({
                icon: 'error',
                title: 'Bio should not be more than 500 characters'
            });
            return;
        }

        updatePhotographerAbout(e).then(response => {
            window.Toast.fire({
                icon: 'success',
                title: 'Details updated successfully'
            });
            setUser({...user, "access_token": response.access_token})
            setActiveStep(activeStep + 1);
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });
    };



    // console.log('user',user);
    return   <div className='min-h-screen' >
                <MobilePortfolioTitleSection/>
                <div className="flex pb-6 ">

                    <div className="flex flex-col ml-10 w-2/3">
                        {/* steps section */}
                        <div className="flex flex-col mt-7">
                            <div className='flex mb-11' >
                                <Text className="font-medium text-xl" >Edit your portfolio in just 3 easy steps!</Text>
                            </div>

                            {/* <div className='flex w-full pt-11 justify-center items-center' >

                                <span className={`rounded-full w-5 h-5 mb-3 ${(activeStep == 1 )  ? "bg-primary-green" : "bg-primary-red" }` } onClick={()=>{setActiveStep(1)}} ></span>

                                <div className={` h-[0.625rem] border-none mb-3 w-1/2 ${(activeStep == 1  )  ? "bg-zinc-700 shadow-sidebar" : "bg-primary-red"}` }></div>

                                <span className={`rounded-full w-5 h-5 mb-3 ${(activeStep == 2 )  ? "bg-primary-green" : (activeStep == 3 ) ? "bg-primary-red" : (activeStep == 4 ) ? "bg-primary-red" : "bg-zinc-700"  }` } onClick={()=>{setActiveStep(2)}} ></span>
                                <div className={` h-[0.625rem] border-none mb-3 w-1/2 ${(activeStep == 2 )  ? "bg-zinc-700 shadow-sidebar" : (activeStep == (1)) ? "bg-zinc-700 shadow-sidebar" : 'bg-primary-red'}` }></div>

                                <span className={`rounded-full w-5 h-5 mb-3 ${(activeStep == 3 )  ? "bg-primary-green" : (activeStep == 4 ) ? "bg-primary-red" : "bg-zinc-700"}` } onClick={()=>{setActiveStep(3)}} ></span>
                                <div className={` h-[0.625rem] border-none mb-3 w-1/2 ${(activeStep == 3 )  ? "bg-zinc-700 shadow-sidebar" : (activeStep == (2)) ? "bg-zinc-700 shadow-sidebar" : (activeStep == (1)) ? "bg-zinc-700 shadow-sidebar" : 'bg-primary-red'}` }></div>

                                <span className={`rounded-full w-5 h-5 mb-3 ${(activeStep == 4 )  ? "bg-primary-green" : "bg-zinc-700"}` } onClick={()=>{setActiveStep(4)}} ></span>

                            </div>

                            <div className="flex justify-between w-full">

                                <div className='flex flex-col ' >

                                    <Text as='p' className="text-left text-xl font-medium"  >Step 1</Text>
                                    <Text as='p' className="text-base" >Name your <br/> App</Text>
                                </div>

                                <div className='flex flex-col ' >

                                    <Text as='p' className="text-center text-xl font-medium" >Step 2</Text>
                                    <Text as='p' className="text-center text-base" >Add your bio</Text>
                                </div>

                                <div className='flex flex-col ' >

                                    <Text as='p' className="text-center text-xl font-medium" >Step 3</Text>
                                    <Text as='p' className="text-center text-base" >Add or delete <br/> Images </Text>
                                </div>

                                <div className='flex flex-col ' >

                                    <Text as='p' className='text-right text-xl font-medium' >Step 4</Text>
                                    <Text as='p' className="text-center text-base" >Send for <br/> review</Text>
                                </div>
                            </div> */}

                            <SliderComponent
                                defaultValue = {0}
                                marks={ {
                                    0: 'Step 1',
                                    1: 'Step 2',
                                    2: 'Step 3',
                                }}
                                min={0}
                                max={2}
                                onChange={(e)=>{console.log(setActiveStep(e+1))}}

                             />
                        </div>



                         {/*Center Section  */}
                            <div className='flex w-full h-full shadow-image mt-16 rounded-xl pb-3' >


                                {activeStep === 1 &&
                                <div className="flex flex-col ml-7 mr-7 mt-9 pb-14 w-full h-full">
                                <Text className='text-xl font-medium text-secondary-300'>
                                    Add your bio
                                </Text>
                                <Text className='text-sm text-secondary-300 mt1'>
                                    Bio will appear in the About us section of your mobile portfolio
                                </Text>
                                <div className="rounded-xl mt-3 w-full h-full">
                                    <textarea rows="6" value={about} onChange={(e)=>{setAbout(e.target.value)}} className={`w-full pl-6 bg-secondary-800 pt-7 ${about ? 'shadow-input-filled' : 'shadow-card'}`} placeholder="Type here...">

                                    </textarea>
                                </div>
                                <Text className='text-sm text-secondary-600 mt-3'>
                                    Maximum 500 characters
                                </Text>
                                </div>
                                }

                                {activeStep === 2 &&
                                    <div className="flex flex-col w-full h-full px-9 pb-9">

                                            <div className="flex justify-between">

                                                <div className='flex gap-10 mt-7 justify-center text-sm'>
                                                    <div className={`flex items-center justify-center w-[9rem]   p-3 rounded-xl cursor-pointer ${isImageSectionActive ? 'shadow-text-shadow' : ''}`} onClick={()=>{setIsImageSectionActive(!isImageSectionActive);setIsVideoSectionActive(!isVideoSectionActive)}} >
                                                        <Text className={`text-center ${isImageSectionActive ? 'text-primary-green' : ''}`} >Images</Text>
                                                        <span className='w-[1.438rem] h-[1.25rem] bg-secondary-600 mt-[0.094rem] mb-[0.094rem] ml-[0.65rem] rounded-l text-xs pt-[0.125rem] pl-[0.25rem] pl-[0.25rem] pb-[0.063rem] ' >30</span>
                                                    </div>

                                                    <div className={`flex items-center justify-center w-[9rem]   p-3 rounded-xl cursor-pointer ${isVideoSectionActive ? 'shadow-text-shadow' : ''}`} onClick={()=>{setIsImageSectionActive(!isImageSectionActive);setIsVideoSectionActive(!isVideoSectionActive)}} >
                                                        <Text className={`text-center ${isVideoSectionActive ? 'text-primary-green' : ''}`}  >Videos</Text>
                                                        <span className='w-[1.438rem] h-[1.25rem] bg-secondary-600 mt-[0.094rem] mb-[0.094rem] ml-[0.65rem] rounded-l text-xs pt-[0.125rem] pl-[0.25rem] pl-[0.25rem] pb-[0.063rem] ' >30</span>
                                                    </div>
                                                </div>

                                                <div className='flex gap-10 mt-7 justify-center text-sm'>
                                                    <div className='shadow-inner-all  rounded-md w-[12.5rem] p-3'>
                                                        <div className='flex items-center'>
                                                            <input
                                                                type='radio'
                                                                checked='checked'
                                                                name='radio-button'
                                                                className='accent-cyan-500'
                                                            />
                                                            <span className='pl-2'>Skip Duplicates</span>
                                                        </div>
                                                    </div>
                                                    <div className='items-center shadow-dark-input rounded-md w-[12.5rem] p-3'>
                                                        <div className='flex items-center'>
                                                            <input
                                                                type='radio'
                                                                name='radio-button'
                                                                className='accent-cyan-500 border'
                                                            />
                                                            <span className='pl-2'>Overwrite Duplicates</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex flex-col mt-6 rounded-lg p-5 justify-center items-center w-full border border-dashed border-zinc-700 w-full h-full'>
                                            <div className='mb-2'>
                                                <img
                                                    src={photo}
                                                    width='60'
                                                    text-align='center'
                                                    height='60'
                                                    alt='drag & drop your photo'
                                                />
                                            </div>
                                            <div className='text-sm'>
                                                Drag & drop images to upload
                                                <span className='mt-2 flex justify-center flex-wrap'>
                                                    OR
                                                </span>
                                            </div>
                                            <Button className='mt-4 text-sm px-6' text='Browse Files' />
                                            <div className='text-xs mt-5 text-zinc-400'>
                                                Maximum upload file size: 8 MB
                                            </div>
                                        </div>
                                    </div>
                                }



                                {activeStep === 3 &&
                                    <div className="flex flex-col w-full h-full px-9 pb-9">
                                            <div className="flex justify-between">
                                            <div className='flex gap-10 mt-7 justify-center text-sm'>
                                                    <div className={`flex items-center justify-center w-[9rem]   p-3 rounded-xl cursor-pointer ${isImageSectionActive ? 'shadow-text-shadow' : ''}`} onClick={()=>{setIsImageSectionActive(!isImageSectionActive);setIsVideoSectionActive(!isVideoSectionActive)}} >
                                                        <Text className={`text-center ${isImageSectionActive ? 'text-primary-green' : ''}`} >Images</Text>
                                                        <span className='w-[1.438rem] h-[1.25rem] bg-secondary-600 mt-[0.094rem] mb-[0.094rem] ml-[0.65rem] rounded-l text-xs pt-[0.125rem] pl-[0.25rem] pl-[0.25rem] pb-[0.063rem] ' >30</span>
                                                    </div>

                                                    <div className={`flex items-center justify-center w-[9rem]   p-3 rounded-xl cursor-pointer ${isVideoSectionActive ? 'shadow-text-shadow' : ''}`} onClick={()=>{setIsImageSectionActive(!isImageSectionActive);setIsVideoSectionActive(!isVideoSectionActive)}} >
                                                        <Text className={`text-center ${isVideoSectionActive ? 'text-primary-green' : ''}`}  >Videos</Text>
                                                        <span className='w-[1.438rem] h-[1.25rem] bg-secondary-600 mt-[0.094rem] mb-[0.094rem] ml-[0.65rem] rounded-l text-xs pt-[0.125rem] pl-[0.25rem] pl-[0.25rem] pb-[0.063rem] ' >30</span>
                                                    </div>
                                                </div>

                                                <div className='flex gap-10 mt-7 justify-center text-sm'>
                                                    <button
                                                        className={`rounded-lg shadow-button font-sm w-[6.7rem] h-[1.87rem] `}

                                                    >Add Photos</button>
                                                </div>
                                            </div>

                                            <div className='flex flex-col mt-4' >
                                                <Text as="p" className="text-sm" >Drag and move images to change the order</Text>

                                                {/* section for showing images and videos */}
                                                <div className='flex flex-col mt-6 rounded-lg p-5 justify-center items-center w-full border border border-zinc-700 w-full h-full'>


                                                </div>
                                            </div>


                                    </div>
                                }
                            </div>

                        {/* Button Section */}
                        <div className={`flex mt-10 ${activeStep == 4 ? 'justify-between' : 'justify-end'}`} >
                        {/* <div className={`flex mt-10 justify-end'`} > */}

                             {activeStep == 1 &&
                                <>
                                <button

                                    className='className="text-sm text-center text-light-gray shadow-button-new rounded-lg py-[9px] px-[16px] font-semibold w-[11rem] h-9 mx-4'
                                    onClick={(e)=>{setActiveStep(activeStep + 1)}}
                                >Skip to next step</button>
                                <Button
                                    text="Save Changes"
                                    disabled={!about}
                                    className='h-9'
                                    onClick={(e)=>{updateBio(about)}}
                                />
                                </>
                             }

                            {activeStep == 2 &&
                                <>
                                <button

                                    className='className="text-sm text-center text-light-gray shadow-button-new rounded-lg py-[9px] px-[16px] font-semibold w-[11rem] h-9 mx-4'
                                    onClick={(e)=>{setActiveStep(activeStep + 1)}}
                                >Skip to next step</button>
                                <Button
                                    text="Save Changes"
                                    disabled={!about}
                                    className='h-9 ml-10'
                                    onClick={(e)=>{updateBio(about)}}
                                />
                                </>
                             }

                            {activeStep == 3 &&
                                <>
                                <Button
                                    text="Save Changes"
                                    // disabled={!bio}
                                    className='h-9'
                                />
                                </>
                             }
                        </div>
                    </div>



                    <div className="flex flex-col w-1/3 content-center items-center">
                        {/* <div id="mobile-portfolio-show">
                            <div className="grid grid-rows-2 grid-flow-col gap-4 mb-20">
                                <div className="row-span-2">
                                    <div className='flex justify-center text-base pb-6'>Preview</div>
                                    <div className='flex justify-center'><img src={MobilePortfolioPreview} className='' width="300"/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="flex flex-col justify-center items-center mt-14">
                            <div className='text-base'>Preview</div>
                            <div className='mt-3'>
                                <img src={MobilePortfolioPreview} className='w-64' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

};

export default EditPortfolio;
