import React, {  useState } from "react";

import Button from "../../components/common/Button";
import CreateEvent from "../../components/common/CreateEvent";
import DropDown from "../../components/common/DropDown";
import SortByDropDown from "../../components/common/DropDown/SortBy";
import "../Event/components/Popups/SortingOptions/sortingOptions.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Store/User";

const SearchEvents = ({
  onKeyUp,
  searchQuery = "",
  tagClicked,
  sortEvents,
  handleEventModal,
  addEvent,
  buttonDisabled,
}) => {
  const { user } = useUser();
  const [showEventCreateModal, setShowEventCreateModal] = useState(false);
  const navigate = useNavigate();



  function handleEventCreateModal() {
    handleEventModal(true);
    setShowEventCreateModal(true);
  }

  function handleEventCreateModalClose(isModalOpen) {
    setShowEventCreateModal(isModalOpen);
  }

  return (
    <div className="mx-auto">
      <div className="grid grid-cols-1 xl:grid-cols-1 gap-y-2.5 flex items-center text-slate-50 mb-5">
        <div className="flex w-full text-3xl text-secondary-300 font-medium">
          My Events
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-gap gap-5 flex-wrap">
            <div>
              <div className="relative">
                <i className="absolute top-3 left-4">
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </i>
                <input
                  onChange={(e) => {
                    onKeyUp(e.target.value);
                  }}
                  type="text"
                  placeholder="Search Events"
                  size="31"
                  value={searchQuery}
                  className="bg-secondary-900 text-sm h-9 px-12 rounded-lg placeholder-secondary-500 placeholder:text-sm  outline outline-transparent focus:outline-transparent w-[22rem]"
                  name="search"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-gap gap-5 flex-wrap justify-end items-center mt-5 lg:mt-0">
            <div>
              <button
                id="global-analytics"
                onClick={() => {
                  navigate("/global-analytics");
                }}
                className="text-primary-green whitespace-nowrap underline cursor-pointer text-base font-semibold"
              >
                Global Analytics
              </button>
            </div>
            {/* <div>
                        <DropDown/>
                    </div> */}
            <div
              id="general-settings"
              onClick={() => {
                navigate("/general-settings");
              }}
              className="flex items-center text-sm shadow-card rounded-[10px] w-[2.575rem] h-[1.87rem]"
              style={{ padding: "2px 4px" }}
            >
              <span className="pl-2 cursor-pointer">
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>

            <div>
              <SortByDropDown sortEvents={sortEvents} />
            </div>

            {/* <div
                        className='flex relative items-center text-sm shadow-button rounded-lg whitespace-nowrap text-colors-secondary-800 w-[6.87rem] h-[1.875rem] rounded-xl '>{sortFilterTitle}
                        <span  className='pl-2 cursor-pointer' onClick={toggleSortBy} ><DownArrow className="w-3 h-3" ></DownArrow></span>
                            <div className='absolute p-4 bg-dark-gray shadow-dark-input w-[200px] z-40 w-[200px] right-2 top-[95%]'>
                            <div className='flex flex-col gap-3 cursor-pointer text-colors-secondary-800 text-sm'>
                                <div className='disabled-view'>Date</div>
                                <div onClick={(e)=>{getSortType('desc','date-created')}}  className='hover:bg-violet-400' >New - Old</div>
                                <div onClick={(e)=>{getSortType('asc','date-created')}} className='hover:bg-violet-400' >Old - New</div>




                                <div className='border-t border-zinc-700 px-10 h-1 '></div>
                                <div className='disabled-view'>Name</div>
                                <div onClick={(e)=>{getSortType('asc','name')}} className='hover:bg-violet-400' >A - Z</div>
                                <div onClick={(e)=>{getSortType('desc','name')}} className='hover:bg-violet-400' >Z - A</div>

                                <div className='border-t border-zinc-700 px-10 h-1 '></div>
                                <div className='disabled-view'>Number of Images</div>
                                <div onClick={(e)=>{getSortType('asc','image-number')}} className='hover:bg-violet-400' >Smallest - Largest</div>
                                <div onClick={(e)=>{getSortType('desc','image-number')}} className='hover:bg-violet-400' >Largest - Smallest</div>
                            </div>
                        </div>
                    </div> */}
            {/* <div
                        className='flex relative items-center text-sm shadow-button rounded-lg p-2 px-3 whitespace-nowrap'>{tagFilterTitle}
                        <span onClick={toggleFilterBy} className='pl-2 cursor-pointer'><DownArrow></DownArrow></span>
                        {tagFilterOptions && <div className='absolute rounded-lg bg-dark-gray shadow-dark-input z-50 w-[200px] right-15 top-[99%]'>
                            <div className='flex select-none flex-col gap-3 text-colors-secondary-800 text-sm'>
                                <div onClick={()=>{eventWithAllTag()}} className="cursor-pointer capitalize px-4 py-2 hover:bg-violet-400">
                                    All tags
                                </div>
                                {
                                    tags.map(function (tag, key) {
                                        return <div key={key} onClick={() => {tagClicked(tag)}}
                                                    className="cursor-pointer capitalize px-4 py-2 hover:bg-violet-400">
                                            {tag}
                                        </div>;
                                    })
                                }
                            </div>
                        </div>}
                    </div> */}
            <div>
              <DropDown tagClicked={tagClicked} />
            </div>

            <Button
              title={
                !buttonDisabled
                  ? ""
                  : user.permissions.create_event == false
                  ? "Upgrade Subscription"
                  : "Trial expired"
              }
              id="create-event"
              className="rounded-[10px] font-semibold bg-button text-secondary-300 font-medium text-sm text-center whitespace-nowrap shadow-button"
              onClick={() => {
                handleEventCreateModal();
              }}
              text="Create Event"
              disabled={user.permissions.create_event ? false : true}
            />

            {showEventCreateModal ? (
              <CreateEvent
                handleEventCreateModalClose={handleEventCreateModalClose}
                handleEventModal={handleEventModal}
                addEvent={addEvent}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchEvents;
