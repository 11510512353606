import { useState, useRef, useCallback, useEffect } from "react";
import nextTick from "next-tick";

function useAsyncQueue(opts) {
  const { done, drain, inflight } = opts;
  let { concurrency } = opts;
  if (concurrency < 1) concurrency = Infinity;

  const [stats, setStats] = useState({
    numPending: 0,
    numInFlight: 0,
    numDone: 0,
  });

  const drained = useRef(true);
  const inFlight = useRef([]);
  const pending = useRef([]);
  const isStopped = useRef(false);

  useEffect(() => {
    if (isStopped.current) {
      return;
    }

    if (
      stats.numDone > 0 &&
      drain &&
      inFlight.current.length === 0 &&
      pending.current.length === 0 &&
      !drained.current
    ) {
      drained.current = true;
      return nextTick(drain);
    }

    while (
      inFlight.current.length < concurrency &&
      pending.current.length > 0
    ) {
      drained.current = false;
      const task = pending.current.shift();
      inFlight.current.push(task);
      setStats((stats) => ({
        ...stats,
        numPending: stats.numPending - 1,
        numInFlight: stats.numInFlight + 1,
      }));
      inflight && inflight({ ...task, stats });
      const result = task.task();
      result
        .then(() => {
          inFlight.current.pop();
          setStats((stats) => ({
            ...stats,
            numInFlight: stats.numInFlight - 1,
            numDone: stats.numDone + 1,
          }));
          done && done({ ...task, result, stats });
        })
        .catch(() => {
          inFlight.current.pop();
          setStats((stats) => ({
            ...stats,
            numInFlight: stats.numInFlight - 1,
            numDone: stats.numDone + 1,
          }));
          done && done({ ...task, result, stats });
        });
    }
  }, [concurrency, done, drain, inflight, stats]);

  const add = useCallback(
    (task) => {
      if (!isStopped.current) {
        pending.current.push(task);
        setStats((stats) => ({
          ...stats,
          numPending: stats.numPending + 1,
        }));
      }
    },
    [isStopped]
  );

  const stop = useCallback(() => {
    isStopped.current = true;
  }, []);

  const start = useCallback(() => {
    isStopped.current = false;
  }, []);

  return { add, stats, stop  , start};
}

export default useAsyncQueue;
