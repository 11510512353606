import React, { useState } from "react";

import DialogWrapper from "../../../../components/common/Dialog";
import ImageQueue from "../../../../components/common/ImageQueue";
import BottomUploadModal from "../BottomUploadModal";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import MinimizeIcon from "../../../../assets/icons/MinimizeIcon";
import { useUploader } from "../../../../Store/Uploader";
import PauseIcon from "../../../../assets/icons/PauseIcon";

const UploadModal = ({ open = true, close }) => {
  const { setShowUploader, isUploading, showStopUploadWarning } = useUploader();
  const [isMinimziedModal, showMinimziedModal] = useState(false);

  const openMinimizedModal = () => {
    showMinimziedModal(true);
  };

  const handleClose = () => {
    if (!isUploading) {
      setShowUploader(false);
    }
  };
  return (
    <>
      {!isMinimziedModal && (
        <DialogWrapper
          open={open}
          close={close}
          customWidth="width-100"
          hideTitle={true}
          hideClose={true}
        >
          <div className="flex justify-center flex-col">
            <div className="flex justify-between h-full items-center">
              <p className="text-md text-light-gray">Upload Queue</p>
              <div className="flex gap-4">
                {isUploading ? (
                  <button title="Pause Upload" onClick={showStopUploadWarning}>
                    <PauseIcon />
                  </button>
                ) : (
                  <></>
                )}
                <span className="cursor-pointer" onClick={openMinimizedModal}>
                  <MinimizeIcon fill={"#EEE"} />
                </span>
                <span className="cursor-pointer" onClick={handleClose}>
                  <CloseIcon
                    height="16"
                    fill={isUploading ? "#aaa" : "#eee"}
                    width="16"
                  />
                </span>
              </div>
            </div>
            <ImageQueue ulClass="max-h-[30rem] overflow-y-auto" />
          </div>
        </DialogWrapper>
      )}
      {isMinimziedModal && <BottomUploadModal />}
    </>
  );
};

export default UploadModal;
