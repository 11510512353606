import React from "react";

function BackButton(props) {
    const { className = "", text = "", disabled = false, onClick } = props;
    return (
        <button
            className={`rounded-lg -button shadow-dark-input py-[9px] px-[16px] dark:text-slate-100 font-semibold  ${className}`}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default BackButton;
