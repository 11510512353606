import React, { useEffect } from "react";
import { useUploader } from "../../../Store/Uploader";
import Router from "../Router";

function Main() {
  let { isUploading, toggleUploadFlag, callVectorInitApi } = useUploader();

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [isUploading]);

  const alertUser = (e) => {
    if (isUploading) {
      toggleUploadFlag();
      callVectorInitApi();
      e.preventDefault();
      e.returnValue = "";
    } else {
      return true;
    }
  };

  return <Router />;
}

export default Main;
