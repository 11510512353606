import { useState } from "react";
import { Link } from "react-router-dom";
import { initialiseGA } from "../../../functions";
import Button from "../Button";
import { useUploader } from "../../../Store/Uploader";

export default function CookiePopUp({handleEventModal, handleEventCreateModalClose, addEvent}) {
    const { showCookiePopup,setShowCookiePopup } = useUploader();
    let coockieStatus = localStorage.getItem('cookieAcceptance')
    let [coockieState,setCookieState] = useState(coockieStatus)

    let acceptCookies = ()=>{
        localStorage.setItem('cookieAcceptance','true')
        setCookieState('true')
        initialiseGA()
    }
    let declineCookies = ()=>{
        localStorage.setItem('cookieAcceptance','false')
        setCookieState('false')
        setShowCookiePopup(false)
    }
    

    return(
        <>
        {(coockieState !== 'true' && showCookiePopup) ?
        (
            <div className="flex justify-center items-center fixed w-full  bottom-2 z-[999] ">
                {/* <div className="text-sm w-[75%] mx-auto py-2 rounded-lg bg-white grid grid-cols-2 lg:grid-cols-6 gap-4 pl-6 pr-5"> */}
                <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row w-[75%]  py-2 rounded-lg bg-white justify-between px-4 ">
                    <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row text-center justify-start items-center text-black text-xs font-medium">We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies. &nbsp; <a className="text-sm underline" href="/privacy">Privacy Policy</a>
                    </div>
                    <div className="text-center 4xl:text-right whitespace-nowrap">
                        <button className="bg-black text-sm text-white border border-black p-1 mr-4 px-4 rounded-[10px]" onClick={acceptCookies}>Accept</button>
                        <button className="bg-white text-sm text-black border-black border p-1 px-4  rounded-[10px]" onClick={declineCookies}><abbr title="Reject Non Functional Cookies">Reject</abbr></button>    
                    </div>
                </div>
            </div>
        ):<></>}
        </>
        
    )

}