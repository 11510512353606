import React, { useEffect, useState } from "react";
import { useFolder } from "../../../Store/Folder/Show";
import { debounce } from "lodash";

function Search(props) {
    const { className, placeholder = "Search", size = "31" } = props;
    const [searchWord, setSearchWord] = useState("")
    const {setSearchTerm} = useFolder()
    useEffect(()=>{
            searchItem(searchWord)
    },[searchWord])
    const searchItem = debounce((k) => {
        setSearchTerm(k)
      }, 500)
    return (
        <div className='relative'>
            <i className='absolute top-3 left-4'>
            <svg className="w-4 h-4" fill="#eee" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">

                </path> </svg>
            </i>
            <input value={searchWord}
                   onChange={(e)=>setSearchWord(e.target.value)}
                   type="text"
                   placeholder={placeholder}
                   size={size}
                   className={`bg-black text-sm h-10 px-12 rounded-lg ${className}`}
                   name="search" />
        </div>
    );
}

export default Search;
