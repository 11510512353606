import React, { useState } from "react";
import GridChooser from "./gridChooser";

const LogoPositionManager = ({landScapeClicked, portraitClicked ,lvalue, pvalue}) => {

    return <div className="flex items-start">
        {/* <GridChooser pvalue={pvalue} portraitClicked={portraitClicked} alignment="vertical" key="vertical" /> */}
        <GridChooser lvalue={lvalue} landScapeClicked={landScapeClicked} alignment="horizontal" key="horizontal" />
    </div>;
}
export default LogoPositionManager;
