import React, { useEffect, useLayoutEffect, useRef } from "react";
import { getNotifications, userRegister } from "../../apis";
import uniqid from "uniqid";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import { getCookie } from "../../functions";
import { REFERRAL_KEY } from "../../constants";

const SocialLogin = ({
  setUser,
  userCameFrom,
  countryCode,
  loginPage,
  setRequestOnGoing,
}) => {
  const navigate = useNavigate();

  const buttonRef = useRef();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      registerWithGoogle(userInfo);
    },
  });

  function registerWithGoogle(user) {
    if (user) {
      const { source, medium, campaign } = getCookie(REFERRAL_KEY)
        ? JSON.parse(getCookie(REFERRAL_KEY))
        : {};
      userRegister(
        user.given_name,
        user.family_name,
        user.email,
        uniqid(),
        null,
        null,
        user.sub,
        null,
        userCameFrom,
        countryCode,
        source ?? document.referrer,
        medium,
        campaign
      )
        .then(async (response) => {
          window.Toast.fire({
            icon: "success",
            title: "Google Sign Up Successful",
          });
          if (userCameFrom === "algocull") {
            window.location = "algocull://?jwt=" + response.access_token;
          } else if (userCameFrom === "algoedit") {
            window.location = "algoedit://?jwt=" + response.access_token;
          } else {
            const access_token = response.access_token;
            localStorage.removeItem("access_token");
            Cookies.set("access_token", access_token, {
              expires: 7,
              secure: true,
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
            setUser({
              ...user,
              access_token: access_token,
            });
            setRequestOnGoing(true);
            if (userCameFrom === "payments") {
              window.location = `//payments.${process.env.REACT_APP_URL}`;
              return;
            } else if (userCameFrom === "uploader") {
              window.location = "uploader://?jwt=" + response.access_token;
            }
            navigate("/");
          }
        })
        .catch((errors) => {
          window.Toast.fire({
            icon: "error",
            title: "Google Sign Up Failed",
          });
        });
    }
  }

  function registerWithFacebook(response) {
    let user = response;

    if (user) {
      userRegister(
        user.first_name,
        user.last_name,
        user.email,
        uniqid(),
        null,
        null,
        null,
        user.id
      )
        .then((response) => {
          window.Toast.fire({
            icon: "success",
            title: "Facebook Sign Up Successful",
          });
          let access_token = response.access_token;
          setUser({ ...user, access_token: access_token });
          navigate("/");
        })
        .catch((errors) => {
          window.Toast.fire({
            icon: "error",
            title: "Facebook Sign Up Failed",
          });
        });
    }
  }

  useLayoutEffect(() => {
    if (
      loginPage &&
      (userCameFrom === "algocull" || userCameFrom === "algoedit")
    )
      setTimeout(() => buttonRef.current.click(), 100);
  }, []);

  return (
    <div className="gap-4 flex flex-wrap 2xl:flex-nowrap items-center justify-center">
      <div className="flex items-center justify-center  xl:justify-start 2xl:justify-start cursor-pointer">
        <div
          ref={buttonRef}
          onClick={() => login()}
          className="flex items-center w-7 h-7 shadow-card rounded-[10px]"
        >
          <img
            className="m-auto"
            src="https://algomage.s3.ap-south-1.amazonaws.com/images/google-icon.png"
            width="24"
            height="24"
            alt="Google"
          />
        </div>
        <label
          onClick={() => login()}
          className="pl-3 text-primary-green underline text-base font-semibold cursor-pointer whitespace-nowrap"
        >
          Sign up with Google
        </label>
      </div>

      {/* <FacebookLogin
                appId="504362424400334"
                scope="public_profile,email"
                fields="email,first_name,last_name,id"
                callback={registerWithFacebook}
                render={renderProps => (
                    <div onClick={renderProps.onClick}
                         className='flex items-center justify-center  xl:justify-start 2xl:justify-start cursor-pointer'>
                        <img
                            onClick={renderProps.onClick}
                            src="https://algomage.s3.ap-south-1.amazonaws.com/images/facebook-icon.png.png"
                            className="w-7 h-7 shadow-card"
                            alt='Facebook'
                        />
                        <label
                            onClick={renderProps.onClick}
                            className='pl-3 text-primary-green underline text-base underline font-semibold cursor-pointer whitespace-nowrap'>
                            Sign up with Facebook
                        </label>
                    </div>
                )}
            /> */}
    </div>
  );
};

export default SocialLogin;
