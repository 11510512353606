import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import Text from "../../../../../../components/common/Text";
import ThreeDots from "../../../../../../components/common/ThreeDots";
import Button from "../../../../../../components/common/Button";
import DownArrow from "../../../../../../assets/icons/DownArrow";
import EditIcon from "../../../../../../assets/icons/EditIcon";
import {useEvent} from "../../../../../../Store/Event/Show";
import {InputEditable, InputText} from "../../../../../../components/common/Input";
import {
    publishEvent,
    updateEventTitle,
} from "../../../../../../apis";
import SettingsOptions from "../../../../../../pages/MyEventsPinned/components/Popups/SettingsOptions";
import _ from "lodash";
import {useUser} from "../../../../../../Store/User";
import Swal from "sweetalert2";
import {useUploader} from "../../../../../../Store/Uploader";
// import { useEvent } from "../../../../../Store/Event/Show";

function TitleSection(props) {
    let {event,setEvent} = useEvent(),
        [showEventSettingsDropdown, setShowEventSettingsDropdown] = useState(false);
    let {isUploading, uploadFolders} = useUploader()
    const [editTitle, setEditTitle] = useState(false),
        {className = "", onClick} = props,
        [title, setTitle] = useState(null),
        {dashboardUrl} = useUser(),
        [titleError, setTitleError] = useState(null),
        navigate = useNavigate();

    function settingsClickHandler() {
        setShowEventSettingsDropdown(!showEventSettingsDropdown);
    }



    // return (
    //     !_.isEmpty(event) ?
    //         <div className='flex shadow-header px-10 items-center justify-between h-[5rem]'>
    //             <div className='flex items-center'>
    //         <span className="flex  min-w-[20rem]  items-center font-medium">
    //             <Link to={dashboardUrl}
    //                   className='font-medium mr-3 cursor-pointer' onClick={() => {
    //             }}
    //             >
    //                 <DownArrow className={"rotate-90"}/>
    //             </Link>
    //                 <Text
    //                     as='h1'
    //                     className='text-3xl text-light-gray'>
    //                     {`yaser`}
    //                 </Text>
    //                 <span
    //                     title={"Edit Event Name"}
    //                     className='font-medium ml-3'

    //                     >
    //                 </span>
    //             </span>
    //             </div>
    //         </div> : <></>
    // );
    return (
        <div className='flex items-center h-[4.6rem] shadow-header'>
    <div className='flex items-center w-full'>
        {/* <div className='flex' > */}
            <Link to={'/'}>
        <span
            className='flex pl-10  mr-6 items-center font-medium cursor-pointer
            text-primary-green text-base font-semibold underline'>
            <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
                Go Back
            </span>
            </Link>

            <Link to={`/events/${event.id}/analytics`}>
                <span
                    className='flex  mr-6 items-center font-medium cursor-pointer text-primary-green text-base font-semibold underline'>
                <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
                    Go Back to Analytics
                </span>
            </Link>
            <Text
            as='h1'
            className='flex text-3xl text-light-gray'
        >
            {`${event.name} - Favorite Lists`}
            </Text>
        {/* </div> */}



    </div>

        </div>
    );
}

export default TitleSection;
