import React, { useState, useEffect } from "react";
import _ from "lodash";

import Title from "../../../../components/common/Title";
import Text from "../../../../components/common/Text";
import GridSpacingSmall from "../../../../assets/icons/GridSpacingSmall";
import GridSpacingLarge from "../../../../assets/icons/GridSpacingLarge";
import GridSpacingRegular from "../../../../assets/icons/GridSpacingRegular";
import GridLayoutHorizontal from "../../../../assets/icons/GridLayoutHorizontal";
import ThumbnailLarge from "../../../../assets/icons/ThumbnailLarge";
import ThumbnailSmall from "../../../../assets/icons/ThumbnailSmall";
import dark from "../../../../assets/images/dark.png";
import light from "../../../../assets/images/light.png";
import { useEvent } from "../../../../Store/Event/Show";
import { updateGalleryLayout, updateRoundedImage } from "../../../../apis";
import "./galleryLayout.css";
import GridLayoutVertical from "../../../../assets/icons/GridLayoutVertical";
import ThumbnailRegular from "../../../../assets/icons/ThumbnailRegular";
import SwitchWithTitle from "../../../GeneralSettings/components/SwitchWithTitle";

const GalleryLayout = () => {
  const { event } = useEvent();
  const [imageRounded, setImageRounded] = useState(false);
  const [galleryLayout, setGalleryLayout] = useState({
    gridSpacing: null,
    gridLayout: null,
    thumbnailSize: null,
    backgroundColor: null,
  });

  useEffect(() => {
    !_.isEmpty(event) &&
      setGalleryLayout({
        gridSpacing: event.design.gridSpacing,
        gridLayout: event.design.gridLayout,
        thumbnailSize: event.design.thumbnailSize,
        backgroundColor: event.design.backgroundColor,
        isUpdated: false,
      });
    setImageRounded(event.isImageRounded);
  }, [event]);

  //function use for updating gallery layout. Here we are sending all the values of the gallery layout to the backend everytime we change any value in the gallery layout.
  useEffect(() => {
    if (!_.isEmpty(event) && galleryLayout.isUpdated === true) {
      updateGalleryLayout(event, galleryLayout)
        .then(function (response) {
          window.Toast.fire({
            icon: "success",
            title: "Event gallery design updated successfully",
          });
        })
        .catch((error) => {
          window.Toast.fire({
            icon: "error",
            title: "Event gallery design could not be updated",
          });
        });
    }
  }, [galleryLayout]);

  const handleRoundImage = (e) => {
    setImageRounded(e);
    updateRoundedImage(event, e)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Event gallery design updated successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Event gallery design could not be updated",
        });
        setImageRounded(!e);
      });
  };

  return (
    <>
      <Title
        className="pb-4 border-b border-secondary-600 mt-4"
        text="Gallery Layout"
      />
      <div className="flex flex-wrap justify-start mt-6 gap-x-36 gap-y-10">
        <div className="flex flex-col">
          <Text
            as="p"
            className="mb-[10px] text-secondary-300 text-base font-medium"
          >
            Grid spacing
          </Text>
          <div className="flex gap-5">
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    gridSpacing: "small",
                    isUpdated: true,
                  });
                }}
                id="gridSpacingSmall"
                className={`hover:cursor-pointer ${
                  galleryLayout.gridSpacing === "small"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <GridSpacingSmall galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Small</div>
            </div>
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    gridSpacing: "regular",
                    isUpdated: true,
                  });
                }}
                id="gridSpacingRegular"
                className={`hover:cursor-pointer ${
                  galleryLayout.gridSpacing === "regular"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <GridSpacingRegular galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Regular</div>
            </div>

            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    gridSpacing: "large",
                    isUpdated: true,
                  });
                }}
                id="gridSpacingLarge"
                className={`hover:cursor-pointer ${
                  galleryLayout.gridSpacing === "large"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <GridSpacingLarge galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Large</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Text as="p" className="mb-[10px] text-base font-medium">
            Grid layout
          </Text>
          <div className="flex gap-5">
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    gridLayout: "vertical",
                    isUpdated: true,
                  });
                }}
                id="gridLayoutVertal"
                className={`hover:cursor-pointer ${
                  galleryLayout.gridLayout === "vertical"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <GridLayoutVertical galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Vertical</div>
            </div>

            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    gridLayout: "horizontal",
                    isUpdated: true,
                  });
                }}
                id="gridLayoutHorizontal"
                className={`hover:cursor-pointer ${
                  galleryLayout.gridLayout === "horizontal"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <GridLayoutHorizontal galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Horizontal</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-start mt-10 gap-x-36 gap-y-10">
        <div className="flex flex-col">
          <Text as="p" className="mb-[10px] text-base font-medium">
            Thumbnails
          </Text>
          <div className="flex gap-5">
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    thumbnailSize: "small",
                    isUpdated: true,
                  });
                }}
                id="thumbnailSmall"
                className={`hover:cursor-pointer ${
                  galleryLayout.thumbnailSize === "small"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <ThumbnailSmall galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Small</div>
            </div>
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    thumbnailSize: "regular",
                    isUpdated: true,
                  });
                }}
                id="thumbnailRegular"
                className={`hover:cursor-pointer ${
                  galleryLayout.thumbnailSize === "regular"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <ThumbnailRegular galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Regular</div>
            </div>

            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    thumbnailSize: "large",
                    isUpdated: true,
                  });
                }}
                id="thumbnailLarge"
                className={`hover:cursor-pointer ${
                  galleryLayout.thumbnailSize === "large"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <ThumbnailLarge galleryLayout={galleryLayout} />
              </div>
              <div className="grid-text text-sm mt-4">Large</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Text as="p" className="mb-[10px] text-base font-medium">
            Preview theme for viewers
          </Text>
          <div className="flex gap-5">
            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    backgroundColor: "light",
                    isUpdated: true,
                  });
                }}
                id="backgroundLight"
                className={`hover:cursor-pointer ${
                  galleryLayout.backgroundColor === "light"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <div
                  className={
                    galleryLayout.backgroundColor === "light"
                      ? "border border-cyan-600 rounded-lg"
                      : ""
                  }
                >
                  <img src={light} alt="only-img-text" />
                </div>
              </div>
              <div className="grid-text text-sm mt-4">Light mode</div>
            </div>

            <div>
              <div
                onClick={() => {
                  setGalleryLayout({
                    ...galleryLayout,
                    backgroundColor: "dark",
                    isUpdated: true,
                  });
                }}
                id="backgroundDark"
                className={`hover:cursor-pointer ${
                  galleryLayout.backgroundColor === "dark"
                    ? "grid-layout-square-active"
                    : "grid-layout-square"
                }`}
              >
                <div
                  className={
                    galleryLayout.backgroundColor === "dark"
                      ? "border border-cyan-600 rounded-lg"
                      : ""
                  }
                >
                  <img src={dark} alt="only-img-text" />
                </div>
              </div>
              <div className="grid-text text-sm mt-4">Dark mode</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 pb-[40px]">
        {/* <Text as='p' className='text-secondary-300 text-xl'>
                        Image Rounded
                    </Text> */}

        <div
          id="downloads-size"
          className="flex flex-wrap 2xl:flex-nowrap gap-x-32 gap-y-8 mt-6"
        >
          <div className="flex flex-col basis-1/2 max-w-[29rem]">
            <SwitchWithTitle
              className="font-medium"
              title="Rounded corners"
              value={imageRounded}
              subtitle={
                "Toggle to switch between rounded v/s sharp corners for gallery images"
              }
              onChange={() => {
                imageRounded ? handleRoundImage(0) : handleRoundImage(1);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className='flex flex-row m-[20px] mb-[50px]'>
                <div className='flex flex-col basis-1/2'>
                    <Text as='p' className='mb-[10px]'>
                        Navigation style
                    </Text>
                    <div className='flex '>
                        <div>
                            <div className='grid-layout-square'>
                                <img src={ImageContainer} alt='only-img-text' />
                            </div>
                            <div className='grid-text'>Image</div>
                        </div>

                        <div>
                            <div className='grid-layout-square'>
                                <img src={ImagePlusText} alt='only-img-text' />
                                <span className='grid-layout-text'>
                                    Image Name
                                </span>
                            </div>
                            <div className='grid-text'>Image + Text</div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default GalleryLayout;
