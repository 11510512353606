import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import _ from "lodash";

import bulkUploadIcon from "../../../../assets/images/bulk-upload-icon.png";
import Button from "../../../../components/common/Backbutton";
import DialogWrapper from "../../../../components/common/Dialog";
import { useUploader } from "../../../../Store/Uploader";
import { useEvent } from "../../../../Store/Event/Show";
import { createNewFolder, getImages } from "../../../../apis";
import { useFolder } from "../../../../Store/Folder/Show";

const OneShopUpload = ({ open, close }) => {
  const [duplicates, setDuplicates] = useState("skip");
  const { event, setEvent } = useEvent();
  const [order, setOrder] = useState(0);
  const { onDrop, isUploading } = useUploader();
  const { setGuests } = useFolder();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    let folders = event.folders;
    if (folders.length > 0) {
      let newfolders = folders.filter(
        (folder) => folder.slug !== "guest-uploads"
      );
      if (newfolders.length > 0) {
        newfolders = newfolders.sort((a, b) => a.order - b.order);
        setOrder(_.last(newfolders).order + 1);
      }
    }
    setOrder(1);
  }, [event]);

  const startUpload = async (acceptedFiles) => {
    if (isUploading) {
      window.Toast.fire({
        icon: "error",
        title: "Please wait for the current upload to finish.",
      });
      return false;
    }

    if (acceptedFiles.length < 1) {
      window.Toast.fire({
        icon: "error",
        title: "No files to upload",
      });
      return false;
    }
    let pathArray = acceptedFiles[0].path.replace(/^\/|\/$/g, "").split("/");
    if (pathArray.length === 1) {
      window.Toast.fire({
        icon: "error",
        title:
          "Please select parent folder. You cannot upload individual images.",
      });
      return false;
    }
    if (pathArray.length !== 3) {
      window.Toast.fire({
        icon: "error",
        title: "Please select parent folder with subfolder as each subevent.",
      });
      return false;
    }

    let readyToUploadFolders = [];
    let folderWiseImagesToUpload = {};

    let existingFolderNames = [];
    event.folders.map((f) => {
      existingFolderNames[f.name.toLowerCase()] = f;
    });
    let folderWiseFiles = [];
    //Get all folders in selected files
    acceptedFiles.map((f) => {
      let folderName = f.path
        .replace(/^\/|\/$/g, "")
        .split("/")[1]
        .toLowerCase();
      if (!(folderName in folderWiseFiles)) {
        folderWiseFiles[folderName] = [];
      }
      folderWiseFiles[folderName].push(f);
    });
    let foldOrder = order;
    await Promise.all(
      Object.keys(folderWiseFiles).map(async (key) => {
        if (key in existingFolderNames) {
          if (
            window.confirm(`${key} already exists. Do you want to Merge it?`)
          ) {
            let response = await getImages(existingFolderNames[key].id);
            const imagesForFolder = response.data;
            const guestObject = {};
            if (response.guests) {
              response.guests.forEach((el) => (guestObject[el.id] = el.email));
            }
            setGuests(guestObject);
            if (imagesForFolder) {
              existingFolderNames[key].images = imagesForFolder;
              readyToUploadFolders.push(existingFolderNames[key]);
              folderWiseImagesToUpload[existingFolderNames[key].id] =
                folderWiseFiles[key];
            } else {
              existingFolderNames[key].images = [];
              readyToUploadFolders.push(existingFolderNames[key]);
              folderWiseImagesToUpload[existingFolderNames[key].id] =
                folderWiseFiles[key].map((f) => {
                  f.status = "Failed";
                  f.error = "Network Error";
                  return f;
                });
            }
          } else {
            readyToUploadFolders.push(existingFolderNames[key]);
            folderWiseImagesToUpload[existingFolderNames[key].id] =
              folderWiseFiles[key].map((f) => {
                f.status = "Failed";
                f.error = "Duplicate Folder";
                return f;
              });
          }
        } else {
          foldOrder++;
          let newFolder = await createNewFolder(event, key, foldOrder);
          if (newFolder) {
            setEvent((prev) => ({
              ...prev,
              folders: [...prev.folders, newFolder],
            }));
            readyToUploadFolders.push(newFolder);
            folderWiseImagesToUpload[newFolder.id] = folderWiseFiles[key];
          }
        }
      }),
      { concurrency: 1 }
    );
    onDrop(readyToUploadFolders, folderWiseImagesToUpload, duplicates, event);
    close();
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg",
    onDrop: startUpload,
    noClick: false,
    noKeyboard: false,
    multiple: true,
    preventDropOnDocument: false,
    disabled: isUploading,
  });
  return (
    <DialogWrapper open={open} close={close} customWidth="width-70">
      <div className="flex justify-center items-center  text-light-gray">
        <div className="w-full p-5 text-center rounded-lg">
          <div className="flex justify-between items-center text-xl font-normal">
            <div className="mx-auto text-lg items-center">One Shot Upload</div>
          </div>
          <div className="flex flex-wrap gap-6 mt-4 justify-center text-sm">
            <div
              className={`${
                duplicates === "skip"
                  ? "shadow-input-filled"
                  : "shadow-dark-input"
              } cursor-pointer rounded-md w-[200px] p-3`}
            >
              <div
                className="flex items-center"
                onClick={() => {
                  setDuplicates("skip");
                }}
              >
                <input
                  type="radio"
                  checked={duplicates === "skip" ? true : false}
                  name="duplicates"
                  onChange={() => {
                    setDuplicates("skip");
                  }}
                  value={"skip"}
                  className="accent-cyan-500"
                />
                <span className="pl-2 text-sm">Skip Duplicates</span>
              </div>
            </div>
            <div
              className={`items-center ${
                duplicates === "skip"
                  ? "shadow-dark-input"
                  : "shadow-input-filled"
              } cursor-pointer rounded-md w-[200px] p-3`}
            >
              <div
                className="flex items-center"
                onClick={() => {
                  setDuplicates("replace");
                }}
              >
                <input
                  type="radio"
                  checked={duplicates === "skip" ? false : true}
                  name="duplicates"
                  onChange={() => {
                    setDuplicates("replace");
                  }}
                  value={"replace"}
                  className="accent-cyan-500 border"
                />
                <span className="pl-2 text-sm">Overwrite Duplicates</span>
              </div>
            </div>
          </div>
          <div
            {...getRootProps({
              className:
                "flex flex-col mt-6 rounded-lg p-5 justify-center shadow-dark-input items-center w-full border border-dashed border-zinc-700",
            })}
          >
            <div className="mb-2">
              <img
                src={bulkUploadIcon}
                width="60"
                text-align="center"
                height="60"
                alt="drag & drop your pic"
              />
            </div>
            <input
              {...getInputProps()}
              directory=""
              webkitdirectory=""
              mozdirectory=""
            />
            <div className="text-base">
              Drag &amp; Drop event folder that contains sub-folders which have
              images
              <span className="mt-2 flex justify-center flex-wrap">OR</span>
            </div>
            <Button
              className="mt-4 text-base px-6"
              text="Browse Parent Folder"
            />
            {isSafari ? (
              <div className="text-zinc-400 mt-4 text-[10px]">
                Note:- We detected you are using safari browser. For better
                upload experience we recommend using google chrome
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default OneShopUpload;
