import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { InputEmail, InputText } from "../../components/common/Input";
import getStarted from "../../assets/images/illustration-get-started.png";
import Button from "../../components/common/Button";
import { useUser } from "../../Store/User";
import { titleUpdate } from "../../functions";
import SocialLogin from "../../components/SocialLogin/Index";
import { GoogleOAuthProvider } from "@react-oauth/google";

const SigninAdmin = () => {
  const navigate = useNavigate();
  const { setRequestOnGoing } = useUser();

  const [userLoginDetails, setUserLoginDetails] = useState({
    email: null,
    password: null,
  });
  const [errors, setErrors] = useState({});
  const { user, setUser, loginUser } = useUser();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [countryCode, setCountryCode] = useState("US");
  let search = window.location.search;
  let getParam = new URLSearchParams(search);
  let source = getParam.get("source");

  useEffect(() => {
    if (userLoginDetails.email && userLoginDetails.password) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [userLoginDetails]);

  useEffect(() => {
    titleUpdate(`Sign-In`);
    async function fetchData() {
      const response = await fetch("https://country.pixnif.com/");
      const data = await response.json();
      setCountryCode(data.country.code);
    }
    fetchData();
  }, []);

  const handleUserLogin = async (e) => {
    e.preventDefault();
    if (userLoginDetails.email === null || userLoginDetails.email === "") {
      setErrors({ ...errors, email: "Email is required" });
      return;
    }
    if (userLoginDetails.email) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!pattern.test(userLoginDetails.email.trim())) {
        setErrors({ ...errors, email: "Invalid email" });
        return;
      }
    }

    if (
      userLoginDetails.password == null ||
      userLoginDetails.password === " "
    ) {
      setErrors({ ...errors, password: "Password is required" });
      return;
    }
    setButtonDisable(true);
    try {
      await loginUser(userLoginDetails.email, userLoginDetails.password);

      if (source === "download") {
        navigate("/download");
        return;
      }
      if (source === "payments") {
        window.location = `//payments.${process.env.REACT_APP_URL}`;
        return;
      }
      navigate("/");
      setErrors({ ...errors, userLoginDetails: { email: "", password: "" } });
    } catch (error) {
      setButtonDisable(false);
      window.Toast.fire({
        icon: "error",
        title: "Login Failed",
      });

      setErrors({ ...errors, userLoginDetails: null });
      if (error?.response?.data?.message.includes("email")) {
        setErrors({ ...errors, email: error.response.data.message });
      }
      if (error?.response?.data?.message.includes("credentials")) {
        setErrors({
          ...errors,
          password: error.response.data.message,
        });
      }
    }
  };

  useEffect(() => {
    if (user.access_token && source === "algocull") {
      window.location = "algocull://?jwt=" + user.access_token;
    } else if (user.access_token && source === "algoedit") {
      window.location = "algoedit://?jwt=" + user.access_token;
    } else if (user.access_token && source === "uploader") {
      window.location = "uploader://?jwt=" + user.access_token;
    }
  }, []);

  return (
    <>
      {user.access_token ? (
        <Navigate to="/" />
      ) : (
        <div className="px-10 max-w-[80rem] mx-auto flex items-center align-middle min-h-[calc(100vh_-_80px)]">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 flex items-center text-secondary-300">
            <div className="lg:pb-10 lg:max-w-[30rem] xl:max-w-[30rem] 2xl:max-w-[30rem] 3xl:max-w-[30rem]">
              <div className="flex items-center justify-center pt-10 lg:justify-start xl:justify-start 2xl:justify-start 3xl:justify-start">
                <img
                  src={getStarted}
                  width="100"
                  height="101"
                  alt="Get Started"
                />
              </div>
              <div className="mt-4 text-base font-medium text-center text-color-secondary xl:justify-start 2xl:justify-start 3xl:justify-start lg:text-left xl:text-left 2xl:text-left 3xl:text-left ">
                About Algomage
              </div>
              <h1 className="text-secondary-300 text-center font-medium text-xl pt-2.5  md:3xl lg:text-3xl xl:text-3xl xl:text-left lg:text-left">
                Algomage is the world's only end to end AI powered image post
                production solution
              </h1>
            </div>

            <div className="shadow-card my-10 px-[1.875rem] pt-[1.875rem] pb-[2rem] rounded-[10px]  xl:min-w-[35rem] 2xl:min-w-[35rem]">
              <h1 className="text-color-secondary text-xl font-medium text-center lg:text-2xl lg:text-left xl:text-2xl xl:text-left 2xl:text-2xl 2xl:text-left">
                Sign In
              </h1>
              <p className="text-color-secondary text-base text-center py-2 font-medium lg:text-base lg:text-left xl:text-base xl:text-left 2xl:text-base 2xl:text-left">
                New here?{" "}
                {source === "download" ? (
                  <Link
                    to={"/register?source=download"}
                    className="text-primary-green font-semibold underline pl-2"
                  >
                    Create An Account
                  </Link>
                ) : (
                  <Link
                    to={"/register"}
                    className="text-primary-green font-semibold underline pl-2"
                  >
                    Create An Account
                  </Link>
                )}
              </p>
              <div className="mt-5">
                <div className="flex flex-col pb-4 md:!w-full">
                  <InputEmail
                    onChange={(e) => {
                      setUserLoginDetails({ ...userLoginDetails, email: e });
                      setErrors({ ...errors, email: null });
                    }}
                    value={userLoginDetails.email}
                    label="Email ID"
                    error={errors.email}
                    onKeyDown={(e) => {
                      handleUserLogin(e);
                    }}
                    autoFocus={true}
                    className="!max-w-full "
                  />
                </div>
                <div className="flex flex-col pb-4 relative">
                  <InputText
                    onChange={(e) => {
                      setUserLoginDetails({ ...userLoginDetails, password: e });
                      setErrors({ ...errors, password: null });
                    }}
                    value={userLoginDetails.password}
                    label="Password"
                    type="password"
                    error={errors.password}
                    onKeyDown={(e) => {
                      handleUserLogin(e);
                    }}
                    className="!max-w-full "
                  />
                </div>
                <div className="grid justify-items-end">
                  <Link to={"/forgot-password"}>
                    <span className="text-xs text-primary-green underline cursor-pointer">
                      Forgot Password
                    </span>
                  </Link>
                </div>
              </div>

              <div className="flex justify-center py-5  lg:xl-28 2xl:mb-28">
                <Button
                  onClick={(e) => {
                    handleUserLogin(e);
                  }}
                  text="Sign In"
                  disabled={buttonDisable}
                  className="!h-[2.5rem] shadow-card"
                />
              </div>
              <div className="flex justify-center items-center">
                <div className="relative flex w-full pb-5 items-center">
                  <div className="flex-grow border-t border-neutral-700"></div>
                  <span className="flex-shrink mx-4 text-neutral-500 text-xs">
                    Or
                  </span>
                  <div className="flex-grow border-t border-neutral-700"></div>
                </div>
              </div>
              <GoogleOAuthProvider clientId="137315411674-1chibppfh2ed2umal3su5k29gchdesv1.apps.googleusercontent.com">
                <SocialLogin
                  setUser={setUser}
                  loginPage
                  countryCode={countryCode}
                  userCameFrom={source}
                  setRequestOnGoing={setRequestOnGoing}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SigninAdmin;
