import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import EyeSlashIcon from "../../../../../../../../assets/icons/EyeSlashIcon.jsx";
import DownloadIcon from "../../../../../../../../assets/icons/DownloadIcon.jsx";
import DeleteIcon from "../../../../../../../../assets/icons/DeleteIcon.jsx";
import ShareIcon from "../../../../../../../../assets/icons/ShareIcon.jsx";
import { useFolder } from "../../../../../../../../Store/Folder/Show.jsx";
import { deleteImg, flag } from "../../../../../../../../apis/upload.js";
import ShareModal from "../../../../../ShareModal/index.jsx";
import CoverImageIcon from "../../../../../../../../assets/icons/CoverImageIcon.jsx";
import { postChangeCoverImage } from "../../../../../../../../apis/index.js";
import { useEvent } from "../../../../../../../../Store/Event/Show.jsx";
import {
  OG_IMAGE_SUBDOMAIN,
  RESIZE_IMAGE_CDN,
  RESIZE_IMAGE_SUBDOMAIN,
} from "../../../../../../../../constants/index.js";

const Iconslist = ({ photo, index }) => {
  const { eventId } = useParams();
  const { setEvent } = useEvent();
  let { folder, images, setImages } = useFolder();
  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const deleteHandler = useCallback(
    (e) => {
      e.preventDefault();
      Swal.fire({
        title: "Warning!",
        text: `Are you sure you want to permanently delete ${photo.originalImageName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Delete`,
      }).then(function (key) {
        if (key.isConfirmed) {
          deleteImg(photo.id, folder.id, eventId)
            .then((response) => {
              window.Toast.fire({
                icon: "success",
                title: "Image deleted successfully",
              });
              let newImages = images.filter((i) => i.id !== photo.id);
              setImages([...newImages]);
            })
            .catch((error) => {
              window.Toast.fire({
                icon: "error",
                title: "Image could not be deleted",
              });
            });
        }
      });
    },
    [photo]
  );

  const downloadHandler = useCallback(
    (e) => {
      e.preventDefault();
      fetch(
        photo.url
          .replace(RESIZE_IMAGE_SUBDOMAIN, OG_IMAGE_SUBDOMAIN)
          .replace("+", "%2B"),
        {
          method: "GET",
        }
      )
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", photo.originalImageName); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    [photo]
  );

  const privacyHandler = useCallback(
    async (e) => {
      e.preventDefault();
      let res = await flag(
        photo.id,
        photo.isPrivate ? 0 : 1,
        folder.id,
        eventId
      );
      if (res.status >= 200 && res.status <= 204) {
        window.Toast.fire({
          icon: "success",
          title: `Image is now ${photo.isPrivate ? "public" : "private"}`,
        });
        let newImages = images;
        newImages[index].isPrivate = !photo.isPrivate;
        //console.log(index, newImages);
        setImages([...newImages]);
      } else {
        window.Toast.fire({
          icon: "error",
          title: `Unable to make image ${
            photo.isPrivate ? "public" : "private"
          }.`,
        });
      }
    },
    [photo]
  );

  const shareHandler = useCallback(
    (e) => {
      e.preventDefault();
      setShareLink(
        photo.url
          .replace(RESIZE_IMAGE_SUBDOMAIN, OG_IMAGE_SUBDOMAIN)
          .replace("+", "%2B") +
          "?v=" +
          photo.version
      );
      setShowSharePopUp(true);
    },
    [photo]
  );

  const setCoverImage = async () => {
    try {
      const postData = {
        id: photo.id,
        eventId: eventId,
      };
      const { data } = await postChangeCoverImage(postData);
      setEvent((prev) => ({
        ...prev,
        desktopCoverImageUrl: data.data.desktopCoverImageUrl,
      }));
      window.Toast.fire({
        icon: "success",
        title: "Cover image changed",
      });
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        icon: "error",
        title: "Cover image could not be changed",
      });
    }
  };

  return (
    <div className="flex align-center flex-wrap gap-4 mt-2">
      <span
        className="cursor-pointer"
        onClick={setCoverImage}
        title="Set Cover Image"
      >
        <CoverImageIcon />
      </span>
      <span className="cursor-pointer" onClick={deleteHandler} title="Delete">
        <DeleteIcon />
      </span>
      {!!!photo.isVideo && (
        <span
          className="cursor-pointer downloadTest"
          onClick={downloadHandler}
          title="Download"
        >
          <DownloadIcon />
        </span>
      )}
      <span
        className="cursor-pointer"
        onClick={privacyHandler}
        title={photo.isPrivate ? "Make Public" : "Make Private"}
      >
        <EyeSlashIcon color={photo.isPrivate ? "#05b1b2" : "#fff"} />
      </span>
      {!!!photo.isVideo && (
        <span className="cursor-pointer" onClick={shareHandler} title="Share">
          <ShareIcon />
        </span>
      )}
      {showSharePopUp && (
        <ShareModal
          open={showSharePopUp}
          close={() => {
            setShowSharePopUp(false);
          }}
          shareLink={shareLink}
        />
      )}
    </div>
  );
};

export default Iconslist;
