import './Switch.css';
import React, {useEffect, useState, useCallback} from 'react';
import { defaults } from 'lodash';

const Switch = ({onChange, defaultState, disable}) => {
    const [toggle, setToggle] = useState();
    
    function buttonToggled() {
        onChange(!toggle);
        setToggle(!toggle);
    }
    useEffect(() => {
        setToggle(defaultState)
    });
    // console.log("defaultState",defaultState);
    return (
        toggle ?
            <div
                // className={`w-10 h-5 flex items-center bg-cyan-500 rounded-full p-1 ${defaultState } ${disable ? disable : 'cursor-pointer bg-black-gray'}`}
                className={defaultState ? 'w-10 h-5 flex items-center bg-cyan-500 rounded-full p-1' :'w-10 h-5 flex items-center bg-black-gray rounded-full p-1 cursor-pointer'  }
                // onClick={() => {
                //     buttonToggled()
                // }}
                onClick={ e => disable ? e.preventDefault() : buttonToggled()}
                >
                <div
                    className={"bg-black h-4 w-4 rounded-full shadow-md transform transform translate-x-4"}>
                </div>
            </div> :
            <div
                className={`w-10 h-5 flex items-center rounded-full p-1 ${defaultState ? 'bg-cyan-500':'bg-black' } ${disable ? disable : 'cursor-pointer'}`}
                // onClick={() => {
                //     buttonToggled()
                // }}
                onClick={ e => disable ? e.preventDefault() : buttonToggled() }
                >
                <div
                    // className={"bg-black-gray h-4 w-4 rounded-full shadow-md transform"}>
                    className={` h-4 w-4 rounded-full shadow-md transform ${defaultState ? 'bg-black-gray':'bg-black-gray' }`}>
                </div>
            </div>
    );
};

export default Switch;
