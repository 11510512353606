import React, {useState, useEffect, useRef} from 'react';
import Button from '../../../../components/common/Button';
import Text from '../../../../components/common/Text';
import Title from '../../../../components/common/Title';
import DownArrow from "../../../../assets/icons/DownArrow";
import GuestVisits from "../../../../assets/images/GuestVisits.png";
import MobilePortfolioPreview from "../../../../assets/images/MobilePortfolioPreview_img.png";
import {Link} from 'react-router-dom';
import {useUser} from "../../../../Store/User";
import {useNavigate} from "react-router-dom";
import app_stats from "../../../../assets/images/app_stats.png";
import app_results from "../../../../assets/images/app_results.png";
import Chart from "react-apexcharts";
import _ from 'lodash';
import {getPortfolioDetails, updateShowPortfolioAppLink} from "../../../../apis";
import IPhoneTemplate from "../iPhone/Index.jsx";
import Swal from "sweetalert2";

const MobilePortfolio = (props) => {
    let navigate = useNavigate();
    const {user, setUser} = useUser();
    const [sharePortfolio, setSharePortfolio] = useState(1);
    const [chartFilterOptions, setChartFilterOptions] = useState(false);
    const chartFilters = ['Daily', 'Monthly', 'Annually', 'Yearly'];
    const [chartFilter, setChartFilter] = useState('Daily');
    const [dat, setDat] = useState({});
    const [xAxisLable, setXaxisLable] = useState();
    const [isHide, setIsHide] = useState(true);
    const [showAppUrl, setShowAppUrl] = useState(0);
    const [portfolioDetails, setPortfolioDetails] = useState({});

    let calenderFilterContainer = useRef();

    // /console.log('user', user);

    // function to close the dropdown when clicked outside
    useEffect(() => {
        let handler = (event) => {
            /*if (!calenderFilterContainer.current.contains(event.target)) {
                setChartFilterOptions(false);
            }*/
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)
        };
    }, []);


    useEffect(() => {
        !_.isEmpty(user) && setShowAppUrl(user.photographer.show_app_download_url);

        if (!_.isEmpty(user)) {
            getPortfolioDetails(user.id).then(res => {
                    setPortfolioDetails(res);
                }
            )
        }
        ;
    }, []);


    const chartData = {
        options: {
            chart: {
                id: "Analytics-chart",
                toolbar: {
                    show: false
                },
            },
            colors: ['#a64492'],
            fill: {
                type: "gradient",
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0.2,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: false,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 20, 50],
                    colorStops: []
                }
            },

            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: dat.length < 3 ? 7 + "%" : 20 + (60 / (1 + 30 * Math.exp(-dat.length / 3))) + "%",
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            xaxis: {
                categories: xAxisLable
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                theme: false,
                style: {
                    fontSize: '12px',
                    fontFamily: undefined
                },
                onDatasetHover: {
                    highlightDataSeries: false,
                },
                marker: {
                    show: true,
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            }
        },
        series: [
            {
                name: "No of Images",
                data: dat,
            }
        ]

    };

    const chartFilterToggle = (e) => {
        setChartFilterOptions(!chartFilterOptions);
    }

    const chartFilterClicked = (e) => {
        setChartFilter(e)
    }

    const handleShowAppUrl = (e) => {
        setShowAppUrl(!showAppUrl);
        updateShowPortfolioAppLink(e).then(response => {
            window.Toast.fire({
                icon: 'Success',
                title: 'Updated successfully.'
            });
            setUser({...user, access_token: response.access_token});
        }).catch(err => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        })


    }

    function buyPlan(){
        Swal.fire({
            icon: 'error',
            title: 'Please Subscribe to plan access this feature.'
        });
    }


    return <div ref={props.setRef} id="mobile-portfolio" className="mt-24">
        {_.isEmpty(portfolioDetails) ?
            <div className='mt-4' >
                <Title
                    className='pb-4 border-zinc-700 border-b '
                    text="Mobile App"
                />
                <div className='flex flex-row mb-10 mt-[1.87rem]'>
                    <div className='flex flex-col basis-full'>
                        <div className='flex p-8 justify-between items-center rounded-lg bg-black'>
                            <Text as="p" className='mb-4 text-sm'>
                                Promote your brand by sharing your mobile app with clients and guests.
                                <br/>Create a mobile app here in just 4 easy steps!
                            </Text>
                            {
                               ( (user.subscription.plan_type === 'free') || (user.permissions && user.permissions.create_event == false)) ?(
                                // ((user.subscription.plan_type === 'free')) ?(
                                    <Button text="Create Now" onClick={()=>{buyPlan()}}/>
                                ):
                                (<Link to={'/create-portfolio'}>
                                    <Button text="Create Now"/>
                                </Link>)
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            :
            <div id="mobile-portfolio-show" className='mt-4'>
                <div className='border-secondary-600 border-b mb-4 flex items-baseline justify-between'>
                    <div className="flex items-center">
                        <Title
                            className='pb-4'
                            text=" Mobile App"
                        />
                        <div className='ml-3 pb-4 underline text-primary-green font-medium cursor-pointer'>
                            {   
                                ((user.subscription.plan_type === 'free') || (user.permissions && user.permissions.create_event == false)) ?(
                                    <p onClick={()=>{buyPlan()}}>customize</p>
                                    
                                ):
                                (!_.isEmpty(portfolioDetails) &&
                                <Link to={'/create-portfolio'}>
                                    Customize
                                </Link>)
                            }
                            
                        </div>
                    </div>

                    <div>
                        {/* <Button className="w-[13.5rem] h-[2.5rem] relative whitespace-nowrap" text={"Download Mobile App"} ></Button> */}
                    </div>
                </div>
                      
                {/*<div
                    className='flex w-full shadow-image h-16 pl-5 pr-5 rounded-xl mb-11 justify-between items-center'>
                    <Text>Ask your client to download the app to view their photos?</Text>
                    <div className="flex gap-4">
                        <div
                            className={`flex items-center ${(showAppUrl == 1) ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 w-[7rem] h-[2.75rem]`}>
                            <label className="w-full cursor-pointer">
                                <input
                                    type="radio"
                                    checked={showAppUrl == 1}
                                    // onChange={() => setShowAppUrl(1)}
                                    onChange={() => {
                                        handleShowAppUrl(true)
                                    }}
                                    name="portfolio-share"
                                    className="accent-cyan-500 "
                                />
                                <span className="pl-2">Yes</span>
                            </label>
                        </div>

                        <div
                            className={` flex items-center ${(showAppUrl == 0) ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 w-[7rem] h-[2.75rem]`}>
                            <label className="w-full cursor-pointer">
                                <input
                                    type="radio"
                                    checked={showAppUrl == 0}
                                    // onChange={() => setShowAppUrl(0)}
                                    onChange={() => {
                                        handleShowAppUrl(false)
                                    }}
                                    className="accent-cyan-500"
                                    name="portfolio-share"
                                />
                                <span className="pl-2">No</span>
                            </label>
                        </div>
                    </div>
                </div>*/}


                <div className="flex flex-wrap 2xl:flex-nowrap gap-x-20 mb-20 px-5">
                    <div className="w-full">
                        <IPhoneTemplate/>
                    </div>

                    <div className="w-full">
                        <div className='pt-10'>
                            <div className='flex items-center justify-between'>
                                <div className="flex w-2/3 gap-10">
                                    <div className='text-2xl font-medium whitespace-nowrap'>Statistics</div>

                                    {/*<div
                                        className='flex w-[12.5rem] items-center p-2 px-4 text-sm dark:shadow-dark-input h-[36px]  cursor-pointer relative shadow-button whitespace-nowrap rounded-lg justify-between'
                                        ref={calenderFilterContainer}>{chartFilter}
                                        <span onClick={chartFilterToggle} className='pl-2 cursor-pointer'>
                                    <DownArrow className={`${chartFilterOptions ? 'rotate-180' : ''}`}></DownArrow>
                                </span>

                                        {chartFilterOptions && <div
                                            className='absolute mt-3 rounded-lg bg-dark-gray shadow-dark-input z-40 w-[12.5rem] right-0 top-[95%]'>
                                            <div
                                                className='flex select-none flex-col gap-3 text-light-gray text-sm'>
                                                {
                                                    chartFilters.map(function (chart, key) {
                                                        return <div key={key} onClick={() => {
                                                            chartFilterClicked(chart);
                                                            chartFilterToggle();
                                                        }}
                                                                    className="cursor-pointer capitalize px-4 py-2 hover:bg-black">
                                                            {chart}
                                                        </div>;
                                                    })
                                                }
                                            </div>
                                        </div>}

                                    </div>*/}
                                </div>

                                {/*<div className='flex justify-end w-1/3 '>
                                    <div
                                        className=' flex items-center p-2 px-4 text-sm dark:shadow-dark-input w-[7rem] h-[36px] cursor-pointer relative shadow-button whitespace-nowrap rounded-lg justify-between'>{chartFilter}

                                        <span onClick={chartFilterToggle}
                                              className='flex  pl-2 cursor-pointer'><DownArrow></DownArrow></span>
                                    </div>

                                </div>*/}
                            </div>
                            {/* chart */}
                            <div className='mt-4'>
                                {/* <img src={GuestVisits}/> */}
                                {
                                    dat.length > 0 ?
                                        <Chart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="bar"
                                            // width="500"
                                        />
                                        :
                                        <div
                                            className='flex justify-center item-center flex-col border border-2 border-slate-700'>
                                            <Text
                                                as='p'
                                                className='text-xl text-light-gray pt-4 pb-2 text-center'
                                            >
                                            </Text>
                                            <Text as='p' className='text-base text-light-gray pb-4 text-center'>
                                                No data available {/*for this {chartFilter.toLowerCase()}*/}
                                            </Text>
                                        </div>
                                }
                            </div>

                            {/*<div className='flex bg-black w-full h-24 mt-9 rounded-xl justify-between px-11'>
                                <div className="flex justify-center items-center">
                                    <img src={app_stats} className="w-[3.15rem] h-[3.15rem]" alt=""/>
                                    <div className="flex flex-col ml-5">
                                        <Text as='p' className="font-bold text-xl">0</Text>
                                        <Text as='p' className="font-medium text-sm ">app downloads</Text>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={app_results} className="w-[3.15rem] h-[3.15rem]" alt=""/>
                                    <div className="flex flex-col ml-5">
                                        <Text as='p' className="font-bold text-xl">0%</Text>
                                        <Text as='p' className="font-medium text-sm ">higher than previous
                                            week</Text>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        }

    </div>;

};

export default MobilePortfolio;
