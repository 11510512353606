import React from "react";

function Button(props) {
  const { className = "", text = "", disabled = false, onClick, id="",title='' } = props;
  const btnColor = disabled
    ? "bg-button-disabled text-gray"
    : "bg-button text-light-gray shadow-button";
  return (
    <button
      title={title}
      id={id}
      className={`rounded-[10px] font-semibold w-[11rem] h-[1.87rem] ${btnColor} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Button;
