/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Swal from "sweetalert2";

import {
  doResizeImageUpload,
  doUpload,
  postEventUploadFlag,
  redoFaceSearch,
} from "../../apis/upload";
import { getFolderImagesCount, postVectorInit } from "../../apis";
import { useUser } from "../User";
import {
  IMAGE_LIMIT_REACHED,
  MAX_FILE_TO_UPLOAD,
  MAX_SUB_EVENT_IMAGE_REACHED_ERROR,
  PHOTOGRAPHER_ACCESS_TO_WEB_UPLOAD,
} from "../../constants";
import useAsyncQueue from "../../hooks/useAsyncQueue";
const Uploader = React.createContext();
Uploader.displayName = "Uploader";

const UPLOAD_LENGTH_TO_CALL_VECTOR_API = 100;

export const useUploader = () => React.useContext(Uploader);

export const UploaderProvider = ({ children }) => {
  const { user } = useUser();

  const [showUploader, setShowUploader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isRetry, setIsRetry] = useState(false);
  const [duplicates, setDuplicates] = useState("skip");
  const [uploadFolders, setUploadFolders] = useState([]);
  const [uploadEvent, setUploadEvent] = useState([]);
  const [completedImages, setCompletedImages] = useState([]);
  const [failedImages, setFailedImages] = useState([]);
  const [groupedErrorFiles, setGroupedErrorFiles] = useState([]);
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [abortController, setAbortController] = useState([]);
  const [iterator, setIterator] = useState(1);
  const [folderImageCount, setFolderImageCount] = useState({});

  const maxUploadSize = user.subscription?.restrictions?.image_size_limit ?? 30;

  const showMaxImageReachedPopUp = () =>
    Swal.fire({
      title: `Your subscriptions allows only ${user.subscription?.restrictions?.folder_image_limit} number of images per sub event. `,
      html: `Please contact <a href="mailto:hello@algomage.com" class="text-primary-green underline">sales<a> to upgrade your plan`,
      icon: "error",
      confirmButtonText: "Ok",
    });

  const stateRef = useRef();
  stateRef.current = { completedImages, failedImages };
  useEffect(() => {
    if (!showUploader) {
      setUploadEvent({});
      setUploadFolders([]);
      setFilesToUpload([]);
      setCompletedImages([]);
      setFailedImages([]);
      setGroupedErrorFiles([]);
      setFolderImageCount({});
    }
  }, [showUploader]);

  useEffect(() => {
    if (!isRetry) {
      setCompletedImages(filesToUpload.filter((f) => f.status === "Success"));
    }
    setFailedImages(filesToUpload.filter((f) => f.status === "Failed"));
  }, [isRetry, filesToUpload]);

  useEffect(() => {
    if (failedImages.length > 0) {
      let grouped = _.groupBy(failedImages, (img) => img.error);
      setGroupedErrorFiles(grouped);
    }
  }, [failedImages]);

  const done = () => {};
  const drain = () => {};
  const concurrency = 5;
  const queue = useAsyncQueue({
    concurrency,
    done,
    drain,
  });

  const uploadInProgress = () => {
    Swal.fire({
      title: "There is already an active upload for this event.",
      text: "Please wait till the other upload  is complete before uploading new photos.",
      icon: "error",
      confirmButtonText: "Ok",
    });
  };

  const retryUpload = async (file = {}) => {
    if (isUploading) {
      window.Toast.Fire({
        icon: "error",
        title: "Upload is going on",
      });
      return;
    } else setIsUploading(true);
    setIsRetry(true);
    if (!_.isEmpty(file)) {
      const data = await getFolderImagesCount(
        { id: file.folderId },
        uploadEvent.id
      );
      setFolderImageCount((prev) => ({
        ...prev,
        [file.folderId]: data.images_count,
      }));
      if (data.is_uploading) {
        uploadInProgress();
        return;
      }
      let newFilestoUpload = filesToUpload.map((ftu) => {
        if (ftu.name === file.name) {
          ftu.error = "";
          ftu.status = "Uploading";
          ftu.duplicate = "replace";
        }
        return ftu;
      });
      try {
        await postEventUploadFlag({
          id: uploadEvent.id,
          isUploading: 1,
        });
        setFilesToUpload(newFilestoUpload);
      } catch (error) {
        if (error.response.status && error.response.data?.errors)
          uploadInProgress();
      }
    } else {
      abortController.forEach((el) => el?.abort());
      const folderIds = Array.from(
        new Set(filesToUpload.map((el) => el.folderId))
      );
      let isUploading = false;
      for await (const f of folderIds) {
        const data = await getFolderImagesCount({ id: f }, uploadEvent.id);
        isUploading = data.is_uploading;
        setFolderImageCount((prev) => ({
          ...prev,
          [f]: data.images_count,
        }));
      }

      if (isUploading) {
        uploadInProgress();
        return;
      }

      let newFilestoUpload = filesToUpload.map((ftu) => {
        if (
          ftu.error === "Duplicate File" ||
          ftu.error === IMAGE_LIMIT_REACHED
        ) {
        } else if (ftu.size > maxUploadSize * 1024 * 1024) {
          ftu.status = "Failed";
          ftu.error = `Size More Than ${maxUploadSize} MB`;
        } else if (ftu.type !== "image/jpeg" && ftu.type !== "image/jpg") {
          ftu.status = "Failed";
          ftu.error = "File Type Not Allowed";
        } else if (ftu.status === "Failed") {
          ftu.status = "Uploading";
          ftu.duplicate = "replace";
          ftu.error = "";
        }
        return ftu;
      });
      try {
        if (newFilestoUpload.some((el) => el.status === "Uploading")) {
          await postEventUploadFlag({
            id: uploadEvent.id,
            isUploading: 1,
          });
        }
        setGroupedErrorFiles([]);
        setFilesToUpload(newFilestoUpload);
      } catch (error) {
        if (error.response.status && error.response.data?.errors)
          uploadInProgress();
      }
    }
  };

  const onDrop = async (
    readyTouploadFolders,
    foldeWiseImagesToUpload,
    duplicates = "skip",
    event
  ) => {
    let isUploading = false;
    const count = Object.keys(foldeWiseImagesToUpload).reduce((acc, el) => {
      acc = acc + foldeWiseImagesToUpload[el].length;
      return acc;
    }, 0);

    if ( !PHOTOGRAPHER_ACCESS_TO_WEB_UPLOAD.includes(user.photographer.id) && count > MAX_FILE_TO_UPLOAD) {
      Swal.fire({
        title: "Reached image limit",
        html: `<div>To upload more than 50 images please use the desktop app. Increase your upload speed by 4x using desktop app. Download the app from <a class="text-primary-green" href="https://algomage.com/download">here</a></div>`,
        icon: "error",
      });
      window.location = "algoshare://?jwt=" + user.access_token;
      return;
    }
    for await (const f of readyTouploadFolders) {
      const data = await getFolderImagesCount(f, f.eventId);
      isUploading = data.is_uploading;
      setFolderImageCount((prev) => ({
        ...prev,
        [f.id]: data.images_count,
      }));
    }

    if (isUploading) {
      uploadInProgress();
      return;
    }

    if (showUploader && !isUploading) {
      setShowUploader(false);
    }
    const nonValidNameCharacter = /[#%+]/;
    let readyFiles = [];
    let newUploadFolders = await Promise.all(
      readyTouploadFolders.map(async (folder) => {
        let existingImageNames = folder.images.map((i) => i.originalImageName);
        let totalSize = 0;
        let filesWithStatus = await Promise.all(
          foldeWiseImagesToUpload[folder.id].map((f) => {
            if (f.status) {
              readyFiles.push(f);
              return f;
            }
            if (
              existingImageNames.indexOf(f.name) > -1 &&
              duplicates === "replace"
            ) {
              f.isImageDuplicate = true;
            }
            if (
              existingImageNames.indexOf(f.name) > -1 &&
              duplicates === "skip"
            ) {
              f.status = "Failed";
              f.error = "Duplicate File";
            } else if (f.size > maxUploadSize * 1024 * 1024) {
              f.status = "Failed";
              f.error = `Size More Than ${maxUploadSize} MB`;
            } else if (f.type !== "image/jpeg" && f.type !== "image/jpg") {
              f.status = "Failed";
              f.error = "File Type Not Allowed";
            } else if (nonValidNameCharacter.test(f.name)) {
              f.status = "Failed";
              f.error = "Name invalid";
            } else {
              f.status = "Uploading";
              f.error = "";
            }
            f.folderId = folder.id;
            totalSize = totalSize + f.size;
            readyFiles.push(f);
            return f;
          }),
          { concurrency: 1 }
        );

        folder.fileToUpload = filesWithStatus;

        return folder;
      }),
      { concurrency: 1 }
    );

    try {
      await postEventUploadFlag({
        id: readyTouploadFolders[0].eventId,
        isUploading: 1,
      });
      setUploadEvent(event);
      setDuplicates(duplicates);
      setUploadFolders(newUploadFolders);
      setFilesToUpload(readyFiles);
      setShowUploader(true);
      setIsUploading(true);
    } catch (error) {
      if (error.response.status && error.response.data?.errors)
        uploadInProgress();
    }
  };

  const callVectorInitApi = async () => {
    postVectorInit(uploadEvent?.awsEventName);
  };

  const toggleUploadFlag = async () =>
    await postEventUploadFlag({ id: uploadEvent.id, isUploading: 0 });

  const showStopUploadWarning = () =>
    Swal.fire({
      icon: "warning",
      title: "Stop upload",
      text: "Are you sure you want to stop the upload",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      preConfirm: stopUpload,
      confirmButtonText: "Stop",
    });

  const stopUpload = async () => {
    setIsUploading(false);
    setFilesToUpload([]);
    await callVectorInitApi();
    await toggleUploadFlag();
    window.location.reload();
  };

  useEffect(async () => {
    if (
      completedImages.length + failedImages.length === filesToUpload.length &&
      filesToUpload.length
    ) {
      setIsUploading(false);
      toggleUploadFlag();
      if (uploadEvent.newFaceSearchModel) callVectorInitApi();
      if (uploadEvent.id === 3773 || uploadEvent.id === 16931) {
        await redoFaceSearch(uploadEvent.id);
      }
    }
  }, [completedImages, failedImages]);

  /** Calling init api on every 100 images status change */
  useEffect(() => {
    if (
      completedImages.length + failedImages.length ===
      UPLOAD_LENGTH_TO_CALL_VECTOR_API * iterator
    ) {
      setIterator((prev) => prev + 1);
      if (uploadEvent.newFaceSearchModel) callVectorInitApi();
    }
  }, [completedImages, failedImages, iterator]);

  useEffect(() => {
    if (filesToUpload.length > 0) {
      const imageCount = {};
      queue.start();
      filesToUpload.forEach((f, i) => {
        if (!Object.keys(imageCount).some((el) => el === f.folderId)) {
          imageCount[f.folderId] = folderImageCount[f.folderId];
        }
        if (f.status === undefined || f.status === "Uploading") {
          const controller = new AbortController();
          setAbortController((prev) => [...prev, controller]);
          const task = {
            id: i,
            task: () =>
              new Promise(async (resolve, reject) => {
                if (!f.isImageDuplicate)
                  imageCount[f.folderId] = imageCount[f.folderId] + 1;
                if (
                  user.subscription.restrictions.folder_image_limit &&
                  imageCount[f.folderId] >
                    user.subscription?.restrictions.folder_image_limit
                ) {
                  f.status = "Failed";
                  f.error = IMAGE_LIMIT_REACHED;
                  reject();
                  showMaxImageReachedPopUp();
                }
                let res = {
                  file: {
                    status: null,
                  },
                };
                if (user.subscription?.restrictions?.image_optimize) {
                  res = await doResizeImageUpload(
                    f,
                    i,
                    f.folderId,
                    f.duplicate ?? duplicates,
                    controller
                  );
                } else {
                  res = await doUpload(
                    f,
                    i,
                    f.folderId,
                    f.duplicate ?? duplicates,
                    controller
                  );
                }

                if (res.result && res.file.status === "Success") {
                  if (!!!res.file.returnData.isVideo) {
                    res.file.returnData.src =
                      res.file.returnData.src +
                      "?w=1600&v=" +
                      res.file.returnData.version;
                  }
                  setCompletedImages([
                    ...stateRef.current.completedImages,
                    res.file.returnData,
                  ]);
                  if (!res.file.isImageDuplicate) {
                    setFolderImageCount((prev) => ({
                      ...prev,
                      [res.file.returnData.folderId]:
                        prev[res.file.returnData.folderId] + 1,
                    }));
                  }
                } else {
                  if (res.error?.message === "canceled") {
                  } else {
                    if (
                      res.error?.response?.data?.message ===
                      MAX_SUB_EVENT_IMAGE_REACHED_ERROR
                    ) {
                      showMaxImageReachedPopUp();
                      setFailedImages([
                        ...stateRef.current.failedImages,
                        res.file,
                      ]);
                    } else {
                      setFailedImages([
                        ...stateRef.current.failedImages,
                        res.file,
                      ]);
                    }
                  }
                }

                resolve(res.file);
              }),
          };
          queue.add(task);
        }
      });
      return () => queue.stop();
    }
  }, [filesToUpload]);

  return (
    <Uploader.Provider
      value={{
        onDrop,
        retryUpload,
        showUploader,
        duplicates,
        uploadEvent,
        uploadFolders,
        setShowUploader,
        isUploading,
        filesToUpload,
        completedImages,
        failedImages,
        groupedErrorFiles,
        showCookiePopup,
        setShowCookiePopup,
        toggleUploadFlag,
        callVectorInitApi,
        showStopUploadWarning,
      }}
    >
      {children}
    </Uploader.Provider>
  );
};
