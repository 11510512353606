/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Title from "../../../../components/common/Title";
import _, { debounce, filter } from "lodash";
import { getEventGuestEmail, getEventGuest } from "../../../../apis";
import { useEvent } from "../../../../Store/Event/Show";
import DetailComponent from "./DetailComponent";
import Information from "../../../../assets/images/information.png";
import Select from "../../../../components/common/Select";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const Sharing = ({ selectSharingPerson, setSelectSharingPerson }) => {
  const { event } = useEvent(),
    [guests, setGuests] = useState([]),
    [isDropDownOpen, setIsDropDownOpen] = useState(false),
    [openRoles, setOpenRoles] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [showSortOptions, setShowSortingOptions] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");

  const dropdownRef = useRef();
  const guestRef = useRef([]);
  const debounceTimer = useRef();

  const searchGuest = (e) => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      if (e.target.value === "") setGuests(guestRef.current);
      else {
        const guestClone = structuredClone(guests);
        const filteredResult = guestClone.filter(
          (el) =>
            el.name?.includes(e.target.value) ||
            el.email?.includes(e.target.value)
        );
        setGuests(filteredResult);
      }
    }, 500);
  };

  const toggleSortOptions = () => {
    setShowSortingOptions((prev) => !prev);
  };

  const closeSortOption = () => setShowSortingOptions(false);

  useOutsideClick(dropdownRef, closeSortOption);

  const resetSort = () => {
    setSelectedSort("");
    setShowSortingOptions(false);
    setGuests(guestRef.current);
  };

  const emailSortAsc = () => {
    const guestClone = structuredClone(guests);
    guestClone.sort((a, b) => a.email.toLowerCase().localeCompare(b.email));
    setShowSortingOptions(false);
    setSelectedSort("emailAsc");
    setGuests(guestClone);
  };

  const emailSortDesc = () => {
    const guestClone = structuredClone(guests);
    guestClone
      .sort((a, b) => a.email.toLowerCase().localeCompare(b.email))
      .reverse();
    setShowSortingOptions(false);
    setSelectedSort("emailDesc");
    setGuests(guestClone);
  };

  const nameAsc = () => {
    const guestClone = structuredClone(guests);
    const nullNames = guestClone.filter((el) => !el.name);
    const names = guestClone.filter((el) => el.name);
    names.sort((a, b) => a.name.toLowerCase().localeCompare(b.name));
    setShowSortingOptions(false);
    setSelectedSort("nameAsc");
    setGuests([...names, ...nullNames]);
  };

  const nameDesc = () => {
    const guestClone = structuredClone(guests);
    const nullNames = guestClone.filter((el) => !el.name);
    const names = guestClone.filter((el) => el.name);
    names.sort((a, b) => a.name.toLowerCase().localeCompare(b.name)).reverse();

    setShowSortingOptions(false);
    setSelectedSort("nameDesc");
    setGuests([...names, ...nullNames]);
  };

  const downloadTextFile = ()=>{
   const anchorTag =  document.createElement("a")
   document.body.appendChild(anchorTag)
   const data = new Blob([...guests.map((el,iterator)=>(iterator + 1) + ".  " + el.email + "\n")], {type: "text/plain"})
    anchorTag.href = URL.createObjectURL(data)
    anchorTag.download = "guestList.txt"
    anchorTag.click()
    URL.revokeObjectURL(data)
   document.body.removeChild(anchorTag)
  }

  useEffect(() => {
    if (!_.isEmpty(event)) {
      getEventGuest(event).then(function (response) {
        setGuests(response);
        guestRef.current = response;
      });
    }
  }, [event]);

  useEffect(() => {
    if (searchWord === "") setGuests(guestRef.current);
  }, [searchWord]);

  return (
    <>
      <Title
        className="pb-4 border-zinc-700 border-b font-medium mt-4"
        text="Sharing"
      />

      <div className="flex justify-even items-center font-medium text-secondary-300 mt-7">
        {_.isEmpty(guests) && !searchWord
          ? "You haven't shared this event with anyone"
          : "Registered people"}
        {!_.isEmpty(guests) ? (
          <span className="pl-3 cursor-pointer">
            <img
              className="cursor-pointer"
              onClick={() => {
                setOpenRoles(true);
                document.body.style.overflow = "hidden";
              }}
              src={Information}
            />
          </span>
        ) : null}
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <input
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          type="search"
          placeholder="Search email address or name"
          size={31}
          className="bg-black text-sm px-4 max-h-[3.125rem] w-full md:w-[300px] my-4 rounded-[10px] py-[0.93rem]"
          name="search"
          onKeyDown={searchGuest}
        />
        <div
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
          className="relative max-w-[28rem] w-fit"
        >
          <Select
            onClick={() => toggleSortOptions()}
            text="Sort By"
            className="mt-4"
            isOpen={showSortOptions}
          />
          {showSortOptions && (
            <div className="absolute z-[100] top-[60px] right-0 py-4 bg-theme-light dark:bg-dark-gray shadow-light-input dark:shadow-dark-input w-[200px]">
              <div className="flex flex-col gap-3 cursor-pointer text-dark-gray1  dark:text-light-gray text-sm">
                <div className="disabled-view pl-4">Email</div>
                <button
                  className={`py-2 pl-4 ${
                    selectedSort === "emailAsc" ? "text-primary-green" : ""
                  } text-left hover:bg-transparent':'hover:text-cyan-500 hover:bg-black`}
                  onClick={emailSortAsc}
                >
                  A - Z
                </button>
                <button
                  className={`py-2 pl-4 ${
                    selectedSort === "emailDesc" ? "text-primary-green" : ""
                  } text-left hover:bg-transparent':'hover:text-cyan-500 hover:bg-black`}
                  onClick={emailSortDesc}
                >
                  Z - A
                </button>

                <div className="border-t border-zinc-700 px-10 h-1 "></div>
                <div className="pl-4 disabled-view">Name</div>
                <button
                  onClick={nameAsc}
                  className={`py-2 pl-4 ${
                    selectedSort === "nameAsc" ? "text-primary-green" : ""
                  } text-left hover:bg-transparent':'hover:text-cyan-500 hover:bg-black`}
                >
                  A - Z
                </button>
                <button
                  onClick={nameDesc}
                  className={`py-2 pl-4 ${
                    selectedSort === "nameDesc" ? "text-primary-green" : ""
                  } text-left hover:bg-transparent':'hover:text-cyan-500 hover:bg-black`}
                >
                  Z - A
                </button>

                <div className="border-t border-zinc-700 px-10 h-1 "></div>
                <button className="py-2 text-left pl-4 hover:bg-transparent':'hover:text-cyan-500 hover:bg-black" onClick={resetSort}>
                  Reset
                </button>
              </div>
            </div>
          )}
        </div>
        <button onClick={downloadTextFile} className="underline text-primary-green cursor-pointer mt-4 md:mt-0 md:mb-4 text-left">Download list</button>
      </div>

      {!_.isEmpty(guests) ? (
        <div className="flex justify-between flex-wrap gap-y-6 mt-6">
          {guests.map(function (guest, key) {
            return (
              <DetailComponent
                selectSharingPerson={selectSharingPerson}
                setSelectSharingPerson={setSelectSharingPerson}
                key={key}
                guest={guest}
                setGuests={setGuests}
              />
            );
          })}
        </div>
      ) : null}

      {!!!guests.length && searchWord && (
        <div>
          {`No user email or name exist with this search key : `}
          <b>{searchWord}</b>
        </div>
      )}

      {openRoles ? (
        <div className="fixed w-full z-10 h-screen top-0 left-0 bg-bb-500 flex items-center  ">
          <div className="w-1/2 max-w-[50rem] mx-auto bg-[#1a1a1a] py-5 px-7 rounded-xl">
            <div className="flex justify-between w-full">
              <h1 className="text-3xl font-medium mb-1">Viewer Roles</h1>
              <a
                className="text-3xl font-medium mb-1"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenRoles(false);
                  document.body.style.overflow = "auto";
                }}
              >
                X
              </a>
            </div>
            <p className="text-[1rem]">
              Assigning different viewer roles can decide the viewer's access to
              the event{" "}
            </p>
            <div className="w-full mt-5 mb-4 h-[1px] bg-[#555555]"></div>
            <h5 className="font-medium text-xl mb-1">Co-admin</h5>
            <ul className="list-disc px-4 mb-5 text-sm">
              <li>
                Upload (to guest folder), hide, download, favourite, share
                images and add them to cart{" "}
              </li>
            </ul>
            <h5 className="font-medium text-xl">Viewer</h5>
            <ul className="list-disc px-4 mt-3 text-sm">
              <li>Download, favourite, share images and add them to cart</li>
            </ul>

            <div className="flex justify-center mt-10 mb-7">
              <button
                text="Done"
                className="rounded-lg dark:shadow-button font-semibold py-3 px-10 min-w-[150px] bg-button flex items-center justify-center"
                onClick={(e) => {
                  setOpenRoles(false);
                  document.body.style.overflow = "auto";
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Sharing;
