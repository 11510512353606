import React from "react";

const MinimizeIcon = props => {
    const { height = "16", width = "16", fill = "#EEE" } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.857 5.714c0 .631-.512 1.143-1.143 1.143H1.143a1.143 1.143 0 0 1 0-2.286h1.812l-2.62-2.62A1.143 1.143 0 0 1 1.95.335l2.62 2.62V1.143a1.143 1.143 0 0 1 2.286 0v4.571zm7.986-1.128a1.143 1.143 0 0 1 0 2.285h-4.571a1.143 1.143 0 0 1-1.143-1.143V1.157a1.143 1.143 0 1 1 2.285 0V2.97L14.035.349a1.143 1.143 0 1 1 1.616 1.616l-2.62 2.62h1.812zM6.857 14.857a1.143 1.143 0 0 1-2.286 0v-1.812l-2.62 2.62A1.143 1.143 0 0 1 .335 14.05l2.62-2.62H1.143a1.143 1.143 0 1 1 0-2.286h4.571c.631 0 1.143.512 1.143 1.143v4.571zM10.286 16a1.143 1.143 0 0 1-1.143-1.143v-4.571c0-.631.512-1.143 1.143-1.143h4.571a1.143 1.143 0 0 1 0 2.286h-1.812l2.62 2.62a1.143 1.143 0 0 1-1.616 1.616l-2.62-2.62v1.812c0 .631-.512 1.143-1.143 1.143z" fill="#EEE" fillRule="nonzero" />
        </svg>
    );
};

export default MinimizeIcon;
