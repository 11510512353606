import React, {useState, useEffect} from "react";
import Title from "../../../../components/common/Title";
import Text from "../../../../components/common/Text";
import Search from "../../../../components/common/Search";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
// import DeleteIcon from "../../../../assets//DeleteIcon";
import {getEventFavList, updateEventFavList, deleteEventFavouriteList, deleteFavList} from '../../../../apis';
import {useEvent} from "../../../../Store/Event/Show";
import Swal from "sweetalert2";
import _ from "lodash";

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

const FavouriteListSettings = () => {
    const {event} = useEvent();
    const [favList, setFavList] = useState([]);
    const [filteredFavList, setFilteredFavList] = useState([]);
    const [timer, setTimer] = useState(null);
    const [searchWord, setSearchWord] = useState("");
    const [currentFavSelected, setCurrentFavSelected] = useState(null);
    const [maxFav, setMaxFav] = useState();

    useEffect(() => {
        if (!_.isEmpty(event)) {
            getEventFavList(event).then(function (response) {
                setFavList(response);
                setFilteredFavList(response);
            });
        }
    }, [event]);

    useEffect(() => {
        if (!_.isEmpty(favList) && !_.isEmpty(searchWord)) {
            let favListsByName = favList.filter(item =>
                    item.listName.toLowerCase().includes(searchWord.toLowerCase())),
                favListByEmail = favList.filter(item =>
                    item.email.toLowerCase().includes(searchWord.toLowerCase())),
                combinedFavList = favListsByName.concat(favListByEmail),
                set = new Set(combinedFavList);

            setFilteredFavList(Array.from(set));
        } else {
            setFilteredFavList(favList);
        }
    }, [searchWord, favList]);


    const updateListCount = (e, favListId) => {
        const Imagecount = e.target.value == "" ? 0 : parseInt(e.target.value);
        
        clearTimeout(timer);
        setFavList(favList.map(item => {
                if (item.id == favListId) {
                    item.albumLimit = Imagecount;
                }
                return item;
            }));
        const newTimer = setTimeout(() => {
            updateEventFavList(favListId, Imagecount).then(function (response) {
                window.Toast.fire({
                    icon: 'success',
                    title: 'Max allowed images Updated'
                });
            }).catch(function (error) {
                window.Toast.fire({
                    icon: 'error',
                    title: 'Error updating max allowed images'
                    });
                setFavList(favList.map(item => {
                    if (item.id == favListId) {
                        item.albumLimit = maxFav;
                    }
                    return item;
                }
                ));
            });

        }, 2000);
        setTimer(newTimer)
    }

    function deleteList(favListId) {
        Swal.fire({
            title: 'Warning!',
            text: `Are you sure you want to permanently delete this list?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Delete`
        }).then(function (key) {
                if (key.isConfirmed) {
                    deleteFavList(event.id, favListId, 'event-settings').then((response) => {
                        setFavList(response);
                        window.Toast.fire({
                            icon: 'success',
                            title: 'List deleted successfully'
                        });
                    }).catch((error) => {
                        window.Toast.fire({
                            icon: 'error',
                            title: 'List could not be deleted'
                        });
                    });
                }
            }
        );
    }

    function updateFavListOrder() {
        let updatedFavLIst = _.sortBy(favList, 'email');
        setFavList(updatedFavLIst);
    }

    const rowHeaders = ["Email", "List Name", "Max Selection",""];
    const { items, requestSort, sortConfig } = useSortableData(filteredFavList);

    return (
        <div id="favourite-list-settings" className="mt-36">
            <Title
                className='pb-4 border-zinc-700 border-b font-medium'
                text='Favourite List Settings'
            />

            <Text as='p' className='mt-5 mb-5'>
                <span className='mr-[100px] text-base font-medium'>
                    Log of limits on favourite folders
                </span>
            </Text>

            <div className='relative w-full'>
                <i className='absolute top-4 left-4'>
                    <svg className="w-4 h-4" fill="#eee" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd">

                        </path>
                    </svg>
                </i>
                <input value={searchWord}
                       onChange={(e) => setSearchWord(e.target.value)}
                       type="text"
                       placeholder="Search email address or list name"
                       size={31}
                       className="bg-black w-full text-sm px-12 max-h-[3.125rem] rounded-[10px] py-[0.93rem]"
                       name="search"/>
            </div>

            <ul className='flex flex-col  mt-8 mb-[50px]'>
                <li className='flex flex-row bg-black1 items-center text-base px-[1.25rem] py-5 rounded-md '>
                    {/* {rowHeaders.map(item => {
                        return (
                            <div className=' basis-1/4 pr-8'>
                                <Text as='p' className='text-base font-medium'>
                                    {item}
                                </Text>
                            </div>
                        );
                    })} */}
                    <div className=' basis-1/4 pr-8'>
                        <div className="flex items-center gap-x-3">
                            <Text as='p' className='text-base font-medium'>
                                Email
                            </Text>
                            <img onClick={() => requestSort('email')} src="/sortIcon.webp" className='w-3 h-3  cursor-pointer' alt="" />
                        </div>
                    </div>
                    <div className=' basis-1/4 pr-8'>
                    <div className="flex items-center gap-x-3">
                            <Text as='p' className='text-base font-medium'>
                                List Name
                            </Text>
                            <img onClick={() => requestSort('listName')} src="/sortIcon.webp" className='w-3 h-3  cursor-pointer' alt="" />
                        </div>

                    </div>
                    <div className=' basis-1/4 pr-8'>
                    <div className="flex items-center gap-x-3">
                            <Text as='p' className='text-base font-medium whitespace-nowrap'>
                            Max Selection
                            </Text>
                            <img onClick={() => requestSort('albumLimit')} src="/sortIcon.webp" className='w-3 h-3  cursor-pointer' alt="" />
                        </div>
                        {/* <Text as='p' className='text-base font-medium'>
                            Max Selection
                        </Text> */}
                    </div>
                </li>

                <div className={`${items.lenght <= 10 ? '':'max-h-[55rem] overflow-y-auto' }`}>
                    {items.map(list => {
                        const {email, listName, albumLimit, id} = list;
                        return (
                            <li className='flex flex-row  py-8 items-center border-b border-black-gray px-[1.25rem] text-secondary-300 text-sm '>
                                <div title={email} className='basis-1/4 truncate pr-20 '><span >{email}</span></div>
                                <div className='basis-1/4'>{listName}</div>
                                <div className='basis-1/4 '>
                                    <input
                                        value={albumLimit ?? ''}
                                        className={`bg-dark-gray focus:shadow-input-filled shadow-input-empty outline-none rounded-[10px] text-light-gray pl-3 py-2 max-w-[5.063rem] h-[2.5rem]`}
                                        onChange={(e) => {
                                            updateListCount(e, id)
                                        }}
                                        type='number'
                                        min='0'
                                    />
                                </div>
                                <div className='basis-1/4'>
                                    <img
                                        src={require("../../../../assets/images/delete-icon.png")}
                                        // height={20}
                                        className='cursor-pointer w-[1.5rem]'
                                        // width={20}
                                        onClick={() => {
                                            deleteList(id)
                                        }}
                                        alt=""/>
                                </div>
                            </li>

                        );
                    })}
                </div>
            </ul>
        </div>
    );
};

export default FavouriteListSettings;
