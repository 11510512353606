import React from "react";

const DeleteIcon = props => {
    const { height = "1rem", width = "1rem", color = "#EEE", onClick} = props;
    return (
        <svg height={height} width={width} onClick ={onClick} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z"/>
                <path fill={color} d="M7 0a1 1 0 0 0-.894.553L5.382 2H2a1 1 0 1 0 0 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a1 1 0 0 0 0-2h-3.382L9.894.553A1 1 0 0 0 9 0H7zM6 5a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1z" fillRule="nonzero"/>
            </g>
        </svg>
    );
};

export default DeleteIcon;
