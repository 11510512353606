import React, { useState,useEffect, useRef } from "react";
import Text from "../Text";
import checkedsvg from "../../../assets/images/checked.jpeg";
import _ from "lodash"

function Checkboxgen(props) {
    const {
        className,
        checked = false,
        desc="",
        id = "",
        label = "",
        name = "",
        disabled = false,
        onChange = ()=>{},
        value = "",
        uncheckedColor = 'dark'
    } = props;
    const [isChecked, setChecked] = useState(checked);
    const checkBoxRef = useRef()
    const handlChange =  (e) =>{
        setChecked(!isChecked)
        onChange(e)
    }

    useEffect(()=>{
        setChecked(checked)
    },[checked])
    const myStyle={
        backgroundImage: `url(${checkedsvg})`,
        maxWidth:'100%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer'
    };

    return (

        <label
            className={`text-light-gray text-sm flex  relative ${className} ${disabled?'cursor-not-allowed':''} `}
            htmlFor={id}
            title={desc}
        >
            <input
                ref={checkBoxRef}
                className={`absolute w-0 h-0 opacity-0  `}
                type='checkbox'
                name={name || id}
                id={id}
                value={value}
                checked={isChecked}
                disabled={disabled}
                onChange={handlChange}
            />
            <span className={`w-6 h-6 bg-co-${uncheckedColor}-gray shadow-input-filled mt-1 rounded
             ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
             style={ isChecked ? myStyle : {}} onClick = {()=>{checkBoxRef.current.click()}}>
            </span>
            <span className='flex flex-col ml-6'>
                <Text className='flex cursor-pointer text-base font-medium whitespace-nowrap'>{label}</Text>
                {desc && <Text className='flex text-sm text-gray'>
                    {desc}
                </Text>}
            </span>
            </label>

    );
}

export default Checkboxgen;
