import _ from "lodash";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {getEventDetails} from '../../apis/index';
import { titleUpdate } from "../../functions";

const Show = React.createContext();
Show.displayName = 'Event';

export const useEvent = () => React.useContext(Show);

export const EventProvider = ({children}) => {
    const [event, setEvent] = useState({})
    const [eventId, setEventId] = useState(useParams()['eventId'])
    useEffect(async () => {
        if (!_.isEmpty(eventId)) {
            let eventDetails = await getEventDetails(eventId);
            titleUpdate(`Event Details - ${eventDetails.name}`)
            setEvent(eventDetails);
        }

    }, [eventId])

    return (
        <Show.Provider value={{event, setEvent, setEventId}}>{children}</Show.Provider>
    );
};
