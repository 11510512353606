import {
  getEventIndexDetails,
  getSearchedEventIndexDetails,
  getEventByTagAndSort,
} from "../../../apis";
import { isNull } from "lodash/lang";
import _ from "lodash";

export default ({
  links,
  name,
  setEventIndex,
  setPagination,
  setIsLoaded,
  setError,
  searchQuery = "",
  sortSelect = "latest",
  tagSelect = "All Tags",
}) => {
  let paginationLinks = links.slice(1, -1),
    firstLink = links[0],
    lastLink = links.at(-1);

  function getPaginatedEvents(url) {
    if (!isNull(url)) {
      setIsLoaded(false);
      let page = url.split("?page=").pop();
      if (!_.isEmpty(searchQuery)) {
        getSearchedEventIndexDetails(searchQuery, page)
          .then((response) => {
            setEventIndex(response.data);
            setPagination(response.meta);
            setIsLoaded(true);
          })
          .catch((error) => {
            //setError(true);
          });
      } else if (sortSelect !== "latest" && tagSelect !== "All Tags") {
        getEventByTagAndSort(tagSelect, sortSelect, page)
          .then((response) => {
            setEventIndex(response.data);
            setPagination(response.meta);
            setIsLoaded(true);
          })
          .catch((error) => {
            //setError(true);
          });
      } else {
        getEventIndexDetails(name === "expired" ?  page+"&status=expired" : page)
          .then((response) => {
            addQueryParam(page);
            setEventIndex(response.data);
            setPagination(response.meta);
            setIsLoaded(true);
          })
          .catch((error) => {
            setError(true);
          });
      }
    }
  }

  function addQueryParam(page) {
    const url = new URL(window.location.href);

    if (page > 1) {
      url.searchParams.set(name, page);
      window.history.pushState({}, "", url.toString());
    } else {
      window.history.pushState(
        {},
        document.title,
        window.location.href.split("?")[0]
      );
    }
  }

  return (
    <div className="flex items-center justify-center mt-12">
      <nav
        className="relative z-0 inline-flex rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <span
          onClick={(e) => {
            e.preventDefault();
            getPaginatedEvents(firstLink.url);
          }}
          className="relative cursor-pointer inline-flex items-center text-secondary-500 shadow-card rounded-[10px] p-2.5 mr-7"
        >
          <svg
            className="h-3.5 w-3.5"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M14.5.51v14H.5v-14z" />
              <path
                d="M11.295.91a1.402 1.402 0 0 1 0 1.982L6.679 7.508l4.616 4.616a1.402 1.402 0 0 1-1.983 1.983L3.705 8.5a1.402 1.402 0 0 1 0-1.983L9.312.91a1.402 1.402 0 0 1 1.983 0z"
                fill="#AAA"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </span>
        {paginationLinks.map(function (link, key) {
          return (
            <a
              href={link.url}
              key={key}
              onClick={(e) => {
                e.preventDefault();
                getPaginatedEvents(link.url);
              }}
              className={`relative cursor-pointer inline-flex items-center px-4 py-2 ${
                link.active ? "text-primary-green" : "text-secondary-500"
              }`}
            >
              {" "}
              {link.label}{" "}
            </a>
          );
        })}
        <span
          onClick={(e) => {
            e.preventDefault();
            getPaginatedEvents(lastLink.url);
          }}
          className="relative cursor-pointer inline-flex items-center text-secondary-500 shadow-card rounded-[10px] p-2.5 ml-7"
        >
          <svg
            className="h-3.5 w-3.5"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M.5.51v14h14v-14z" />
              <path
                d="M3.705 14.09a1.402 1.402 0 0 1 0-1.982l4.616-4.616-4.616-4.616A1.402 1.402 0 0 1 5.688.893L11.295 6.5a1.402 1.402 0 0 1 0 1.983L5.688 14.09a1.402 1.402 0 0 1-1.983 0z"
                fill="#AAA"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </span>
      </nav>
    </div>
  );
};
