import React, {useState} from "react";
import DownArrow from "../../../../assets/icons/DownArrow";
import Text from "../../Text";
import {Link,useNavigate} from "react-router-dom";


// function SettingsTitleSection({text = "Go back to Dashboard", headerText = "General settings",backLink}){
    // const navigate = useNavigate();
// const SettingsTitleSection = () => {
//     return
//         <div className='flex items-center h-[5rem] shadow-header'>
//         <div className='flex items-center w-full'>
//             <Link to={'/dashboard'}>
//       <span
//           className='flex pl-10 min-w-[20rem] mr-6 items-center font-medium cursor-pointer text-cyan text-base font-semibold underline'>
//           <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
//             Go Back
//       </span>
//             </Link>

//             <Text
//                 as='h1'
//                 className='flex text-3xl text-light-gray'
//             >
//                 Profile
//             </Text>
//         </div>
//     </div>;

// }
const GeneralSettingTitle = () => {
    return <div className='flex items-center h-[4.6rem] shadow-header'>
        <div className='flex items-center w-full'>
            <Link to={'/'}>
      <span
          className='flex pl-10 min-w-[20rem] mr-6 items-center font-medium cursor-pointer text-primary-green text-base font-semibold underline'>
          <DownArrow className="rotate-90 mr-2.5" color="#00b7b8"/>
            Go Back to Dashboard
      </span>
            </Link>

            <Text
                as='h1'
                className='flex text-3xl text-light-gray'
            >
                General Settings
            </Text>
        </div>
    </div>;
};

export default GeneralSettingTitle;
