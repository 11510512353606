import React from "react";

function IconButton(props) {
    const { className = "", disabled = false, onClick, children ,title="", id="" } = props;
    return (
        <button
            id={id}
            className={`shadow-card rounded-lg px-3 py-2.5 bg-transparent ${className}`}
            disabled={disabled}
            onClick={onClick}
            title={title}
        >
            {children}
        </button>
    );
}

export default IconButton;
