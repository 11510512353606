import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import {
  deleteEvent,
  markEventAsExpired,
  publishEvent,
} from "../../../../apis";
import { useUploader } from "../../../../Store/Uploader";
import { useUser } from "../../../../Store/User";

const EventsMore = ({ event, updateEvents, setOpenCopyEventCode }) => {
  const { user } = useUser();
  const { uploadEvent } = useUploader();

  const moveToExpired = (e) => {
    if (uploadEvent.id === event.id) {
      return false;
    }
    markEventAsExpired(event).then(function () {
      updateEvents();
      window.Toast.fire({
        icon: "success",
        title: `${event.name} event marked as expired`,
      });
    });
    e.stopPropagation();
  };

  const markEventAsDeleted = (e) => {
    if (uploadEvent.id === event.id) {
      return false;
    }
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete ${event.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteEvent(event)
          .then(function () {
            updateEvents();
            window.Toast.fire({
              icon: "success",
              title: `${event.name} event deleted`,
            });
          })
          .catch((error) => {
            console.log(error.response);
            window.Toast.fire({
              icon: "error",
              title:
                `${error.response?.data?.message}` ??
                "Unable to delete the event.",
            });
          });
      },
    }).then(function (key) {
      if (key.isConfirmed) {
      }
    });

    e.stopPropagation();
  };

  const markEventAsPublished = (e) => {
    e.stopPropagation();

    Swal.fire({
      title: "Publish Event!",
      text: `Are you sure you want to publish ${event.name}?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Publish",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return publishEvent(event, true)
          .then(function (response) {
            updateEvents();
            window.Toast.fire({
              icon: "success",
              title: `${event.name} event published successfully`,
            });
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: `${event.name} event could not be published`,
            });
          });
      },
    }).then(function (key) {
      if (key.isConfirmed) {
      }
    });
  };

  const markEventAsUnpublished = (e) => {
    publishEvent(event, false)
      .then(function (response) {
        updateEvents();
        window.Toast.fire({
          icon: "success",
          title: `${event.name} event unpublished successfully`,
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: `${event.name} event could not be unpublished`,
        });
      });
  };

  function handleCopyEventModal(e) {
    setOpenCopyEventCode(true);
  }

  return (
    <div className="absolute overflow-hidden rounded-[10px] bg-secondary-800 shadow-card z-40 w-48 right-4 top-[95%]">
      <div className="flex select-none flex-col text-secondary-300 text-sm">
        {event.isPublished ? (
          <div className="cursor-pointer py-1.5 hover:text-primary-green hover:bg-black px-4 ">
            {user.permissions.share_event ? (
              <Link
                className="w-full block  hover:overflow-hidden"
                to={"/share/" + event.id}
              >
                Share
              </Link>
            ) : (
              <p
                title="Upgrade Subscription"
                className="w-full block cursor-not-allowed hover:overflow-hidden"
              >
                Share
              </p>
            )}
          </div>
        ) : (
          <div className="cursor-pointer px-4 py-3 hover:text-primary-green   hover:bg-black hover:overflow-hidden">
            <Link className="w-full block" to={"/share/" + event.id}>
              Pre-registration share
            </Link>
          </div>
        )}
        {/*<div className="cursor-pointer px-4 py-1.5 hover:text-primary-green">Make a Copy</div>*/}
        <hr className="border-t border-secondary-600 mx-4" />
        {!event.hasExpired ? (
          <div
            title={uploadEvent.id === event.id ? "Upload in progress" : ""}
            className={`${
              uploadEvent.id === event.id
                ? "cursor-not-allowed text-slate-500"
                : "cursor-pointer hover:bg-black hover:text-primary-green"
            } px-4 py-1.5`}
            onClick={moveToExpired}
          >
            Move to Expired Events
          </div>
        ) : (
          ""
        )}
        <div className="cursor-pointer px-4 py-1.5 hover:text-primary-green  hover:bg-black">
          <Link to={"/events/" + event.id + "/settings"}>
            Edit Event Details
          </Link>
        </div>

        <div
          className="event-card cursor-pointer px-4 py-1.5 hover:text-primary-green  hover:bg-black"
          onClick={() => {
            handleCopyEventModal(event.id);
          }}
        >
          Copy Event Code
        </div>

        {!event.isPublished &&
        !event.hasExpired &&
        user.permissions.create_event ? (
          <div
            className="cursor-pointer px-4 py-1.5 hover:text-primary-green  hover:bg-black"
            onClick={markEventAsPublished}
          >
            Publish Event
          </div>
        ) : !event.hasExpired ? (
          <div
            className="cursor-pointer px-4 py-1.5 hover:text-primary-green  hover:bg-black"
            onClick={markEventAsUnpublished}
          >
            Move Event to draft
          </div>
        ) : (
          ""
        )}
        <div
          title={uploadEvent.id === event.id ? "Upload in progress" : ""}
          className={`${
            uploadEvent.id === event.id
              ? "cursor-not-allowed text-slate-500"
              : "cursor-pointer hover:bg-black hover:text-red-500"
          } px-4 py-1.5 `}
          onClick={markEventAsDeleted}
        >
          Delete Event
        </div>
      </div>
    </div>
  );
};

export default EventsMore;
