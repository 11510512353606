import React, {Fragment, useRef, useState, useContext, useEffect} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import CloseIcon from "../../../../assets/icons/CloseIcon";
import _ from 'lodash';
import {useEvent} from "../../../../Store/Event/Show";
import RadioButton from "../../../common/RadioButton";
import {moveImagesToFolder} from "../../../../apis";
import {useFolder} from "../../../../Store/Folder/Show";


export default function MoveFolderPopup({setShowMoveFolder, currentFolder, selectedImages, setSelectedImages}) {

    let {event} = useEvent(),
        {images, setImages} = useFolder();
    const [errors, setErrors] = useState({}),
        [errorsCount, setErrorsCount] = useState(0),
        [moving, setMoving] = useState(false),
        [folders, setFolders] = useState([]),
        [handleDuplicates, setHandleDuplicates] = useState('skip'),
        [selectedFolderId, setSelectedFolderId] = useState(null),
        cancelButtonRef = useRef(null),
        [open, setOpen] = useState(true);

    useEffect(async () => {
        if (!_.isEmpty(event.folders)) {
            let folders = event.folders.filter(folder => folder.id !== currentFolder.id);
            setFolders(folders.sort((a, b) => a.order - b.order));
        } else {
            setFolders([]);
        }
    }, [event]);

    const handleClose = (e) => {
        setOpen(false);
        setShowMoveFolder(false);
        setMoving(false);
        setSelectedFolderId(null);
        setErrorsCount(0);
        setErrors([]);
    }

    function moveImages(type = 'move') {
        setErrorsCount(0);
        setErrors({});
        setMoving(true);
        moveImagesToFolder(selectedImages, currentFolder.id, selectedFolderId, type, handleDuplicates)
            .then((response) => {
                setImages(response.images);
                setMoving(false);
                setErrorsCount(response.errorImages);
                setErrors(response.errorMessages);
                if (response.errorImages === 0) {
                    setSelectedImages([]);
                    handleClose();
                    window.Toast.fire({
                        icon: 'success',
                        title: type === 'move' ? 'Images moved successfully' : 'Images copied successfully'
                    });
                }
            });
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    onClose={(e) => {
                        handleClose(e)
                    }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                     ref={cancelButtonRef}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"

                    >
                        <div
                            className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="p-8">
                                <div className="sm:items-start">
                                    <div className="text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300">
                                            Move Selected Images
                                            <span onClick={(e) => {
                                                handleClose(e)
                                            }} className="cursor-pointer"><CloseIcon fill='#eee'/></span>
                                        </Dialog.Title>
                                        {
                                            moving ?
                                                <div className="mt-10 text-center">
                                                    Moving selected images...
                                                </div>
                                                :
                                                errorsCount > 0 ?
                                                    <div className="mt-10">
                                                        <h3 className="w-full py-2 bg-black text-primary-error-red text-center rounded-[10px]">{errorsCount} out
                                                            of {selectedImages.length} images failed</h3>
                                                        <br/>
                                                        <h3>These following errors occurred.</h3>
                                                        <ol className="px-5 list-disc">
                                                            {Object.values(errors).map((error, index) => {
                                                                return <li key={index}>{error}</li>
                                                            })}
                                                        </ol>
                                                    </div>
                                                    :
                                                    <div className="mt-1.5">
                                                        <h3 className="text-base font-normal mb-5">Choose the folder to move into</h3>

                                                        <div className="flex flex-row justify-between gap-5 mb-9 mr-10">
                                                            <div className="flex flex-col w-full">
                                                                <RadioButton
                                                                    width={'w-full'}
                                                                    checked={handleDuplicates === 'skip'}
                                                                    handleOnClick={() => setHandleDuplicates('skip')}
                                                                    label="Skip Duplicates"
                                                                />
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <RadioButton
                                                                    width={'w-full'}
                                                                    checked={handleDuplicates === 'overwrite'}
                                                                    handleOnClick={() => setHandleDuplicates('overwrite')}
                                                                    label="Overwrite Duplicates"
                                                                />
                                                            </div>
                                                        </div>

                                                        <h4 className="mb-5 text-sm text-secondary-500">
                                                            {currentFolder.name} ({selectedImages.length} images selected)
                                                        </h4>
                                                        <div className="grid grid-cols-2 gap-5 max-h-32 overflow-y-scroll">

                                                            {
                                                                folders.map(function (folder) {
                                                                    return <div key={folder.id}
                                                                                className={`cursor-pointer capitalize truncate rounded-[10px] py-2 pl-5 ${selectedFolderId === folder.id ? 'bg-black text-primary-green' : 'bg-transparent text-white'}`}
                                                                                onClick={() => setSelectedFolderId(folder.id)}>{folder.name}</div>
                                                                })
                                                            }
                                                        </div>
                                                        <div className='flex gap-5 justify-center mt-10'>
                                                            <button
                                                                className={`rounded-lg font-semibold w-[11rem] py-2 ${_.isNull(selectedFolderId) ? 'bg-button-disabled' : "shadow-button"} text-light-gray `}
                                                                disabled={_.isNull(selectedFolderId)}
                                                                onClick={() => {
                                                                    moveImages('copy')
                                                                }}
                                                            >
                                                                Make a Copy
                                                            </button>
                                                            <button
                                                                className={`rounded-lg font-semibold w-[11rem] py-2 ${_.isNull(selectedFolderId) ? 'bg-button-disabled' : "shadow-button bg-button"} text-light-gray `}
                                                                disabled={_.isNull(selectedFolderId)}
                                                                onClick={() => {
                                                                    moveImages()
                                                                }}
                                                            >
                                                                Move
                                                            </button>
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
