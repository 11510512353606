import {Component} from 'react';
import Errors from '../Errors';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
        console.log('Logging', error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <Errors message={'Something went wrong!'} text={'Please try again after sometime.'}  />
        );
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;