import React, { useEffect, useState, useCallback } from "react";

import SwitchWithTitle from "../SwitchWithTitle";
import Text from "../../../../components/common/Text";

import Title from "../../../../components/common/Title";
import { InputEditable } from "../../../../components/common/Input";
import _ from "lodash";
import { useUser } from "../../../../Store/User";
import {
  updatePhotographerDetails,
  updatePhotographerTrackingId,
} from "../../../../apis";

const AnalyticsSection = () => {
  const { user, setUser } = useUser();
  const [gaValue, setGaValue] = useState(user.photographer.ga_tracking_id);
  const [newGA, setNewGA] = useState(0);
  const [gaError, setGaError] = useState(null);
  const [executePhotographerDebounce, setExecutePhotographerDebounce] =
    useState(false);
  const [successStatus, setSuccessStatus] = useState({ ga: null });

  useEffect(() => {
    setNewGA(gaValue);
    if (!_.isEmpty(user) && !_.isNull(user.photographer.ga_tracking_id)) {
      setNewGA(1);
    }
  }, []);

  useEffect(() => {
    if (executePhotographerDebounce) {
      updatePhotographerTrackingId(gaValue)
        .then((response) => {
          window.Toast.fire({
            icon: "success",
            title: gaValue
              ? "Tracking ID added successfully"
              : "Tracking ID deleted successfully",
          });
          setNewGA(gaValue);
          setUser({ ...user, access_token: response.access_token });
        })
        .catch((error) => {
          setNewGA("");
          setGaValue("");
          window.Toast.fire({
            icon: "error",
            title: "Tracking ID could not be added",
          });
        });

      setExecutePhotographerDebounce(false);
    }
  }, [executePhotographerDebounce]);

  function updateTracking(val) {
    setGaValue(val);
    // setExecutePhotographerDebounce(false);
    if (/\b[A-Z][A-Z0-9]?-[A-Z0-9]{4,10}(?:\-[1-9]\d{0,3})?\b/.test(val)) {
      setGaError(null);
      updatePhotographerTrackingId(gaValue)
        .then((response) => {
          window.Toast.fire({
            icon: "success",
            title: gaValue
              ? "Tracking ID added successfully"
              : "Tracking ID deleted successfully",
          });
          setNewGA(gaValue);
          setUser({ ...user, access_token: response.access_token });
          setSuccessStatus({ ...successStatus, ga: true });
        })
        .catch((error) => {
          setNewGA("");
          setGaValue("");
          window.Toast.fire({
            icon: "error",
            title: "Tracking ID could not be added",
          });
        });
      //  photographerDebounce();
      //  setExecutePhotographerDebounce(true);
    } else {
      // setExecutePhotographerDebounce(false);
      setGaError("*GA code is wrong");
    }
  }
  function removeGA() {
    setNewGA("");
    setGaValue("");
    updatePhotographerDetails({ gaTrackingId: "null" })
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Google Analytics deleted successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error processing Google Analytics",
        });
      });
  }

  return (
    <>
      <Title
        className="pb-4 border-zinc-700 border-b mb-4 mt-4"
        text="Analytics"
      />
      <div className="flex flex-row justify-between gap-32">
        <div className="flex flex-col w-full">
          <SwitchWithTitle
            className=""
            title="Enable Google Analytics tracking"
            value={newGA}
            onChange={() => {
              newGA ? removeGA() : setNewGA(1);
            }}
            // onChange={newGA ? removeGA : setNewGA(0)}
            // disable = {!!newGA ? '':'disable'}
          />
        </div>

        <div className="flex flex-col w-full ">
          {newGA ? (
            <>
              <p className="flex justify-between items-center text-light-gray text-base mb-4">
                <span className="inline-block">
                  Enter Google Analytics tracking ID
                </span>
              </p>
              <div className="relative">
                <InputEditable
                  onUpdate={(value) => {
                    updateTracking(value);
                  }}
                  onChange={(e) => setGaValue(e.target.value)}
                  placeholder={"G-XXXXXXXXX"}
                  value={gaValue ? gaValue : ""}
                  error={gaError}
                  success={successStatus.ga}
                />
              </div>
              <Text as="p" className="text-sm my-4">
                You can enable Google Analytics on your collections by entering
                your Google Analytics Tracking ID. <br />
                {/* <Link to="/https://support.google.com/sites/answer/97459?hl=en" className="cursor-pointer" linkName="Learn more"/> */}
                <a
                  href="https://support.google.com/sites/answer/97459?hl=en"
                  className="text-primary-green text-xs font-semibold underline cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </Text>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AnalyticsSection;
