/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import {
  deleteFolder,
  postDeleteAll,
  privateFolder,
  updateFolderName,
} from "../../apis";
import { useEvent } from "../../Store/Event/Show";
import { useFolder } from "../../Store/Folder/Show";
import { useUploader } from "../../Store/Uploader";
import { InputEditable } from "../common/Input";

export default function ({
  folder,
  currentFolderId,
  showFolderDropdown,
  setSelectedImages,
  setShowFolderDropdown,
  setFolders,
  currentFolderName,
  setCurrentFolderName,
}) {
  const { event, setEvent } = useEvent();
  const { uploadEvent } = useUploader();
  const { setFolderId, setImages } = useFolder();
  const [cancelRename, setCancelRename] = useState(false);
  const [folderInputField, setFolderInputField] = useState(false);
  const [folderName, setFolderName] = useState(null);

  useEffect(() => {
    setFolderName(folder.name);
  }, [folder]);

  const showDropdownFunction = (e) => {
    if (showFolderDropdown === folder.id) setShowFolderDropdown(null);
    else setShowFolderDropdown(folder.id);
  };

  function deleteAllImages() {
    if (uploadEvent.id === event.id) {
      return false;
    }
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete all images of sub-event ${folder.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!folder.imagesCount) {
          window.Toast.fire({
            icon: "error",
            title: "No image to delete",
          });
        }
        const payload = {
          folderId: folder.id,
          imageCount: folder.imagesCount,
          eventId: event.id,
          slug: event.slug,
        };
        return postDeleteAll(payload)
          .then(() => {
            window.Toast.fire({
              icon: "success",
              title:
                folder.imagesCount > 1000
                  ? "All images will be deleted in few minutes"
                  : "All images deleted successfully",
            });
            if(folder.id === currentFolderId ){
              setImages([])
            }
            setSelectedImages([]);
            setFolders((prev) => {
              const prevCopy = structuredClone(prev);
              prevCopy.forEach((el) => {
                if (el.id === folder.id) {
                  el.publicImagesCount = 0;
                  el.imagesCount = 0;
                }
              });
              return prevCopy;
            });
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Folder could not be deleted",
            });
          });
      },
    });
  }

  function deleteCurrentFolder() {
    if (uploadEvent.id === event.id) {
      return false;
    }
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete sub-event ${folder.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteFolder(folder, event.slug)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Folder deleted successfully",
            });
            let folders = response.data;
            setEvent({ ...event, folders: folders });
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Folder could not be deleted",
            });
          });
      },
    });
  }

  function handleFolderPrivate(status) {
    privateFolder(folder, status)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Folder status changed successfully",
        });
        let folders = response.data;
        setFolders(folders.sort((a, b) => a.order - b.order));
        let newEvent = event;
        newEvent.folders = response.data;
        setEvent({ ...newEvent });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Folder status could not be changed",
        });
      });
  }

  function updateCurrentFolderName(value) {
    setFolderInputField(!folderInputField);
    setFolderName(value);
    if (folder.name === currentFolderName) setCurrentFolderName(value);
    let name = folderName;

    if (name === undefined || name === null || name === "") {
      window.Toast.fire({
        icon: "error",
        title: "Folder name cannot be empty",
      });
      return false;
    }
    const filter = ["my photos", "myphotos"];
    if (filter.includes(name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase())) {
      window.Toast.fire({
        icon: "error",
        title: 'Folder name cannot be "my photos"',
      });
      setFolderName(folder.name);
      if (folder.name === currentFolderName) setCurrentFolderName(folder.name);
      return false;
    }
    updateFolderName(folder, name)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Folder name updated successfully",
        });
        let folders = response.data.filter(
          (folder) => folder.slug !== "guest-uploads"
        );
        setFolders(folders.sort((a, b) => a.order - b.order));
        let newEvent = event;
        newEvent.folders = response.data;
        setEvent({ ...newEvent });
      })
      .catch((error) => {
        let errors = error.response.data.message;

        if (errors.name) {
          window.Toast.fire({
            icon: "error",
            title: errors ?? "Internal server error",
          });
        } else {
          window.Toast.fire({
            icon: "error",
            title: "Folder name could not be Updated",
          });
        }
      });
  }

  function switchFolder(folder) {
    if (folder.id !== currentFolderId) {
      setImages(null);
      setFolderId(folder.id);
      setSelectedImages([]);
      return true;
    }
    return false;
  }

  return folderInputField && !cancelRename ? (
    <div>
      <InputEditable
        actionText="Update"
        showCancelButton={true}
        setCancel={setCancelRename}
        value={folderName}
        onUpdate={(value) => updateCurrentFolderName(value)}
        onChange={(e) => {
          setFolderName(e.target.value);
        }}
      />
    </div>
  ) : (
    <li
      className={`${
        currentFolderId === folder.id ? "bg-black" : ""
      } flex cursor-pointer flex-row relative rounded-[10px] justify-between hover:bg-black px-5 items-center py-3 h-[40px] text-light-gray`}
    >
      {!_.isEqual(folder.slug, "guest-uploads") ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 cursor-grab rotate-45 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
          />
        </svg>
      ) : (
        ""
      )}
      <span>
        {folder.showOnlyOnFaceSearch &&
        !_.isEqual(folder.slug, "guest-uploads") ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 cursor-grab "
            fill="#fff"
            viewBox="0 0 24 24"
            stroke="#fff"
          >
            <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z" />
          </svg>
        ) : (
          ""
        )}
      </span>
      <span
        title={folder.name}
        onClick={() => {
          switchFolder(folder);
        }}
        className={`${
          currentFolderId === folder.id ? "text-primary-green" : ""
        } truncate text-base ml-2 w-3/4`}
      >
        {folder.name}
      </span>

      <span className="flex justify-center items-center">
        <span className="flex rounded-md justify-center px-2 items-center text-xs bg-black-gray min-w-4 h-[1.15rem] mr-2">
          {folder.imagesCount ?? 0}
        </span>

        <div title="More">
          <svg
            onClick={(e) => showDropdownFunction(e)}
            xmlns="http://www.w3.org/2000/svg"
            className={`${
              showFolderDropdown === folder.id ? "text-primary-green" : ""
            } h-6 w-6 show-folder-options`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </div>
        {showFolderDropdown === folder.id ? (
          <div
            className={`top-10 z-40 absolute right-5 w-44 rounded-[10px] shadow-card bg-secondary-800 overflow-hidden`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="" role="none">
              {!_.isEqual(folder.slug, "guest-uploads") ? (
                <div
                  title={
                    folder.showOnlyOnFaceSearch
                      ? ""
                      : "Images from this sub event will be visible only via face search"
                  }
                  onClick={() => {
                    handleFolderPrivate(folder.showOnlyOnFaceSearch ? 0 : 1);
                  }}
                  className="text-gray-700 px-4 py-3 hover:bg-black block whitespace-nowrap text-sm cursor-pointer hover:text-primary-green"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                >
                  {folder.showOnlyOnFaceSearch === 1
                    ? "Make Public"
                    : "Make Private"}
                </div>
              ) : (
                <></>
              )}
              {!_.isEqual(folder.slug, "guest-uploads") ? (
                <div
                  onClick={() => {
                    setCancelRename(false);
                    setFolderInputField(true);
                  }}
                  className="text-gray-700 px-4 py-3 hover:bg-black block  text-sm cursor-pointer hover:text-primary-green"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                >
                  Rename
                </div>
              ) : (
                <></>
              )}
              <div
                onClick={deleteAllImages}
                title={uploadEvent.id === event.id ? "Upload in progress" : ""}
                className={`${
                  uploadEvent.id === event.id || !folder.imagesCount
                    ? "text-slate-500 cursor-not-allowed"
                    : "text-gray-700 hover:bg-black hover:text-primary-error-red"
                } px-4 py-3 block text-sm`}
                role="menuitem"
                tabIndex="-1"
                id="menu-item-1"
              >
                Delete All Images
              </div>
              {!_.isEqual(folder.slug, "guest-uploads") ? (
                <div
                  onClick={deleteCurrentFolder}
                  title={
                    uploadEvent.id === event.id ? "Upload in progress" : ""
                  }
                  className={`${
                    uploadEvent.id === event.id
                      ? "text-slate-500 cursor-not-allowed"
                      : "text-gray-700 hover:bg-black hover:text-primary-error-red"
                  } px-4 py-3 block text-sm`}
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-1"
                >
                  Delete
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </span>
    </li>
  );
}
