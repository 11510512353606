import React, { useState,useEffect } from "react";
import Text from "../Text";
import checkedsvg from "../../../assets/images/checked.jpeg";
import _ from "lodash"
import {Link} from 'react-router-dom'

function Checkbox(props) {
    const {
        className,
        checked = false,
        desc="",
        id = "",
        label = "",
        name = "",
        additionclass='mt-1',
        disabled = false,
        onChange = () => {},
        value = "",
        labelClass='text-xs',
        label_two = "",
    } = props;
    const [isChecked, setChecked] = useState(value);
    const [isUpdated, setUpdated] = useState(false);

    const changeHandler = val => {
        setUpdated(true);
        if(isChecked == 0){
            setChecked(1);
        }else{
            setChecked(0);
        }
    };

    useEffect(() => {
        if(isUpdated){
            onChange(isChecked);
        }
        setUpdated(false);
    }, [isUpdated])


    const myStyle={
        backgroundImage: `url(${checkedsvg})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer'
    };

    return (
        <label
            className={`text-light-gray text-sm flex cursor-pointer  relative `}
            htmlFor={id}
            title={`${_.isEmpty(desc)? 'Not set':'' }`}
        >
            <input
                className={`absolute w-0 h-0 opacity-0  `}
                type='checkbox'
                name={name || id}
                id={id}
                checked={isChecked}
                onChange={changeHandler}

            />
            <span className={`w-4 h-4 bg-dark-gray shadow-input-filled rounded cursor-pointer ${additionclass}`} style={ isChecked ? myStyle : {}} ></span>
            <span className='flex items-center  ml-3'>
                <Text className={`flex items-center pt-1 ${labelClass} text-secondary-300`}>{label}
                    <Link to="/terms" >
                        <span className="text-primary-green" >{label_two}</span>
                    </Link>
                </Text>
            </span>
        </label>
    );
}

export default Checkbox;
