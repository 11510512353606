import React, {Fragment, useRef, useState, useContext} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Button from '../../../../../../components/common/Button';
import CloseIcon from "../../../../../../assets/icons/CloseIcon";
import {InputText} from '../../../../../../components/common/Input/input-text';
import {InputDate} from '../../../../../../components/common/Input/input-date';
// import {createNewEvent} from '../../../apis';
import moment from 'moment';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';


export default function LightroomPopUp({showLightroom,setShowLightroom,lightRoomData, setLightRoomData}) {
    
    const navigate = useNavigate();
    const [open, setOpen] = useState(true)
    const [eventDetails, setEventDetails] = useState({
        name: '',
        startDate: null,
        endDate: null,
    });
    const [copyTextBtn , setCopyTextBtn] = useState("Copy");
    const [errors, setErrors] = useState({});

    const cancelButtonRef = useRef(null);
    
    // const initialFocus = {cancelButtonRef}

    const handleClose = (e) => {
        setOpen(false);
        setShowLightroom(false);
        setCopyTextBtn("Copy");
    }

    const handleExtension = (e) => {
        //console.log(e.target.value)

    }

    const copyToClipboard = async() => {
        await navigator.clipboard.writeText(lightRoomData);
        setCopyTextBtn("Copied")
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    onClose={(e) => {
                        handleClose(e)
                    }}
                    >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" ref={cancelButtonRef}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        
                    >
                        <div
                            className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="p-8">
                                <div className="sm:items-start">
                                    <div className="text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300">
                                            Light Room Export
                                            <span onClick={(e) => {
                                            handleClose(e)
                                        }} className="cursor-pointer"><CloseIcon fill='#eee'/></span>
                                        </Dialog.Title>
                                        <div className="mt-10">
                                            <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                                                <div className="flex items-center">
                                                    <textarea className='w-full h-32 rounded-xl bg-dark-gray p-2 mt-3 text-light-gray shadow-inner-all resize-none' autoFocus={true} value={lightRoomData} readOnly/>
                                                </div>
                                            </div>
                                            {/* <div className='flex pt-10'>
                                                <button  >Add Extension</button>
                                                <input onChange={(e)=>{handleExtension(e)}} className='ml-5 rounded-l bg-dark-gray text-light-gray shadow-dark-input' ></input>
                                            </div> */}
                                            <div className='flex justify-center pt-10'>
                                                <button
                                                    className="rounded-lg shadow-button font-semibold w-[11rem] py-2 bg-button text-light-gray"
                                                    // disabled={buttonDisable}
                                                    onClick={()=>{copyToClipboard()}}   
                                                >
                                                    {copyTextBtn}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
