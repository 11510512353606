/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../../assets/icons/CloseIcon";

export default function DialogWrapper(props) {
    const { title, open, close, hideTitle = false, hideClose=false } = props;
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as='div'
                className="fixed z-40 inset-0 overflow-y-auto dark"
                initialFocus={cancelButtonRef}
                onClose={() => {}}
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-80 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='hidden sm:inline-block sm:align-middle sm:h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[60%] sm:w-full '>
                            <div className='bg-dark-gray px-6 pt-6 pb-4'>
                                
                                    <div className='flex justify-items-end mb-5'>
                                        {!hideTitle && (
                                            <div className=''>
                                                <Dialog.Title
                                                    as='h3'
                                                    className='text-dark-gray1 dark:text-light-gray text-3xl font-medium'
                                                >
                                                    {title}
                                                </Dialog.Title>
                                            </div>
                                         )}
                                         {!hideClose && (
                                        <span
                                            className='text-3xl cursor-pointer absolute top-4 right-4'
                                            onClick={close}
                                        >
                                            <CloseIcon
                                                fill="#eee"
                                            />
                                        </span>
                                        )}
                                    </div>
                               
                                {props.children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
