import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Steps } from "intro.js-react";

import TitleSection from "../../components/common/TitleSection/index";
import FilterSection from "../../components/common/FilterSection";
import Title from "../../components/common/Title";
import AlbumPreviewSection from "../../components/common/AlbumPreviewSection";
import GettingStarted from "./components/GettingStarted";
import GalleryListView from "./components/GalleryListView";
import GalleryTileView from "./components/GalleryTileView";
import { useFolder } from "../../Store/Folder/Show";
import Loading from "../Loading";
import MoveFolderPopup from "../../components/Event/Popups/MoveFolder";
import { getCookie, setCookie } from "../../functions";
import { useUploader } from "../../Store/Uploader";
import { useEvent } from "../../Store/Event/Show";
import { getEventDetails } from "../../apis";
import { useUser } from "../../Store/User";
import { useHelpContext } from "../../Store/Help";

const UploadContent = () => {
  const { event, setEvent } = useEvent(),
    [currentSubPage, setCurrentSubPage] = useState("Loading"),
    [showDropdown, setShowDropdown] = useState(null),
    [tileSize, setTileSize] = useState(1),
    [eventImagesCount, setEventImagesCount] = useState(null),
    [showMoveFolder, setShowMoveFolder] = useState(null),
    [selectedImages, setSelectedImages] = useState([]),
    [currentFolderName, setCurrentFolderName] = useState(null),
    { isUploading } = useUploader();
  const [folderSize, setFolderSize] = useState();

  const params = useParams();
  const eventId = params["eventId"];

  const { user } = useUser();
  const { showSteps, setShowSteps } = useHelpContext();

  const steps = [
    {
      element: "#cover-image-change",
      title: "Cover Image",
      intro: "Change the cover image of your event from here.",
      position: "bottom",
    },
    {
      element: "#description",
      title: "Event Description",
      intro: "Describe the event. This will be visible on your photo gallery",
      position: "bottom",
    },
    {
      element: "#add-sub-event",
      title: "Add Sub Event",
      intro:
        'By default, every new Event will be created with a "Highlights" sub-event, but you can easily use the + Add New button to create additional ones.',
      position: "bottom",
    },
    {
      element: "#one-shot-upload-button",
      title: "One Shot Upload",
      intro:
        "Upload the complete event in one go. Upload a folder with all sub folders containing images",
      position: "bottom",
    },
    {
      element: "#event-settings-more",
      title: "Event Settings",
      intro: "Adds watermark, archive or delete event etc.",
      position: "bottom",
    },
    {
      element: "#publish-more",
      title: "Publish",
      intro:
        "Publishing an event makes it viewable by those who have access. It also sends our email to pre-registered users of their face recognized images.",
      position: "bottom",
    },
    {
      element: "#share-more",
      title: event.isPublished ? "Share" : "Pre-Share",
      intro: event.isPublished
        ? "Share Your event with your guests"
        : "Share event link with attendees to pre-register their faces to recieve images automatically once event is published",
      position: "bottom",
    },
  ];

  let {
    folder,
    images,
    setImages,
    folderId,
    imageFilters,
    currentView,
    searchTerm,
  } = useFolder();

  useEffect(() => {
    if (isUploading === false) {
      setImages(null);
    }
  }, [isUploading]);

  const callEventDetails = async () => {
    const eventDetails = await getEventDetails(eventId);
    setEvent(eventDetails);
  };

  useEffect(() => {
    const size = Array.isArray(images)
      ? images?.reduce((acc, el) => (acc += +el.size), 0)
      : 0;
    setFolderSize((size / 1024).toPrecision(2));
  }, [images]);

  useEffect(() => {
    if (!isUploading && Array.isArray(images)) callEventDetails();
  }, [isUploading, images]);

  useEffect(() => {
    if (_.isNull(images)) {
      setCurrentSubPage("Loading");
    } else if (
      images &&
      images.length <= 0 &&
      imageFilters === null &&
      searchTerm == ""
    ) {
      setCurrentSubPage("GettingStarted");
    } else if (
      images &&
      images.length <= 0 &&
      (imageFilters !== null || searchTerm !== "")
    ) {
      setCurrentSubPage("NoImages");
    } else {
      if (currentView === "tile") setCurrentSubPage("GalleryTileView");
      else setCurrentSubPage("GalleryListView");
    }
  }, [folder, folderId, images, currentView]);

  useEffect(() => {
    if (folder) {
      setCurrentFolderName(folder.name);
    }
  }, [folder]);
  const getComponent = useCallback(
    ({
      selectedImages,
      setSelectedImages,
      setShowMoveFolder,
      tileSize,
      currentSubPage,
      setCurrentSubPage,
    }) => {
      switch (currentSubPage) {
        case "Loading":
          return <Loading />;
        case "NoImages":
          return (
            <div className="m-4 text-cyan-500">No images or videos found</div>
          );
        case "GettingStarted":
          return (
            <GettingStarted
              currentSubPage={currentSubPage}
              setCurrentSubPage={setCurrentSubPage}
            />
          );
        case "GalleryListView":
          return (
            <GalleryListView
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              setShowMoveFolder={setShowMoveFolder}
            />
          );
        case "GalleryTileView":
          return (
            <GalleryTileView
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              setShowMoveFolder={setShowMoveFolder}
              tileSize={tileSize}
              eventId={eventId}
              slug={event.slug}
            />
          );
        default:
          return (
            <GettingStarted
              currentSubPage={currentSubPage}
              setCurrentSubPage={setCurrentSubPage}
            />
          );
      }
    },
    [currentSubPage, tileSize, images]
  );

  const currentPage = getComponent({
    selectedImages,
    setSelectedImages,
    setShowMoveFolder,
    tileSize,
    currentSubPage,
    setCurrentSubPage,
  });

  function closeFolderDropdown(e) {
    if (typeof e.target.className.indexOf === "function") {
      if (e.target.className.indexOf("show-folder-options") < 0) {
        setShowDropdown(null);
      }
    }
  }

  useEffect(() => {
    if (Object.keys(event).length) {
      const sum = event.folders.reduce((acc, el) => {
        acc += el.imagesCount;
        return acc;
      }, 0);
      setEventImagesCount(sum);
    }
  }, [event]);

  const displaySteps =
    user.subscription.plan_type === "free"
      ? !getCookie("tourUploadPage") || showSteps
      : showSteps;

  return (
    <div onClick={closeFolderDropdown}>
      <TitleSection eventImagesCount={eventImagesCount} />
      {_.isEmpty(folder) ? (
        <Loading />
      ) : (
        <div className="flex">
          <div>
            {displaySteps && (
              <Steps
                enabled={true}
                steps={steps}
                initialStep={0}
                onExit={(step) => {
                  setCookie("tourUploadPage", 1);
                  setShowSteps(false);
                }}
                options={{
                  doneLabel: "Got it",
                }}
              />
            )}
            <AlbumPreviewSection
              currentFolderId={folder.id}
              showFolderDropdown={showDropdown}
              setSelectedImages={setSelectedImages}
              setShowFolderDropdown={setShowDropdown}
              eventImagesCount={eventImagesCount}
              setEventImagesCount={setEventImagesCount}
              currentFolderName={currentFolderName}
              setCurrentFolderName={setCurrentFolderName}
            />
          </div>

          <div className="flex flex-col ml-5 mr-10 mt-5 grow">
            <FilterSection
              tion
              imageCount={images !== null ? images.length : 0}
              currentSubPage={currentSubPage}
              setCurrentSubPage={setCurrentSubPage}
              tileSize={tileSize}
              setTileSize={setTileSize}
              eventImagesCount={eventImagesCount}
            />

            <Title
              className="mt-7 mb-5"
              text={currentFolderName}
              count={!_.isEmpty(images) ? images.length : 0}
              showSize={currentSubPage === "GalleryListView"}
              size={folderSize}
            />
            {currentPage}
          </div>
        </div>
      )}

      {showMoveFolder && (
        <MoveFolderPopup
          showMoveFolder={showMoveFolder}
          setShowMoveFolder={setShowMoveFolder}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          currentFolder={folder}
        />
      )}
    </div>
  );
};

export default UploadContent;
