import React, { useCallback, useState } from "react";
import { storeVideo } from "../../../../apis/upload";
import Button from "../../../../components/common/Button";
import { useFolder, youtube_parser } from "../../../../Store/Folder/Show";
import "./style.css";
import _ from 'lodash'

const AddVideoLink = ({setShowVideoUploader}) => {
    const [videoLink,setVideoLink] = useState("")
    const [linkError,setLinkError] = useState("")
    let {folder,images,setImages} = useFolder()
    let validateVideoURl = useCallback(()=>{
        var p = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com|youtube\.com)\/([\w\/]+)([\?].*)?$/;
        return (videoLink.match(p)) ? true : false;
    },[videoLink])
    const addVideo = useCallback(async ()=>{
        if(! _.isEmpty(videoLink) && validateVideoURl()){
            setLinkError("")
            let res = await storeVideo(videoLink,folder.id)
            if(res.status === 200 || res.status === 201){
                window.Toast.fire({
                    icon: 'sucess',
                    title: `Video added succesfully`
                });
                let newImage = res.data.data
                if(newImage.src.indexOf('vimeo')>-1){
                    newImage.orginalImageUrl = decodeURIComponent(newImage.src).replace("vimeo","vumbnail")+".jpg"
                    newImage.height = 360
                    newImage.width = 640
                }else{
                    newImage.orginalImageUrl = `https://img.youtube.com/vi/${youtube_parser(decodeURIComponent(newImage.src))}/0.jpg`
                    newImage.height = 360
                    newImage.width = 480
                }
                newImage.src = decodeURIComponent(newImage.src)
                
                setImages([...images,newImage])
                setShowVideoUploader(true)
            }else{
                window.Toast.fire({
                    icon: 'error',
                    title: `There was an error adding video`
                });
            }
        }else{
            setLinkError("Please enter valid youtube or vimeo URL")
        }
    },[videoLink])


    return (
        // <div className='flex items-center flex-col text-slate-50 py-10'>
        //     <div className=''>
        //         <div className='text-xl font-normal text-center'>
        //             Add Video Link
        //         </div>
        //         <div className='mx-auto text-sm'>
        //             <div className=' mb-2 text-xs px-4 text-zinc-500'>
        //                 {linkError===""?
        //                 'Enter Video Link': 
        //                 <span className="text-inline-block text-right text-xs pr-4 text-error-red">{linkError}</span>}
        //             </div>
        //             <div className='relative bg-dark-gray pr-3 rounded-lg shadow-button-new'>
                        
                        
        //                 <input
        //                     type='text'
        //                     placeholder='https://www.youtube.com/'
        //                     size='70'
        //                     className='bg-dark-gray text-fuchsia-800 text-sm h-14 px-4 rounded-lg hover:border-none focus-visible:outline-none'
        //                     name=''
        //                     value={videoLink}
        //                     onChange={(e)=>{setVideoLink(e.target.value)}}
        //                 />
        //                     <Button className='px-8' text='Embed' onClick={addVideo}></Button>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className='flex justify-center item-center  flex-col  text-slate-50 mx-4'>
            <div className='w-full p-5 text-center'>
                <div className='text-xl font-normal'>Add Video Link</div>
            </div>
            <div className='mx-auto text-sm mb-20'>
                    <div className=' mb-2 text-xs px-4 text-zinc-500'>
                        {linkError===""?
                        'Enter Video Link': 
                        <span className="text-inline-block text-right text-xs pr-4 text-error-red">{linkError}</span>}
                    </div>
                    <div className='relative bg-dark-gray pr-3 rounded-lg shadow-button-new'>
                    
                    
                        <input
                            type='text'
                            placeholder='https://www.youtube.com/'
                            size='70'
                            className='bg-dark-gray text-fuchsia-800 text-sm h-14 w-3/4 px-4 rounded-lg hover:border-none focus-visible:outline-none'
                            name=''
                            value={videoLink}
                            onChange={(e)=>{setVideoLink(e.target.value)}}
                        />
                            <Button className='px-8 w-1/4' text='Embed' onClick={addVideo}></Button>
                    </div>
                </div>                
        </div>
    );
};

export default AddVideoLink;
