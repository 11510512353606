/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState,useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DownArrow from "../../../assets/icons/DownArrow";
import {useEventIndex} from "../../../Store/Event/Index";
import _ from 'lodash';
import { getAllTags} from "../../../apis";

// const people = [
//   {
//     id: 1,
//     name: 'Wade Cooper',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 2,
//     name: 'Arlene Mccoy',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 3,
//     name: 'Devon Webb',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
//   },
//   {
//     id: 4,
//     name: 'Tom Cook',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 5,
//     name: 'Tanya Fox',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 6,
//     name: 'Hellen Schmidt',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 7,
//     name: 'Caroline Schultz',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 8,
//     name: 'Mason Heaney',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 9,
//     name: 'Claudie Smitham',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     id: 10,
//     name: 'Emil Schaefer',
//     // avatar:
//     //   'https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Example({tagClicked}) {
  const {events, isLoaded, hasError} = useEventIndex();
  const [selected, setSelected] = useState("All Tags")
  const [tag, setTag] = useState([]);

//   useEffect(async () => {
//     let tag = !_.isEmpty(events) ? events.flatMap(function (event, key) {
//         return event.tags;
//     }) : [];
//     setTag(_.uniqBy(tag,'id'));
// }, [events]);


  useEffect(async () => {
      getAllTags().then(response => {
        setTag(response.filter(tag => tag.title))
      }).catch(errors => {

      });
  }, []);

  const onOptionChange = (e) => {
    if(e === "All Tags"){
      setSelected(e);
      tagClicked(e);
      return;
    }

    let tagName = tag.find(function (tag) {
      return tag.id === e;
    });
    setSelected(tagName.title);
    tagClicked(e)
  }



  return (
    <Listbox value={selected} onChange={(e)=>{onOptionChange(e)}}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className="flex relative items-center text-sm shadow-card py-2 px-4 whitespace-nowrap text-colors-secondary-300 min-w-[6.875rem] h-[1.875rem] rounded-xl">
              <span className="flex items-center">
                <span className="block truncate">{selected}</span>
              </span>
              <span className="inset-y-0 right-0 flex items-center pointer-events-none ">
                {/* <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                <DownArrow className=" h-3 w-3 ml-3 text-gray-400" aria-hidden="true" rotate={open ? true : false} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-[0.625rem] w-36 bg-dark-gray shadow-dark-input max-h-56 rounded-xl r-2 top-[95%] text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ">
              <Listbox.Option

                    className={({ active }) =>
                      classNames(
                        active ? 'text-primary-green bg-black' : 'text-secondary-300',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={"All Tags"}

                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* <img  alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                          <span className='rounded-full w-3 h-3  mt-1 mr-3 mb-2'> </span>
                          <span
                            className={classNames(selected ? ' text-primary-green' : 'font-normal', 'block truncate')}
                          >
                            All Tags
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? ' text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                {tag.map((tags) => (
                  <Listbox.Option
                    key={tags.id}
                    className={({ active }) =>
                      classNames(
                        active ? ' bg-black text-primary-green' : 'text-secondary-300',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }

                    value={tags.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* <img src={tags.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                          <span className={`rounded-full w-3 h-3  mt-1 mr-3 mb-2`} style={{backgroundColor:tags.colour}} > </span>
                          <span
                            className={classNames(selected ? ' text-primary-green' : 'font-normal', 'block truncate')}
                          >

                            {tags.title}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-primary-green' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
