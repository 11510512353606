import axios from "axios";
import qs from "qs";
import EXIF from "exif-js";
import Cookies from "js-cookie";

import {
  ENVIRONMENT,
  IMAGE_LIMIT_REACHED,
  MAX_SUB_EVENT_IMAGE_REACHED_ERROR,
} from "../constants";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const token = Cookies.get("access_token");

const defaultOptions = {
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
let fetch = axios.create(defaultOptions);

// Set the AUTH token for any request
fetch.interceptors.request.use(function (config) {
  const token = Cookies.get("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

function getImageDimensions(image) {
  return new Promise(async (res) => {
    const i = new Image();
    i.onload = () => {
      res({
        width: i.width,
        height: i.height,
      });
    };
    i.src = image.preview;
  });
}

function getImageDimensionsWithImage(image) {
  return new Promise(async (res) => {
    const i = new Image();
    i.onload = () => {
      res({
        width: i.width,
        height: i.height,
        img: i,
      });
    };
    i.src = image.preview;
  });
}

async function getExifData(file) {
  return new Promise((res) => {
    if (file && file.name) {
      EXIF.getData(file, function () {
        var exifData = EXIF.pretty(this);
        //console.log(exifData)
        if (exifData) {
          res({
            orientation: EXIF.getTag(this, "Orientation") || 1,
            originalDateTime:
              EXIF.getTag(this, "DateTimeOriginal") || file.lastModifiedDate,
          });
        } else {
          res({ orientation: 1, originalDateTime: file.lastModifiedDate });
        }
      });
    }
  });
}

async function storeImage(file, filePath, folderId, handleDuplicates) {
  let { orientation, originalDateTime } = await getExifData(file);
  let data = qs.stringify({
    fileName: file.name,
    fileSize: (file.size / Math.pow(1024, 2)).toFixed(2),
    folderId: folderId,
    filePath: filePath,
    handleDuplicates: handleDuplicates,
    "metadata[width]": file.width,
    "metadata[height]": file.height,
    "metadata[dateTimeOriginal]": originalDateTime,
    "metadata[orientation]": orientation,
  });
  let config = {
    method: "post",
    url: "images/folder",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  return await fetch(config);
}

async function storeResziedImage(
  resizedFile,
  ogFile,
  filePath,
  folderId,
  handleDuplicates
) {
  let { orientation, originalDateTime } = await getExifData(ogFile);
  let data = qs.stringify({
    fileName: ogFile.name,
    fileSize: (resizedFile.size / Math.pow(1024, 2)).toFixed(2),
    folderId: folderId,
    filePath: filePath,
    handleDuplicates: handleDuplicates,
    "metadata[width]": resizedFile.width,
    "metadata[height]": resizedFile.height,
    "metadata[dateTimeOriginal]": originalDateTime,
    "metadata[orientation]": orientation,
  });
  let config = {
    method: "post",
    url: "images/folder",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  return await fetch(config);
}

export async function storeVideo(url, folderId) {
  let data = qs.stringify({
    fileName: url,
    fileSize: 1,
    folderId: folderId,
    filePath: url,
    handleDuplicates: "skip",
    "metadata[width]": 16,
    "metadata[height]": 9,
    "metadata[dateTimeOriginal]": null,
    "metadata[orientation]": 1,
    isVideo: true,
  });
  let config = {
    method: "post",
    url: "images/folder",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  return await fetch(config);
}

async function getSignedUrl(filename, filetype, folderId, handleDuplicates) {
  var data = qs.stringify({
    fileName: filename,
    mimeType: filetype,
    folderId: folderId,
    handleDuplicates: handleDuplicates,
    type: "images",
  });

  const url = ENVIRONMENT === "staging" ? "presigned-url-gcp" : "photographers/presigned-url";
  var config = {
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  const res = await fetch(config);
  return res;
}

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      "image/jpeg",
      0.95
    );
  });
}

export const doResizeImageUpload = async (
  file,
  ind,
  folderId,
  handleDuplicates,
  controller
) => {
  return new Promise(async (res) => {
    if (file.status === "Uploading" || !file.status) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onerror = (error) => {
        console.log(error);
        file.status = "Failed";
        file.error =
          error.currentTarget.error.name === "NotFoundError"
            ? "File Not Found"
            : "File Invalid";
        res({ result: false, index: ind, file: file });
      };
      const startUploadProcess = async () => {
        fileReader.removeEventListener("load", startUploadProcess);

        Object.assign(file, { preview: URL.createObjectURL(file) });
        let { width, height, img } = await getImageDimensionsWithImage(file);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const maxWidth = 1600;
        const maxHeight = 1600;

        URL.revokeObjectURL(file.preview);

        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        file.width = width;
        file.height = height;
        try {
          let presignedUrl = await getSignedUrl(
            file.name,
            file.type,
            folderId,
            handleDuplicates
          );
          if (presignedUrl.status === 201) {
            const blob = await getCanvasBlob(canvas);

            const resizedFile = new File([blob], file.name, {
              type: blob.type,
            });
            const headers =
              ENVIRONMENT === "staging"
                ? {
                    "Content-Type": "application/octet-stream",
                  }
                : {
                    "Content-Type": file,
                    "x-amz-storage-class": "INTELLIGENT_TIERING",
                  };
            const config = {
              method: "put",
              url: presignedUrl.data.preSignedUrl,
              headers: headers,
              data: resizedFile,
              signal: controller.signal,
            };
            let uploadStatus = await axios(config);
            if (uploadStatus.status === 200) {
              let filePath = presignedUrl.data.destinationUrl;
              resizedFile.width = width;
              resizedFile.height = height;
              let storeResponse = await storeResziedImage(
                resizedFile,
                file,
                filePath,
                folderId,
                handleDuplicates
              );
              if (storeResponse.status >= 200 && storeResponse.status <= 204) {
                file.status = "Success";
                file.error = "";
                file.finalUrl = filePath;
                storeResponse.data.data.folderId = folderId;
                file.returnData = storeResponse.data.data;
                res({ result: true, index: ind, file: file });
              } else {
                file.status = "Failed";
                file.error = "Network Error";
                res({ result: false, index: ind, file: file });
              }
            } else {
              file.status = "Failed";
              file.error = "Network Error";
              res({ result: false, index: ind, file: file });
            }
          } else {
            file.status = "Failed";
            file.error = "Network Error";
            res({ result: false, index: ind, file: file });
          }
        } catch (e) {
          if (e.response?.data?.message === MAX_SUB_EVENT_IMAGE_REACHED_ERROR) {
            file.status = "Failed";
            file.error = IMAGE_LIMIT_REACHED;
            res({ result: false, index: ind, file: file, error: e });
            return;
          }
          if (e.message === "canceled") {
            res({ result: false, index: ind, file: file, error: e });
          } else {
            file.status = "Failed";
            file.error = "Network Error";
            res({ result: false, index: ind, file: file, error: e });
          }
        }
      };

      fileReader.addEventListener("load", startUploadProcess);
    } else {
      res({ result: false, index: ind, file: file });
    }
  });
};

export async function doUpload(
  file,
  ind,
  folderId,
  handleDuplicates,
  controller
) {
  return new Promise(async (res) => {
    if (file.status === "Uploading" || !file.status) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onerror = (error) => {
        console.log(error);
        file.status = "Failed";
        file.error =
          error.currentTarget.error.name === "NotFoundError"
            ? "File Not Found"
            : "File Invalid";
        res({ result: false, index: ind, file: file });
      };

      const startUploadProcess = async () => {
        fileReader.removeEventListener("load", startUploadProcess);
        try {
          let presignedUrl = await getSignedUrl(
            file.name,
            file.type,
            folderId,
            handleDuplicates
          );
          if (presignedUrl.status === 201) {
            const headers =
              ENVIRONMENT === "staging"
                ? {
                    "Content-Type": "application/octet-stream",
                  }
                : {
                    "Content-Type": file,
                    "x-amz-storage-class": "INTELLIGENT_TIERING",
                  };
            var config = {
              method: "put",
              url: presignedUrl.data.preSignedUrl,
              headers: headers,
              data: file,
              signal: controller.signal,
            };
            let uploadStatus = await axios(config);
            if (uploadStatus.status === 200) {
              Object.assign(file, { preview: URL.createObjectURL(file) });
              let { width, height } = await getImageDimensions(file);
              URL.revokeObjectURL(file.preview);
              file.width = width;
              file.height = height;
              let filePath = presignedUrl.data.destinationUrl;
              let storeResponse = await storeImage(
                file,
                filePath,
                folderId,
                handleDuplicates
              );
              //console.log("storeResponse", storeResponse.status)
              if (storeResponse.status >= 200 && storeResponse.status <= 204) {
                file.status = "Success";
                file.error = "";
                file.finalUrl = filePath;
                storeResponse.data.data.folderId = folderId;
                file.returnData = storeResponse.data.data;
                //file.imageDate = storeResponse.data.data.image_date
                res({ result: true, index: ind, file: file });
              } else {
                file.status = "Failed";
                file.error = "Network Error";
                res({ result: false, index: ind, file: file });
              }
            } else {
              file.status = "Failed";
              file.error = "Network Error";
              res({ result: false, index: ind, file: file });
            }
          } else {
            file.status = "Failed";
            file.error = "Network Error";
            res({ result: false, index: ind, file: file });
          }
        } catch (e) {
          console.log(e)
          if (e.response?.data?.message === MAX_SUB_EVENT_IMAGE_REACHED_ERROR) {
            file.status = "Failed";
            file.error = IMAGE_LIMIT_REACHED;
            res({ result: false, index: ind, file: file, error: e });
            return;
          }
          if (e.message === "canceled") {
            res({ result: false, index: ind, file: file, error: e });
          } else {
            file.status = "Failed";
            file.error = "Network Error";
            res({ result: false, index: ind, file: file, error: e });
          }
        }
      };

      fileReader.addEventListener("load", startUploadProcess);
    } else {
      res({ result: false, index: ind, file: file });
    }
  });
}

export const flag = async (imageId, visibility, folderId, eventId) => {
  let dataObj = {};
  if (Array.isArray(imageId))
    dataObj = { imageIds: imageId, folderId: folderId , visibility:visibility  , eventId};
  else dataObj = { imageId: imageId, folderId: folderId , visibility: visibility , eventId };
  var data = qs.stringify(dataObj);
  var config = {
    method: "post",
    url: process.env.REACT_APP_API_ENDPOINT + "images/admin/flag",
    data: data,
    headers: {
      Accept: "application/json",
      "X-XSRF-TOKEN": "{{ xsrf-token }}",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    var result = await fetch(config);
    return result;
  } catch (e) {
    return {
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    };
  }
};

export const deleteImg = async (imageId, folderId , eventId) => {
  let dataObj = {};
  if (Array.isArray(imageId)) {
    dataObj = { folderId: folderId, ids: imageId , eventId: eventId };
  } else {
    dataObj = { folderId: folderId, id: imageId, eventId: eventId };
  }
  var data = qs.stringify(dataObj);
  var config = {
    method: "delete",
    url: process.env.REACT_APP_API_ENDPOINT + "images",
    headers: {
      Accept: "application/json",
      "X-XSRF-TOKEN": "{{ xsrf-token }}",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    var result = await fetch(config);
    return result;
  } catch (e) {
    return {
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    };
  }
};

export const redoFaceSearch = async (eventId) => {
  let dataObj = { eventId: eventId };

  var data = qs.stringify(dataObj);
  var config = {
    method: "post",
    url: process.env.REACT_APP_API_ENDPOINT + "face/search/redo",
    headers: {
      Accept: "application/json",
      "X-XSRF-TOKEN": "{{ xsrf-token }}",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  try {
    var result = await fetch(config);
    return result;
  } catch (e) {
    return {
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    };
  }
};

export const postEventUploadFlag = (postData) =>
  fetch.post(`events/uploading`, postData);
