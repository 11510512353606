import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles/input-phone.scss";
import Text from "../Text";

const PhoneInputWrapper = ({value="", error='', onChange, tabIndex=-1}) => {

    return (
        <PhoneInput
            containerClass={`rounded-[10px] max-w-[28rem] phone-input`}
            inputClass={`bg-transparent px-[16px] py-2 mt-1 text-light-gray`}
            dropdownClass={'rounded-[10px] !bg-secondary-800 !text-white'}
            country='in'
            enableAreaCodes={true}
            placeholder={""}
            value={value}
            onChange={onChange}
            inputProps={tabIndex}
        />
    );
};

export const InputPhoneCode = props => {
    const { value, onChange, error, success, onUpdate,className,  processing = false,} = props;

    const [isProcessing, setIsProcessing] = useState(false),
          [isSuccess, setIsSuccess] = useState(false);

    useEffect(async () => {
        setIsProcessing(processing);
    }, [processing]);

    useEffect(async () => {
        setIsSuccess(success);
    }, [success]);

    return (
        <div className={`flex flex-col max-w-[28rem] ${className}`}>
            <Text as="label"
                            className={`${error ? '' : 'mb-4'} flex justify-between items-baseline text-base`}>
                <span className="inline-block">Phone</span>
            </Text>
            {error && <span className="inline-block text-right text-xs pr-4 text-error-red">{error}</span>}
            <div className={`flex justify-between items-center pr-5 rounded-[10px] bg-dark-gray mt-1 text-light-gray
               ${!value ? 'shadow-input-empty' : 'shadow-input-filled'}                 
               ${error ? 'outline outline-1 outline-error-red' : 'outline-none'}`
            }>

                <PhoneInputWrapper value={value} error={error} onChange={onChange}/>

                {/* {success && !error ?
                    <div className="checkmark-wrapper opacity-0 animate-fadeOut">
                        <svg
                            className="checkmark mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div> : null
                } */}

                {/* {isProcessing && !success && !error ?
                    <svg className="animate-spin mr-3 h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4">
                        </circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg> : null
                } */}

                    {/* <span onClick={(event) => {
                        setIsSuccess(false);
                        setIsProcessing(true);
                        onUpdate(value);
                    }}
                          className="inline-block text-xs font-semibold whitespace-nowrap underline cursor-pointer text-primary-green">Update</span> */}
            </div>
        </div>
    );
};
