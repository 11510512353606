import React from "react";
import Text from "../../../../components/common/Text";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import {
  logoTextColors,
  waterMarkPlacement,
  waterMarkPlacementPreset,
} from "../WatermarkSection/configs";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import { deleteWatermark } from "../../../../apis";
import Swal from "sweetalert2";

const Tabs = ({
  presets,
  changePreset,
  currentPreset,
  setPresets,
  setLogoType,
  logoType,
}) => {
  const deleteWater = (id, title) => {
    Swal.fire({
      title: "Delete Watermark " + title + "?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: ()=>{
        return deleteWatermark(id)
        .then((response) => {
          changePreset(null);
          window.Toast.fire({
            icon: "success",
            title: "Your WaterMark " + title + " has been deleted.",
          });
          setPresets(response);
        })
        .catch((err) => {
          Swal.fire("Error!", "Something went wrong", "error");
        });
    }
  })
}

  return (
    <div className="max-w-[95%] overflow-x-auto pl-1 pr-6">
      <ul className="flex text-light-gray mt-5 mb-12">
        <li
          onClick={() => {
            setLogoType({ ...logoType, type: "file", text: "" });
            changePreset(null);
            document.getElementById("presetName").focus();
            document.getElementById("watermark-create-form").scrollIntoView({
              behavior: "smooth",
            });
          }}
          className={`py-2 flex-shrink-0 w-[180px] h-[140px] cursor-pointer px-6 mr-4 rounded-[10px] flex flex-col justify-center items-center
                            ${
                              !currentPreset.id
                                ? "shadow-card"
                                : "shadow-dark-input"
                            } 
                `}
        >
          <PlusIcon />
          <Text className="text-sm mt-3.5">Add new preset</Text>
        </li>
        {!presets
          ? "Loading..."
          : presets.map(function (preset) {
              return (
                <div key={preset.id}>
                  <li
                    className={`mr-4 w-[180px]  flex-shrink-0 cursor-pointer h-[140px] rounded-[10px] relative  overflow-hidden
                        ${
                          currentPreset && currentPreset.id === preset.id
                            ? "shadow-inner-all border border-primary-green"
                            : "shadow-dark-input"
                        }`}
                    onClick={() => {
                      setLogoType({ ...logoType, type: "file" });
                      changePreset(preset);
                    }}
                  >
                    {preset.logoUrl ? (
                      <div className={"w-[40%] rounded-lg"}>
                        <img
                          className={
                            preset.fullWatermark ? "w-full h-full" : "w-[20%]"
                          }
                          src={preset.logoUrl}
                          style={waterMarkPlacementPreset(preset, true)}
                          alt={preset.title}
                        />
                      </div>
                    ) : (
                      <div
                        className={"w-[40%] rounded-lg"}
                        style={waterMarkPlacementPreset(preset, true)}
                      >
                        {preset.logoText}
                      </div>
                    )}
                  </li>
                  <div className="flex gap-4">
                    <Text
                      as="p"
                      title={preset.title}
                      className="text-sm text-light-gray pt-1 pl-2  w-2/3 truncate "
                    >
                      {preset.title}
                    </Text>
                    <div
                      onClick={(e) => {
                        setLogoType({
                          ...logoType,
                          type: "text",
                          text: preset.logoText,
                        });
                        deleteWater(preset.id, preset.title);
                      }}
                      className="pt-2"
                    >
                      <DeleteIcon color="#00b7b8" />
                    </div>
                  </div>
                </div>
              );
            })}
      </ul>
    </div>
  );
};

export default Tabs;
