import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "../../../../../../assets/icons/DeleteIcon";
import EyeSlashIcon from "../../../../../../assets/icons/EyeSlashIcon";
import DownloadIcon from "../../../../../../assets/icons/DownloadIcon";

import ShareIcon from "../../../../../../assets/icons/ShareIcon";
import SortIcon from "../../../../../../assets/icons/SortIcon";
import Checkboxgen from "../../../../../../components/common/Checkboxgen";
import { deleteImg, flag } from "../../../../../../apis/upload";
import { useEvent } from "../../../../../../Store/Event/Show";
import { useUser } from "../../../../../../Store/User";
import {
  getFavouriteImages,
  deleteFavList,
  removeFavImage,
  deleteEvent,
  favouriteDownload,
} from "../../../../../../apis";
import Swal from "sweetalert2";
import { useFolder } from "../../../../../../Store/Folder/Show";
// import ShareModal from "../ShareModal";
import Bulkactions from "../../../../../../components/Event/Popups/Bulkactions";
import { useParams } from "react-router-dom";
import DownloadOptions from "../DownloadOptions";
import LightroomPopUp from "../LightroomPopUp";
import IconButton from "../../../../../../components/common/IconButton";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Loading";
import FavoritiesDialog from "../FavoritiesDialog";
import { OG_IMAGE_CDN, RESIZE_IMAGE_CDN } from "../../../../../../constants";

// import {useEvent} from "../../../../../../Store/Event/Show";
// import DeleteIcon from '../../../../../../assets/icons/DeleteIcon';

const GalleryListView = ({
  eventId,
  favList,
  setFavList,
  currentFavList,
  images,
  setImages,
  currentListName,
  handleFavListChange,
  imageCount,
  setImageCount,
}) => {
  // const {event} = useState({});
  const { event } = useEvent();
  const { user } = useUser();
  let navigate = useNavigate();
  let { folder } = useState([]);
  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const [shareLink, setShareLink] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [showOption, setShowOption] = useState(false);
  const [showLightroom, setShowLightroom] = useState(false);
  const [lightRoomData, setLightRoomData] = useState({});
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const downloadImage = (image) => {
    const originalImgSrc = image.url.replace(RESIZE_IMAGE_CDN, OG_IMAGE_CDN);
    fetch(originalImgSrc, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", image.originalImageName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {});
  };

  function deleteImage(image) {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to remove ${image.originalImageName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Remove`,
    }).then(function (key) {
      if (key.isConfirmed) {
        removeFavImage(image.id, currentFavList, eventId)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Image deleted successfully",
            });
            let newImages = images.filter((i) => i.id !== image.id);
            setImages([...newImages]);
            setImageCount(imageCount - 1);
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Image could not be Removed",
            });
          });
      }
    });
  }

  const handleLightRoom = () => {
    getFavouriteImages(eventId, currentFavList, 0, 1).then((res) => {
      setLightRoomData(res.data);
      setShowLightroom(!showLightroom);
    });
  };
  const exportAsCsv = () => {
    getFavouriteImages(eventId, currentFavList, 1, 0).then((res) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "favourite_images.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // setIsOpen(false);
    });
  };

  const handleDeleteFavList = () => {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete ${currentListName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete Favourite List`,
    }).then(function (key) {
      if (key.isConfirmed) {
        deleteFavList(eventId, currentFavList, "favourite-list")
          .then((res) => {
            window.Toast.fire({
              icon: "success",
              title: "Favourite list deleted successfully",
            });
            setFavList(favList.filter((f) => f.id !== currentFavList));
            if (res.length > 0) {
              handleFavListChange(res[0].id);
            } else {
              navigate("/events/" + eventId + "/analytics");
            }
          })
          .catch((err) => {
            window.Toast.fire({
              icon: "error",
              title: "Favourite list could not be deleted",
            });
          });
      }
    });
  };

  function getFolderName(folderId) {
    const folder = event.folders.filter((folder, index) => {
      return folder.id == folderId;
    });
    return folder[0].name;
  }

  const submitDownload = async (e) => {
    // e.preventDefault();
    var res = await favouriteDownload(
      event.id,
      user.user.email,
      1,
      currentFavList,
      "favourites",
      user.user.id
    );
    // console.log("res",res)

    // if(res.status == 1){
    //     // console.log(res)
    //     window.Toast.fire({
    //       icon: 'success',
    //       title:  res.message ? res.message : 'Download in process, please wait for it to complete you wil receive an email shortly.'
    //     });
    // }else{
    //     window.Toast.fire({
    //         icon: 'error',
    //         title: res.e.message[0]
    //     });
    // }
    // console.log("res",res)
    // if(res.downloadRequestId){
    //     let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+event.id+ '/'+event.slug+'/'+res.downloadRequestId;
    //     // console.log(url);
    //     window.open(url, '_blank').focus();
    // }

    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: res.message
          ? res.message
          : "Download in process, please wait for it to complete you wil receive an email shortly.",
      });
      localStorage.setItem("downloadInitiatedFrom", window.location.href);
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.e.message[0],
      });
    }
    if (res.downloadRequestId) {
      // let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+eventDetails.data.slug+'/'+Base64.encode(res.dl.toString())
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/downloadNow" +
        "/" +
        event.id +
        "/" +
        event.slug +
        "/" +
        res.downloadRequestId;
      // console.log(url);
      window.open(url, "_blank").focus();
    }
    // close(false)
  };

  return (
    <>
      {_.isEmpty(images) ? (
        <Loading />
      ) : (
        <>
          <div className="flex justify-between items-center ">
            <div className={`flex items-center text-2xl text-secondary-300  `}>
              {currentListName}
              <span className="ml-2 px-1 py-0.5 rounded bg-black-gray text-xs">
                {imageCount}
              </span>
            </div>

            <div className="relative flex gap-4 justify-center">
              {/* <IconButton> */}
              <button
                onClick={() => {
                  exportAsCsv();
                }}
                className="text-primary-green font-medium "
              >
                Download Excel
              </button>
              {/* </IconButton> */}
              <button
                onClick={() => {
                  handleLightRoom();
                }}
                className="text-primary-green font-medium"
              >
                Lightroom Copylist
              </button>
              <IconButton
                onClick={() => {
                  handleDeleteFavList();
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  submitDownload();
                }}
              >
                <DownloadIcon />
              </IconButton>

              {/* <span onClick={()=>{setShowOption(!showOption)}} className="cursor-pointer" >
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className={"h-6 w-6 fill-current text-secondary-300 "}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
                            </svg>
                            {showOption &&
                                <DownloadOptions eventId={eventId}
                                                currentFavList={currentFavList}
                                                handleLightRoom={handleLightRoom}

                                />
                            }
                        </span> */}
            </div>
          </div>

          <div className="text-light-gray pt-0 relative mt-4">
            <ul className="flex flex-col">
              <li className="flex flex-row bg-black1 h-[40px] items-center text-base px-[1.25rem] rounded-md">
                {/* <div className="basis-0 pr-8"><Checkboxgen />
                            </div> */}
                <div className="basis-2/5">
                  <span className="flex items-center">
                    Name
                    <span className="pl-2"> </span>{" "}
                  </span>
                </div>
                <div className="basis-1/5">
                  <span className="flex items-center">
                    Folder Name
                    <span className="pl-2"> </span>
                  </span>
                </div>
                <div className="basis-1/5">File Size</div>
                <div className="basis-1/5"> </div>
              </li>
              {images.map((image, index) => {
                return (
                  <li
                    className="border-b border-black-gray py-2"
                    key={image.id}
                  >
                    <div
                      className={`${
                        selectedImages.includes(image.id)
                          ? "bg-black/80 border border-solid border-[#00b7b8] rounded-2xl"
                          : ""
                      } flex py-7 px-[1.25rem] flex-row items-center`}
                    >
                      {/* <div className="basis-0 pr-8"><Checkboxgen onChange={handleImageSelect} checked={selectedImages.includes(image.id)} value={image.id} /></div> */}
                      <div className="flex basis-2/5 items-center">
                        <div className="relative w-20 rounded-[10px] overflow-hidden h-0 pb-[45px]">
                          <img
                            className="absolute inset-0 w-full h-full object-cover"
                            src={image.src + "?w=440"}
                          />
                        </div>
                        <div className="px-4 break-words max-w-[400px]">
                          {image.originalImageName}
                        </div>
                      </div>
                      <div className="basis-1/5 flex items-center">
                        {getFolderName(image.folderId)}
                      </div>
                      <div className="basis-1/5 flex items-center">
                        {image.isVideo
                          ? "-"
                          : Number(image.size).toFixed(2) + " MB"}
                      </div>
                      <div className="flex flex-row gap-8 basis-1/5">
                        {/* <span className="cursor-pointer" >
                                                <EyeSlashIcon color={!image.isPrivate ? "#fff" : '#05b1b2'} height="16"
                                                              width="16"/>
                                            </span> */}
                        {/* {event.isPublished && !image.isVideo ? <span className="cursor-pointer" onClick={() => {
                                                    shareImage(image)
                                                }}>
                                                <ShareIcon height="16" width="16"/></span> : <></>} */}

                        {!image.isVideo && (
                          <span
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadImage(image);
                            }}
                          >
                            <DownloadIcon height="16" width="16" />
                          </span>
                        )}

                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            deleteImage(image);
                          }}
                        >
                          <DeleteIcon height="16" width="16" />
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            {/* <ShareModal open={showSharePopUp} close={() => {
                        setShowSharePopUp(false)
                    }} shareLink={shareLink}/> */}
          </div>
        </>
      )}
      {selectedImages.length > 0 ? (
        <Bulkactions
          setSelectedImages={setSelectedImages}
          selectedImages={selectedImages}
          eventId={eventId}
          slug={event.slug}
        />
      ) : (
        <></>
      )}
      {showLightroom && (
        <LightroomPopUp
          showLightroom={showLightroom}
          setShowLightroom={setShowLightroom}
          lightRoomData={lightRoomData}
          setLightRoomData={setLightRoomData}
        />
      )}

      {showDownloadOptions && (
        <FavoritiesDialog
          favList={favList}
          currentFavList={currentFavList}
          showDownloadOptions={showDownloadOptions}
          setShowDownloadOptions={setShowDownloadOptions}
        />
      )}
    </>
  );
};

export default GalleryListView;
