import React, { useState } from "react";

import DownArrow from "../../../../assets/icons/DownArrow";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import MaximizeIcon from "../../../../assets/icons/MaximizeIcon";
import BottomModal from "../../../../components/common/BottomModal";
import UploadModal from "../UploadModal";
import ImageQueue from "../../../../components/common/ImageQueue";
import { useUploader } from "../../../../Store/Uploader";
import PauseIcon from "../../../../assets/icons/PauseIcon";

const BottomUploadModal = () => {
  const { setShowUploader, isUploading, showStopUploadWarning } = useUploader();

  const [isMaximizedModal, showMaximizedModal] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  

  const openMaximizedModal = () => {
    showMaximizedModal(true);
  };
  const handleClose = () => {
    if (!isUploading) {
      setShowUploader(false);
    }
  };


  return (
    <>
      {!isMaximizedModal && (
        <BottomModal parentID="bottom-popup">
          <div
            style={{
              bottom: isClosed ? "-20rem" : 0,
              boxShadow:
                "4px 4px 10px 0 rgba(0, 0, 0, 0.7), -4px -4px 10px 0 rgba(58, 58, 58, 0.3)",
            }}
            className={`fixed right-5 p-4 bg-dark-gray w-[23.8rem] h-[24.1rem] duration-200 z-[99]`}
          >
            <div className="flex justify-center flex-col">
              <div className="flex justify-between mt-2">
                <p className="text-base text-light-gray">Upload Queue</p>
                <div className="flex gap-4 justify-center items-center">
                  {isUploading ? (
                    <button
                      title="Pause Upload"
                      onClick={showStopUploadWarning}
                    >
                      <PauseIcon />
                    </button>
                  ) : (
                    <></>
                  )}
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setIsClosed(!isClosed);
                    }}
                  >
                    <DownArrow rotate={isClosed} />
                  </span>
                  <span className="cursor-pointer" onClick={openMaximizedModal}>
                    <MaximizeIcon fill={"#EEE"} />
                  </span>
                  {!isUploading && (
                    <span className="cursor-pointer" onClick={handleClose}>
                      <CloseIcon
                        height="16"
                        fill={isUploading ? "#aaa" : "#eee"}
                        width="16"
                      />
                    </span>
                  )}
                </div>
              </div>
              <ImageQueue isBottom={true} />
            </div>
          </div>
        </BottomModal>
      )}
      {isMaximizedModal && <UploadModal />}
    </>
  );
};

export default BottomUploadModal;
