import React from "react";
import { Input } from ".";

export const InputText = props => {
   
    return (
        <Input
            {...props}

            type={props.type}

           

            inputAttr={{
                className:
                    "rounded-xl bg-dark-gray p-2 shadow-dark",
            }}
        />
    );
};
