import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import CloseIcon from "../../../assets/icons/CloseIcon";
import { InputText } from "../Input";

import { createNewEvent } from "../../../apis";
import DatePicker from "react-date-picker";
import "../../../assets/DatePicker.css";
import "../../../assets/Calendar.css";
import CalenderIcon from "../../../assets/icons/CalenderIcon";
import { MAX_EVENT_REACHED_MESSAGE } from "../../../constants";
import { useUser } from "../../../Store/User";
import Loading from "../../../pages/Loading";

export default function CreateEvent({
  handleEventModal,
  handleEventCreateModalClose,
  addEvent,
}) {
  const navigate = useNavigate();
  const { user } = useUser();
  const [open, setOpen] = useState(true);
  const [eventDetails, setEventDetails] = useState({
    name: "",
    startDate: "",
    endDate: "",
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const [errors, setErrors] = useState({});

  const cancelButtonRef = useRef(null);

  const handleCreateEvent = (e) => {
    e.preventDefault();

    if (eventDetails.name === "") {
      setErrors({ ...errors, name: "Event Name is required" });
      return;
    }

    if (eventDetails.name) {
      const regex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9- ]*$/;
      if (!regex.test(eventDetails.name)) {
        setErrors({ ...errors, name: "Invalid name" });
        return;
      }
    }

    if (moment(eventDetails.startDate).isAfter(eventDetails.endDate)) {
      setErrors({ ...errors, endDate: "End date should be after start date" });
      return;
    }

    setButtonDisable(true);
    setApiLoader(true);
    createNewEvent(eventDetails)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Event Created Successfully",
        });
        setOpen(false);
        handleEventModal(false);
        handleEventCreateModalClose(false);
        navigate("/events/" + response.id);
      })
      .catch((error) => {
        setApiLoader(false);
        setButtonDisable(false);
        if (error.response?.data?.message === "Event Limit Reach.") {
          MAX_EVENT_REACHED_MESSAGE(
            user.subscription?.restrictions?.events_limit
          );
          handleClose();
        } else {
          if (error.response.data.message) {
            setErrors({ ...errors, name: error.response.data.message });
            window.Toast.fire({
              icon: "error",
              message: error.response.data.message,
            });
          } else {
            window.Toast.fire({
              icon: "error",
              message: "Internal server error",
            });
          }
        }
      });
  };

  const handleClose = (e) => {
    setOpen(false);
    handleEventModal(false);
    handleEventCreateModalClose(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-dark-gray text-light-gray align-bottom rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full min-w-[35rem]">
              <div className="p-8">
                <div className="sm:items-start">
                  <div className="text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl leading-6 flex justify-between font-medium text-secondary-300"
                    >
                      Create Event
                      <span
                        onClick={(e) => {
                          handleClose(e);
                        }}
                        className="cursor-pointer"
                      >
                        <CloseIcon width="20" height="20" fill="#eee" />
                      </span>
                    </Dialog.Title>
                    <div className="mt-5">
                      <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                        <div className="flex items-center">
                          <InputText
                            onChange={(e) => {
                              setEventDetails({ ...eventDetails, name: e });
                              setErrors({ ...errors, name: null });
                            }}
                            placeholder="Event Name"
                            value={eventDetails.name}
                            error={errors.name}
                            label="Event Name"
                            onKeyDown={(e) => {
                              handleCreateEvent(e);
                            }}
                            className="!w-full min-w-[31.25rem]"
                          />
                        </div>
                      </div>
                      <div className="mt-8">
                        <div className="items-center">
                          <div className="datepicker relative form-floating mb-3">
                            <label className="text-gray text-sm flex justify-between pl-4">
                              Event Date
                            </label>
                            <DatePicker
                              onChange={(e) => {
                                setEventDetails({
                                  ...eventDetails,
                                  startDate:
                                    e && moment(e).format("YYYY-MM-DD"),
                                });
                                setErrors({ ...errors, startDate: "" });
                              }}
                              format={"dd-MM-y"}
                              onKeyDown={(e) => e.preventDefault()}
                              dayPlaceholder="dd"
                              calendarIcon={<CalenderIcon />}
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              value={
                                eventDetails.startDate
                                  ? new Date(
                                      eventDetails.startDate.replaceAll(
                                        "-",
                                        "/"
                                      )
                                    )
                                  : ""
                              }
                              className={`bg-dark-gray mt-1 text-secondary-300 px-2 min-w-full py-1.5 rounded-lg text-sm transition ease-in-out
                                                            ${
                                                              !eventDetails.startDate
                                                                ? "shadow-input-empty"
                                                                : "shadow-input-empty"
                                                            }  
                                                            `}
                            />
                          </div>
                        </div>
                        {/*<div className="flex flex-wrap items-center">
                                                    <div className="datepicker relative form-floating mb-3 xl:w-96">
                                                    <label className='text-gray text-sm flex justify-between pl-4'>Expiry Date</label>
                                                    <DatePicker  onChange={(e) => {
                                                                setEventDetails({...eventDetails, endDate: e && moment(e).format('Y-M-D')  });
                                                                setErrors({...errors, endDate: ''})

                                                            }}
                                                            format={'dd-MM-y'}
                                                            minDate={eventDetails.startDate?new Date(eventDetails.startDate):new Date()}
                                                            onKeyDown={e=>e.preventDefault()}
                                                            dayPlaceholder='dd'
                                                            monthPlaceholder='mm'
                                                            yearPlaceholder='yyyy'
                                                            value={eventDetails.endDate?new Date(eventDetails.endDate):""}
                                                            className={`bg-dark-gray mt-1 text-light-gray px-2 min-w-[190px] w-full py-1.5 rounded-lg transition ease-in-out
                                                            ${!eventDetails.endDate ? 'shadow-input-empty' : 'shadow-input-filled'}
                                                            `}
                                                            />
                                                    </div>
                                                </div>*/}
                      </div>
                      <div className="flex justify-center pt-10">
                        {apiLoader ? (
                          <Loading noFullScreen="h-auto" />
                        ) : (
                          <button
                            className="rounded-lg shadow-button font-semibold w-[11rem] py-2 bg-button text-light-gray"
                            disabled={buttonDisable}
                            onClick={(e) => {
                              handleCreateEvent(e);
                            }}
                          >
                            Create Event
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
