import React, { useState, useEffect } from "react";

import Title from "../../../../components/common/Title";
import Text from "../../../../components/common/Text";
import Checkbox from "../../../../components/common/Checkbox";
import {
  InputEmail,
  InputText,
  InputEditable,
} from "../../../../components/common/Input";

import SwitchWithTitle from "../../../GeneralSettings/components/SwitchWithTitle";
import RadioButtonSmall from "../../../../components/common/RadioButtonSmall";
import EnableButton from "../../../GeneralSettings/components/EnableButton/Index";
import DownArrow from "../../../../assets/icons/DownArrow";
import RadioButton from "../../../../components/common/RadioButton";

import "./downloadSettings.css";
import { useEvent } from "../../../../Store/Event/Show";
import { useUser } from "../../../../Store/User";

import _, { isNull } from "lodash";
import {
  updateDownloadSettings,
  updateSingleDownloadSettings,
  restrictEmailForEvent,
  getEventGuestEmail,
  handleFolderDownload,
  updateEmailRestriction,
  updateFolderRestrictions,
  getFoldersOfEvent,
  updateDigitalDownloads,
} from "../../../../apis";

import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import BigTick from "../../../../assets/icons/BigTick";
import PlusIcon from "../../../../assets/icons/PlusIcon";

const DownloadSettings = ({
  isBulkDownloadEnable,
  setIsBulkDownloadEnable,
  isSingleDownloadEnable,
  setIsSingleDownloadEnable,
}) => {
  const singleDownloadSizes = ["optimized", "original"];
  const bulkDownloadSizes = ["optimized", "original"];
  const { event, setEvent } = useEvent();
  let { user } = useUser();

  // const [isBulkDownloadEnable, setIsBulkDownloadEnable] = useState(false);
  // const [isSingleDownloadEnable, setIsSingleDownloadEnable] = useState(false);
  const [chargeDigitalDownload, setChargeDigitalDownload] = useState(false);
  const [bulkDownloadParam, setBulkDownloadParam] = useState({
    highResSiz: null,
    webSize: null,
  });

  const [isHigResChecked, setIsHigResChecked] = useState(false);
  const [isWebChecked, setIsWebChecked] = useState(false);

  const [singleDownloadParam, setSingleDownloadParam] = useState({
    size: null,
  });
  const [selectedDownloadSize, setSelectedDownloadSize] = useState(null);

  const [showAdvanceOption, setShowAdvanceOption] = useState(false);

  const [guestEventEmail, setGuestEventEmail] = useState(null);
  const [restrictedEmail, setRestrictedEmail] = useState([]);
  const [isEmailRestrictionEnabled, setIsEmailRestrictionEnabled] =
    useState(null);
  const [timer, setTimer] = useState(null);

  const [eventFolders, setEventFolders] = useState({});
  const [restrictFolderDownload, setRestrictFolderDownload] = useState([]);
  const [isFolderRestrictionEnabled, setIsFolderRestrictionEnabled] =
    useState(false);
  const [currentRestrictedEmail, setCurrentRestrictedEmail] = useState([]);
  const [success, setSuccess] = useState({ email: false });
  const [error, setError] = useState({ email: false });
  // TODO: From the API get the number of rows
  // const [priceRows , setPriceRows] = useState([1])
  const [priceRows, setPriceRows] = useState([
    { quantity: "1", amount: "100" },
  ]);
  // console.log('user',user)

  //setting state for bulk image
  useEffect(() => {
    if (
      !_.isEmpty(event) &&
      !_.isEmpty(event.downloadBulkImageSize) &&
      !_.isNull(event.downloadBulkImageSize)
    ) {
      setIsBulkDownloadEnable(true);
      setShowAdvanceOption(true);
      setBulkDownloadParam({
        highResSiz: event.downloadBulkImageSize,
        webSize: event.downloadBulkImageWebSize,
      });
    }

    if (!_.isEmpty(event)) {
      setIsEmailRestrictionEnabled(
        _.isNull(event.allowAllUsersToDownload)
          ? false
          : event.allowAllUsersToDownload
      );
    }
  }, [event]);

  //setting state for single image
  useEffect(() => {
    if (
      !_.isEmpty(event) &&
      !_.isEmpty(event.downloadSingleImageSize) &&
      !_.isNull(event.downloadSingleImageSize)
    ) {
      setIsSingleDownloadEnable(true);
      setSingleDownloadParam({ size: event.downloadSingleImageSize });
    }

    if (!_.isEmpty(event)) {
      getEventGuestEmail(event, "restricted").then(function (response) {
        setGuestEventEmail(response);
      });

      setChargeDigitalDownload(event.isDownloadChargeable);
      if (!_.isNull(event.downloadPricing)) {
        setPriceRows(event.downloadPricing.price);
      }
    }

    if (!_.isEmpty(event) && !_.isEmpty(event.folders)) {
      setEventFolders(event.folders);
    }

    if (!_.isEmpty(event) && !_.isEmpty(event.folders)) {
      event.folders.map((item) => {
        if (item.isDownloadAble === 0) {
          setRestrictFolderDownload((prevState) => [...prevState, item]);
          setIsFolderRestrictionEnabled(true);
        }
      });
    }
  }, [event]);

  useEffect(() => {
    if (!_.isEmpty(event)) {
      getEventGuestEmail(event, "restricted").then(function (response) {
        setGuestEventEmail(response);
      });
    }
  }, [restrictedEmail]);

  //function called with Bulk Download is disabled
  function offBulkDown() {
    setIsBulkDownloadEnable(false);
    updateDownloadSettings(event, null)
      .then(function (response) {
        setEvent(response);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Bulk download settings could not be updated",
        });
        //window.location.reload();
      });
  }

  //function called with Bulk Download is disabled
  function offDigitalDownload() {
    chargeDigitalDownload(false);
    updateDigitalDownloads(event, 0)
      .then(function (response) {})
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Bulk download settings could not be updated",
        });
        //window.location.reload()
        // chargeDigitalDownload(true);
      });
  }

  //update bulk download settings
  function updateBulkDownload(size) {
    setBulkDownloadParam({
      ...bulkDownloadParam,
      highResSiz: size,
    });

    updateDownloadSettings(event, size)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: `Bulk download settings updated successfully`,
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Bulk download settings could not be updated",
        });
        //window.location.reload();
      });
  }

  //handle folder restriction
  function handleFolderRestriction(e) {
    if (e === 1) {
      setIsFolderRestrictionEnabled((prev) => !prev);
      updateFolderRestrictions(event, e)
        .then(function () {
          window.Toast.fire({
            icon: "success",
            title: "Folder download settings updated successfully",
          });
        })
        .catch((error) => {
          setIsFolderRestrictionEnabled((prev) => !prev);
          window.Toast.fire({
            icon: "error",
            title: "Folder download settings could not be updated",
          });
        });
    } else {
      setIsFolderRestrictionEnabled((prev) => !prev);
      getFoldersOfEvent(event.id)
        .then((response) => {
          setEventFolders(response);
        })
        .catch((error) => {
          console.error(error);
          setIsFolderRestrictionEnabled((prev) => !prev);
        });
    }
  }

  //function called with Bulk Download is enabled
  function onBulkDown() {
    if (event.isDownloadChargeable) {
      window.Toast.fire({
        icon: "error",
        title: "Disable Digital Downloads",
      });
      return;
    }
    setIsBulkDownloadEnable(true);
    setBulkDownloadParam({
      highResSiz: "original",
    });
    updateDownloadSettings(event, "original")
      .then(function (response) {
        setEvent(response);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Bulk download settings could not be updated",
        });
        //window.location.reload();
      });
  }

  function handlePriceChange(e, index) {
    const { name, value } = e.target;
    const list = [...priceRows];
    list[index][name] = value;
    setPriceRows(list);
  }

  function updatePrice(status) {
    setChargeDigitalDownload(status);

    const priceArray = JSON.stringify(priceRows, null, 2);
    const price = JSON.parse(priceArray, null, 2);

    updateDigitalDownloads(event.id, status, "inr", price)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Digital Download Setting Updated",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error in updating frame price",
        });
        setChargeDigitalDownload(!status);
      });
  }

  //function called with Single Download is disabled
  function offSingleDown() {
    setIsSingleDownloadEnable(false);
    setSingleDownloadParam({ size: null });
    updateSingleDownloadSettings(event, null)
      .then(function (response) {
        setEvent(response);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Single download settings could not be updated",
        });
      });
  }

  // function to make visible signle download section
  function makeSingleDownloadVisible() {
    if (event.isDownloadChargeable) {
      window.Toast.fire({
        icon: "error",
        title: "Disable Digital Downloads",
      });
      return;
    }
    setIsSingleDownloadEnable(true);
    setSingleDownloadParam({ size: "original" });
    updateSingleDownloadSettings(event, "original")
      .then(function (response) {
        setEvent(response);
        // window.Toast.fire({
        //     icon: 'success',
        //     title: 'Single download settings updated successfully'
        // });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Single download settings could not be updated",
        });
      });
  }

  //function callled when selecting single images size
  function onSingleDown(e) {
    setSingleDownloadParam({ size: e });
    updateSingleDownloadSettings(event, e)
      .then(function (response) {
        setEvent(response);
        window.Toast.fire({
          icon: "success",
          title: "Single download settings updated successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Single download settings could not be updated",
        });
      });
  }

  //function to add restricted email
  function addRestrictedEmail(e) {
    let email = e;
    if (email.indexOf("@") < 0) {
      setError({
        ...error,
        email: "Invalid Email",
      });
      return false;
    }
    restrictEmailForEvent(event, email, 0)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Restricted email added successfully",
        });

        setCurrentRestrictedEmail("");
        setSuccess({ ...success, email: true });
        let newRestrictedEmailList = response.filter(
          (item) => item.status === 0
        );
        setGuestEventEmail(newRestrictedEmailList);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Restricted email could not be added",
        });
      });
  }

  //function to remove restricted email
  function removeRestrictedEmail(email, id) {
    restrictEmailForEvent(event, email, 1).then(function (response) {
      window.Toast.fire({
        icon: "success",
        title: "Restricted email removed successfully",
      });
      let newRestrictedEmail = response.filter((item) => item.status == 0);
      setGuestEventEmail(newRestrictedEmail);
    });
  }

  //function to enable and disable email restriction
  function handleEmailRestriction(status) {
    setIsEmailRestrictionEnabled(status);
    updateEmailRestriction(event, status)
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Email restriction updated successfully",
        });
      })
      .catch((error) => {
        setIsEmailRestrictionEnabled(!status);
        window.Toast.fire({
          icon: "error",
          title: "Email restriction could not be updated",
        });
      });
  }

  //function to restrict folder download
  function handleRestrictedDownload(folderId, isRestricted) {
    handleFolderDownload(folderId, isRestricted)
      .then(function (response) {
        setEventFolders(response);
        window.Toast.fire({
          icon: "success",
          title: "Folder status updated successfully",
        });
      })
      .catch((error) => {
        getFoldersOfEvent(event.id).then((response) => {
          setEventFolders(response);
        });
        window.Toast.fire({
          icon: "error",
          title: "Folder download settings could not be updated",
        });
      });
  }

  const increasePriceRow = () =>
    setPriceRows((prev) => [...prev, { quantity: "", amount: "" }]);

  const removePrice = (i) => {
    const priceRowsCopy = [...priceRows];
    priceRowsCopy.splice(i, 1);
    setPriceRows(priceRowsCopy);
  };

  return (
    <>
      <Title
        className="pb-4 mb-6 border-b border-secondary-600 mt-4"
        text="Download Settings"
      />

      <Text as="p" className="text-secondary-300 text-xl">
        Download images
      </Text>

      <div
        id="downloads-size"
        className="flex flex-wrap 2xl:flex-nowrap gap-x-32 gap-y-8 mt-6"
      >
        <div className="flex flex-col basis-1/2">
          <SwitchWithTitle
            className="mb-8 font-medium"
            title="Single image download"
            value={isSingleDownloadEnable}
            // subtitle={isSingleDownloadEnable ? 'Turn off to disable single image download' : 'Turn on to allow your clients to download \n single photos from this event'}
            subtitle={
              "Turn on to allow your clients to download \n single photos from this event"
            }
            onChange={() => {
              isSingleDownloadEnable
                ? offSingleDown()
                : makeSingleDownloadVisible();
            }}
          />

          {/* <SwitchWithTitle
                            className='mb-8 font-medium'
                            title='Enable Digital Download'
                            subtitle={'Turn on to allow your clients to pay for'}
                            value={chargeDigitalDownload}
                            onChange={() => {
                                chargeDigitalDownload ? offDigitalDownload() : onDigitalDownload()
                            }}

                        /> */}
          {isSingleDownloadEnable ? (
            <div
              className={
                isSingleDownloadEnable
                  ? "mt-10"
                  : "mt-10 point-event-none opacity-10"
              }
            >
              <Text as="p" className="font-medium">
                Download sizes
              </Text>
              <Text as="p" className="text-sm mt-4">
                One-click download size
              </Text>

              <div className="flex gap-x-4  mt-3">
                {/* <div
                                        className={`flex items-center ${(singleDownloadParam.size == "optimized") ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                        <label className="w-full cursor-pointer">
                                            <input
                                                type="radio"
                                                name="access_type_single"
                                                checked={singleDownloadParam.size === 'optimized'}
                                                onChange={() => onSingleDown('optimized')}
                                                className="accent-cyan-500"
                                            />
                                            <span className="pl-2">Optimized</span>
                                            <span className="pl-2 text-xs">(1600 px)</span>
                                        </label>

                                    </div> */}
                <RadioButton
                  width={"w-1/3"}
                  checked={singleDownloadParam.size === "optimized"}
                  handleOnClick={() => onSingleDown("optimized")}
                  label="1600 px"
                />

                {/* <div
                                        className={`flex items-center ml-4 ${(singleDownloadParam.size === "original") ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                        <label className="w-full cursor-pointer">
                                            <input
                                                type="radio"
                                                name="access_type_single"
                                                checked={singleDownloadParam.size === 'original'}
                                                onChange={() => onSingleDown('original')}
                                                className="accent-cyan-500"
                                            />
                                            <span className="pl-2">Original</span>
                                            <span className="pl-2 invisible text-xs">(3600 px)</span>
                                        </label>
                                    </div> */}
                <RadioButton
                  width={"w-1/3"}
                  checked={singleDownloadParam.size === "original"}
                  handleOnClick={() => onSingleDown("original")}
                  label="Original"
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col basis-1/2">
          <SwitchWithTitle
            className="mb-8 font-medium"
            title="Bulk image download"
            subtitle={
              "Turn on to allow your clients to download \n all galleries from this event"
            }
            value={isBulkDownloadEnable}
            onChange={() => {
              isBulkDownloadEnable ? offBulkDown() : onBulkDown();
            }}
          />

          {isBulkDownloadEnable ? (
            <div
              className={
                isBulkDownloadEnable
                  ? "mt-10"
                  : "point-event-none mt-[10px] opacity-10"
              }
            >
              <Text as="p" className="font-medium">
                Download sizes
              </Text>
              <Text as="p" className="text-sm mt-4 invisible">
                One-click download size
              </Text>
              <div className="flex gap-y-4 gap-x-4  w-full  mt-3">
                {/* <div
                                        className={`flex  items-center ${(bulkDownloadParam.highResSiz == "optimized") ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                        <label className="w-full cursor-pointer">
                                            <input
                                                type="radio"
                                                name="access_type"
                                                checked={bulkDownloadParam.highResSiz === 'optimized'}
                                                onChange={() => updateBulkDownload('optimized')}
                                                className="accent-cyan-500"
                                            />
                                            <span className="pl-2">Optimized</span>
                                            <span className="pl-2 text-xs">(1600 px)</span>
                                        </label>
                                    </div> */}
                <RadioButton
                  width={"w-1/3"}
                  checked={bulkDownloadParam.highResSiz === "optimized"}
                  handleOnClick={() => updateBulkDownload("optimized")}
                  label="1600 px "
                />
                <RadioButton
                  width={"w-1/3"}
                  checked={bulkDownloadParam.highResSiz === "original"}
                  handleOnClick={() => updateBulkDownload("original")}
                  label="Original"
                />
                <RadioButton
                  width={"w-1/3"}
                  checked={bulkDownloadParam.highResSiz === "both"}
                  handleOnClick={() => updateBulkDownload("both")}
                  label="Both"
                />
                {/* <div
                                        className={`flex items-center ml-4 mr-4 ${(bulkDownloadParam.highResSiz === "original") ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                        <label className="w-full cursor-pointer">
                                            <input
                                                type="radio"
                                                name="access_type"
                                                checked={bulkDownloadParam.highResSiz === 'original'}
                                                onChange={() => updateBulkDownload('original')}
                                                className="accent-cyan-500"
                                            />
                                            <span className="pl-2">Original</span>
                                            <span className="pl-2 invisible text-xs">(3600 px)</span>
                                        </label>
                                    </div> */}
                {/* <div
                                        className={`flex items-center  ${(bulkDownloadParam.highResSiz === "both") ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                        <label className="w-full cursor-pointer">
                                            <input
                                                type="radio"
                                                name="access_type"
                                                checked={bulkDownloadParam.highResSiz === 'both'}
                                                onChange={() => updateBulkDownload('both')}
                                                className="accent-cyan-500"
                                            />
                                            <span className="pl-2">Both</span>
                                            <span className="pl-2 invisible">Both</span>
                                            <span className="pl-2 invisible text-xs">(3600 px)</span>
                                        </label>
                                    </div> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-[5.5rem] p-5 advanced-options">
        <div className="flex justify-between w-full">
          <div className="flex flex-col  text-secondary-300 font-medium">
            Advanced options
          </div>
          <div className="flex flex-col  text-secondary-300 font-medium">
            <span
              className="pl-2 cursor-pointer"
              onClick={() => {
                setShowAdvanceOption(!showAdvanceOption);
              }}
            >
              <DownArrow
                className={`${showAdvanceOption ? "rotate-180" : ""}`}
              />
            </span>
          </div>
        </div>

        <div className="flex">
          <div className="flex flex-col basis-1/2 mt-4 advanced-sub-text text-sm">
            Restrict specific emails from downloading and prevent sub-events
            from being downloaded
          </div>
        </div>
      </div>
      {isBulkDownloadEnable && showAdvanceOption && (
        <>
          <div
            id="downloads-restrictions"
            className="flex flex-row flex-wrap 2xl:flex-nowrap gap-x-32 gap-y-8 mt-10"
          >
            <div className="flex flex-col basis-1/2">
              <SwitchWithTitle
                className="mb-8 font-medium"
                title="Block specific emails"
                subtitle="Block downloads to emails you have entered here."
                value={isEmailRestrictionEnabled}
                onChange={() => {
                  isEmailRestrictionEnabled
                    ? handleEmailRestriction(0)
                    : handleEmailRestriction(1);
                }}
                success={success.email}
                error={error.email}
              />

              <div className="mt-1 w-[400px]">
                <InputEditable
                  id="res-email-input"
                  placeholder="Enter email"
                  required={!isEmailRestrictionEnabled}
                  onUpdate={(e) => {
                    addRestrictedEmail(e);
                  }}
                  value={currentRestrictedEmail}
                  onChange={(e) => {
                    setCurrentRestrictedEmail(e.target.value);
                  }}
                  success={success.email}
                  error={error.email}
                  readOnly={!isEmailRestrictionEnabled}
                />
              </div>

              <div className="mt-9 w-[400px]">
                <Text as="p" className="mb-[20px] font-medium">
                  Blocked email addresses
                </Text>
                <div className="email-addresses overflow-y-auto">
                  <ul className="flex flex-col ">
                    {!_.isEmpty(guestEventEmail) &&
                      guestEventEmail.map((list) => {
                        const { id, status, email } = list;
                        return (
                          <li className="flex justify-between  pt-2 pb-4 items-center border-b border-black-gray text-light-gray">
                            <div className="truncate">{email}</div>
                            <div className="px-[1.25em]">
                              <DeleteIcon
                                onClick={(e) => {
                                  removeRestrictedEmail(email, id);
                                }}
                              />
                            </div>
                          </li>
                        );
                      })}

                    {/* <li className='flex flex-row pt-2 pb-4 items-center border-b border-black-gray  text-light-gray'>
                                        <div className='basis-1/4'>
                                            <span className='entered-address'>
                                                aakanksha.vira@fractalink.com
                                            </span>
                                        </div>
                                        <div className='basis-1/4 px-[1.25em]'>
                                            <DeleteIcon/>
                                        </div>

                                    </li> */}
                  </ul>
                </div>
                {/* <div className='flex justify-end mt-[20px]'>
                            <button type='button' className='actions-button'>
                                <div
                                    className='flex justify-center items-center'
                                    style={{
                                        color: "#ffffff",
                                    }}
                                >
                                    Save
                                </div>
                            </button>
                        </div> */}
              </div>
            </div>

            <div className="flex flex-col basis-1/2">
              <SwitchWithTitle
                className="mb-8 font-medium"
                title="Restrict Download for Sub-Event Folders"
                subtitle={
                  "Enable to restrict download for specific sub-event folder in the collection. This applies to both Bulk and Single Photo Download."
                }
                value={isFolderRestrictionEnabled}
                onChange={() => {
                  isFolderRestrictionEnabled
                    ? handleFolderRestriction(1)
                    : handleFolderRestriction(0);
                }}
              />

              {!!isFolderRestrictionEnabled && (
                <div className="mt-[10px]">
                  {!_.isEmpty(eventFolders) &&
                    eventFolders.map((folder) => {
                      return (
                        <div className="flex justify-start pb-5 gap-x-7">
                          <div className="flex flex-col min-w-[30%] text-light-gray truncate">
                            <span className="truncate">{folder.name}</span>
                          </div>
                          <div className="flex flex-col text-light-gray">
                            <div className="flex items-center">
                              <input
                                id={folder.id}
                                type="radio"
                                checked={
                                  folder.isDownloadAble === 1 ||
                                  folder.isDownloadAble === null
                                }
                                onChange={() => {
                                  handleRestrictedDownload(folder.id, 1);
                                }}
                                className={`accent-cyan-500 border 
                                        ${
                                          folder.isDownloadAble === 1 ||
                                          folder.isDownloadAble === null
                                            ? ""
                                            : "shadow-card"
                                        }`}
                              />
                              <label
                                className="pl-2 whitespace-nowrap text-light-gray cursor-pointer"
                                htmlFor={folder.id}
                              >
                                Allow download
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col text-light-gray">
                            <div className="flex items-center">
                              <input
                                id={`block-${folder.id}`}
                                type="radio"
                                checked={folder.isDownloadAble === 0}
                                onChange={() => {
                                  handleRestrictedDownload(folder.id, 0);
                                }}
                                className={`accent-cyan-500 border 
                                        ${
                                          folder.isDownloadAble === 0
                                            ? ""
                                            : "shadow-card"
                                        }`}
                              />
                              <label
                                className="pl-2 dark:text-light-gray cursor-pointer"
                                htmlFor={`block-${folder.id}`}
                              >
                                Block
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DownloadSettings;
