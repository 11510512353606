import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import _ from "lodash";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { InputText, InputEmail } from "../../components/common/Input";
import getStarted from "../../assets/images/illustration-get-started.png";
import CheckboxSignUp from "../../components/common/CheckboxSignUp";
import Button from "../../components/common/Button";
import { userRegister } from "../../apis";
import { useUser } from "../../Store/User";
import { getCookie, titleUpdate } from "../../functions";
import SocialLogin from "../../components/SocialLogin/Index";
import { InputPhoneCode } from "../../components/common/Input/input-phone-with-code";
import { REFERRAL_KEY } from "../../constants";

const SignupAdmin = () => {
  const searchParams = useSearchParams()[0];
  const { setRequestOnGoing } = useUser();

  const navigate = useNavigate();
  const [userRegisterDetails, setUserRegisterDetails] = useState({
    firstName: "",
    lastName: "",
    email: searchParams.get("email"),
    confirmPassword: "",
    phone: "",
    dialCode: "",
    isTermsChecked: false,
  });
  const [errors, setErrors] = useState({});
  const { user, setUser } = useUser();

  const [buttonDisable, setButtonDisable] = useState(true);
  const [isIndian, setIsIndian] = useState(false);
  const [countryCode, setCountryCode] = useState("US");

  let search = window.location.search;
  let getParam = new URLSearchParams(search);
  let source = getParam.get("source");

  const userCameFrom = searchParams.get("rf");

  useEffect(() => {
    titleUpdate(`Sign-Up`);
    async function fetchData() {
      const response = await fetch("https://country.pixnif.com/");
      const data = await response.json();
      setCountryCode(data.country.code);
      if (data.country.name == "India") {
        setIsIndian(true);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(userRegisterDetails.firstName) &&
      !_.isEmpty(userRegisterDetails.lastName) &&
      !_.isEmpty(userRegisterDetails.email) &&
      !_.isEmpty(userRegisterDetails.confirmPassword) &&
      userRegisterDetails.isTermsChecked &&
      (isIndian == true ? !_.isEmpty(userRegisterDetails.phone) : true)
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [userRegisterDetails]);

  const formValidation = () => {
    let error = {};
    let formIsValid = true;
    if (userRegisterDetails.firstName == null) {
      formIsValid = false;
      error["firstName"] = "First name is required";
    }

    if (
      userRegisterDetails.firstName != null &&
      userRegisterDetails.firstName.length < 2
    ) {
      formIsValid = false;
      error["firstName"] = "First name should be greater than 2 characters";
    }

    if (
      userRegisterDetails.lastName == null ||
      userRegisterDetails.lastName === ""
    ) {
      formIsValid = false;
      error["lastName"] = "Last name is required";
    }

    if (
      userRegisterDetails.lastName != null &&
      userRegisterDetails.lastName.length < 2
    ) {
      formIsValid = false;
      error["lastName"] = "Last name should be greater than 2 characters";
    }

    if (!userRegisterDetails.email) {
      formIsValid = false;
      error["email"] = "Email is required";
    }

    if (userRegisterDetails.email) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!pattern.test(userRegisterDetails.email)) {
        formIsValid = false;
        error["email"] = "Email is invalid";
      }
    }

    if (isIndian && !userRegisterDetails.phone) {
      formIsValid = false;
      error["phone"] = "Mobile Number is required";
    }

    if (isIndian && userRegisterDetails.phone) {
      let phoneNumber = userRegisterDetails.phone.replace(
        userRegisterDetails.dialCode,
        ""
      );
      // console.log('phoneNumber',phoneNumber)
      let phoneRegex = /^\d{6,}$/;
      if (!phoneRegex.test(phoneNumber)) {
        formIsValid = false;
        error["phone"] = "Enter valid phone number";
      }
    }

    if (!userRegisterDetails.confirmPassword) {
      formIsValid = false;
      error["confirmPassword"] = " Password is required";
    }

    setErrors(error);
    return formIsValid;
  };

  const handleUserRegister = (e) => {
    e.preventDefault();
    const { source, medium, campaign } = getCookie(REFERRAL_KEY)
      ? JSON.parse(getCookie(REFERRAL_KEY))
      : {};
    formValidation() &&
      (setButtonDisable(true),
      userRegister(
        userRegisterDetails.firstName,
        userRegisterDetails.lastName,
        userRegisterDetails.email,
        userRegisterDetails.confirmPassword,
        userRegisterDetails.phone.replace(userRegisterDetails.dialCode, ""),
        userRegisterDetails.dialCode,
        null,
        null,
        userCameFrom,
        countryCode,
        source ?? document.referrer,
        medium,
        campaign
      ).then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Sign Up Successful",
        });
        window.fbq("track", "CompleteRegistration");
        window.fbq("track", "StartTrial", {
          value: "0.00",
          currency: "USD",
          predicted_ltv: "0.00",
        });

        localStorage.removeItem("access_token");
        if (userCameFrom === "algocull") {
          window.location = "algocull://?jwt=" + response.access_token;
          return;
        }
        if (userCameFrom === "algoedit") {
          window.location = "algoedit://?jwt=" + response.access_token;
          return;
        } else {
          Cookies.set("access_token", response.access_token, {
            expires: 7,
            secure: true,
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          });
          setUser({
            ...user,
            access_token: response.access_token,
          });
          setRequestOnGoing(true);
          navigate("/");
        }
      })).catch((error) => {
        setButtonDisable(false);
        window.Toast.fire({
          icon: "error",
          title: "Sign Up Failed",
        });

        const message = error?.response?.data?.message;

        if (message.includes("email")) {
          setErrors({ ...errors, email: message });
        }
        if (message.includes("name")) {
          setErrors({
            ...errors,
            firstName: message,
          });
        }
        if (message.includes("lastName")) {
          setErrors({
            ...errors,
            lastName: message,
          });
        }
        if (message.includes("password")) {
          setErrors({
            ...errors,
            confirmPassword: message,
          });
        }
      });
  };

  useEffect(() => {
    if (!_.isEmpty(user.access_token)) {
      if (userCameFrom === "algocull" || userCameFrom === "algoedit") {
        window.location = "//payments." + process.env.REACT_APP_URL;
      } else if (userCameFrom === "uploader") {
        window.location = "uploader://?jwt=" + user.access_token;
      } else navigate("/");
    }
  }, []);

  return (
    <div className="px-10 max-w-[80rem] mx-auto flex items-center align-middle min-h-[calc(100vh_-_80px)]">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-12 flex items-center  text-secondary-300">
        <div className="lg:pb-10 lg:max-w-[30rem] xl:max-w-[30rem] 2xl:max-w-[30rem] 3xl:max-w-[30rem]">
          <div className="flex items-center justify-center pt-10 lg:justify-start xl:justify-start 2xl:justify-start 3xl:justify-start">
            <img src={getStarted} width="100" height="101" alt="Get Started" />
          </div>
          <h1 className="text-secondary-300 text-center font-medium text-xl pt-2.5  md:3xl lg:text-3xl xl:text-3xl xl:text-left lg:text-left">
            Algomage is the world's only end to end AI powered image post
            production solution
          </h1>
        </div>

        <div className="shadow-card my-10 px-[1.875rem] pb-8 pt-[1.875rem] rounded-[10px] xl:min-w-[35rem] 2xl:min-w-[35rem]">
          <h1 className="text-color-secondary text-xl font-medium text-center lg:text-2xl lg:text-left xl:text-2xl xl:text-left 2xl:text-2xl 2xl:text-left">
            Sign Up
          </h1>
          <p className="text-color-secondary text-base py-2 font-normal text-left">
            Already have an account?
            {source === "download" ? (
              <Link
                to={"/login?source=download"}
                className="text-primary-green font-semibold underline pl-2"
              >
                Sign In
              </Link>
            ) : (
              <Link
                to={"/login"}
                className="text-primary-green font-semibold underline pl-2"
              >
                Sign In
              </Link>
            )}
            {/* <Link
                                    to={'/login'}
                                    className='text-primary-green font-semibold underline pl-2'
                                >
                                    Sign In
                                </Link> */}
          </p>
          <div className="mt-5">
            <div className="flex flex-col pb-4">
              <InputEmail
                className="!max-w-[32rem] md:!max-w-full sm:!max-w-full px-5 lg:px-0 xl:px-0"
                label="Email ID"
                type="email"
                onChange={(e) => {
                  setUserRegisterDetails({
                    ...userRegisterDetails,
                    email: e,
                  });
                  setErrors({ ...errors, email: null });
                }}
                value={userRegisterDetails.email}
                error={errors.email}
                onKeyDown={(e) => {
                  handleUserRegister(e);
                }}
                autoFocus={true}
              />
            </div>
            <div className="flex flex-col sm:flex-wrap 2xl:flex-wrap xl:flex-wrap md:flex-wrap lg:flex-wrap lg:flex-row justify-between gap-5 grow pb-4 max-w-[32rem] md:max-w-full sm:max-w-full">
              <InputText
                className="lg:w-[47%] !max-w-[32rem] md:!max-w-full sm:!max-w-full px-5 lg:px-0 xl:px-0"
                label="First Name"
                type="text"
                onChange={(e) => {
                  setUserRegisterDetails({
                    ...userRegisterDetails,
                    firstName: e,
                  });
                  setErrors({ ...errors, firstName: null });
                }}
                value={userRegisterDetails.firstName}
                error={errors.firstName}
                onKeyDown={(e) => {
                  handleUserRegister(e);
                }}
              />

              <InputText
                className="lg:w-[47%] !max-w-[32rem] md:!max-w-full sm:!max-w-full px-5 lg:px-0 xl:px-0"
                label="Last Name"
                type="text"
                onChange={(e) => {
                  setUserRegisterDetails({
                    ...userRegisterDetails,
                    lastName: e,
                  });
                  setErrors({ ...errors, lastName: null });
                }}
                value={userRegisterDetails.lastName}
                error={errors.lastName}
                onKeyDown={(e) => {
                  handleUserRegister(e);
                }}
              />
            </div>
            {isIndian && (
              <div className="flex flex-col pb-4">
                <InputPhoneCode
                  className="!max-w-[32rem] md:!max-w-full sm:!max-w-full px-5 lg:px-0 xl:px-0"
                  label="Phone Number"
                  type="text"
                  onChange={(value, country, e, formattedValue) => {
                    setUserRegisterDetails({
                      ...userRegisterDetails,
                      phone: value,
                      dialCode: country.dialCode,
                    });
                    setErrors({ ...errors, phone: null });
                    // console.log(country);
                  }}
                  value={userRegisterDetails.phone}
                  error={errors.phone}
                  onKeyDown={(e) => {
                    handleUserRegister(e);
                  }}
                  autoFocus={true}
                />
              </div>
            )}

            <div className="flex flex-col pb-4">
              <InputText
                className="!max-w-[32rem] md:!max-w-full sm:!max-w-full px-5 lg:px-0 xl:px-0"
                label="Password"
                type="password"
                onChange={(e) => {
                  setUserRegisterDetails({
                    ...userRegisterDetails,
                    confirmPassword: e,
                  });
                  setErrors({ ...errors, confirmPassword: null });
                }}
                value={userRegisterDetails.confirmPassword}
                error={errors.confirmPassword}
                onKeyDown={(e) => {
                  handleUserRegister(e);
                }}
              />
            </div>
            <CheckboxSignUp
              id="terms"
              label="Accept all &nbsp; "
              label_two="Terms &amp; Conditions"
              onChange={(e) => {
                setUserRegisterDetails({
                  ...userRegisterDetails,
                  isTermsChecked: !userRegisterDetails.isTermsChecked,
                });
              }}
              className="text-xs text-secondary-300"
              checked={userRegisterDetails.isTermsChecked}
            />
          </div>
          <div className="flex justify-center py-5">
            <Button
              onClick={(e) => {
                handleUserRegister(e);
              }}
              text="Sign Up"
              disabled={buttonDisable}
              className="!h-[2.5rem] shadow-card"
            />
          </div>

          <div className="flex justify-center items-center">
            <div className="relative flex w-full pb-5 items-center">
              <div className="flex-grow border-t border-neutral-700"></div>
              <span className="flex-shrink mx-4 text-neutral-500 text-xs">
                Or
              </span>
              <div className="flex-grow border-t border-neutral-700"></div>
            </div>
          </div>

          <GoogleOAuthProvider clientId="137315411674-1chibppfh2ed2umal3su5k29gchdesv1.apps.googleusercontent.com">
            <SocialLogin
              setUser={setUser}
              userCameFrom={userCameFrom}
              countryCode={countryCode}
              setRequestOnGoing={setRequestOnGoing}
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

export default SignupAdmin;
