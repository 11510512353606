import React, { useState } from "react";
import Swal from "sweetalert2"

import Text from "../../../../components/common/Text";
import Button from "../../../../components/common/Button";
import getStarted from "../../../../assets/images/illustration-get-started.png";
import DialogWrapper from "../../../../components/common/Dialog";
import AddImages from "../AddImages";
import AddVideoLink from "../AddVideoLink";
import { useUser } from "../../../../Store/User";

const GettingStarted = ({ setCurrentSubPage }) => {
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [showVideoUploader, setShowVideoUploader] = useState(false);
  const { user } = useUser();
  return (
    <div className="flex justify-center item-center flex-col border border-2 border-[#aaaaaa1a] ">
      <div className="flex justify-center mt-24">
        <img src={getStarted} width="100" height="101" alt="Get Started" />
      </div>
      <Text
        as="p"
        className="text-2xl text-light-gray font-medium pt-4 pb-2 text-center"
      >
        Get Started!
      </Text>
      <Text as="p" className="text-base text-light-gray pb-4 text-center">
        Add photos to start editing and sharing them instantly.
      </Text>
      <div className="flex justify-center mb-24">
        {user.permissions.upload_images ? (
          <button
            id="add-images-new"
            className="rounded-lg shadow-button font-semibold w-[11rem] h-[2.5rem] mr-4"
            onClick={() => {
              setShowImageUploader(true);
              // Swal.fire({
              //   icon: "warning",
              //   title: "Maintenance Alert",
              //   text: "Image Uploads are temporarily disabled on web application due to maintenance activity. Please use Algoshare desktop app for seamless image uploads",
              // });
            }}
          >
            Add Images
          </button>
        ) : (
          <button
            title="Upgrade Subscription"
            id="add-images-new"
            className="rounded-lg shadow-button font-semibold w-[11rem] h-[2.5rem] mr-4 cursor-not-allowed"
          >
            Add Images
          </button>
        )}
        {user.permissions.upload_images ? (
          <button
            className="rounded-lg shadow-button font-semibold w-[11rem] h-[2.5rem]"
            onClick={() => {
              setShowVideoUploader(true);
            }}
          >
            Add Video Link
          </button>
        ) : (
          <button
            title=" Upgrade Subscription"
            className="rounded-lg shadow-button font-semibold w-[11rem] h-[2.5rem] cursor-not-allowed"
          >
            Add Video Link
          </button>
        )}
      </div>
      {showImageUploader && (
        <DialogWrapper
          open={showImageUploader}
          close={() => {
            setShowImageUploader(false);
          }}
          customWidth="width-70"
        >
          {" "}
          <AddImages setShowImageUploader={setShowImageUploader} />{" "}
        </DialogWrapper>
      )}
      {showVideoUploader && (
        <DialogWrapper
          open={showVideoUploader}
          close={() => {
            setShowVideoUploader(false);
          }}
          customWidth="width-70"
        >
          {" "}
          <AddVideoLink setShowVideoUploader={setShowVideoUploader} />{" "}
        </DialogWrapper>
      )}
    </div>
  );
};

export default GettingStarted;
