import React from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import photo from "../../../../assets/images/photo.png";
import Button from "../../../../components/common/Backbutton";
import { useUploader } from "../../../../Store/Uploader";
import { useFolder } from "../../../../Store/Folder/Show";
import { useEvent } from "../../../../Store/Event/Show";
import { useUser } from "../../../../Store/User";
import { MAX_FILE_TO_UPLOAD } from "../../../../constants";

const AddImages = ({ setShowImageUploader }) => {
  const { folder, images } = useFolder();
  const { user } = useUser();
  const { event } = useEvent();
  const { onDrop, isUploading } = useUploader();
  const [duplicates, setDuplicates] = useState("skip");

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); //check if safari

  const startUpload = (acceptedFiles, rejectedFiles) => {
    if (isUploading) {
      window.Toast.fire({
        icon: "error",
        title: "Please wait for the current upload to finish.",
      });
      return false;
    }
    if (rejectedFiles.length > 0) {
      window.Toast.fire({
        icon: "error",
        title:
          "Some dropped files were rejected as only jpeg images are allowed to be uploaded",
      });
    }
    if (acceptedFiles.length < 1) {
      window.Toast.fire({
        icon: "error",
        title: "No files to upload",
      });
      return false;
    }
    let pathArray = acceptedFiles[0].path.replace(/^\/|\/$/g, "").split("/");
    if (pathArray.length > 1) {
      Swal.fire({
        title: `Uploading a folder?`,
        showCancelButton: true,
        icon: "warning",
        html: `You are uploading a folder. All the files in folder &amp; its subfolders will get uploaded in subevent <b>${folder.name}</b>`,
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          let newAcceptedFiles = acceptedFiles.map((f) => {
            f.path.replace(/^.*[\\\/]/, "");
            return f;
          });
          let readyToUploadFolders = [{ ...folder, images: images }];
          let folderWiseImagesToUpload = {};
          folderWiseImagesToUpload[folder.id] = newAcceptedFiles;
          setShowImageUploader(false);
          onDrop(
            readyToUploadFolders,
            folderWiseImagesToUpload,
            duplicates,
            event
          );
        }
      });
    } else {
      let readyToUploadFolders = [{ ...folder, images: images }];

      let folderWiseImagesToUpload = {};
      folderWiseImagesToUpload[folder.id] = acceptedFiles;

      setShowImageUploader(false);
      onDrop(readyToUploadFolders, folderWiseImagesToUpload, duplicates, event);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/jpg",
    onDrop: startUpload,
    noClick: false,
    noKeyboard: false,
    multiple: true,
    preventDropOnDocument: false,
    disabled: isUploading,
  });
  return (
    <div className="flex justify-center item-center  flex-col  text-slate-50 mx-4">
      <div className="w-full p-5 text-center">
        <div className="text-xl font-normal">Add Images</div>
        <div className="flex flex-wrap gap-6 mt-4 justify-center text-sm">
          <div
            className={`${
              duplicates === "skip"
                ? "shadow-input-filled"
                : "shadow-dark-input"
            } cursor-pointer rounded-md w-[200px] p-3`}
          >
            <div
              className="flex items-center"
              onClick={() => {
                setDuplicates("skip");
              }}
            >
              <input
                type="radio"
                checked={duplicates === "skip" ? true : false}
                name="duplicates"
                onChange={() => {
                  setDuplicates("skip");
                }}
                value={"skip"}
                className="accent-cyan-500"
              />
              <span className="pl-2 text-sm">Skip Duplicates</span>
            </div>
          </div>
          <div
            className={`items-center ${
              duplicates === "skip"
                ? "shadow-dark-input"
                : "shadow-input-filled"
            } cursor-pointer rounded-md w-[200px] p-3`}
          >
            <div
              className="flex items-center"
              onClick={() => {
                setDuplicates("replace");
              }}
            >
              <input
                type="radio"
                checked={duplicates === "skip" ? false : true}
                name="duplicates"
                onChange={() => {
                  setDuplicates("replace");
                }}
                value={"replace"}
                className="accent-cyan-500 border"
              />
              <span className="pl-2 text-sm">Overwrite Duplicates</span>
            </div>
          </div>
        </div>
        <div
          {...getRootProps({
            className:
              "flex flex-col mt-6 rounded-lg p-5 shadow-dark-input justify-center items-center w-full border border-dashed border-zinc-700",
          })}
        >
          <div className="mb-2">
            <img
              src={photo}
              width="60"
              text-align="center"
              height="60"
              alt="drag &amp; drop your pic"
            />
          </div>
          <div className="text-sm">
            <input {...getInputProps()} />
            Drag &amp; drop images to upload
            <span className="mt-2 flex justify-center flex-wrap">OR</span>
          </div>
          <Button className="mt-4 text-sm px-6" text="Browse Files" />
          <div className="text-xs mt-5 text-zinc-400">
            Maximum upload file size:{" "}
            {user.subscription?.restrictions?.image_size_limit ?? 25} MB
          </div>
          <div className="text-xs mt-2 text-zinc-400">
            Maximum image upload via web: {MAX_FILE_TO_UPLOAD} files.
            <div>
              For more, download our app{" "}
              <a
                onClick={(e) => e.stopPropagation()}
                className="text-primary-green"
                href="https://algomage.com/download"
              >
                here
              </a>
            </div>
          </div>
          {isSafari ? (
            <div className="text-zinc-400 mt-4 text-[10px]">
              Note:- We detected you are using safari browser. For better upload
              experience we recommend using google chrome
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddImages;
