import React,{useState,useEffect,useCallback,useRef} from "react";
import DialogWrapper from "../../../../../../components/common/Dialog";
import { Input } from "../../../../../../components/common/Input";
import { Dialog, Transition } from "@headlessui/react";
import Checkmark from "../../../../../../components/common/Checkmarks";
import Checkbox from "../../../../../../components/common/CheckboxSignUp";
import _ from 'lodash';
import { useEvent } from "../../../../../../Store/Event/Show";
import Button from "../../../../../../components/common/Button";
import {useUser} from "../../../../../../Store/User";
import {favouriteDownload} from "../../../../../../apis";


var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=0;var c1=0;var c2=0;var c3=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

const FavoritiesDialog = ({favList, currentFavList,showDownloadOptions, setShowDownloadOptions }) => {
    const {event} = useEvent();
    const {user} = useUser();
    const [opens, setOpens] = useState(true)
    const [show, setShow] = useState(false);
    const [folders, setFolders] = useState([]);
    const [downloadSize,setDownloadSize] = useState(1);
    const [selectedFolders, setSelectedFolders] = useState(emptyFolderImagesCheck(favList));
    
    useEffect(() => {
        if(!_.isEmpty(favList)){
            setFolders(favList)
        }
    }, [favList])

    let ref= useRef()

    function emptyFolderImagesCheck(data){
        // return data.map(element => 
        // { 
        //     return element.id;
        // }).filter(notUndefined => notUndefined !== undefined)
    }

    const handleOnChange = useCallback((e) => {
        if(!e.target.checked)  
            setSelectedFolders(selectedFolders.filter(f=>Number(f)!==Number(e.target.value)))
        else
            setSelectedFolders(selectedFolders.concat([Number(e.target.value)]))
      },[selectedFolders])

      const handleOnChangeAll = useCallback((e)=>{
      
        if(!e.target.checked)
          setSelectedFolders([])
        else
          setSelectedFolders(emptyFolderImagesCheck(favList))
    },[selectedFolders])

    
    
    const submitDownload = async(e) => {
        e.preventDefault();
        if(event.downloadBulkImageSize == 'original'){
            setDownloadSize(0)
        }
        if(event.downloadBulkImageSize == 'optimized'){
            setDownloadSize(1)
        }
        
        var res = await favouriteDownload(event.id,user.user.email,Number(downloadSize),currentFavList,'favourites',user.user.id);
        console.log("res",res)

        if(res.status == 1){
            // console.log(res)
            window.Toast.fire({
              icon: 'success',
              title:  res.message ? res.message : 'Download in process, please wait for it to complete you wil receive an email shortly.'
            });
        }else{
            window.Toast.fire({
                icon: 'error',
                title: res.e.message[0]
            });
        }
        
        // console.log("res",res)
        if(res.dl){
            let url = window.location.protocol + '//' +window.location.host+'/downloadNow/'+event.id+ '/'+event.slug+'/'+res.dl;
            // console.log(url);
            window.open(url, '_blank').focus();
        }
        setShowDownloadOptions(true)

    }
    // console.log("favList",favList)
    
    return (
        <div className="inline-block bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-3 sm:align-middle sm:max-w-lg w-full ">
        <DialogWrapper className="bg-secondary-800" open={showDownloadOptions} close={()=>{setShowDownloadOptions(false)}}  >
            <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-secondary-300">
                      Download Favourite List <span className="absolute right-6">
                </span>
            </Dialog.Title>
        <div className="sm:flex sm:items-start md:flex md:items-start lg:flex lg:items-start mb-5">
            <form ref={ref} className="w-full" >
            <div className="mt-3 sm:mt-0 sm:text-left md:text-left lg:text-left w-full">
            {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pt-3 break-all mt-10">
                    <div className="flex items-center">
                        <label
                            className={`select-none cursor-pointer flex items-center text-xs md:text-base font-semibold text-primary-green underline`}
                            htmlFor="selectAll"
                        >
                            <input
                                    className={`form-check-input shadow-input-filled mr-5 ${selectedFolders.length === emptyFolderImagesCheck(favList).length ? 'h-4 accent-primary-green rounded min-w-[16px]' : 'appearance-none h-[16px] w-[16px] border border-theme-light dark:border-dark-gray rounded-sm bg-theme-light dark:bg-dark-gray dark:shadow-dark-inner-all shadow-light-checkbox accent-blue focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer h-4 accent-primary-green rounded '}`}
                                    type='checkbox'
                                    name='folders'
                                    id="selectAll"
                                    checked={selectedFolders.length === emptyFolderImagesCheck(favList).length?true:false}
                                    onChange={handleOnChangeAll}
                                    aria-describedby="select-all"
                                />
                                {selectedFolders.length === emptyFolderImagesCheck(favList).length?'Deselect':'Select'} All
                        </label>
                    </div>
                    {favList.map((f)=>{
                        
                            return(
                                <div key={f.id} className="flex items-center">
                                    <label
                                        className={`text-dark-gray1 select-none cursor-pointer dark:text-light-gray flex items-center text-dark-gray1 text-xs md:text-base`}
                                        htmlFor={f.id}
                                    >
                                        <input
                                            className={`form-check-input shadow-input-filled mr-5 ${selectedFolders.indexOf(f.id)>-1 ? 'h-4 accent-primary-green rounded min-w-[16px]' : 'appearance-none h-[16px] w-[16px] border border-theme-light dark:border-dark-gray rounded-sm bg-theme-light dark:bg-dark-gray dark:shadow-dark-inner-all shadow-light-checkbox accent-blue focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer h-4 accent-primary-green rounded '}
                                            ${f.name.length > 35 ? 'md:translate-y-[-75%]' : ''}`}
                                            type='checkbox'
                                            name='folders'
                                            id={f.id}
                                            checked={selectedFolders.indexOf(f.id)>-1?true:false}
                                            onChange={handleOnChange}
                                            value={f.id}
                                            aria-describedby={f.id}
                                        />
                                        {f.name.replace(/-/g," ").toUpperCase()}<span className={`text-[8px] md:text-xs text-dark-gray1 ml-5 dark:text-[#eeeeee] ${f.name.length > 35 ? 'md:translate-y-[0%]' : ''}`}></span>
                                    </label>
                                    
                                </div>
                             )
                        
                    }).filter(notUndefined => notUndefined !== undefined)}


            </div> */}
                <div>
                    {event.downloadBulkImageSize == 'both' &&
                    <>
                    <div className='flex flex-row grow pb-4 pt-9 text-xs md:text-base text-dark-gray1 dark:text-light-gray'>
                        Choose download size
                    </div>
                    <div className="flex gap-4">
                                <div
                                    className={`flex items-center ${(downloadSize == 1) ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                    <label className="w-full cursor-pointer">
                                        <input
                                            type="radio"
                                            checked={downloadSize == 1}
                                            // onChange={() => setShowAppUrl(1)}
                                            onChange={() => {
                                                setDownloadSize(1)
                                            }}
                                            name="portfolio-share"
                                            className="accent-cyan-500"
                                        />
                                        <span className="pl-2 text-secondary-300">High Resolution</span>
                                    </label>
                                </div>

                                <div
                                    className={` flex items-center ${(downloadSize == 0) ? 'shadow-inner-all' : 'shadow-dark-input'} rounded-md p-3 `}>
                                    <label className="w-full cursor-pointer">
                                        <input
                                            type="radio"
                                            checked={downloadSize == 0}
                                            // onChange={() => setShowAppUrl(0)}
                                            onChange={() => {
                                                setDownloadSize(0)
                                            }}
                                            className="accent-cyan-500"
                                            name="portfolio-share"
                                        />
                                        <span className="pl-2 text-secondary-300">Web Size</span>
                                    </label>
                                </div>
                    </div>
                    
                    </>
            }
                </div>

                <div className='w-full flex justify-center pb-4 pt-10'>
                <Button  text="Download" onClick={(e)=>{submitDownload(e)}} />
                
            </div>
            </div>
            </form>
        </div>
        
        
    </DialogWrapper>
    </div>
    );
};

export default FavoritiesDialog;
