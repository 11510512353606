import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import IconButton from "../IconButton";
import Search from "../Search";
import Select from "../Select";
import SliderComponent from "../Slider";
import HamburgerIcon from "../../../assets/icons/HamburgerIcon";
import UploadIcon from "../../../assets/icons/UploadIcon";
import GalleryIcon from "../../../assets/icons/GalleryIcon";
import OneShotUpload from "../../../pages/UploadContent/components/OneShotUpload";
import FilterOptions from "../../../pages/MyEventsPinned/components/Popups/FilterOptions";
import AddMoreOptions from "../../../pages/MyEventsPinned/components/Popups/AddMoreOptions";
import SortingOptions from "../../../pages/MyEventsPinned/components/Popups/SortingOptions";
import { useFolder } from "../../../Store/Folder/Show";
import AddImages from "../../../pages/UploadContent/components/AddImages";
import AddVideoLink from "../../../pages/UploadContent/components/AddVideoLink";
import DialogWrapper from "../Dialog";
import { useUploader } from "../../../Store/Uploader";
import { useUser } from "../../../Store/User";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import { postDownloadGallery } from "../../../apis";
import { useEvent } from "../../../Store/Event/Show";
import "./filterSection.css";
import { PHOTOGRAPHER_ACCESS_TO_WEB_UPLOAD } from "../../../constants";

function FilterSection(props) {
  const {
    currentSubPage,
    setCurrentSubPage,
    imageCount,
    setTileSize,
    tileSize,
    eventImagesCount,
  } = props;

  const { eventId } = useParams();
  const { setCurrentView } = useFolder();
  const { isUploading } = useUploader();
  const { user } = useUser();
  const { event } = useEvent();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isShowSortOptions, setShowSortOptions] = useState(false);
  const [isShowFilterOptions, setShowFilterOptions] = useState(false);
  const [isShowAddMoreOptions, setShowAddMoreOptions] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [showVideoUploader, setShowVideoUploader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);

  // const [isShowSortingOptions, setShowSortingOptions] = useState(false);

  const sortClickHandler = (e) => {
    setShowSortOptions(!isShowSortOptions);
    e.stopPropagation();
  };

  const filterClickHandler = (e) => {
    setShowFilterOptions(!isShowFilterOptions);
    e.stopPropagation();
  };

  const addMoreClickHandler = (e) => {
    setShowAddMoreOptions(!isShowAddMoreOptions);
    e.stopPropagation();
  };

  const openUploaModal = (e) => {
    setShowUploadModal(true);
  };

  const handleClose = (e) => {
    setShowUploadModal(false);
    e.stopPropagation();
  };

  const showDownloadPopUp = () =>
    Swal.fire({
      icon: "success",
      title: "Download gallery",
      text: "Do you want to download the whole gallery?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: downloadGalleryPhotos,
    });

  const downloadGalleryPhotos = async () => {
    try {
      setDownloadLoader(true);
      const folderIds = event.folders
        .filter((el) => el.imagesCount > 0)
        .map((el) => el.id);
      const { data } = await postDownloadGallery(eventId, folderIds);
      window.Toast.fire({
        icon: "success",
        title: data.message
          ? data.message
          : "Download in process, please wait for it to complete you wil receive an email shortly.",
      });
      const url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/downloadNow/" +
        event.id +
        "/" +
        event.slug +
        "/" +
        data.downloadRequestId;
      window.open(url, "_blank").focus();
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        icon: "error",
        title: "Something went wrong while downloading the photos",
      });
    } finally {
      setDownloadLoader(false);
    }
  };

  // below code closes the add tag options popup when clicked outside
  let sortDropDownContainer = useRef();
  let filterByDropDownContainer = useRef();
  let addMoreDropDownContainer = useRef();

  // function to close the dropdown when clicked outside
  useEffect(() => {
    let handler = (event) => {
      if (!sortDropDownContainer.current?.contains(event.target)) {
        setShowSortOptions(false);
      }
      if (!filterByDropDownContainer.current?.contains(event.target)) {
        setShowFilterOptions(false);
      }
      if (!addMoreDropDownContainer.current?.contains(event.target)) {
        setShowAddMoreOptions(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="flex items-center justify-between flex-wrap">
      <div className="flex">
        <Search placeholder="Search" size="10" />
        <div className="relative whitespace-nowrap" ref={sortDropDownContainer}>
          <span onClick={sortClickHandler}>
            <Select
              isOpen={isShowSortOptions}
              className="ml-3"
              text={`Sort By`}
            />
          </span>
          {isShowSortOptions && (
            <SortingOptions
              imageCount={imageCount}
              setShowSortOptions={setShowSortOptions}
              setCurrentSubPage={setCurrentSubPage}
            />
          )}
        </div>
        <div
          className="relative whitespace-nowrap"
          ref={filterByDropDownContainer}
        >
          <span onClick={filterClickHandler}>
            <Select
              isOpen={isShowFilterOptions}
              className="ml-3 "
              text="Filter By"
            />
          </span>
          {isShowFilterOptions && (
            <FilterOptions
              setShowFilterOptions={setShowFilterOptions}
              setCurrentSubPage={setCurrentSubPage}
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        {currentSubPage === "GalleryTileView" ? (
          <SliderComponent
            marks={{ 0: "S", 1: "M", 2: "L" }}
            sliderValue={tileSize}
            onChange={setTileSize}
            min={0}
            max={2}
            dots={false}
            dotStyle={{ display: "none" }}
          />
        ) : (
          <></>
        )}
        <IconButton
          className=""
          title="Tile View"
          onClick={() => {
            localStorage.setItem("defaultView", "tile");
            setCurrentView("tile");
          }}
        >
          <GalleryIcon
            color={currentSubPage === "GalleryTileView" ? "#00B7B8" : "#eee"}
          />
        </IconButton>
        <IconButton
          title="List View"
          className="m-3 mr-6"
          onClick={() => {
            localStorage.setItem("defaultView", "list");
            setCurrentView("list");
          }}
        >
          <HamburgerIcon
            color={currentSubPage === "GalleryListView" ? "#00B7B8" : "#eee"}
          />
        </IconButton>

        <IconButton
          onClick={() =>
            downloadLoader
              ? window.Toast.fire({
                  icon: "error",
                  title: "Download request in progress.",
                })
              : eventImagesCount
              ? showDownloadPopUp()
              : window.Toast.fire({
                  icon: "error",
                  title: "No images to download.",
                })
          }
          title="Download Gallery photos"
          className="mr-3"
        >
          {downloadLoader ? (
            <div className="clipPathLoader"></div>
          ) : (
            <DownloadIcon />
          )}
        </IconButton>

        {user.permissions.upload_images ? (
          <IconButton
            id="one-shot-upload-button"
            className={`${isUploading ? "cursor-not-allowed" : ""} mr-3`}
            onClick={() => {
              if (
                PHOTOGRAPHER_ACCESS_TO_WEB_UPLOAD.includes(user.photographer.id)
              ) {
                if (isUploading) {
                } else {
                  openUploaModal();
                }
              } else {
                Swal.fire({
                  title: "One shot upload",
                  html: `<div>To use one shot upload download the desktop application and from there you can upload it. Increase your upload speed by 4x using desktop app. Download the app from <a class="text-primary-green" href="https://algomage.com/download">here</a></div>`,
                  icon: "info",
                });
                window.location = "algoshare://?jwt=" + user.access_token;
              }
            }}
            title="Oneshot Upload"
          >
            <UploadIcon color={`${isUploading ? "grey" : "white"}`} />
          </IconButton>
        ) : (
          <div title="Upgrade Subscription">
            <IconButton
              id="one-shot-upload-button"
              className={`${
                isUploading ? "cursor-not-allowed" : "cursor-not-allowed"
              } mr-3`}
              title="Upgrade Subscription"
            >
              <UploadIcon color={`${isUploading ? "grey" : "grey"}`} />
            </IconButton>
          </div>
        )}
        {user.permissions.upload_images ? (
          <div className="relative" ref={addMoreDropDownContainer}>
            <span onClick={addMoreClickHandler}>
              <Select
                isOpen={isShowAddMoreOptions}
                text="Add More"
                className="ml-3 whitespace-nowrap"
              />
            </span>
            {isShowAddMoreOptions && (
              <AddMoreOptions
                setShowAddMoreOptions={setShowAddMoreOptions}
                setShowImageUploader={setShowImageUploader}
                setShowVideoUploader={setShowVideoUploader}
              />
            )}
          </div>
        ) : (
          <div title="Upgrade Subscription" className="relative ">
            <span>
              <Select
                isOpen={isShowAddMoreOptions}
                text="Add More"
                className="cursor-not-allowed ml-3 whitespace-nowrap"
              />
            </span>
          </div>
        )}
      </div>

      {showUploadModal && (
        <OneShotUpload open={showUploadModal} close={handleClose} />
      )}
      {showImageUploader && (
        <DialogWrapper
          open={showImageUploader}
          close={() => {
            setShowImageUploader(false);
          }}
          hideTitle={true}
          customWidth="width-70"
        >
          {" "}
          <AddImages setShowImageUploader={setShowImageUploader} />{" "}
        </DialogWrapper>
      )}
      {showVideoUploader && (
        <DialogWrapper
          open={showVideoUploader}
          close={() => {
            setShowVideoUploader(false);
          }}
          hideTitle={true}
          customWidth="width-70"
        >
          {" "}
          <AddVideoLink setShowVideoUploader={setShowVideoUploader} />{" "}
        </DialogWrapper>
      )}
    </div>
  );
}

export default FilterSection;
