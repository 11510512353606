import LoadingGif from "../../assets/images/loading.gif";
function Loading(props) {
  const { className, noFullScreen = "", text = "" } = props;
  return (
    <div className={className}>
      <div
        className={`grid place-items-center ${
          noFullScreen ? noFullScreen : "h-screen"
        } w-full bg-transparent`}
      >
        <div className="flex flex-col justify-center items-center text-light-gray max-w-sm">
          <img src={LoadingGif} width={150} height={150} alt="Loader" />
          <h3 className="text-2xl mb-5">{text ?? "Loading..."}</h3>
        </div>
      </div>
    </div>
  );
}

export default Loading;
