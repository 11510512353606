import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import { getNotifications, refreshToken, userLogin } from "../apis";
import { getUserDetails } from "../apis/user";

const User = React.createContext();
User.displayName = "User";

export const useUser = () => React.useContext(User);

export const UserProvider = ({ children }) => {
  let token = Cookies.get("access_token");
  if (["", "null", "undefined"].includes(token)) token = null;

  const initialUser = {
    user: {},
    photographer: {},
    subscription: {},
    access_token: token,
    notifications: [],
    permissions: {},
    address: {},
  };
  const [dashboardUrl, setDashboardUrl] = useState("/");
  const [user, setUser] = useState(initialUser);
  const [requestOnGoing, setRequestOnGoing] = useState(true);

  const loginUser = async (email, password) => {
    try {
      const response = await userLogin(email, password);
      localStorage.removeItem("access_token");
      Cookies.set("access_token", response.access_token, {
        expires: 7,
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      const notifications = await getNotifications();
      setUser((prev) => ({
        ...prev,
        access_token: response.access_token,
        notifications: notifications.data,
        permissions: notifications.permissions,
      }));
      setRequestOnGoing(true);
    } catch (err) {
      console.log(err.response);
      setRequestOnGoing(false);
      throw err;
    }
  };

  const refreshAccessToken = async () => {
    try {
      let response = await refreshToken();
      localStorage.removeItem("access_token");
      Cookies.set("access_token", response.access_token, {
        expires: 7,
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      const notifications = await getNotifications();

      setUser((prev) => ({
        ...prev,
        access_token: response.access_token,
      }));
      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const callUserDetails = async () => {
    try {
      const { data } = await getUserDetails();
      const notifications = await getNotifications();
      localStorage.removeItem("access_token");
      Cookies.set("access_token", token, {
        expires: 7,
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      if (data.photographer.frame_prices)
        data.photographer.frame_prices = JSON.parse(
          data.photographer.frame_prices
        );
      setUser({
        photographer: data.photographer,
        subscription: data.subscription,
        user: {
          ...data.user,
          photographer: data.photographer,
          subscription: data.subscription,
        },
        notifications: notifications.data,
        permissions: notifications.permissions,
        access_token: token,
        address: data.address,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setRequestOnGoing(false);
    }
  };

  const cookieChangeListener = (e) => {
    if (e.deleted.some((el) => el.name === "access_token")) {
      window.location.reload();
    }
  };

  const fetchNewUserDetails = async () => {
    try {
      Cookies.set("access_token", user.access_token, {
        expires: 7,
        secure: true,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      const { data } = await getUserDetails();
      if (data.photographer.frame_prices)
        data.photographer.frame_prices = JSON.parse(
          data.photographer.frame_prices
        );
      setUser((prev) => ({
        ...prev,
        photographer: data.photographer,
        subscription: data.subscription,
        user: {
          ...data.user,
          photographer: data.photographer,
          subscription: data.subscription,
        },
        address: data.address,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setRequestOnGoing(false);
    }
  };

  useEffect(() => {
    if (
      token &&
      !Object.keys(user.photographer).length &&
      !Object.keys(user.subscription).length
    ) {
      callUserDetails();
    } else setRequestOnGoing(false);
    window?.cookieStore?.addEventListener("change", cookieChangeListener);
    return () =>
      window?.cookieStore?.removeEventListener("change", cookieChangeListener);
  }, []);

  useEffect(() => {
    if (user.access_token) {
      fetchNewUserDetails(user.access_token);
    }
  }, [user.access_token]);

  return (
    <User.Provider
      value={{
        user,
        setUser,
        dashboardUrl,
        setDashboardUrl,
        loginUser,
        refreshAccessToken,
        requestOnGoing,
        setRequestOnGoing,
      }}
    >
      {children}
    </User.Provider>
  );
};
