import React from "react";
import toast, { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom";

import App from "./components/App";
import { UserProvider } from "./Store/User";
import "./devices.min.css";
import reportWebVitals from "./reportWebVitals";
import "./introjs.css";
import "./swal.css";
import "./app.css";

window.Toast = {
  fire(props) {
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } text-light-gray mt-3 shadow-card pr-5 rounded-[8px] bg-dark-gray text-xs flex justify-between items-center pointer-events-auto`}
        >
          {props.icon === "error" ? (
            <span className="bg-primary-error-red rounded-l-[8px] p-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          ) : (
            <span className="bg-primary-green rounded-l-[8px] p-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          )}

          <span className="text-base pl-2.5 pr-10">{props.title || props.message}</span>
          <svg
            onClick={() => {
              toast.dismiss();
              toast.remove();
            }}
            className="cursor-pointer"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M.5 0h24v24H.5z" />
              <path
                d="M1.085.622c.78-.78 2.045-.78 2.825 0L12.487 9.2 21.065.622a1.998 1.998 0 1 1 2.825 2.825l-8.577 8.578 8.577 8.577a1.998 1.998 0 0 1-2.825 2.825l-8.578-8.577-8.577 8.577a1.998 1.998 0 0 1-2.825-2.825l8.577-8.577-8.577-8.578a1.998 1.998 0 0 1 0-2.825z"
                fill="#EEE"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </div>
      ),
      { id: props.title, duration: props.duration ?? 2000 }
    );
  },
};

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
    <Toaster position="bottom-center" />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
