import React, {useState, useEffect} from "react";
import Text from "../../../../../components/common/Text";
import RadioButton from "../../../../../components/common/RadioButton";
import Select from "../../../../../components/common/Select";
import {InputFile, InputText} from "../../../../../components/common/Input";
// import {onLogoFileChange} from "../../../../functions";
import {useUser} from "../../../../../Store/User";
import DownArrow from "../../../../../assets/icons/DownArrow";
import _ from "lodash"
import {updatePhotographerLogoTextAtt, updatePhotographerFavicon} from "../../../../../apis";
import ColorDropDown from "../../../../../components/common/DropDown/PresetWatermarkDropDown/Color";
import TextDropDown from "../../../../../components/common/DropDown/PresetWatermarkDropDown/Text";

const WatermarkUploadSection = (props) => {
    const {user, setUser} = useUser();
    const {
        className, fontColor, colorClicked, styleClicked, font, value = '',
        onLogoTextChange, error, onLogoFileChange, fileName, logoType, setLogoType,
        logoTextColors
    } = props;

    const styles = ['Arial', 'Monospace',]//'Helvetica','Poppins','Oswald','Roboto'];
    const [selectedFont, setSelectedFont] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);

    useEffect(() => {
        !_.isEmpty(user) && setSelectedFont(user.photographer.logo_text_font);
        !_.isEmpty(user) && setSelectedColor(user.photographer.logo_text_colour);
    }, [user]);




    const [colorFilterOptions, setColorFilterOptions] = useState(false);
    const [styleFilterOptions, setStyleFilterOptions] = useState(false);

    const colorToggle = (e) => {
        setColorFilterOptions(!colorFilterOptions);
    }
    const styleToggle = (e) => {
        setStyleFilterOptions(!styleFilterOptions);
    }

    const onFontChange = (e) => {
        styleClicked(e);
    }


    const onColorChange = (e) => {
        colorClicked(e);
    }

    // console.log('user',user);

    return (
        <div className={`${className} mb-9`}>
            <Text as="p" className="mb-4 text-base font-medium text-secondary-300s">
                Watermark
            </Text>
            <div className="flex flex-row justify-between gap-5 mb-9 mr-10">
                <div className="flex flex-col w-full">
                    <RadioButton
                        // className='w-full'
                        width={'w-full'}
                        checked={logoType.type === 'text'}
                        handleOnClick={() => setLogoType({...logoType, type: 'text',logoUrl:''})}
                        label="Watermark text"
                    />
                </div>

                <div className="flex flex-col w-full">
                    <RadioButton
                        // className='w-full'
                        width={'w-full'}
                        checked={logoType.type === 'file'}
                        handleOnClick={() => setLogoType({...logoType, type: 'file',logoText:''})}
                        label="Watermark icon"
                    />
                </div>
            </div>
            {logoType.type === 'file' ? (
                <>
                <InputFile
                    className=""
                    label="Upload Watermark icon"
                    overlapLabel="Upload"
                    name='logo'
                    allowedFileTypes={['.png',  'jpg', 'jpeg']}
                    fileName={_.truncate(fileName) ?? null}
                    onFileChange={(e) => {
                        onLogoFileChange(e)
                    }}
                />
                {_.isEmpty(error.logo) ? '' :
                <label className="text-gray text-sm text-primary-error-red">{error.logo}</label>}
                </>
            ) : (
                <>
                    <Text as="p" className="mb-4 text-base font-medium text-secondary-300">
                        Watermark text
                    </Text>
                    <InputText onChange={(e) => {
                       const specialCharactersRegex = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/
                       if(specialCharactersRegex.test(e)){
                            window.Toast.fire({
                                title: "Special characters are not allowed",
                                icon: "error",
                                duration: 1000
                            })
                       }
                       else
                        setLogoType({...logoType, text: e});
                    }} value={logoType.text}/>
                    {_.isEmpty(error.logoText) ? '' :
                        <label className="text-gray text-sm text-primary-error-red">{error.logoText}</label>}

                    {_.isEmpty(error) ? '' : <label className="text-gray text-sm text-primary-error-red">{error.logoText}</label>}
                    <div className="flex flex-row mt-[40px] ">
                        <div className="flex flex-col w-full pr-7">
                            <Text as="p" className="mb-4 font-medium text-secondary-300 text-base">
                                Font style
                            </Text>
                            <TextDropDown items={styles} className={'w-full'}
                                          onSelect={onFontChange} initialValue={font}/>

                        </div>
                        <div className="flex flex-col w-full ">
                            <Text as="p" className="mb-4 font-medium text-secondary-300 text-base">
                                Font color
                            </Text>
                            {/* <CommonDropDown items={colors} className={'w-full'} /> */}
                            <ColorDropDown items={logoTextColors} onSelect={onColorChange} className={'w-full'}
                                           initialValue={fontColor}/>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default WatermarkUploadSection;
