import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";

import Text from "../Text";
import { removeEventTag, updateFolderOrder, getAllTags } from "../../../apis";
import { createNewFolder } from "../../../apis";
import { useEvent } from "../../../Store/Event/Show";
import { updateEventDescription, updateEventTag } from "../../../apis";
import FolderShow from "../../../components/Folder/Show";
import { InputEditable, InputText } from "../../../components/common/Input";
import { Link } from "react-router-dom";
import { useUser } from "../../../Store/User";
import { useFolder } from "../../../Store/Folder/Show";
import AddSubEvent from "../../../pages/UploadContent/components/AddSubEvent";
import moment from "moment";
import { getOptimizedImage, copyText } from "../../../functions";

function AlbumPreviewSection({
  currentFolderId,
  showFolderDropdown,
  setShowFolderDropdown,
  setSelectedImages,
  eventImagesCount,
  currentFolderName,
  setCurrentFolderName,
}) {
  let { event, setEvent } = useEvent(),
    { user } = useUser();

  const { setFolderId, setImages } = useFolder();
  const photographer = user.photographer;
  const [description, setDescription] = useState(""),
    [photographerTags, setPhotographerTags] = useState(null),
    [timer, setTimer] = useState(null),
    [folders, setFolders] = useState([]),
    [guestFolders, setGuestFolders] = useState([]),
    [addFolderPopUp, setAddFolderPopUp] = useState(false),
    [folderName, setFolderName] = useState(""),
    [folderNameError, setFolderNameError] = useState(false),
    [showAddTag, setShowAddTag] = useState(false),
    [showAddTagInput, setShowAddTagInput] = useState(false),
    [newTag, setNewTag] = useState(""),
    [showAddTagOptions, setShowAddTagOptions] = useState(false),
    [showAddSubEvent, setShowAddSubEvent] = useState(false);

  const [showSubEventLoader, setShowSubEventLoader] = useState(false);

  let menuContainer = useRef(null);
  let addNewFolderContainer = useRef(null);

  useEffect(async () => {
    getAllTags()
      .then((response) => {
        setPhotographerTags(
          response.filter(
            (tag) => !["Wedding", "Birthday", "Corporate"].includes(tag.title)
          )
        );
      })
      .catch((errors) => {});
  }, []);

  useEffect(() => {
    if (!_.isEmpty(event.folders)) {
      let folders = event.folders.filter(
        (folder) => folder.slug !== "guest-uploads"
      );
      setFolders(folders.sort((a, b) => a.order - b.order));
      setGuestFolders(
        event.folders.filter((folder) => folder.slug === "guest-uploads")
      );
      setDescription(event.description);
    } else {
      setFolders([]);
    }
  }, [event.folders]);

  function setFolderOrder(e) {
    folders.forEach((folder, index) => {
      updateFolderOrder(folder, index + 1)
        .then(function (response) {
          window.Toast.fire({
            icon: "success",
            title: "Folder order changed successfully",
          });
        })
        .catch(function (error) {
          window.Toast.fire({
            icon: "error",
            title: "Error updating folder order",
          });
        });
    });
  }

  const url =
    "https://" + photographer.subDomain + "." + process.env.REACT_APP_URL;

  const onDescriptionChange = (e) => {
    let newDescription = e.target.value;
    setDescription(newDescription);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      updateEventDescription(event, newDescription).then(function (response) {
        setDescription(response.description);
        window.Toast.fire({
          icon: "success",
          title: `Description updated successfully`,
        });
      });
    }, 2000);
    setTimer(newTimer);
  };

  function updateDescription() {
    updateEventDescription(event, description)
      .then(function (response) {
        setDescription(response.description);
        window.Toast.fire({
          icon: "success",
          title: `Description updated successfully`,
        });
      })
      .catch((errors) => {
        let error = errors.response.data?.message;
        window.Toast.fire({
          duration: 5000,
          icon: "error",
          title: error ?? "Internal server error",
        });
      });
  }

  function addNewFolder(e) {
    const filter = ["my photos", "myphotos"];
    if (
      filter.includes(folderName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase())
    ) {
      setFolderName("");
      setFolderNameError("Folder name cannot be 'my photos'");
      return;
    }
    let order = folders.length > 0 ? folders[folders.length - 1].order + 1 : 1;
    setShowSubEventLoader(true);
    createNewFolder(event, folderName, order)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: `Sub-Event ${response.name} added successfully`,
        });
        setShowAddSubEvent(false);
        setAddFolderPopUp(false);
        setFolderName("");
        setFolders([...folders, response]);
        let newEvent = event;
        newEvent.folders.push(response);
        setEvent({ ...newEvent });
        setFolderId(response.id);
        setImages(null);
        setShowSubEventLoader(false);
      })
      .catch((error) => {
        setShowSubEventLoader(false);
        let errors = error?.response?.data?.message;
          if(errors){
          setFolderNameError(errors);
        } else {
          window.Toast.fire({
            icon: "error",
            title: "Error creating sub-event",
          });
        }
      });
  }

  const addTag = (e, eventId) => {
    setShowAddTagInput(!showAddTagInput);
  };

  //function to add new customize tag
  const updateNewTag = (e, eventId) => {
    if (newTag == "" || newTag == null) {
      window.Toast.fire({
        icon: "error",
        title: "Tag Name cannot be Empty",
      });
      return;
    }
    let randomColor =
      "#" +
      ("00000" + ((Math.random() * (1 << 24)) | 0).toString(16)).slice(-6);
    updateEventTag(eventId, newTag, randomColor)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "New Tag Added",
        });
        event.tags = [{ title: newTag, colour: randomColor }];
        setShowAddTag(false);
        setShowAddTagInput(false);
        setShowAddTagOptions(false);
        setNewTag("");
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Add Tag Failed",
        });
      });
  };

  function UpdatePreDefineTag(e, eventId, tagName, tagColour) {
    e.preventDefault();
    updateEventTag(eventId, tagName, tagColour)
      .then((response) => {
        window.Toast.fire({
          icon: "success",
          title: "Tag Added",
        });
        if (!_.isEmpty(event.tags)) {
          event.tags[0].title = tagName;
          event.tags[0].colour = tagColour;
        } else {
          event.tags = [{ title: tagName, colour: tagColour }];
        }
        setShowAddTagOptions(false);
        setShowAddTagInput(false);
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Add Tag Failed",
        });
      });
  }

  const removeTag = () => {
    removeEventTag(event.id, event.tags[0].id)
      .then((response) => {
        event.tags = [];
        setShowAddTagOptions(false);
        setShowAddTagInput(false);
        window.Toast.fire({
          icon: "success",
          title: "Tag Removed Successfully",
        });
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Add Tag Failed",
        });
      });
  };

  const showAddTagOptionsFunc = (e) => {
    setShowAddTagOptions(!showAddTagOptions);
  };

  useEffect(() => {
    let handler = (event) => {
      if (!menuContainer.current.contains(event.target)) {
        setShowAddTagOptions(false);
      }
      if (
        addNewFolderContainer.current !== null &&
        !addNewFolderContainer.current.contains(event.target)
      ) {
        setAddFolderPopUp(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // console.log('event', event);

  return (
    <div className="h-full">
      <div className="min-w-[20rem] shadow-sidebar pl-10 pt-7 pr-6 pb-20 h-full sticky top-0 rounded-tr-3xl rounded-br-[10px]">
        <div className="relative rounded-2xl w-64 aspect-video overflow-hidden">
          <Link
            to={`settings#cover-image`}
            id="cover-image-change"
            className="absolute w-full block cursor-pointer text-primary-green uppercase text-xs font-medium px-4 py-[15px] text-center top-1/2 -translate-y-1/2 bg-white z-0 opacity-70 hover:opacity-90"
          >
            Change Cover
          </Link>
          <img
            className="object-cover object-center"
            src={getOptimizedImage(event.desktopCoverImageUrl)}
            alt="album preview"
          />
        </div>
        <div className="flex justify-between text-zinc-400 text-sm w-64 mt-3">
          <div className="flex flex-row items-center" ref={menuContainer}>
            {_.isEmpty(event.tags[0]) ? (
              <div className="flex relative justify-start items-center">
                <span className="rounded-full w-3 h-3 add-tag-oval mr-2"></span>
                <span
                  onClick={() => {
                    showAddTagOptionsFunc();
                  }}
                  className="cursor-pointer"
                >
                  Add Tag
                </span>
                {showAddTagOptions === true ? (
                  <div className="absolute bg-dark-gray shadow-dark-input w-[200px] z-50 w-[200px] right-15 top-[95%] overflow-hidden rounded-xl mt-3">
                    <div className="flex flex-col  cursor-pointer text-light-gray text-sm">
                      <div className="max-h-32 overflow-y-scroll">
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Wedding",
                              "#da1ea5"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green "
                        >
                          <span className="rounded-full w-3 h-3 wedding-oval mr-2 mt-1"></span>
                          <span>Wedding</span>
                        </div>
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Birthday",
                              "#871eda"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green "
                        >
                          <span className="rounded-full w-3 h-3 birthday-oval mr-2 mt-1"></span>
                          <span>Birthday</span>
                        </div>
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Corporate",
                              "#1ea1da"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green"
                        >
                          <span className="rounded-full w-3 h-3 corporate-oval mr-2 mt-1"></span>
                          <span>Corporate</span>
                        </div>
                        {!_.isEmpty(photographerTags) ? (
                          photographerTags.map((tag) => {
                            return (
                              <div
                                onClick={(e) => {
                                  UpdatePreDefineTag(
                                    e,
                                    event.id,
                                    tag.title,
                                    tag.colour
                                  );
                                }}
                                className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green"
                              >
                                <span
                                  style={{ backgroundColor: tag.colour }}
                                  className="rounded-full w-3 h-3  corporate-oval mr-2 mt-1"
                                ></span>
                                <span className="capitalize truncate">
                                  {tag.title}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="border-t border-secondary-600 px-10 pt-1 h-1"></div>
                      {showAddTagInput ? (
                        <>
                          <div className="px-2 mb-4">
                            <InputText
                              onChange={(e) => {
                                setNewTag(e);
                              }}
                              value={newTag}
                              placeholder="Add New Tag"
                              className="mb-3"
                            />
                            <span
                              onClick={(e) => {
                                updateNewTag(e, event.id);
                              }}
                              className="whitespace-nowrap underline cursor-pointer text-blue"
                            >
                              Update
                            </span>
                            <span
                              onClick={(e) => {
                                setShowAddTagInput(false);
                              }}
                              className="whitespace-nowrap underline cursor-pointer text-red-400 ml-4"
                            >
                              Cancel
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={(e) => {
                              addTag(e, event.id);
                            }}
                            className="underline font-semibold text-base underline-offset-4 text-primary-green px-4 py-3 hover:bg-black"
                          >
                            Customise
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="flex capitalize relative justify-start items-center">
                <span
                  className="rounded-full w-3 h-3  mr-2"
                  style={{ backgroundColor: event.tags[0].colour }}
                ></span>
                <span
                  onClick={() => {
                    showAddTagOptionsFunc();
                  }}
                  className="cursor-pointer truncate"
                >
                  {event.tags[0].title}
                </span>
                {showAddTagOptions === true ? (
                  <div className="absolute  bg-dark-gray shadow-dark-input w-[200px] z-50 w-[200px] right-15 top-[95%] overflow-hidden rounded-xl">
                    <div className="flex flex-col cursor-pointer text-light-gray text-sm">
                      <div className="max-h-32 overflow-y-scroll">
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Wedding",
                              "#da1ea5"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green "
                        >
                          <span className="rounded-full w-3 h-3 wedding-oval mr-2 mt-1 "></span>
                          <span>Wedding</span>
                        </div>
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Birthday",
                              "#871eda"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green"
                        >
                          <span className="rounded-full w-3 h-3 birthday-oval mr-2 mt-1"></span>
                          <span>Birthday</span>
                        </div>
                        <div
                          onClick={(e) => {
                            UpdatePreDefineTag(
                              e,
                              event.id,
                              "Corporate",
                              "#1ea1da"
                            );
                          }}
                          className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green"
                        >
                          <span className="rounded-full w-3 h-3 corporate-oval mr-2 mt-1"></span>
                          <span>Corporate</span>
                        </div>
                        {!_.isEmpty(photographerTags) ? (
                          photographerTags.map((tag) => {
                            return (
                              <div
                                onClick={(e) => {
                                  UpdatePreDefineTag(
                                    e,
                                    event.id,
                                    tag.title,
                                    tag.colour
                                  );
                                }}
                                className="flex relative px-4 py-3 hover:bg-black hover:text-primary-green"
                              >
                                <span
                                  style={{ backgroundColor: tag.colour }}
                                  className="rounded-full w-3 h-3 corporate-oval mr-2 mt-1"
                                ></span>
                                <span className="truncate">{tag.title}</span>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="border-t border-secondary-600 px-10 pt-1 h-1"></div>
                      {showAddTagInput ? (
                        <>
                          <div className="px-2 mb-4">
                            <InputText
                              onChange={(e) => {
                                setNewTag(e);
                              }}
                              value={newTag}
                              placeholder="Add New Tag"
                              className="mb-3"
                            />
                            <span
                              onClick={(e) => {
                                updateNewTag(e, event.id);
                              }}
                              className="whitespace-nowrap underline cursor-pointer text-blue"
                            >
                              Update
                            </span>
                            <span
                              onClick={(e) => {
                                setShowAddTagInput(false);
                              }}
                              className="whitespace-nowrap underline cursor-pointer text-red-400 ml-4"
                            >
                              Cancel
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={(e) => {
                              addTag(e, event.id);
                            }}
                            className="underline font-semibold text-base underline-offset-4 text-primary-green px-4 py-3 hover:bg-black"
                          >
                            Customise
                          </div>
                          <div
                            onClick={(e) => {
                              removeTag();
                            }}
                            className="font-semibold text-base text-red-500 px-4 py-3 hover:bg-black"
                          >
                            Remove Tag
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className="text-gray">
            {!_.isNull(event.date) && moment(event.date).fromNow(true)}
          </div>
          <div className="text-gray">
            {!_.isNull(eventImagesCount) ? eventImagesCount : 0} photos
          </div>
        </div>
        <div className="flex w-64 content-center flex-col flex-wrap mt-4">
          <div className="flex justify-between">
            <label className="text-black-gray text-xs" htmlFor="description">
              Add Description
            </label>
            <span
              className="text-xs text-primary-green cursor-pointer"
              onClick={updateDescription}
            >
              Update
            </span>
          </div>
          <textarea
            placeholder="Maximum 250 characters"
            className="w-full placeholder:text-secondary-500 placeholder:text-xs text-xs rounded-xl bg-dark-gray p-2 mt-3 text-light-gray shadow-dark-input resize-none"
            onChange={(e) => {
              setDescription(e.target.value.substring(0, 250));
            }}
            id="description"
            name="description"
            rows="4"
            value={description}
          ></textarea>
        </div>
        <div className="flex w-64 content-center flex-col flex-wrap mt-4">
          <div className="flex justify-between w-full">
            <label className="text-light-gray text-xs" htmlFor="event-code">
              {!_.isNull(event) && "Event Code: " + event.id}
            </label>
            <span
              className="text-xs text-primary-green cursor-pointer"
              onClick={(e) => copyText(e, event.id)}
            >
              Copy
            </span>
          </div>
        </div>
        <div className="flex w-64 justify-between my-4 pt-4 border-solid border-t border-black-gray ">
          {!_.isNull(eventImagesCount) && eventImagesCount > 0 ? (
            <a
              href={`${url}/view/${event.slug}`}
              target="_blank"
              className="bg-transparent flex grow py-2 justify-center mr-2 px-1 text-light-gray shadow-dark-input text-sm rounded-lg font-semibold"
            >
              Preview
            </a>
          ) : (
            <span
              title="Please add images to preview"
              className="bg-dark-disabled-button text-gray cursor-not-allowed flex grow py-2 justify-center mr-2 px-1 shadow-dark-input text-sm rounded-lg font-semibold"
            >
              Preview
            </span>
          )}
          <Link
            to="analytics"
            className="bg-transparent flex grow py-2 justify-center px-1 text-light-gray shadow-dark-input text-sm rounded-lg font-semibold"
          >
            Insights
          </Link>
        </div>
        <div className="pt-4 pb-2 w-64">
          <div className=" flex justify-between items-center">
            <Text as="p" className="text-sm text-light-gray">
              Sub-Events
            </Text>
            {/* <Link linkName={"+ Add New"}/> */}
            <div className="relative cursor-pointer">
              {user.permissions.upload_images ? (
                <span
                  onClick={(e) => {
                    setShowAddSubEvent(!showAddSubEvent);
                  }}
                >
                  <Text
                    as="p"
                    id="add-sub-event"
                    className="text-sm underline
                                        text-primary-green font-medium"
                  >
                    + Add New
                  </Text>
                </span>
              ) : (
                <span
                  title="Upgrade Subscription"
                  className="cursor-not-allowed"
                >
                  <Text
                    as="p"
                    id="add-sub-event"
                    className="text-sm underline
                                        text-primary-green font-medium"
                  >
                    + Add New
                  </Text>
                </span>
              )}
              {addFolderPopUp && (
                <div
                  className="absolute p-4 mt-3 bg-dark-gray shadow-dark-input w-[300px] z-40 right-0.5 rounded-[10px] top-[95%]"
                  ref={addNewFolderContainer}
                >
                  <div className="flex flex-col gap-3 cursor-pointer text-light-gray text-sm">
                    <InputEditable
                      actionText="Add"
                      value={folderName}
                      error={folderNameError}
                      processing={false}
                      onUpdate={(value) => {
                        addNewFolder(value);
                        setFolderNameError("");
                      }}
                      onChange={(e) => {
                        setFolderName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {showAddSubEvent && (
            <AddSubEvent
              showAddSubEvent={showAddSubEvent}
              setShowAddSubEvent={setShowAddSubEvent}
              addNewFolder={addNewFolder}
              folderName={folderName}
              folderNameError={folderNameError}
              setFolderNameError={setFolderNameError}
              setFolderName={setFolderName}
              loader={showSubEventLoader}
            />
          )}
          <ul className="mt-3" id="sortable">
            {!_.isEmpty(folders) ? (
              <ReactSortable
                list={folders}
                setList={setFolders}
                onEnd={setFolderOrder}
              >
                {folders.map((folder) => (
                  <FolderShow
                    folder={folder}
                    setFolders={setFolders}
                    showFolderDropdown={showFolderDropdown}
                    setSelectedImages={setSelectedImages}
                    setShowFolderDropdown={setShowFolderDropdown}
                    currentFolderId={currentFolderId}
                    currentFolderName={currentFolderName}
                    setCurrentFolderName={setCurrentFolderName}
                    key={folder.id}
                  />
                ))}
              </ReactSortable>
            ) : (
              ""
            )}
          </ul>
          <ul className="mt-3 border-t border-secondary-600 pt-4">
            {!_.isEmpty(guestFolders)
              ? guestFolders.map((folder) => (
                  <FolderShow
                    folder={folder}
                    setFolders={setFolders}
                    showFolderDropdown={showFolderDropdown}
                    setSelectedImages={setSelectedImages}
                    setShowFolderDropdown={setShowFolderDropdown}
                    currentFolderId={currentFolderId}
                    key={folder.id}
                  />
                ))
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AlbumPreviewSection;
