import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { useEvent } from "../../../../../Store/Event/Show";
import { deleteEvent, markEventAsExpired } from "../../../../../apis";
import Swal from "sweetalert2";

const SettingsOptions = () => {
  let { event } = useEvent();


  const navigate = useNavigate();

  function deleteCurrentEvent() {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to permanently delete ${event.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteEvent(event)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Event deleted successfully",
            });
            navigate("/");
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          });
      },
    });
  }

  function archiveEvent() {
    Swal.fire({
      title: "Warning!",
      text: `Are you sure you want to archive this event?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Archive`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        markEventAsExpired(event)
          .then((response) => {
            window.Toast.fire({
              icon: "success",
              title: "Event archive successfully",
            });
            navigate("/");
          })
          .catch((error) => {
            window.Toast.fire({
              icon: "error",
              title: "Event could not be deleted",
            });
          });
      },
    });
  }

  return (
    <div className="absolute bg-dark-gray shadow-dark-input w-[200px] right-[0px] top-[20px] z-40 rounded-xl overflow-hidden">
      <div className="flex flex-col text-light-gray text-sm">
        <Link
          className="w-full block cursor-pointer px-4 py-3 hover:bg-black hover:text-primary-green"
          to={`settings`}
        >
          Event Settings
        </Link>
        <div className="border-t border-secondary-600 h-1"> </div>
        {event.isPublished &&
        [0, null, undefined].includes(event.hasExpired) ? (
          <div
            onClick={() => {
              archiveEvent();
            }}
            className=" cursor-pointer px-4 py-3 hover:bg-black hover:text-primary-error-red"
          >
            Archive Event
          </div>
        ) : (
          ""
        )}
        <div
          className="cursor-pointer px-4 py-3 hover:bg-black hover:text-primary-error-red"
          onClick={() => {
            deleteCurrentEvent();
          }}
        >
          Delete Event
        </div>
      </div>
    </div>
  );
};

export default SettingsOptions;
