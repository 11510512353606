import React, { useEffect, useRef, useState } from "react";

import {
  getOrderlist,
  getResendOrderDownload,
  postOrderDetails,
  getOrderlistEventWise,
} from "../../../../../apis";
import Text from "../../../../../components/common/Text";
import Title from "../../../../../components/common/Title";
import Loading from "../../../../Loading";
import "./index.css";

const PrintOrdersList = ({ setRef, id, showAsTitle, eventId }) => {
  const [printData, setPrintData] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [activeId, setActiveId] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const orderDetailsRef = useRef([]);
  const timerRef = useRef();

  const resendEmail = async (orderId) => {
    try {
      await getResendOrderDownload(orderId);
      window.Toast.fire({
        icon: "Success",
        title: "Email sent",
      });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        window.Toast.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      } else
        window.Toast.fire({
          icon: "error",
          title: "Server error",
        });
    } finally {
      setResendLoader();
    }
  };

  const callOrderList = async () => {
    try {
      let data;
      if (eventId) {
        data = await getOrderlistEventWise(eventId);
      } else {
        data = await getOrderlist();
      }

      setPrintData(data.data.data ?? []);
      orderDetailsRef.current = [...data.data.data];
    } catch (error) {
      console.error(error);
      window.Toast.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  useEffect(() => {
    callOrderList();
  }, []);

  const headers = [
    "Order ID",
    "Order Date",
    "Event Details",
    "Payment Status",
    "Total Amount",
    "User Email",
  ];

  const getOrderDetails = async (id) => {
    setActiveId(id);
    setShowLoader(true);
    try {
      if (!orderDetails[id]) {
        const data = await postOrderDetails(id);
        setOrderDetails((prev) => ({
          ...prev,
          [id]: data.data.data,
        }));
        setShowLoader(false);
      }
    } catch (err) {
      console.error(err);
      setActiveId(null);
    } finally {
      setShowLoader(false);
    }
  };

  const debounceCall = (value) => {
    clearTimeout(timerRef.current);
    const id = setTimeout(() => {
      if (value) {
        const filteredData = printData.filter((el) =>
          el.userEmail.includes(value)
        );
        console.log(filteredData);
        setPrintData([...filteredData]);
      }
    }, 1500);
    timerRef.current = id;
  };

  const downloadAsCSV = (element, separator = ",") => {
    const filename = element;
    const parentNode = document.getElementById(element);
    const csvRows = [];
    const header = [
      "OrderID",
      "Order Date",
      "Event Name",
      "Payment status",
      "Total amount",
      "User email",
    ];
    csvRows.push(header.join(separator));
    Array.from(parentNode.children).forEach(function (node) {
      let csvLine = [];

      node.querySelectorAll(":scope  div.export").forEach(function (td) {
        const cloneTd = td.cloneNode(true);
        let data;
        if (cloneTd.dataset.val) {
          data = cloneTd.dataset.val.replace(/(\r\n|\n|\r)/gm, "");
        } else {
          Array.from(cloneTd.children).forEach(function (remove) {
            remove.parentNode.removeChild(remove);
          });
          data = cloneTd.textContent.replace(/(\r\n|\n|\r)/gm, "");
        }
        data = data.replace(/(\s\s)/gm, " ").replace(/"/g, '""');
        csvLine.push('"' + data.trim() + '"');
      });
      csvRows.push(csvLine.join(separator));
    });
    var a = document.createElement("a");
    a.style = "display: none; visibility: hidden";
    a.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));
    a.download = filename + ".csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  useEffect(() => {
    if (!searchValue && orderDetailsRef.current.length) {
      setPrintData([...orderDetailsRef.current]);
    }
  }, [searchValue]);

  return (
    <div ref={setRef} id={id} className="text-light-gray mt-8">
      {showAsTitle ? (
        <Title
          className="pb-4 border-zinc-700 border-b mt-10 font-medium"
          text="Order List"
        />
      ) : (
        <Text as="p" className="text-base mb-4 font-medium">
          Order list
        </Text>
      )}
      <div className="relative my-4 flex items-center justify-between">
        <div>
          <i className="absolute top-3 left-4">
            <svg
              className="w-4 h-4"
              fill="#eee"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </i>
          <input
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              debounceCall(e.target.value);
            }}
            type="text"
            placeholder="Search..."
            size={31}
            className={`bg-black text-sm h-10 px-12 rounded-lg`}
            name="search"
          />
        </div>
        {printData.length > 0 ? (
          <div
            className="pr-8 underline  text-primary-green text-sm font-bold cursor-pointer"
            onClick={() => {
              downloadAsCSV("order_list");
            }}
          >
            Download Excel
          </div>
        ) : (
          <></>
        )}
      </div>
      <ul className="flex flex-col">
        <li className="flex flex-row bg-black1 h-[3.125rem]  items-center text-base px-[1.25rem] rounded-md">
          {headers.map((item, id) => {
            return (
              <div
                className={` ${
                  id === 0 ? "w-[100px]" : id === 4 ? "w-[140px]" : "w-[160px]"
                }`}
                key={item}
              >
                <Text as="p" className="text-base  font-medium">
                  {item}
                </Text>
              </div>
            );
          })}
        </li>
        <div id="order_list" className="max-h-[500px] overflow-scroll">
          {printData.length > 0 ? (
            printData
              .filter((item) => item.paymentStatus != "pending")
              .map((item) => {
                const {
                  id,
                  orderDate,
                  eventName,
                  // type,
                  currency,
                  paymentStatus,
                  totalAmount,
                  userEmail,
                } = item;
                return (
                  <div
                    key={id}
                    className="border-b border-black-gray  px-[1.25rem] py-8"
                  >
                    <li
                      className="flex flex-row items-center font-medium text-sm"
                      key={id}
                    >
                      <div
                        className="w-[100px] export text-primary-green cursor-pointer underline "
                        onClick={() => getOrderDetails(id)}
                      >
                        #{id}
                      </div>
                      <div className="w-[160px] export">{orderDate}</div>
                      <div className="w-[160px]">
                        <div
                          title={eventName}
                          className="max-w-[120px] truncate export"
                        >
                          {eventName}
                        </div>
                      </div>
                      {/* <div className="w-[168px] ">{type}</div> */}
                      <div
                        className={`w-[160px] export  ${
                          paymentStatus == "Successful"
                            ? "text-primary-green"
                            : "text-primary-error-red"
                        }`}
                      >
                        {paymentStatus}
                      </div>
                      <div className="w-[140px] export">
                        {currency.toLowerCase() == "usd" ? "$" : "Rs."}{" "}
                        {totalAmount}
                      </div>
                      <div
                        title={userEmail}
                        className="w-[168px] export truncate"
                      >
                        {userEmail}
                      </div>
                      <button
                        disabled={resendLoader}
                        className={`text-xs underline text-primary-green ml-2 ${
                          resendLoader ? " cursor-not-allowed" : ""
                        }`}
                        onClick={() => {
                          setResendLoader(id);
                          resendEmail(id);
                        }}
                      >
                        {resendLoader === id ? (
                          <div className="clipPathLoader mx-auto"></div>
                        ) : (
                          "Resend email"
                        )}
                      </button>
                    </li>
                    {activeId === id && (
                      <div>
                        {showLoader ? (
                          <Loading noFullScreen="h-fit" />
                        ) : (
                          <div className="mt-8 mx-10">
                            {orderDetails[activeId]?.map((el) => (
                              <div key={el.id} className="mt-2 flex gap-20">
                                <span className="min-w-[200px]">
                                  Type :-
                                  {el.frameType === "digital"
                                    ? ` Digital Download`
                                    : " " + el?.frameType}
                                </span>

                                <span className="min-w-[200px]">
                                  {el?.frameStyle !== "null" ? (
                                    `Style :- ${el?.frameStyle}`
                                  ) : (
                                    <></>
                                  )}
                                </span>

                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    el.croppingParameters
                                      ? `${el.imageUrl}?cr=${el.croppingParameters}`
                                      : el.imageUrl
                                  }
                                  className="underline text-primary-green"
                                >
                                  View image
                                </a>
                                {el?.frameUrl && el.frameType !== "digital" ? (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={el.frameUrl}
                                    className="underline text-primary-green"
                                  >
                                    View frame
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })
          ) : (
            <div className="grid-cols-8 auto-cols-max gap-4 ml-2 mt-6 text-base font-semibold bg-black rounded-lg p-3">
              <h1 className="text-center">No list found</h1>
            </div>
          )}
        </div>
      </ul>
    </div>
  );
};

export default PrintOrdersList;
