import React from "react";

import SuccessIcon from "../../../assets/icons/SuccessIcon";
import ProgressBar from "../../../components/common/ProgressBar";
import { useUploader } from "../../../Store/Uploader";
import Erroricon from "./erroricon";
import { IMAGE_LIMIT_REACHED } from "../../../constants";

function ImageQueue({
  isBottom = false,
  ulClass = "max-h-[13rem] overflow-y-auto",
}) {
  const {
    uploadFolders,
    filesToUpload,
    completedImages,
    groupedErrorFiles,
    retryUpload,
  } = useUploader();

  return (
    <div className="flex justify-center text-light-gray flex-col h-full">
      <p className="text-xs text-black-gray">
        Total Uploaded {completedImages.length}/{filesToUpload.length}
      </p>
      <div className="flex justify-around items-center pb-4 border-b border-light-gray">
        <ProgressBar
          percent={Math.round(
            (completedImages.length / filesToUpload.length) * 100
          )}
          size="medium"
        />
        {/* <div className='flex gap-2 ml-4'>
                    <span><CircledPause /></span>
                    <span><CircledClose /></span>
                </div> */}
      </div>
      <div>
        <ul className="mt-[20px]">
          {Object.keys(groupedErrorFiles).map((key) => {
            return (
              <li
                key={key}
                className="flex w-full items-center justify-between shadow-dark-input h-[30px] mb-3 rounded-xl overflow-hidden pr-4"
              >
                <span className="flex items-center text-xs h-[100%] gap-3">
                  <span className="flex items-center justify-center w-10 h-[100%] bg-rose-400">
                    <Erroricon />
                  </span>
                  {groupedErrorFiles[key].length} Uploads Failed | {key}
                </span>
                {key != "Duplicate File" &&
                  key != "Duplicate Folder" &&
                  key !== IMAGE_LIMIT_REACHED &&
                  key != "File Invalid" &&
                  key != "File Not Found" &&
                  !key.includes("Size More Than") &&
                  !key.includes("Name invalid") && (
                    <button
                      onClick={() => {
                        retryUpload({});
                      }}
                      className="text-primary-green text-xs font-semibold underline"
                    >
                      Retry All
                    </button>
                  )}
              </li>
            );
          })}
        </ul>
        <ul className={ulClass}>
          {uploadFolders.map((item) => {
            //const {albumName, progress} = item
            return (
              <span key={item.id}>
                <li className="flex items-center justify-between shadow-dark-input h-[30px] mb-0.5 rounded-xl gap-3 px-4">
                  <div className="flex grow w-[20%]">
                    <span className="text-base pr-4 truncate" title={item.name}>
                      {item.name}
                    </span>
                  </div>
                  <div className="flex grow  w-[60%]">
                    <ProgressBar
                      percent={Math.round(
                        (item.fileToUpload.filter((f) => f.status === "Success")
                          .length /
                          item.fileToUpload.length) *
                          100
                      )}
                      size="small"
                    />
                  </div>
                  {/* <div className='flex grow gap-2 ml-4 justify-end w-[20%]'>
                                            <span><CircledPause /></span>
                                            <span><CircledClose /></span>
                                        </div> */}
                </li>
                {item.fileToUpload.map((val, ind) => {
                  return (
                    <li
                      key={val.name}
                      className="flex items-center justify-between h-[30px] mb-0.5 rounded-xl px-4"
                    >
                      <span
                        className={`text-sm ${
                          isBottom ? "max-w-[40%] truncate" : ""
                        }`}
                      >
                        {val.name}
                      </span>
                      <div>
                        {val.status === "Failed" ? (
                          <>
                            <span className="text-xs mr-4">{val.error}</span>
                            {val.error !== "Duplicate File" &&
                              val.error !== "Duplicate Folder" &&
                              val.error !== IMAGE_LIMIT_REACHED &&
                              val.error != "File Invalid" &&
                              val.error != "File Not Found" &&
                              !val.error.includes("Size More Than") &&
                              !val.error.includes("Name invalid") && (
                                <button
                                  onClick={() => {
                                    retryUpload(val);
                                  }}
                                  className="text-primary-green text-xs font-semibold underline"
                                >
                                  Retry
                                </button>
                              )}
                          </>
                        ) : val.status === "Success" ? (
                          <SuccessIcon />
                        ) : (
                          <span className="text-xs mr-4 animate-pulse">
                            Uploading
                          </span>
                        )}
                      </div>
                    </li>
                  );
                })}
              </span>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ImageQueue;
