import React, {useState, useEffect} from "react";
import Text from "../../../../components/common/Text";
import RadioButton from "../../../../components/common/RadioButton";
import Select from "../../../../components/common/Select";
import {InputFile, InputText} from "../../../../components/common/Input";
import {InputEditable} from "../../../../components/common/Input";
// import {onLogoFileChange} from "../../../../functions";
import {useUser} from "../../../../Store/User";
import DownArrow from "../../../../assets/icons/DownArrow";
import _ from "lodash"
import {updatePhotographerLogoTextAtt, updatePhotographerFavicon,deleteBrandLogo, updatePhotographerLogo} from "../../../../apis";
import CommonDropDown from "../../../../components/common/DropDown/CommonDropDown";
import ColorDropDown from "../../../../components/common/DropDown/ColorDropDown";

const LogoSection = (props) => {
    const {user, setUser} = useUser();
    const {
        className,
        fontColor,
        colorClicked,
        styleClicked,
        font,
        onChange,
        value = '',
        error,
        onLogoFileChange,
        fileName,
        logoType,
        setLogoType,
        isUploadLogoDisable
    } = props;
    const colors = ['Black', 'Blue', 'Brown', 'Green', 'Orange', 'Purple', 'Red', 'White', 'Yellow'];
    const styles = ['Arial', 'Helvetica'];
    const [selectedFont, setSelectedFont] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [logoText, setLogoText] = useState('');
    const [successStatus, setSuccessStatus] = useState({
        'logoText': false,
    });
    const [activeBrandField, setActiveBrandField] = useState();

    useEffect(() => {
        if (!_.isEmpty(user) &&  !_.isEmpty(user.photographer)) {
            setSelectedFont(user.photographer.logo_text_font);
            setSelectedColor(user.photographer.logo_text_colour);
            setLogoText(user.photographer.brand_name);
            setLogoType({...logoType, type: 'text'});
        }

        if (!_.isEmpty(user) && !_.isNull(user.photographer.logo_url)) {
            setLogoType({...logoType, type: 'file'});
        }


    }, [user]);

    const logoTextColors = [
        {
            'colorName': "Black",
            'colorCode': "#000000"
        },
        {
            'colorName': "Blue",
            'colorCode': "#0000FF"
        },
        {
            'colorName': "Brown",
            'colorCode': "#A52A2A"
        },
        {
            'colorName': "Green",
            'colorCode': "#008000"
        },
        {
            'colorName': "Orange",
            'colorCode': "#FFA500"
        },
        {
            'colorName': "Purple",
            'colorCode': "#800080"
        },
        {
            'colorName': "Red",
            'colorCode': "#FF0000"
        },
        {
            'colorName': "White",
            'colorCode': "#FFFFFF"
        },
        {
            'colorName': "Yellow",
            'colorCode': "#FFFF00"
        }

    ];


    const [colorFilterOptions, setColorFilterOptions] = useState(false);
    const [styleFilterOptions, setStyleFilterOptions] = useState(false);

    const colorToggle = (e) => {
        setColorFilterOptions(!colorFilterOptions);
    }
    const styleToggle = (e) => {
        setStyleFilterOptions(!styleFilterOptions);
    }

    const onFontChange = (e) => {
        setSelectedFont(e);
        updatePhotographerLogoTextAtt(logoText, e, selectedColor, null).then(response => {
            setUser({...user, access_token: response.access_token})
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });
    }

    const onColorChange = (e) => {
        setSelectedColor(e);
        updatePhotographerLogoTextAtt(logoText, selectedFont, e, null).then(response => {
            setUser({...user, access_token: response.access_token})
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });
    }

    const UpdateBrandText = (e) => {
        setSuccessStatus({...successStatus, logoText: false});
        updatePhotographerLogoTextAtt(e, selectedFont, selectedColor, null).then(response => {
            setUser({...user, access_token: response.access_token})
            setSuccessStatus({...successStatus, logoText: true});
            setLogoType({...logoType, type: 'text'})
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });
    }
    const deleteBrandIcon = () => {
        deleteBrandLogo().then(response => {
            setUser({...user, access_token: response.access_token})
            setLogoType({...logoType, type: 'text'})
            window.Toast.fire({
                icon: 'success',
                title: 'Brand logo deleted successfully'
            });
        }).catch(error => {
            window.Toast.fire({
                icon: 'error',
                title: 'Error updating your details'
            });
        });

    }

    return (
        <div className={`${className} mb-9`}>
            <Text as="p" className="mb-4 text-base font-medium text-secondary-300s">
                Brand
            </Text>
            <div className="flex flex-row gap-5 mb-9">
                <div className="flex flex-col w-48">
                    <RadioButton
                        width={'w-full'}
                        checked={logoType.type === 'text'}
                        handleOnClick={() => setLogoType({...logoType, type: 'text'})}
                        label="Brand Name"
                    />
                </div>

                <div className="flex flex-col w-48">
                    <RadioButton
                        width={'w-full'}
                        checked={logoType.type === 'file'}
                        handleOnClick={() => setLogoType({...logoType, type: 'file'})}
                        label="Brand Logo"
                    />
                </div>
            </div>

            {logoType.type === 'file' ? (
                <InputFile
                    className=""
                    label="Upload Brand icon"
                    deleteBtn={user.photographer.logo_url ? true : false}
                    overlapLabel="Upload"
                    onFileDelete={(()=>{deleteBrandIcon()})}
                    name='logo'
                    disabled={isUploadLogoDisable}
                    allowedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                    fileName={_.truncate(fileName) ?? null}
                    onFileChange={(e) => {
                        onLogoFileChange(e)
                    }}
                />
            ) : (
                <>
                    <Text as="p" className="mb-4 text-base font-medium text-secondary-300">
                        Brand Name
                    </Text>
                    <InputEditable
                        type="text"
                        onChange={(e) => setLogoText(e.target.value)}
                        value={logoText}
                        onUpdate={(value) => UpdateBrandText(value)}
                        required={true}
                        success={successStatus.logoText}
                    />


                    {_.isEmpty(error) ? '' : <label className="text-gray text-sm text-red-700">{error.logoText}</label>}
                    <div className="flex flex-row gap-x-5 mt-[40px]">
                        <div className="flex flex-col w-48">
                            <Text as="p" className="mb-4 font-medium text-secondary-300 text-base">
                                Font style
                            </Text>

                            <CommonDropDown items={styles} className={'w-full'} onSelect={onFontChange}
                                            dropDownFor="logo-font"/>

                        </div>
                        <div className="flex flex-col w-48">
                            <Text as="p" className="mb-4 font-medium text-secondary-300 text-base">
                                Font color
                            </Text>
                            <ColorDropDown items={logoTextColors} onSelect={onColorChange} className={'w-full'}
                                           dropDownFor="logo-text-color"/>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LogoSection;
