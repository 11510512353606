import React, { useCallback, useEffect, useState } from "react";
// import Text from '../../../
import Text from "../../../components/common/Text";
import Title from "../../../components/common/Title";
import { InputText } from "../../../components/common/Input";
import { useUser } from "../../../Store/User";
import Button from "../../../components/common/Button";
import { Navigate, useNavigate } from "react-router-dom";
// import {getUserDetails, updatePhotographerDomain} from "../../../../apis";
import { updatePhotographerDomain } from "../../../apis";
import _ from "lodash";
import Swal from "sweetalert2";

const AddSubDomain = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [isLogged, setIsLogged] = useState(
    _.isEmpty(user.access_token) ? false : true
  );
  // const [subDomain, setSubDomain] = useState(null);
  const [isSubDomainThere, setIsSubDomainThere] = useState(
    !(_.isEmpty(user.access_token) || _.isEmpty(user.photographer.subDomain))
  );
  const [buttonDisable, setButtonDisable] = useState(true);
  const { className } = props,
    [subDomain, setSubDomain] = useState(""),
    [errors, setErrors] = useState({});

  useEffect(() => {
    if (!_.isEmpty(subDomain)) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [subDomain]);

  function updateSubdomain() {
    if (subDomain === null || subDomain === "" || subDomain === " ") {
      setErrors({ ...errors, subDomain: "Sub Domain is required" });
      return;
    }
    if (subDomain) {
      const pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]+$/;
      if (!pattern.test(subDomain)) {
        setErrors({ ...errors, subDomain: "Invalid Sub Domain" });
        return;
      }
    }
    setButtonDisable(true);
    updatePhotographerDomain({ subDomain })
      .then(function (response) {
        window.Toast.fire({
          icon: "success",
          title: "Details updated successfully",
        });
        setUser({ ...user, access_token: response.access_token });
        setIsSubDomainThere(true);
        navigate("/");
      })
      .catch((error) => {
        window.Toast.fire({
          icon: "error",
          title: "Error updating your details",
        });
        setErrors({ ...errors, subDomain: error.response.data.message });
        setButtonDisable(true);
      });
  }

  useEffect(() => {
    if (user.photographer.subDomain) {
      setIsSubDomainThere(true);
    }
  }, [user.photographer.subDomain]);

  return (
    <>
      {!isLogged ? (
        <Navigate to="/login" />
      ) : isLogged && isSubDomainThere ? (
        <Navigate to="/" />
      ) : (
        <div className="flex justify-center items-center w-full align-middle min-h-[calc(100vh_-_80px)]">
          <div id="domain">
            <Title
              className="pb-4 border-gray border-b"
              text="Add Sub Domain to Proceed Further (Usually Business name)"
            />
            <div>
              <ul className="flex flex-row">
                <li className="flex flex-col w-full">
                  {/* <Text as="p" className='mb-4 flex justify-between'>
                                    Sub-Domain name
                                </Text> */}

                  <div className="mt-1 mx-auto w-[28rem]  mb-6">
                    <InputText
                      type="text"
                      className="bg-transparent p-2 outline-none w-full"
                      value={subDomain}
                      onChange={(e) => {
                        setSubDomain(e.toLowerCase());
                        setErrors({ ...errors, subDomain: null });
                      }}
                      error={errors.subDomain}
                      label="Sub-Domain"
                      onKeyDown={(e) => {
                        updateSubdomain();
                      }}
                      autoFocus={true}
                    />
                    <div>
                      <Text as="p" className="text-left">
                        Your preview gallery urls will start with <br />
                        <span className="text-primary-green underline pt-2">
                          https://
                          {subDomain
                            ? subDomain
                                .replace(/[^a-zA-Z0-9-]/g, "")
                                .toLowerCase()
                            : "your-brand-name"}
                          .algomage.com
                        </span>
                      </Text>
                    </div>

                    {/* <span
                                        onClick={(e) => {updateSubdomain()}}

                                        className="inline-block whitespace-nowrap underline cursor-pointer text-blue">Update</span> */}
                  </div>

                  <div className="flex justify-center py-5">
                    <Button
                      onClick={(e) => {
                        updateSubdomain();
                      }}
                      text="Add Sub-Domain"
                      disabled={buttonDisable}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSubDomain;
