/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import DownArrow from "../../../../assets/icons/DownArrow";
import { useUser } from "../../../../Store/User";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({ items, selectedOption }) {
  const { user } = useUser();

  const [selected, setSelected] = useState(
    user.photographer.country
      ?
          user.photographer.country
      : "Select Country"
  );

  const onOptionChange = (e) => {
    setSelected(e);
    selectedOption(e);
  };

  return (
    <Listbox
      value={selected}
      onChange={(e) => {
        onOptionChange(e);
      }}
    >
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className="flex relative w-full  items-center justify-between text-sm shadow-button rounded-lg p-2 px-3 whitespace-nowrap">
              <span className="flex items-center">
                <span className="block truncate">{selected}</span>
              </span>
              <span className="pl-2 cursor-pointer  ">
                {/* <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                <DownArrow
                  className=" h-3 w-3 ml-3 text-gray-400"
                  aria-hidden="true"
                  rotate={open ? true : false}
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-[0.625rem] w-full bg-dark-gray shadow-dark-input max-h-56 rounded-xl r-2 top-[95%] text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ">
                {items.map((tags) => (
                  <Listbox.Option
                    key={tags.code}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-secondary-300 bg-black"
                          : "text-secondary-300",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={tags.name}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center cursor-pointer">
                          {/* <img src={tags.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                          <span
                            className={`rounded-full w-3 h-3  mt-1  mb-2`}
                            style={{ backgroundColor: tags.colour }}
                          >
                            {" "}
                          </span>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {tags.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
