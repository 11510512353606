/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useState, useEffect} from 'react'
import {Listbox, Transition} from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import DownArrow from "../../../../assets/icons/DownArrow";
import _ from 'lodash';
import Button from "../../../../components/common/Button";
import {useUser} from "../../../../Store/User";
import {userLogout} from '../../../../apis'
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Example({sortEvents}) {
    const [selected, setSelected] = useState(null)
    const {user, setUser} = useUser();
    const [userDetails, setUserDetails] = useState({name: '', email: ''});
    const onOptionChange = (e) => {
        setSelected(e);
    }

    const logoutUser = async (e) => {
        e.preventDefault();
        // await userLogout();
        Cookies.remove("access_token" ,{ expires: 7 , secure: true , domain: process.env.REACT_APP_COOKIE_DOMAIN  })
        localStorage.removeItem('access_token');
        localStorage.removeItem('defaultView');
        window.location.reload();
    }

    const nameCharacter = (!_.isEmpty(user.user) && !_.isEmpty(user.user.lastName)) ? user.user.name.substring(0, 1) + user.user.lastName.substring(0, 1) : (!_.isEmpty(user.user) && _.isEmpty(user.user.lastName)) ? user.user.name.substring(0, 2) : '';

    const email = (!_.isEmpty(user.user)) ? user.user.email : '';

    return (
        <Listbox value={selected} onChange={(e) => {
            onOptionChange(e)
        }}>
            {({open}) => (
                <>
                    {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label> */}
                    <div className="relative">

                        <Listbox.Button className="flex items-center">
                            {/* <span className="flex items-center truncate">
                <span className="block">{selected}</span>
              </span> */}
                            <div className='flex flex-col justify-center rounded-xl bg-cyan-600
              w-[3.5rem] h-[2.5rem] uppercase'>
                                <span className='text-white text-center'>{nameCharacter}</span>
                            </div>
                            <span className="flex items-center pointer-events-none ml-3">
                <DownArrow className="h-3 w-3 text-gray-400" aria-hidden="true" rotate={open ? true : false}/>
              </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >

                            <Listbox.Options
                                className="flex flex-col items-center z-50 origin-top-right absolute right-0 mt-2 w-72 h-80 pt-10  rounded-xl bg-dark-gray shadow-dark-input rounded-xl text-base ring-1 ring-black ring-opacity-5 overflow-auto overflow-x-hidden focus:outline-none sm:text-sm">


                                <Listbox.Option className="">
                                    <div
                                        className="rounded-full bg-cyan-600 h-[7.5rem] w-[7.5rem] flex flex-col justify-center">
                                        <Link to={`/profile`}>
                                            <span className='text-3xl uppercase'>{nameCharacter}</span>
                                        </Link>
                                    </div>
                                </Listbox.Option>

                                <Listbox.Option className='text-center mt-3 truncate text-sm max-w-[75%]'>
                                    <Link to={`/profile`}> {email}</Link>
                                </Listbox.Option>

                                <Listbox.Option className='w-[10.75rem] mt-6'>
                                    <Link to={`/profile`}>
                                        <Button
                                            className='font-medium text-sm px-5 py-0 rounded-xl text-center font-medium hidden md:block lg:block'
                                            text='View Profile'
                                        />
                                    </Link>
                                </Listbox.Option>

                                <Listbox.Option className='flex justify-center '>
                                    <div onClick={(e) => {
                                        logoutUser(e)
                                    }} className="flex">
                                        <span
                                            className='text-sm mt-4 text-primary-green font-medium underline'>Logout</span>
                                    </div>
                                </Listbox.Option>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
