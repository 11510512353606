import React, { useCallback } from "react";
import { useFolder } from "../../../../../Store/Folder/Show";

const FilterOptions = ({setShowFilterOptions,setCurrentSubPage}) => {

    let {imageFilters,setImageFilters,setImages,images} = useFolder()
    const handleFilters = useCallback((filter)=>{
        if(filter !== imageFilters){
            setCurrentSubPage("Loading");
            setShowFilterOptions(false);
            setImages(null);
            setImageFilters(filter);
        }
    },[images])
    return (
        <div className='absolute light:bg-theme-light bg-dark-gray light:shadow-light-input shadow-dark-input w-[200px] right-[0px] top-[30px] rounded-xl z-40 mt-3 overflow-hidden'>
        {/* // <div className='absolute p-4 bg-theme-dark shadow-dark-input  w-[200px] right-[0px] top-[30px] z-10'> */}
            <div className='flex flex-col  cursor-pointer text-light-gray text-sm'>
                
                <div
                    onClick={()=>{handleFilters(null)}} 
                    className={`cursor-pointer px-4 py-3 hover:text-cyan-500 hover:bg-black  ${imageFilters===null?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Show All</div>
                <div
                    onClick={()=>{handleFilters('private')}} 
                    className={`cursor-pointer px-4 py-3 hover:text-cyan-500 hover:bg-black  ${imageFilters==='private'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Private Images</div>
                <div
                    onClick={()=>{handleFilters('public')}} 
                    className={`cursor-pointer px-4 py-3 hover:text-cyan-500 hover:bg-black  ${imageFilters==='public'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Public Images</div>
                <div
                    onClick={()=>{handleFilters('videos')}} 
                    className={`cursor-pointer px-4 py-3 hover:text-cyan-500 hover:bg-black  ${imageFilters==='videos'?'text-cyan-500 cursor-not-allowed hover:bg-transparent':''}`}>Videos</div>
            </div>
        </div>
    );
};

export default FilterOptions;
