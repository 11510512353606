import React, { useEffect, useState } from "react";
import "./selectedImage.css";
import VideoPlayIcon from "../../../../../../assets/icons/VideoPlayIcon";
import Iconslist from "./components/Iconslist";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Checkboxgen from "../../../../../../components/common/Checkboxgen";
import EyeSlashIcon from "../../../../../../assets/icons/EyeSlashIcon";
import { useFolder } from "../../../../../../Store/Folder/Show";
import { GUEST_UPLOAD_SLUG } from "../../../../../../constants";

const PlaceHolder = ({width,height}) => {
    return (
        <div className="flex flex-col justify-center items-center" style={{width,height}}>
            Loading...
        </div>
    )
}

const cont = {
    position: "relative"
  };

const SelectedImage = ({
    index,
    photo,
    margin,
    top,
    left,
    onClick,
    selectedImages,
    setSelectedImages,
    direction
}) => {
    const [isSelected, setIsSelected] = useState(selectedImages.includes(photo.id));
    const [isHovered, setIsHovered] = useState(false);

    const {folder , guests} = useFolder()

    const handleOnClick = e => {
        e.stopPropagation();
        setSelectedImages([photo.id]);
        if(isSelected){
            setSelectedImages(selectedImages.filter(i=>i!==photo.id));
        }else{
            setSelectedImages([...selectedImages,photo.id]);
        }
        setIsSelected(!isSelected);
    };

    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
      }else{
          cont.position = 'relative'
          cont.left = 0
          cont.right = 0
      }

    const handleOnHover = e => {
        setIsHovered(true);
    };

    const handleOnHoverLeave = e => {
        setIsHovered(false);
    };

    useEffect(()=>{
        if(selectedImages.includes(photo.id)) {
            setIsSelected(true)
        }
        else {
            setIsSelected(false)
        }
    },[selectedImages]);

    return (
        <div
            className='relative shadow-dark-input'
            onMouseEnter={handleOnHover}
            onMouseOver={handleOnHover}
            onMouseLeave={handleOnHoverLeave}
            data-fancybox='gallery'
            data-src={photo.src}
            style={{
                margin:margin,
                height: photo.height,
                width: photo.width,
                display: "flex",
                flexFlow: "row wrap",
                overflow:'hidden',
                border:isSelected?'solid 1px #00b7b8':'',
                borderRadius:10,
                ...cont
            }}
        >
            <div
                style={{
                    // margin:margin,
                    // height: photo.height,
                    // width: photo.width,
                   // display: "flex",
                    //flexFlow: "row wrap",
                    /*backgroundImage: `url('${photo.url}')`,
                    backgroundSize: "contain",*/
                    borderRadius: "10px",
                    filter: isSelected || isHovered ? "brightness(0.5)" : "brightness(1)",
                }}
                key={index}
                className={`${!isSelected ? "not-selected" : ""} shadow-image`}
            >
                <LazyLoadImage className="rounded-[10px]" height={photo.height} onClick={()=>{onClick(index)}} placeholder={<PlaceHolder width={photo.width} height={photo.height}/>} width={photo.width} src={photo.isVideo?photo.orginalImageUrl:photo.src} threshold={0}></LazyLoadImage>

                {!!photo.isVideo && !isHovered && (
                    <React.Fragment>
                        <div
                            className='flex w-full justify-between items-center absolute'
                            style={{
                                zIndex: "1",
                                padding: "5px 10px",
                            }}
                        >
                            <div className='video-tag'>Video</div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "33%",
                                left: "43%",
                                pointerEvents: "none",
                            }}
                        >
                            <VideoPlayIcon />
                        </div>
                    </React.Fragment>
                )}

                {(!!!photo.isVideo || isHovered) && (
                    <div
                        className='flex w-full justify-end	items-center absolute'
                        style={{
                            zIndex: "1",
                        }}
                    > </div>
                )}
            </div>
            {(isHovered || isSelected) && (
                <div
                    className='flex absolute top-0 left-0 z-3 w-[95%]'
                    style={{
                        margin,
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {(isHovered || isSelected) && (
                        <React.Fragment>
                            <div onClick={(e)=>e.preventDefault()}
                                className='absolute w-[90%] flex flex-wrap gap-y-3 justify-between top-2'
                            >
                                <Checkboxgen
                                    //className='w-6 h-6'
                                    //type='checkbox'
                                    checked={isSelected}
                                    onChange={handleOnClick}
                                    style={{background:"#00b7b8"}}
                                    //onChange={()=>{}}
                                    uncheckedColor = "light"
                                />
                                <Iconslist photo={photo} index={index}
                                    //onFavClickHandler={onFavClickHandler}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {/* {isHovered && !isSelected && <ActionsButton />}
                    {isSelected && <DeselectButton onClick={handleOnClick} />} */}
                </div>
            )}
            {(!isHovered && !isSelected && !!photo.isPrivate) && (
                <div
                className='flex absolute top-0 left-0 z-3 w-[95%]'
                style={{
                    margin,
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",

                }}>
                    <div onClick={(e)=>e.preventDefault()} className='absolute w-[90%] flex flex-wrap gap-y-3 justify-end top-2'>
                        <EyeSlashIcon color="#05b1b2" />
                    </div>
                </div>
            )}
            {isHovered || isSelected ? 
            <div className="absolute w-full bottom-1 right-1 left-1 text-xs">
            <div className=" truncate">{photo.originalImageName}</div>
            {(folder.slug === GUEST_UPLOAD_SLUG  && photo.guestId && guests[photo.guestId]) ? <div className=" mt-1 truncate">Uploaded by : {guests[photo.guestId]}</div> : <></>}
            </div>
            :''}
        </div>
    );
};

export default SelectedImage;
