import React from "react";

import { UploaderProvider } from "../Store/Uploader";
import CookiePopUp from "./common/CookiePopUp";
import Main from "./common/Main";
import ErrorBoundary from "../pages/ErrorBoundary";
import { useUser } from "../Store/User";
import Loading from "../pages/Loading";
import { HelpContextProvider } from "../Store/Help";

function App() {
  const { requestOnGoing } = useUser();

  return (
    <ErrorBoundary>
      <UploaderProvider>
        <HelpContextProvider>
          {requestOnGoing ? <Loading /> : <Main />}
          <CookiePopUp></CookiePopUp>
        </HelpContextProvider>
      </UploaderProvider>
    </ErrorBoundary>
  );
}

export default App;
