import React, { useEffect, useRef, useState } from "react";
import { Steps } from "intro.js-react";

import ProfileTitleSection from "./components/ProfileTitleSection";
import Domain from "./components/Domain";
import BusinessDetails from "./components/BusinessDetails";
import SocialLinks from "./components/SocialLinks";
import MobilePortfolio from "./components/MobilePortfolio";
import YourPlan from "./components/YourPlan";
import useOnScreen from "../../components/common/Sidebar/IntersectionObserver";
import { getCookie, setCookie, titleUpdate } from "../../functions";
import { useUser } from "../../Store/User";
import { useHelpContext } from "../../Store/Help";

const steps = [
  {
    element: "#custom-domain",
    title: "Domain",
    intro: "Set your custom domain here (Only for paid plan users).",
    position: "bottom",
  },
  {
    element: "#business-details",
    title: "Business Details",
    intro:
      "Highlight your business details on each gallery shared with the guests.",
    position: "bottom",
  },
  {
    element: "#social-links",
    title: "Social Links",
    intro: "Enter all your social links here.",
    position: "bottom",
  },
  {
    element: "#mobile-portfolio",
    title: "Mobile App",
    intro:
      "Get a dedicated app for you for an extra cost Rs. 19,999 per annum.",
    position: "bottom",
  },
  {
    element: "#plans",
    title: "Pricing Plans",
    intro: "Choose a plan that suites you the best.",
    position: "bottom",
  },
  // {
  //     element: '#terms',
  //     title: 'Terms and Conditions',
  //     intro: 'Show these terms & conditions on your event gallery.',
  //     position: 'bottom',
  // },
  // {
  //     element: '#privacy',
  //     title: 'Privacy Policy',
  //     intro: 'Show these privacy policy on your event gallery.',
  //     position: 'bottom',
  // },
];

const ViewProfile = () => {
  const [activeMenu, setActiveMenu] = useState("domain"),
    [showTour, setShowTour] = useState(false),
    domainRef = useRef(),
    isDomainVisible = useOnScreen(domainRef),
    businessRef = useRef(),
    isBusinessVisible = useOnScreen(businessRef),
    socialRef = useRef(),
    isSocialVisible = useOnScreen(socialRef),
    portfolioRef = useRef(),
    isPortfolioVisible = useOnScreen(portfolioRef),
    plansRef = useRef(),
    isPlansVisible = useOnScreen(plansRef);
  // termsRef = useRef(),
  // isTermsVisible = useOnScreen(termsRef),
  // privacyRef = useRef(),
  // isPrivacyVisible = useOnScreen(privacyRef);

  const { user } = useUser();
  const { showSteps, setShowSteps } = useHelpContext();

  useEffect(() => {
    titleUpdate("Your Profile");
    setTimeout(() => setShowTour(true), 3000);
  }, []);

  useEffect(() => {
    if (isDomainVisible) {
      setActiveMenu("domain");
    }
    if (isBusinessVisible && !isDomainVisible) {
      setActiveMenu("business-details");
    }
    if (isSocialVisible && !isBusinessVisible) {
      setActiveMenu("social-links");
    }
    if (isPortfolioVisible && !isSocialVisible) {
      setActiveMenu("mobile-portfolio");
    }
    if (isPlansVisible && !isPortfolioVisible) {
      setActiveMenu("plans");
    }
    // if (isTermsVisible && !isPlansVisible) {
    //     setActiveMenu('terms')
    // }
    // if (isPrivacyVisible && !isTermsVisible) {
    //     setActiveMenu('privacy')
    // }
  }, [isDomainVisible, isBusinessVisible, isSocialVisible, isPlansVisible]);

  // if you want to make terms and privacy section visible uncomment the below code
  // [isDomainVisible, isBusinessVisible, isSocialVisible, isPlansVisible,
  //     isTermsVisible, isPrivacyVisible]);

  const displaySteps =
    user.subscription.plan_type === "free"
      ? !getCookie("profilePage") || showSteps
      : showSteps;

  return (
    <div>
      <div>
        {displaySteps && showTour && (
          <Steps
            enabled={true}
            steps={steps}
            initialStep={0}
            onExit={(step) => {
              setCookie("profilePage", 1);
              setShowSteps(false);
            }}
            options={{
              doneLabel: "Got it",
            }}
          />
        )}
        <ProfileTitleSection />
        <div className="flex">
          <ul className="min-w-[20rem] mr-6 shadow-sidebar pl-10 pt-7 pr-6 h-screen sticky top-0 rounded-tr-3xl rounded-br-[10px]">
            {[
              {
                title: "Domain",
                id: "domain",
              },
              {
                title: "Business Details",
                id: "business-details",
              },
              {
                title: "Social Links",
                id: "social-links",
              },
              {
                title: "Mobile App",
                id: "mobile-portfolio",
              },
              {
                title: "Your Plan",
                id: "plans",
              },
              // {
              //     title: 'Terms and Conditions',
              //     id: 'terms'
              // },
              // {
              //     title: 'Privacy Policy',
              //     id: 'privacy'
              // }
            ].map(({ id, title }) => {
              return (
                <li
                  onClick={() => setActiveMenu(id)}
                  className={`h-[40px] mb-2 text-base text-light-gray pl-4 flex items-center w-full whitespace-nowrap hover:bg-black rounded-lg cursor-pointer
                            ${
                              activeMenu === id
                                ? "text-primary-green bg-black rounder-lg"
                                : "text-secondary-300"
                            }`}
                  key={id}
                >
                  <a className="w-full" href={`#${id}`}>
                    {title}
                  </a>
                </li>
              );
            })}
          </ul>

          <div className="flex flex-col pr-10 grow pb-44">
            <Domain setRef={domainRef} />
            <BusinessDetails setRef={businessRef} />
            <SocialLinks setRef={socialRef} />
            <MobilePortfolio setRef={portfolioRef} />
            <YourPlan setRef={plansRef} />
            {/*<EmailNotifications/>*/}
            {/* <TermsAndConditions setTermsRef={termsRef} setPrivacyRef={privacyRef}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
