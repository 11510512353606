import React from 'react';
import Button from "../../../components/common/Button";
import CloseIcon from "../../../assets/icons/CloseIcon";

const ViewersRoles = () => {
  return <div className='flex justify-center items-center lg:mx-20 text-slate-50 p-4 lg:p-10'>
      <div className='w-70 shadow-dark-input p-10'>
          <div className='flex justify-between items-center text-2xl font-normal'>
              <div className='items-center'>Viewer Roles</div>
              <div><CloseIcon fill="white" /></div>
        </div>
        <div className='text-base mt-2 border-b border-zinc-700 pb-6'>Assigning different viewer roles can decide the viewers’ access to the event</div>
        
        <div className='mt-6'>
            <div className='text-xl font-normal'>Admin</div>
            <div className='pl-5 mt-2 text-base'>
                <ul className='list-disc'>
                    <li>Publish an event</li>
                    <li>Invite members to the event, manage user roles, view member activity</li>
                    <li>Upload, hide, delete, download, favourite, share images and add them to cart</li>
                    <li>Overall settings</li>
                </ul>
            </div>
        </div>

        <div className='mt-6'>
            <div className='text-xl font-normal'>Co-admin</div>
            <div className='pl-5 mt-2 text-base'>
                <ul className='list-disc'>
                    <li>Upload (to guest folder), hide, delete, download, favourite, share images and add them to cart</li>
                </ul>
            </div>
        </div>

        <div className='mt-6'>
            <div className='text-xl font-normal'>Viewer</div>
            <div className='pl-5 mt-2 text-base'>
                <ul className='list-disc'>
                    <li>Download, favourite, share images and add them to cart</li>
                </ul>
            </div>
        </div>

        <div className='flex justify-center mt-10'><Button text="Done" /></div>
          
          
      </div>
  </div>;
};

export default ViewersRoles;
