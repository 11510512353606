import React, {useState, useRef, useEffect} from "react";
import DownArrow from "../../../assets/icons/DownArrow";
import Hamburger from "../../../assets/icons/HamburgerIcon";
import LogoAlgo from "../../../assets/images/algomagelogo.svg";
import Button from "../../../components/common/Button";
import LogoOptions from "../LogoOptions";
import LogoAlgoShare from '../../../assets/images/algosharelogo.svg';
import {Link, useNavigate} from "react-router-dom";
import './navbarAdmin.css';
import LogoDropDown from '../DropDown/LogoDropDown';

const NavbarAdmin = () => {
    const [showLogoOptions, setShowLogoOptions] = useState(false);
    const navigate = useNavigate()
    const [showHamburgerOptions, setShowHamburgerOptions] = useState(false);
    const favClickHandler = e => {
        setShowLogoOptions(!showLogoOptions);
        e.stopPropagation();
    };

    // below code closes the add tag options popup when clicked outside
    let hamburgerDropDownContainer = useRef();
    let logoDropDownContainer = useRef();

    // function to close the dropdown when clicked outside
    useEffect(() => {
        let handler = (event) => {
            if (!hamburgerDropDownContainer.current.contains(event.target)) {
                setShowHamburgerOptions(false);
            }
            if (logoDropDownContainer.current !== undefined) {
                if (!logoDropDownContainer.current.contains(event.target)) {
                    setShowLogoOptions(false);
                }
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)
        };
    });

    return (
        // <div className='bg-theme-light dark:bg-dark-gray'>
        //     <div className='navbar__text-dark text-dark-gray1 dark:text-light-gray p-[20px] shadow-2xl text-center'>
        <div className='bg-theme-dark'>
            <div className='navbar__text-dark py-5 text-light-gray  shadow-2xl text-center box-shadow-nav'>
                <div
                    className='flex flex-wrap px-5 md:px-10 lg:px-10 xl:px-10 2xl:px-10  justify-between items-center mx-auto'>
                    <div>
                        <div className='flex items-center text-gray-900 mr-1'>
                            <Link className="" to='/'>
                                <img
                                    src={LogoAlgo}
                                    alt='Logo'
                                    style={{height: '2.5rem'}}
                                    className='cursor-pointer'
                                />
                            </Link>

                            <span className='border-neutral-600 border-l-2 mx-[1.875rem]'>
                                &nbsp;
                            </span>

                            <span className='font-medium'>
                            <img
                                src={LogoAlgoShare}
                                width='40'
                                height='20'
                                alt='Logo'
                                // className='cursor-pointer'
                            />
                            </span>

                            {/* <div className='relative flex ' ref={logoDropDownContainer} >
                                <span
                                    className='font-medium ml-3 cursor-pointer'
                                    onClick={favClickHandler}
                                >
                                    <DownArrow
                                        rotate={showLogoOptions}
                                    ></DownArrow>
                                </span>

                                {showLogoOptions && <LogoOptions />}
                            </div> */}
                        </div>
                    </div>

                    <div className='flex gap-x-10 items-center md:order-2'>
                        <Button
                            className='font-medium text-sm text-center hidden md:block lg:block'
                            onClick={() => {
                                window.location.assign(`//${process.env.REACT_APP_CORP_WEBSITE_URL}/algoshare#pricing`)
                            }}
                            text='View Plans'
                        />
                        {/* <button
                            type='button'
                            className='font-medium text-base text-center hidden md:block lg:block'

                        >
                            Help
                        </button> */}

                        <Link className="hidden md:block lg:block" to='/login'>
                            <button
                                type='button'
                                className='font-medium text-base text-center hidden md:block lg:block'

                            >
                                Log In
                            </button>
                        </Link>
                        <button
                            data-collapse-toggle='mobile-menu-4'
                            type='button'
                            className='inline-flex relative items-center p-2 text-sm md:hidden'
                            aria-controls='mobile-menu-4'
                            aria-expanded='false'
                            ref={hamburgerDropDownContainer}
                            onClick={() => {
                                setShowHamburgerOptions(!showHamburgerOptions);
                            }}

                        >
                            <span className='sr-only'>Open main menu</span>
                            <Hamburger></Hamburger>
                        </button>
                        {
                            showHamburgerOptions && (
                                <div
                                    className='absolute p-4 light:bg-theme-light bg-dark-gray light:shadow-light-input shadow-dark-input w-[200px] right-[0px] top-[30px] rounded-xl z-40 mt-7'>
                                    <div className='flex flex-col gap-3 cursor-pointer text-light-gray text-sm'>
                                        {/* <Link  > */}
                                        <div
                                            // onClick={()=>{handleFilters(null)}}
                                            className={`cursor-pointer hover:text-cyan-500 `}>View Plans
                                        </div>
                                        {/* </Link> */}

                                        {/* <Link> */}
                                        <div
                                            // onClick={()=>{handleFilters('private')}}
                                            className={`cursor-pointer hover:text-cyan-500 `}>Help
                                        </div>
                                        {/* </Link> */}

                                        <Link to='/login'>
                                            <div
                                                // onClick={()=>{handleFilters('public')}}
                                                className={`cursor-pointer hover:text-cyan-500 `}>Login
                                            </div>
                                        </Link>
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavbarAdmin;
