import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { getDownlaodFolder } from "../../../../../../apis";
import Button from "../../../../../../components/common/Button";

function DownloadNow() {
  const params = useParams();
  const [isDownload, setDownload] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [downloadLink, setDownloadLink] = useState([]);
  const redownloadrequest =
    `${process.env.REACT_APP_API_ENDPOINT}redownloadrequest?downid=` +
    params.downloadId;

  useEffect(() => {
    let intitialRequest = 0;
    if (!downloadLink.length) {
      const intervalId = setInterval(async () => {
        intitialRequest = intitialRequest + 1;
        let res = await getDownlaodFolder(params.downloadId, intitialRequest);
        if (!res.error) {
          setResponseText("Request Processing!!");
          if (res.data.status === "zip_deleted") {
            clearInterval(intervalId);
            setDownload("zip_deleted");
          } else if (res.data.urls.length > 0) {
            setDownload(true);
            setDownloadLink(res.data.urls);
          }
        }
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [downloadLink]);

  return (
    <div className="dark:text-white min-h-screen">
      <div className="container mt-14 mx-auto max-w-screen-lg xl:max-w-screen-xl xl:px-10 px-10 ">
        <div className="col-md-10 mx-auto mt-5">
          <h1 className="text-center sm:text-4xl text-2xl">
            {/* <a href={user.photographer.website} className="login-logodownload pt-3">{  user.photographer.brandName ? user.photographer.brandName :"Algomage" }</a> */}
            Algomage
          </h1>
          <div className="text-center">
            {!isDownload ? (
              <>
                <span id="text1">
                  You will be notified via email once your download is ready.
                  <br />
                  You can also wait on this page{" "}
                </span>
                <h3 className="text-center mt-3 text-dark">{responseText}</h3>
              </>
            ) : isDownload === "zip_deleted" ? (
              <>
                <span id="text1">
                  This download link has expired.
                  <br />
                  Please create a new download request.
                </span>
                <br />
              </>
            ) : (
              downloadLink.length > 0 &&
              downloadLink.map((link, i) => (
                <a href={link} key={i} id="yaser">
                  <button
                    className={`rounded-[10px] shadow-button shadow-dark-input-bottom-right dark:shadow-dark-input py-[9px] px-[16px] dark:text-slate-100 font-semibold font-semibold text-base w-56 flex mx-auto mt-6 justify-center`}
                  >
                    Download Link {i + 1}
                  </button>
                </a>
              ))
            )}
          </div>
          <Link to={`/view/`}>
            <Button className="btn btn-blue w-auto mt-3">Back to Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DownloadNow;
